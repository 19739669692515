const TruckIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1, -0.1)">
        <path
          d="M185 3858 c-3 -7 -4 -299 -3 -648 l3 -635 1510 0 1510 0 3 648 2 647
-1510 0 c-1199 0 -1512 -3 -1515 -12z"
        />
        <path
          d="M3422 2573 l3 -968 108 -3 107 -3 0 30 c0 173 134 375 301 454 219
104 450 65 620 -104 94 -93 159 -237 159 -350 0 -29 1 -30 33 -24 75 17 125
54 160 119 20 39 22 56 25 321 4 306 -3 378 -42 433 -13 19 -102 93 -197 166
-120 91 -178 142 -190 166 -10 19 -45 129 -79 243 -33 114 -72 231 -86 259
-37 74 -121 153 -201 191 l-68 32 -328 3 -327 3 2 -968z m609 746 c50 -17 97
-55 118 -97 10 -20 42 -118 70 -217 28 -99 54 -188 57 -197 5 -17 -15 -18
-315 -18 l-321 0 0 270 0 270 179 0 c106 0 192 -5 212 -11z"
        />
        <path
          d="M185 2347 c-3 -6 -4 -176 -3 -377 l3 -365 163 -3 162 -2 0 38 c0 21
7 64 15 95 49 188 204 343 391 391 305 79 618 -132 666 -446 l12 -78 808 0
808 0 -2 378 -3 377 -1508 3 c-1204 2 -1509 0 -1512 -11z"
        />
        <path
          d="M950 1965 c-228 -64 -341 -313 -239 -529 85 -182 320 -262 503 -172
67 33 140 106 174 176 24 49 27 66 27 160 0 93 -3 112 -27 162 -75 160 -269
250 -438 203z"
        />
        <path
          d="M4090 1966 c-150 -41 -250 -150 -280 -303 -48 -245 178 -473 427
-433 207 34 352 234 313 432 -43 221 -254 361 -460 304z"
        />
      </g>
    </svg>
  );
};

export default TruckIcon;
