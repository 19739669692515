// Styles
import { useStyles } from "@/common/lib/style/hooks";

// Components
import { Theme, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import { Image, SNSIcon } from "@/common/components";
// Libs
import { isNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
    width: "fit-content",
    margin: "0 10px",
  },
  image: {
    position: "relative",
    width: 170,
    height: 170,
  },
  userIcon: {
    width: "100%",
    height: "100%",
    color: theme.palette.logo.darkBlue,
  },
  userImage: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  whatsapp: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  name: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  position: {
    fontSize: "0.95rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  phone: {
    marginBottom: 3,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  language: {
    display: "flex",
    marginBottom: 10,
    "& > p": {
      display: "flex",
      alignItems: "center",
      margin: "0 5px",
      "& > svg": {
        fontSize: "1.15rem",
        color: theme.palette.primary.main,
        marginRight: 3,
      },
      "& > span": {
        fontSize: "0.95rem",
        color: theme.palette.primary.main,
      },
    },
  },
});

const PersonInfo = ({
  imgSrc = undefined,
  name,
  position,
  phone,
  whatsApp,
  langList,
}: any) => {
  const classes = useStyles(styles);

  const handleOnClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className={classes.container}>
      <div className={classes.image}>
        {isNull(imgSrc) ? (
          <AccountCircleIcon className={classes.userIcon} />
        ) : (
          <Image alt="" src={imgSrc} className={classes.userImage} />
        )}
        <SNSIcon className={classes.whatsapp} sns="whatsApp" url={whatsApp} />
      </div>
      <Typography className={classes.name}>{name}</Typography>
      <Typography className={classes.position}>{position}</Typography>
      <Typography
        className={classes.phone}
        onClick={() => handleOnClick(whatsApp)}
      >
        {phone}
      </Typography>
      <div className={classes.language}>
        {langList.map((language: string, idx: number) => {
          return (
            <Typography key={idx}>
              <CheckIcon />
              <span>{language}</span>
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default PersonInfo;
