/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setErrorText,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import { Card, Theme } from "@mui/material";
import { Button, Checkbox, Image } from "@/common/components";
import UploadFileSharpIcon from "@mui/icons-material/UploadFileSharp";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { resizeImage } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  dropzone: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 700,
    padding: 8,
    margin: "20px auto",
    border: "1px solid " + theme.palette.divider,
    borderRadius: 5,
    overflow: "auto",
  },
  dragzone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "60%",
    aspectRatio: "2.5",
    background: theme.palette.primary.light,
    borderRadius: 10,
    padding: 10,
    margin: "auto",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      border: "2px dashed white",
      borderRadius: 10,
      color: "white",
      "& > svg": {
        fontSize: "2.5rem",
        marginBottom: 5,
      },
      "& > span": {
        fontSize: "0.925rem",
        fontWeight: 500,
      },
    },
  },
  dndContainer: {
    height: "100%",
  },
  dndItem: {
    padding: 8,
    "& .content": {
      display: "flex",
      flexDirection: "column",
      "& > img": {
        width: "100%",
        aspectRatio: 1.33,
        WebkitUserDrag: "none",
      },
      "& > span": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "0.75rem",
        padding: "1.5px 5%",
      },
      "& > span.fileName": {
        color: "white",
        background: theme.palette.primary.dark,
      },
    },
    "& .MuiCheckbox-root": {
      position: "absolute !important",
      bottom: 0,
      right: 0,
      "& > svg": {
        fontSize: "1.25rem",
      },
    },
  },
  buttonDiv: {
    marginBottom: 10,
    "& > button": {
      fontSize: "0.975rem",
    },
    "& > button:first-child": {
      marginRight: 5,
    },
    "& > button:last-child": {
      marginLeft: 5,
    },
    "& > button:not(:first-child):not(:last-child)": {
      margin: "0 5px",
    },
  },
  comment: {
    margin: 0,
    paddingLeft: 20,
    listStyle: "square",
    "& > li": {
      fontSize: "0.875rem",
      fontWeight: 500,
      "&.warning": {
        color: theme.palette.error.main,
      },
    },
  },
});

const Dropzone = ({
  accept = { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
  maxSize = 10485760,
  noClick = "true",
  value,
  dispatch,
  ...props
}: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const _dispatch = useTypedDispatch();

  const [checkedList, setCheckedList] = useState<boolean[]>([]);

  const onChange = (
    _sourceId: string,
    sourceIndex: number,
    targetIndex: number,
    _targetId: string | undefined
  ) => {
    const swapValue = swap(value, sourceIndex, targetIndex);
    dispatch(swapValue);

    const swapCheckedList = swap(checkedList, sourceIndex, targetIndex);
    setCheckedList(swapCheckedList);
  };
  const onDrop = async (files: File[]) => {
    const addResizeImage = async (files: File[]) => {
      if (value.length + files.length > 60) {
        files.splice(60 - value.length > 0 ? 60 - value.length : 0);
      }
      // 업로드한 파일과 기존 파일 내 동일한 이름이 있는지 검사
      let isError = false;
      for (const file of files) {
        if (isError) {
          break;
        }
        for (const item of value) {
          if (isError) {
            break;
          }
          if (item.name.split(".")[0] === file.name.split(".")[0]) {
            _dispatch(
              setErrorText({
                title: t("DIALOG.TITLE.SERVER_NOTICE"),
                content: t("DIALOG.CONTENT.EXIST_FILE"),
              })
            );
            isError = true;
          }
        }
      }
      if (!isError) {
        await Promise.all(
          files.map((file: File) => {
            return resizeImage(file, 1280, 960, 75, 0, "file");
          })
        ).then((resizeFiles) => {
          dispatch(value.concat(resizeFiles));
          setCheckedList(
            checkedList.concat(
              [...Array(resizeFiles.length).keys()].map(() => {
                return false;
              })
            )
          );
        });
      }
    };
    _dispatch(loadingOn());
    await addResizeImage(files);
    _dispatch(loadingOff());
  };

  const handleCheckbox = (index: number, value: boolean) => {
    const newData = checkedList.slice();
    newData.splice(index, 1);
    newData.splice(index, 0, value);
    setCheckedList(newData);
  };
  const handleCheckCancel = () => {
    _dispatch(
      setConfirmText({
        title: t("DIALOG.TITLE.CONFIRM_DELETE_IMAGE"),
        content: "",
        onApply: () => {
          const newValue = value.slice();
          checkedList.forEach((checked: boolean, idx: number) => {
            if (checked) {
              newValue.splice(idx, 1);
              newValue.splice(idx, 0, undefined);
            }
          });
          dispatch(newValue.filter((item: any) => item !== undefined));
          setCheckedList(checkedList.filter((item: any) => !item));
        },
        onClose: () => {},
      })
    );
  };
  const handleAllCancel = () => {
    _dispatch(
      setConfirmText({
        title: t("DIALOG.TITLE.CONFIRM_DELETE_ALL_IMAGE"),
        content: "",
        onApply: () => {
          dispatch([]);
          setCheckedList([]);
        },
        onClose: () => {},
      })
    );
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: accept,
    maxSize: maxSize,
    noClick: noClick,
    onDrop: onDrop,
    maxFiles: 60,
  });

  useEffect(() => {
    setCheckedList(
      checkedList.concat(
        [...Array(value.length).keys()].map(() => {
          return false;
        })
      )
    );
  }, []);

  return (
    <>
      <div
        className={
          props.className !== undefined
            ? `${props.className} ${classes.dropzone}`
            : `${classes.dropzone}`
        }
        {...getRootProps()}
        onDoubleClick={open}
      >
        <input {...getInputProps()} />
        {value.length === 0 ? (
          <div className={classes.dragzone}>
            <div>
              <UploadFileSharpIcon />
              <span>{t("DROPZONE.DOUBLE_CLICK_HERE")}</span>
            </div>
          </div>
        ) : (
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="dropzone"
              className={classes.dndContainer}
              boxesPerRow={7}
              rowHeight={135}
            >
              {value.map((file: File, idx: number) => {
                return (
                  <GridItem
                    key={file.name}
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={classes.dndItem}
                  >
                    <Card>
                      <div className="content">
                        <Image src={file} />
                        <span className="fileName">{file.name}</span>
                        <span className="fileSize">
                          {(file.size / 1024).toFixed(2)} KB
                        </span>
                      </div>
                      <Checkbox
                        id={"image-checkbox-" + idx}
                        checked={checkedList[idx]}
                        dispatch={(value: boolean) =>
                          handleCheckbox(idx, value)
                        }
                      />
                    </Card>
                  </GridItem>
                );
              })}
            </GridDropZone>
          </GridContextProvider>
        )}
      </div>
      <div className={classes.buttonDiv}>
        <Button onClick={open}>{t("DROPZONE.ADD_FILE")}</Button>
        <Button onClick={handleCheckCancel}>{t("DROPZONE.DELETE_FILE")}</Button>
        <Button onClick={handleAllCancel}>
          {t("DROPZONE.DELETE_ALL_FILE")}
        </Button>
      </div>
      <ul className={classes.comment}>
        <li>{t("DROPZONE.MAX_FILE")}</li>
        <li>{t("DROPZONE.AVAILABLE_FILE")}</li>
        <li>{t("DROPZONE.MAX_SIZE")}</li>
        <li className="warning">{t("DROPZONE.WARNING")}</li>
      </ul>
    </>
  );
};

export default Dropzone;
