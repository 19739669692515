/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { useSnackbar } from "notistack";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import ListDataTable from "./ListDataTable";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button, Image, Input } from "@/common/components";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// Libs
import i18n from "@/common/lib/lang/i18n";
import {
  isNull,
  isNotNull,
  openNewTab,
  getDateFormat,
  getTokenData,
  getUserTableData,
  validatePassword,
} from "@/common/lib/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { patchUserInfo } from "@/common/lib/api/user";

interface propType {
  data: UserData[];
  setData: Dispatch<SetStateAction<UserData[]>>;
  page: number;
  pageSize: number;
  totalCount: number;
  params: { [key: string]: any };
  setParams: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

interface DataType {
  photo: File | string;
  name: string;
  username: string;
  puid: string;
  email: string;
  phone: string;
  nationality: string;
  address1: string | undefined;
  address2: string | undefined;
  address3: string | undefined;
  state: string | undefined;
  city: string | undefined;
  country: string | undefined;
  zip_code: string | undefined;
  created: Date | null | undefined;
  expired: Date | null | undefined;
  status: string;
}

const styles = (theme: Theme) => ({
  pointer: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: 400,
    padding: 36,
    borderRadius: 10,
    "& > h1": {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 5,
    },
    "& > h2": {
      fontSize: 15,
      fontWeight: "bold",
      marginBottom: 5,
    },
    "& > h3": {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 5,
    },
    "& > ul": {
      margin: 0,
      marginBottom: 10,
      paddingLeft: 20,
      userSelect: "none",
      "& > li": {
        fontSize: 14,
        "&::marker": {
          fontSize: 12,
        },
        "&.bad": {
          color: theme.palette.error.main,
          fontWeight: "bold",
          "&:not(.special)::after": {
            content: "'X'",
            padding: "0 3px",
          },
        },
        "&.good": {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          "&:not(.special)::after": {
            content: "'O'",
            padding: "0 3px",
          },
        },
        "&.special": {
          listStyleType: "none",
        },
      },
    },
    "& > div": {
      marginBottom: 15,
      fontSize: 15,
      padding: "0 10px",
    },
    "& > hr": {
      borderStyle: "dotted",
      borderColor: "rgba(0, 0, 0, 0.38)",
      margin: "5px 0 15px",
    },
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 550,
      minWidth: "unset",
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      paddingTop: 20,
      "& > table": {
        width: 450,
        margin: "5px auto",
        tableLayout: "fixed",
        "& th": {
          userSelect: "none",
        },
        "& td": {
          textAlign: "center",
          userSelect: "none",
          "& > .MuiTextField-root": {
            padding: "0 5px",
            fontSize: "1rem",
            "& .MuiInputAdornment-root": {
              padding: 0,
              "& > p": {
                lineHeight: "unset",
                fontWeight: 500,
                color: theme.palette.text.primary,
              },
            },
            "& .MuiInputBase-input": {
              fontWeight: 500,
              padding: "0 7.5px",
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "1.85rem",
            },
          },
          "& > span": {
            margin: 0,
            fontWeight: 500,
          },
        },
      },
      "& > .MuiTypography-root": {
        marginTop: 5,
        marginBottom: 10,
        fontSize: "0.875rem",
        color: theme.palette.error.main,
        textAlign: "center",
        userSelect: "none",
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
});

const NameCell = (
  info: any,
  data: UserData[],
  setData: Dispatch<SetStateAction<UserData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(info.row.original["name"]);
  const [editable, setEditable] = useState<boolean>(false);

  const patchUserMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_USER_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: UserData) => {
        if (info.row.original["id"] === item.id) {
          item.name = value;
        }
      });
      setData(data_Copy);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const handleOnApply = () => {
    if (editable) {
      if (value === info.row.original["name"]) {
        enqueueSnackbar(t("MANAGE.MSG.EQUAL_USER_NAME"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      patchUserMutation.mutate({
        puid: info.row.original["puid"],
        data: {
          name: value,
        },
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setEditable(false);
      setValue(info.row.original["name"]);
    }
  };

  return (
    <>
      <div className={classes.pointer} onDoubleClick={() => setEditable(true)}>
        {info.row.original["name"]}
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.dialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_USER_NAME")}</DialogTitle>
        <DialogContent>
          <table>
            <thead>
              <tr>
                <th style={{ width: 200 }}>
                  {t("MANAGE.TABLE.CURRENT_USER_NAME")}
                </th>
                <th></th>
                <th style={{ width: 200 }}>
                  {t("MANAGE.TABLE.MODIFY_USER_NAME")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 200 }}>{info.row.original["name"]}</td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td style={{ width: 200 }}>
                  <Input
                    variant="standard"
                    value={value}
                    dispatch={setValue}
                    inputProps={{
                      maxLength: 32,
                    }}
                    fullWidth
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Typography>{t("MANAGE.MSG.NOTICE_MODIFY")}</Typography>
          <div className="actions">
            <Button variant="contained" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.MODIFY")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const UserNameCell = (
  info: any,
  data: UserData[],
  setData: Dispatch<SetStateAction<UserData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(info.row.original["username"]);
  const [editable, setEditable] = useState<boolean>(false);

  const patchUserMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_USER_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: UserData) => {
        if (info.row.original["id"] === item.id) {
          item.username = value;
        }
      });
      setData(data_Copy);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const handleOnApply = () => {
    if (editable) {
      if (value === info.row.original["username"]) {
        enqueueSnackbar(t("MANAGE.MSG.EQUAL_USER_NAME"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      patchUserMutation.mutate({
        puid: info.row.original["puid"],
        data: {
          username: value,
        },
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setEditable(false);
      setValue(info.row.original["username"]);
    }
  };

  return (
    <>
      <div className={classes.pointer} onDoubleClick={() => setEditable(true)}>
        {info.row.original["username"]}
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.dialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_ID")}</DialogTitle>
        <DialogContent>
          <table>
            <thead>
              <tr>
                <th style={{ width: 200 }}>{t("MANAGE.TABLE.CURRENT_ID")}</th>
                <th></th>
                <th style={{ width: 200 }}>{t("MANAGE.TABLE.MODIFY_ID")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 200 }}>{info.row.original["username"]}</td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td style={{ width: 200 }}>
                  <Input
                    variant="standard"
                    value={value}
                    dispatch={setValue}
                    inputProps={{
                      maxLength: 32,
                    }}
                    fullWidth
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Typography>{t("MANAGE.MSG.NOTICE_MODIFY")}</Typography>
          <div className="actions">
            <Button variant="contained" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.MODIFY")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const PasswordCell = (info: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [editable, setEditable] = useState<boolean>(false);

  const passwordMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_CHANGE_PASSWORD"), {
        variant: "success",
      });
      setEditable(false);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
      dispatch(loadingOff());
    },
  });

  const getClassName = (code: number) => {
    let result = "";

    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        if (errorCode & code) {
          return "bad";
        } else {
          return "good";
        }
      }
    }
    return result;
  };
  const handlePasswordOnBlur = () => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
  };
  const handlePasswordOnClick = () => {
    if (isNull(password)) {
      enqueueSnackbar(t("USER.MSG.INPUT_CURRENT_PASSWORD"), {
        variant: "warning",
      });
      return;
    }
    if (isNull(newPassword)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD"), { variant: "warning" });
      return;
    }
    if (isNull(newPasswordConfirm)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD_CONFIRM"), {
        variant: "warning",
      });
      return;
    }
    if (error || errorCode !== 0) {
      enqueueSnackbar(t("USER.MSG.NOT_VALID_PASSWORD"), { variant: "error" });
      return;
    }

    if (info.row.original["puid"] !== undefined) {
      dispatch(loadingOn());
      passwordMutation.mutate({
        puid: info.row.original["puid"],
        data: {
          password: password,
          newPassword: newPassword,
        },
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setEditable(false);
      setPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
    }
  };

  useEffect(() => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setErrorCode(validatePassword(newPassword));
      } else {
        setErrorCode(0);
      }
    } else {
      setErrorCode(0);
    }
  }, [newPassword, newPasswordConfirm]);

  return (
    <>
      <div className={classes.pointer} onDoubleClick={() => setEditable(true)}>
        {t("USER.PASSWORD_CHANGE")}
      </div>
      <Modal className={classes.modal} open={editable} disableAutoFocus={true}>
        <Paper classes={{ root: classes.paper }} square={true}>
          <Typography component="h1">{t("USER.PASSWORD_CHANGE")}</Typography>
          <Typography component="h2">
            {t("USER.PASSWORD_CONDITION.TITLE")}
          </Typography>
          <ul>
            <li className={getClassName(1)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_1")}
            </li>
            <li className={getClassName(2)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_2")}
            </li>
            <li className={getClassName(4)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_3")}
            </li>
            <li className={getClassName(8)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_4")}
            </li>
            <li className={`${getClassName(8)} special`}>
              `~!@#$%^&*()-_=+{}[]:;'"
            </li>
          </ul>
          <Divider />
          <Typography component="h3">
            {t("USER.TARGET_USER", { user: info.row.original["username"] })}
          </Typography>
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.CURRENT_PASSWORD")}
            value={password}
            dispatch={setPassword}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD")}
            error={error || errorCode !== 0}
            value={newPassword}
            dispatch={setNewPassword}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD_CONFIRM")}
            error={error || errorCode !== 0}
            helperText={error ? t("USER.MSG.PASSWORD_ERROR") : ""}
            value={newPasswordConfirm}
            dispatch={setNewPasswordConfirm}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Divider />
          <Button variant="contained" onClick={() => handlePasswordOnClick()}>
            {t("BUTTON.MODIFY")}
          </Button>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setNewPassword("");
              setNewPasswordConfirm("");
              setError(false);
              setErrorCode(0);
              setEditable(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Paper>
      </Modal>
    </>
  );
};

const IndeterminateCheckbox = ({
  indeterminate,
  className,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      name="tbl_cbx"
      type="checkbox"
      ref={ref}
      className={className !== undefined ? className : undefined}
      {...rest}
    />
  );
};

const ListDataContainer = ({
  data,
  setData,
  page,
  pageSize,
  totalCount,
  params,
  setParams,
}: propType) => {
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const [tableData, setTableData] = useState<DataType[]>([]);

  const columns: ColumnDef<DataType>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "photo",
      header: () => <span>{t("MANAGE.COLUMN.PHOTO")}</span>,
      cell: (info: any) => (
        <Image
          src={info.getValue()}
          style={{ display: "block", width: "100%" }}
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.USER_NAME")}</span>
        </Tooltip>
      ),
      cell: (info: any) => NameCell(info, data, setData),
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "username",
      header: () => {
        return tokenData["is_superuser"] ? (
          <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
            <span>{t("MANAGE.COLUMN.USER_ID")}</span>
          </Tooltip>
        ) : (
          t("MANAGE.COLUMN.USER_ID")
        );
      },
      cell: (info: any) => {
        return tokenData["is_superuser"]
          ? UserNameCell(info, data, setData)
          : info.getValue();
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "puid",
      header: () => <span>{t("MANAGE.COLUMN.PUID")}</span>,
      cell: (info: any) => {
        const handleCopyOnClick = () => {
          navigator.clipboard.writeText(info.getValue()).then(
            () => {
              enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
            },
            () => {
              enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
            }
          );
        };
        return (
          <span onClick={() => handleCopyOnClick()}>{info.getValue()}</span>
        );
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      id: "password",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.PASSWORD")}</span>
        </Tooltip>
      ),
      cell: (info: any) => PasswordCell(info),
      enableSorting: false,
    },
    {
      accessorKey: "email",
      header: () => <span>{t("MANAGE.COLUMN.EMAIL")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "phone",
      header: () => <span>{t("MANAGE.COLUMN.PHONE")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "nationality",
      header: () => <span>{t("MANAGE.COLUMN.POSITION")}</span>,
      cell: (info: any) => {
        const getRegionNames = (code: string) => {
          if (code === "S0") {
            return t("USER.POSITION.SUPER_USER");
          } else if (code === "S1") {
            return t("USER.POSITION.MANAGER");
          } else if (code === "S2") {
            return t("USER.POSITION.STAFF");
          } else {
            return new Intl.DisplayNames([i18n.language], {
              type: "region",
            }).of(code);
          }
        };
        return getRegionNames(info.row.original["nationality"]);
      },
      enableMultiSort: true,
    },
    {
      accessorKey: "address1",
      header: () => <span>{t("MANAGE.COLUMN.ADDRESS1")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "address2",
      header: () => <span>{t("MANAGE.COLUMN.ADDRESS2")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "address3",
      header: () => <span>{t("MANAGE.COLUMN.ADDRESS3")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "state",
      header: () => <span>{t("MANAGE.COLUMN.STATE")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "city",
      header: () => <span>{t("MANAGE.COLUMN.CITY")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "country",
      header: () => <span>{t("MANAGE.COLUMN.COUNTRY")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "zip_code",
      header: () => <span>{t("MANAGE.COLUMN.ZIP_CODE")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "created",
      header: () => <span>{t("MANAGE.COLUMN.CREATED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "expired",
      header: () => <span>{t("MANAGE.COLUMN.EXPIRED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "status",
      header: () => <span>{t("MANAGE.COLUMN.STATUS")}</span>,
      enableMultiSort: true,
    },
    {
      id: "modify",
      header: () => <span>{t("MANAGE.COLUMN.MODIFY")}</span>,
      cell: (info: any) => {
        let updateLink = "";
        if (info.row.original["nationality"] === "S0") {
          updateLink = "user/update/" + info.row.original["puid"];
        } else if (info.row.original["nationality"] === "S1") {
          updateLink = "manager/update/" + info.row.original["puid"];
        } else if (info.row.original["nationality"] === "S2") {
          updateLink = "staff/update/" + info.row.original["puid"];
        } else {
          updateLink = "user/update/" + info.row.original["puid"];
        }

        return (
          <>
            <Button
              variant="outlined"
              onClick={() => openNewTab("MANAGEMENT", updateLink)}
            >
              Edit
            </Button>
          </>
        );
      },
      enableSorting: false,
    },
  ];

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  useEffect(() => {
    setTableData(getUserTableData(data));
  }, [data]);

  return (
    <ListDataTable
      data={tableData}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      params={params}
      setParams={setParams}
    />
  );
};

export default ListDataContainer;
