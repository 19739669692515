/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import OfferStatusContainer from "./OfferStatusContainer";
import OfferDataContainer from "./OfferDataContainer";
import OfferPagination from "./OfferPagination";
import { Theme, Typography } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getOfferList } from "@/common/lib/api/offer";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
    "& > div.content": {
      display: "flex",
      alignItems: "center",
      "&::before": {
        display: "inline-block",
        content: "''",
        width: 15,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
      },
      "& > div": {
        "& > .MuiTypography-body1": {
          fontSize: "1.15rem",
          userSelect: "none",
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.975rem",
          userSelect: "none",
          "& > span > span": {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
        },
        "& > .MuiTypography-caption": {
          display: "block",
          color: theme.palette.error.main,
          fontSize: "0.875rem",
          fontWeight: "bold",
          userSelect: "none",
        },
      },
    },
  },
  content: {
    width: 1473,
  },
});

const OfferContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();

  const [data, setData] = useState<OfferData[]>([]);
  const [statusData, setStatusData] = useState<{ [key: string]: number }>({
    ALL: 0,
    WAIT: 0,
    DONE: 0,
    NOT: 0,
  });
  const [params, setParams] = useState<{ [key: string]: any }>({});

  const [init, setInit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("ALL");

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [pageDot, setPageDot] = useState<number>(1);

  const offerListQuery = useQuery(
    "offerList",
    () => {
      dispatch(loadingOn());
      let listParams = { ...params };
      return getOfferList(listParams);
    },
    {
      retry: false,
      onSuccess: (response) => {
        let allCount = 0;
        let waitCount = 0;
        let doneCount = 0;
        let notCount = 0;

        response.data.results.forEach((result: any) => {
          allCount = allCount + 1;
          if (result.response_at === null) {
            waitCount = waitCount + 1;
          } else if (new Date(result.response_at) > new Date(2999, 12, 31)) {
            notCount = notCount + 1;
          } else {
            doneCount = doneCount + 1;
          }
        });

        setData(response.data.results);
        setStatusData({
          ALL: allCount,
          WAIT: waitCount,
          DONE: doneCount,
          NOT: notCount,
        });
        setInit(true);
      },
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [status]);

  useEffect(() => {
    setPageDot(Math.floor((page - 1) / (300 / pageSize)) + 1);
  }, [page]);

  useEffect(() => {
    if (init) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [page, pageSize, params]);

  useEffect(() => {
    if (init) {
      offerListQuery.refetch();
    }
  }, [pageDot, pageSize, params]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <ViewListIcon />
          <Typography>{t("OFFER.TITLE")}</Typography>
        </div>
      </div>
      <div className={classes.content}>
        <OfferStatusContainer
          status={status}
          setStatus={setStatus}
          statusData={statusData}
        />
        <OfferDataContainer
          data={data}
          page={page}
          pageSize={pageSize}
          totalCount={statusData["ALL"]}
          status={status}
          params={params}
          setParams={setParams}
        />
        <OfferPagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalCount={statusData["ALL"]}
        />
      </div>
    </div>
  );
};

export default OfferContainer;
