/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import { Theme, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Input } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { checkVehicleDuplicate } from "@/common/lib/api/vehicle";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  title: {
    paddingBottom: 10,
    borderBottom: "1px solid " + theme.palette.text.primary,
    display: "flex",
    alignItems: "flex-start",
    "& > svg": {
      fontSize: "3.85rem",
      color: theme.palette.primary.main,
      marginRight: 10,
    },
    "& > div": {
      "& > .MuiTypography-body1 > span": {
        fontSize: "1.5rem",
        fontWeight: "bold",
        userSelect: "none",
        "& > span": {
          fontSize: "1.15rem",
        },
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.975rem",
        userSelect: "none",
        lineHeight: "1.35",
      },
      "& > .MuiTypography-caption": {
        display: "block",
        color: theme.palette.error.main,
        fontSize: "0.875rem",
        fontWeight: "bold",
        userSelect: "none",
      },
    },
  },
  vinDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "50px auto",
    "& > .MuiTypography-body1": {
      marginBottom: 17.5,
      "& > span": {
        fontSize: "1.35rem",
        fontWeight: 500,
        userSelect: "none",
        "& > span": {
          fontSize: "1.5rem",
          color: theme.palette.primary.main,
        },
      },
    },
    "& > .MuiTypography-body2": {
      "& > span": {
        fontSize: "1rem",
        fontWeight: 500,
        userSelect: "none",
      },
    },
    "& > div": {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: 17.5,
      "& > .MuiTextField-root": {
        width: 235,
        marginRight: 25,
        fontSize: "1rem",
        "& > .MuiFormHelperText-root:not(.Mui-error)": {
          fontSize: "0.8rem",
          fontWeight: 500,
          color: theme.palette.primary.main,
        },
      },
      "& > .MuiButton-root": {
        fontSize: "0.875rem",
        fontWeight: 400,
        height: "fit-content",
      },
    },
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 75,
    "& > button": {
      width: 175,
      height: 60,
      margin: "0 30px",
      borderRadius: 30,
      fontSize: "1.125rem",
      "&.prev::before": {
        content: "'<'",
        paddingRight: 8,
      },
      "&.next::after": {
        content: "'>'",
        paddingLeft: 8,
      },
    },
  },
  bottom: {
    paddingTop: 10,
    borderTop: "1px solid " + theme.palette.text.primary,
    "& > .MuiTypography-body2": {
      fontSize: "0.975rem",
      userSelect: "none",
    },
    "& > .MuiTypography-caption": {
      display: "block",
      color: theme.palette.error.main,
      fontSize: "0.875rem",
      fontWeight: "bold",
      userSelect: "none",
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
  productCount: number;
  limitCount: number;
  prev: Function;
  next: Function;
}

const RegisterVINContainer = ({
  value,
  dispatch,
  productCount = 0,
  limitCount = 0,
  prev,
  next,
}: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const _dispatch = useTypedDispatch();

  const [vin, setVin] = useState<string>(value.product.vin);
  const [isDisabled, setIsDisabled] = useState<boolean>(
    value.product.vin !== "" ? true : false
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string | null>(
    value.product.vin !== "" ? t("MSG.AVAILABLE_VIN") : ""
  );

  const checkVINMutation = useMutation(checkVehicleDuplicate, {
    onSuccess: (response) => {
      setIsError(response.data);
      if (response.data) {
        setHelperText(t("MSG.ALREADY_EXIST_VIN"));
      } else {
        setIsDisabled(true);
        setHelperText(t("MSG.AVAILABLE_VIN"));
        dispatch({
          ...value,
          product: {
            ...value.product,
            vin: vin,
          },
        });
      }
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      _dispatch(loadingOff());
    },
  });

  const handleOnNext = () => {
    next();
  };
  const handleOnClick = () => {
    if (isDisabled) {
      _dispatch(
        setConfirmText({
          title: t("DIALOG.TITLE.CONFIRM_CHANGE_VIN"),
          content: t("DIALOG.CONTENT.INIT_VEHICLE_DATA"),
          onApply: () => {
            setIsDisabled(false);
            setIsError(false);
            setHelperText("");
            dispatch({
              product: {
                uid: "",
                type: "",
                year: 0,
                maker: "",
                model: "",
                detail_model: "",
                mileage: 0,
                vin: "",
                is_restamp: false,
                mission: "",
                steering: "LHD",
                exhaust: 0,
                horse_power: null,
                fuel: "",
                width: 0,
                height: 0,
                length: 0,
                color: "",
                door: 0,
              },
              media: {
                image: [],
                youtube: "",
                dropbox: "",
              },
              sales: {
                price: 0,
                discount: null,
                discount_created: null,
                discount_expired: null,
                status: "WAIT",
                location_country: "",
                location_region: "",
                advert_text: "",
                advert_explain: "",
                memo: "",
              },
              condition: {
                engine: 0,
                mission: 0,
                brake: 0,
                steering: 0,
                exhaust: 0,
                is_4WD: "",
                is_audio: "",
                is_heater: "",
                is_aircon: "",
                is_window: "",
              },
              inspection: {
                corrosion: "L",
                hole: "N",
                crack: "N",
                accident_history: "",
                simple_repair: "",
                bonnet: "",
                front_fender_l: "",
                front_fender_r: "",
                door_f_l: "",
                door_f_r: "",
                door_r_l: "",
                door_r_r: "",
                trunk_lid: "",
                radiator_support: "",
                rear_fender_l: "",
                rear_fender_r: "",
                roof_panel: "",
                rocker_panel_l: "",
                rocker_panel_r: "",
                front_panel: "",
                cross_member: "",
                inside_panel_l: "",
                inside_panel_r: "",
                trunk_floor: "",
                rear_panel: "",
                side_member_f_l: "",
                side_member_f_r: "",
                side_member_r_l: "",
                side_member_r_r: "",
                wheelhouse_f_l: "",
                wheelhouse_f_r: "",
                wheelhouse_r_l: "",
                wheelhouse_r_r: "",
                pillar_a_l: "",
                pillar_a_r: "",
                pillar_b_l: "",
                pillar_b_r: "",
                pillar_c_l: "",
                pillar_c_r: "",
                package_tray: "",
                dash_panel: "",
                floor_panel: "",
              },
              special_point: undefined,
              car_product: undefined,
              truck_product: undefined,
              bus_product: undefined,
              car_option: undefined,
              truck_option: undefined,
              bus_option: undefined,
            });
          },
          onClose: () => {},
        })
      );
    }
  };
  const handleOnSearch = () => {
    if (vin !== "" && value.product.vin === "") {
      _dispatch(
        setConfirmText({
          title: t("DIALOG.TITLE.CONFIRM_SEARCH_VIN"),
          content: vin,
          onApply: () => {
            _dispatch(loadingOn());
            checkVINMutation.mutate({
              data: {
                vin: vin,
              },
            });
          },
          onClose: () => {},
        })
      );
    }
  };

  useEffect(() => {
    if (isNotNull(value.product.vin)) {
      setIsDisabled(true);
      setVin(value.product.vin);
      setHelperText(value.product.vin !== "" ? t("MSG.AVAILABLE_VIN") : "");
    }
  }, [value]);

  return (
    <>
      <div className={classes.title}>
        <SearchIcon />
        <div>
          <Typography variant="body1">
            <Markup content={t("REGISTER.VIN.TITLE")} />
          </Typography>
          <Typography variant="body2">{t("REGISTER.VIN.CONTENT_1")}</Typography>
          <Typography variant="caption">
            {t("REGISTER.VIN.CONTENT_2")}
          </Typography>
        </div>
      </div>
      <div className={classes.vinDiv}>
        <Typography variant="body1">
          <Markup content={t("REGISTER.VIN.INPUT_VIN")} />
        </Typography>
        <div>
          <Input
            error={isError}
            helperText={helperText}
            label={t("VEHICLE.PRODUCT.VIN")}
            value={vin}
            dispatch={(_value: string) => {
              if (!isDisabled) {
                setVin(_value);
              }
            }}
            onClick={() => handleOnClick()}
            inputProps={{
              maxLength: 20,
            }}
          />
          <Button variant="outlined" onClick={() => handleOnSearch()}>
            {t("BUTTON.INQUIRE")}
          </Button>
        </div>
        <Typography variant="body2">
          <Markup
            content={t("REGISTER.VIN.AVAILABLE_COUNT", {
              product: productCount.toLocaleString(),
              limit: limitCount.toLocaleString(),
            })}
          />
        </Typography>
      </div>
      <div className={classes.buttonDiv}>
        <Button variant="contained" className="prev" onClick={() => prev()}>
          {t("BUTTON.HOME")}
        </Button>
        <Button
          variant="contained"
          className="next"
          onClick={() => handleOnNext()}
        >
          {t("BUTTON.NEXT")}
        </Button>
      </div>
      <div className={classes.bottom}>
        <Typography variant="body2">{t("REGISTER.BOTTOM_1")}</Typography>
        <Typography variant="caption">{t("REGISTER.BOTTOM_2")}</Typography>
      </div>
    </>
  );
};

export default RegisterVINContainer;
