import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { isNotNull } from "@/common/lib/common";

export const checkGroupDuplicate = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/groups/duplication/`;
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data["data"],
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getGroupList = async (params: { [key: string]: any } = {}) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = "/auth/groups/";
  const result = await axios({
    method: "get",
    url: url,
    params: params,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getGroupInfo = async (guid: string) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/groups/${guid}/`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const postGroupInfo = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));
  if (Object.keys(data["data"]).includes("image")) {
    sendData.append("image", data["data"]["image"]);
  }

  const url = "/auth/groups/";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const patchGroupInfo = async (data: { guid: string; data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));
  if (Object.keys(data["data"]).includes("image")) {
    sendData.append("image", data["data"]["image"]);
  }

  const url = `/auth/groups/${data["guid"]}/`;
  const result = await axios({
    method: "patch",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const deleteGroupInfo = async (data: { guid: string }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/auth/groups/${data["guid"]}/`;
  const result = await axios({
    method: "delete",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};
