import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import PersonInfo from "./PersonInfo";
import {
  Fab,
  IconButton,
  Modal,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  fab: {
    width: "3.25rem",
    height: "3.25rem",
    background: "#2BA63A !important",
    color: "white !important",
  },
  snsIcon: {
    width: "2.25rem",
    height: "2.25rem",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    "& > .MuiPaper-root": {
      position: "relative",
      borderRadius: 10,
      padding: "0 10px 15px",
      "& > div.title": {
        background: "#2BA63A !important",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        margin: "0 -10px",
        padding: "12.5px 0",
        "& > .MuiTypography-body1": {
          fontSize: "1.5rem",
          fontWeight: 800,
          color: "white",
          textAlign: "center",
        },
        "& > .MuiTypography-body2": {
          fontSize: "1.125rem",
          fontWeight: 600,
          color: "white",
          textAlign: "center",
        },
      },
      "& > div.content": {
        display: "flex",
        "& > div": {
          transform: "scale(0.85)",
        },
      },
      "& > p": {
        marginTop: -15,
        fontSize: "0.875rem",
        fontWeight: 500,
        textAlign: "center",
        color: theme.palette.error.main,
      },
    },
  },
  closeButton: {
    position: "absolute",
    top: 3,
    right: 3,
    zIndex: 2,
    "& > svg": {
      fontSize: "2.15rem",
      color: "white",
    },
  },
});

const WhatsAppFab = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Fab className={classes.fab} onClick={() => setModalOpen(true)}>
        <WhatsAppIcon className={classes.snsIcon} />
      </Fab>
      <Modal
        className={classes.modal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        disableAutoFocus={true}
      >
        <Paper>
          <IconButton
            className={classes.closeButton}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <div className="title">
            <Typography variant="body1">Contact Sales Staff</Typography>
            <Typography variant="body2">
              Talk to our Staff via WhatsApp.
            </Typography>
          </div>
          <div className="content">
            <PersonInfo
              imgSrc={undefined}
              name={"Dima Kim"}
              position={"CEO"}
              phone={"+82-10-9744-5510"}
              whatsApp={"https://wa.me/821097445510"}
              langList={["Russian"]}
            />
            <PersonInfo
              imgSrc={undefined}
              name={"Ignacio"}
              position={"Sales Manager"}
              phone={"+82-10-7659-9744"}
              whatsApp={"https://wa.me/821076599744"}
              langList={["Spanish", "English"]}
            />
            <PersonInfo
              imgSrc={undefined}
              name={"Matías"}
              position={"Sales Staff"}
              phone={"+82-10-5675-6519"}
              whatsApp={"https://wa.me/821056756519"}
              langList={["Spanish", "English"]}
            />
          </div>
          <Typography>
            <Markup content={t("MSG.CLICK_TO_LINK")} />
          </Typography>
        </Paper>
      </Modal>
    </>
  );
};

export default WhatsAppFab;
