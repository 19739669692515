import axios from "axios";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
// Redux
import { store } from "@/common/lib/redux/store";

export const checkLogin = () => {
  const state = store.getState();
  if (isNotNull(state.auth.loginToken)) {
    return true;
  }
  return false;
};

export const validatePassword = (value: string) => {
  let errorCode = 0;
  const containsDigit = /\d+/;
  const containsAlphabet = /[a-zA-Z]+/;
  const containsSpecial = /[`~!@#$%^&*()\-_=+{}[\]:;'"]+/;

  if (value.length < 8) {
    errorCode = errorCode | 1;
  }
  if (!containsDigit.test(value)) {
    errorCode = errorCode | 2;
  }
  if (!containsAlphabet.test(value)) {
    errorCode = errorCode | 4;
  }
  if (!containsSpecial.test(value)) {
    errorCode = errorCode | 8;
  }
  return errorCode;
};

export const getAutowiniCommission = (value: number) => {
  if (value >= 400 && value <= 726) {
    return 253;
  } else if (value >= 727 && value <= 1196) {
    return 273;
  } else if (value >= 1197 && value <= 1686) {
    return 303;
  } else if (value >= 1687 && value <= 2181) {
    return 313;
  } else if (value >= 2182 && value <= 2646) {
    return 318;
  } else if (value >= 2647 && value <= 3136) {
    return 353;
  } else if (value >= 3137 && value <= 3631) {
    return 363;
  } else if (value >= 3632 && value <= 4126) {
    return 368;
  } else if (value >= 4127 && value <= 4586) {
    return 373;
  } else if (value >= 4587 && value <= 5081) {
    return 413;
  } else if (value >= 5082 && value <= 5576) {
    return 418;
  } else if (value >= 5577 && value <= 6060) {
    return 423;
  } else if (value >= 6061 && value <= 6532) {
    return 439;
  } else if (value >= 6533 && value <= 7004) {
    return 467;
  } else if (value >= 7005 && value <= 7475) {
    return 495;
  } else if (value >= 7476 && value <= 8419) {
    return 524;
  } else if (value >= 8420 && value <= 9362) {
    return 580;
  } else if (value >= 9363 && value <= 10306) {
    return 637;
  } else if (value >= 10307 && value <= 11249) {
    return 693;
  } else if (value >= 11250 && value <= 12192) {
    return 750;
  } else if (value >= 12193 && value <= 14999) {
    return 807;
  } else if (value >= 15000 && value <= 15009) {
    return 825;
  } else if (value >= 15010) {
    return 826 + Math.floor((value - 15010) / 20);
  } else {
    return 0;
  }
};

export const getAutowiniBusCommission = (value: number) => {
  if (value >= 900 && value <= 1196) {
    return 498;
  } else if (value >= 1197 && value <= 1686) {
    return 528;
  } else if (value >= 1687 && value <= 2181) {
    return 538;
  } else if (value >= 2182 && value <= 2646) {
    return 543;
  } else if (value >= 2647 && value <= 3136) {
    return 578;
  } else if (value >= 3137 && value <= 3631) {
    return 588;
  } else if (value >= 3632 && value <= 4126) {
    return 593;
  } else if (value >= 4127 && value <= 4586) {
    return 598;
  } else if (value >= 4587 && value <= 5081) {
    return 638;
  } else if (value >= 5082 && value <= 5576) {
    return 643;
  } else if (value >= 5577 && value <= 6071) {
    return 648;
  } else if (value >= 6072 && value <= 6566) {
    return 653;
  } else if (value >= 6567 && value <= 8561) {
    return 658;
  } else if (value >= 8562 && value <= 9476) {
    return 663;
  } else if (value >= 9477 && value <= 14456) {
    return 748;
  } else if (value >= 14457 && value <= 19326) {
    return 768;
  } else if (value >= 19327 && value <= 24276) {
    return 898;
  } else if (value >= 24277 && value <= 29176) {
    return 948;
  } else if (value >= 29177 && value <= 49176) {
    return 1248;
  } else if (value >= 49177) {
    return 1448;
  } else {
    return 0;
  }
};

export const getTokenData = () => {
  const state = store.getState();
  const result = {
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  };
  const tokenData = parseJWT(state.auth.loginToken);
  if (isNotNull(tokenData)) {
    result["id"] = tokenData.id;
    result["puid"] = tokenData.puid;
    result["guid"] = tokenData.guid;
    result["name"] = tokenData.name;
    result["username"] = tokenData.username;
    result["group"] = tokenData.group;
    result["image"] = tokenData.image;
    result["is_staff"] = tokenData.is_staff;
    result["is_manager"] = tokenData.is_manager;
    result["is_superuser"] = tokenData.is_superuser;
  }
  return result;
};
// return value : String | Number | Boolean | Undefined | Null | Object | Array | RegExp | Math | Date | Function
export const getType = (target: any) => {
  return Object.prototype.toString.call(target).slice(8, -1);
};

export const getStartDate = (_date: Date) => {
  const year = _date.getFullYear();
  const month = _date.getMonth();
  const date = _date.getDate();

  return new Date(year, month, date, 0, 0, 0, 0);
};

export const getEndDate = (_date: Date) => {
  const year = _date.getFullYear();
  const month = _date.getMonth();
  const date = _date.getDate();

  return new Date(year, month, date, 23, 59, 59, 999);
};

export const getUTCDate = (date: Date) => {
  return (
    String(date.getUTCFullYear()).padStart(4, "0") +
    "-" +
    String(date.getUTCMonth() + 1).padStart(2, "0") +
    "-" +
    String(date.getUTCDate()).padStart(2, "0") +
    "T" +
    String(date.getUTCHours()).padStart(2, "0") +
    ":" +
    String(date.getUTCMinutes()).padStart(2, "0")
  );
};

export const isNull = (value: any) => {
  return value === undefined || value === null || value === "" ? true : false;
};

export const isNotNull = (value: any) => {
  return !isNull(value);
};

export const isObjectEmpty = (object: Object) => {
  return object &&
    Object.keys(object).length === 0 &&
    Object.getPrototypeOf(object) === Object.prototype
    ? true
    : false;
};

export const isNotObjectEmpty = (object: Object) => {
  return !isObjectEmpty(object);
};

export const getDateFormat = (value: Date | null, format: string) => {
  if (value === null) {
    return "";
  }

  const date = new Date(value); // UTC 기준 (+0)
  const offset = new Date().getTimezoneOffset(); // Offset (KST : -540)

  const local_date = new Date(date.getTime() - offset * 60 * 1000);

  const year = String(local_date.getFullYear());
  const month = ("0" + (1 + local_date.getMonth())).slice(-2);
  const day = ("0" + local_date.getDate()).slice(-2);
  const hour = ("0" + local_date.getHours()).slice(-2);
  const minute = ("0" + local_date.getMinutes()).slice(-2);
  const second = ("0" + local_date.getSeconds()).slice(-2);

  format = format.replace("yyyy", year);
  format = format.replace("MM", month);
  format = format.replace("dd", day);
  format = format.replace("hh", hour);
  format = format.replace("mm", minute);
  format = format.replace("ss", second);
  return format;
};

export const getCommaFormat = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getOptionLabel = (value: string, list: Option[]) => {
  const result = list.filter((item: Option) => item.value === value);
  if (result.length > 0) {
    return result[0].label;
  }
  return "";
};

export const getOptionValue = (label: string, list: Option[]) => {
  const result = list.filter((item: Option) => item.label === label);
  if (result.length > 0) {
    return result[0].value;
  }
  return "";
};

export const openNewTab = (type: string, url: string) => {
  let openURL = "";
  if (type === "WEB") {
    const host = window.location.host;
    if (host === "localhost:3030") {
      openURL = "http://localhost:3000/" + url;
    } else if (host === "management.autoskorea.com") {
      openURL = "https://www.autoskorea.com/" + url;
    } else {
      openURL = url;
    }
  } else if (type === "MANAGEMENT") {
    const host = window.location.host;
    if (host === "localhost:3030") {
      openURL = "http://localhost:3030/" + url;
    } else if (host === "management.autoskorea.com") {
      openURL = "https://management.autoskorea.com/" + url;
    } else {
      openURL = url;
    }
  }
  window.open(
    openURL,
    "w_" + Date.now() + Math.floor(Math.random() * 1000000).toString()
  );
};

export const parseJWT = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const resizeImage = (
  file: File,
  width: number,
  height: number,
  quality: number,
  rotate: number,
  output: string
) => {
  const promise = new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width, // width
      height, // height
      "jpeg",
      quality, // quality
      rotate, // rotate
      (image: any) => {
        resolve(image);
      },
      output
    );
  });
  return promise;
};

export const getImageFile = async (fileURL: string) => {
  const result = await axios({
    method: "get",
    url: fileURL + "?_",
    responseType: "blob",
    withCredentials: false,
  }).then((response) => {
    const result = fileURL.match(/\/media\/(.*)$/);

    const blob = new Blob([response.data]);
    const fileName = result !== null ? result[1] : uuidv4() + ".jpg";
    const file = new File([blob], fileName, { type: response.data.type });
    return file;
  });
  return result;
};

export const getUserTableData = (data: UserData[]) => {
  const result: any[] = [];

  data.forEach((item: UserData) => {
    result.push({
      id: item.id,
      photo: item.image,
      name: item.name,
      username: item.username,
      puid: item.puid,
      email: item.email,
      phone: item.phone,
      nationality: item.nationality,
      address1: item.address1,
      address2: item.address2,
      address3: item.address3,
      state: item.state,
      city: item.city,
      country: item.country,
      zip_code: item.zip_code,
      created: item.created,
      expired: item.expired,
      status: item.status,
    });
  });
  return result;
};

export const getGroupTableData = (data: GroupData[]) => {
  const result: any[] = [];

  data.forEach((item: GroupData) => {
    result.push({
      id: item.id,
      guid: item.guid,
      photo: item.image,
      name: item.name,
      manager: item.manager,
      count_staff: item.count_staff,
      count_product: item.count_product,
      limit_staff: item.limit_staff,
      limit_product: item.limit_product,
      address1: item.address1,
      address2: item.address2,
      address3: item.address3,
      state: item.state,
      city: item.city,
      country: item.country,
      zip_code: item.zip_code,
      created: item.created,
      expired: item.expired,
      status: item.status,
    });
  });
  return result;
};

export const getVehicleTableData = (data: VehicleData[]) => {
  const result: any[] = [];

  const getDrive = (item: VehicleData) => {
    if (item.product.type === "CAR") {
      if (item.car_product !== undefined) {
        return item.car_product.drive;
      }
    } else if (item.product.type === "TRUCK") {
      if (item.truck_product !== undefined) {
        return item.truck_product.drive;
      }
    } else if (item.product.type === "BUS") {
      return "RWD";
    }
    return null;
  };
  const getOption = (item: VehicleData) => {
    const result: string[] = [];

    if (item.product.type === "CAR") {
      if (item.car_option !== undefined) {
        for (const [key, value] of Object.entries(item.car_option)) {
          if (value) {
            result.push(key);
          }
        }
      }
    } else if (item.product.type === "TRUCK") {
      if (item.truck_option !== undefined) {
        for (const [key, value] of Object.entries(item.truck_option)) {
          if (value) {
            result.push(key);
          }
        }
      }
    } else if (item.product.type === "BUS") {
      if (item.bus_option !== undefined) {
        for (const [key, value] of Object.entries(item.bus_option)) {
          if (value) {
            result.push(key);
          }
        }
      }
    }
    return result;
  };

  data.forEach((item: VehicleData) => {
    result.push({
      photo: item.media.image[0],
      id: item.product.uid,
      type: item.product.type,
      vin: item.product.vin,
      maker: item.product.maker,
      model: item.product.model,
      detail_model: item.product.detail_model,
      status: item.sales.status,
      price: item.sales.price,
      discount: item.sales.discount,
      discount_created: item.sales.discount_created,
      discount_expired: item.sales.discount_expired,
      mileage: item.product.mileage,
      mission: item.product.mission,
      fuel: item.product.fuel,
      color: item.product.color,
      steering: item.product.steering,
      drive: getDrive(item),
      accident_history: item.inspection.accident_history,
      simple_repair: item.inspection.simple_repair,
      inspection: item.inspection,
      option: getOption(item),
      memo: item.sales.memo,
      created: item.product.created,
      modified: item.product.modified,
      sales_on: item.sales.sales_on_at,
      sold_out: item.sales.sold_out_at,
      price_update: item.sales.price_update_at,
      purchase_client: item.sales.purchase_client,
      purchase_price: item.sales.purchase_price,
    });
  });
  return result;
};

export const getBookingRequestTableData = (data: BookingRequestData[]) => {
  const result: any[] = [];

  data.forEach((item: BookingRequestData) => {
    result.push({
      id: item.id,
      photo: item.media.image,
      uid: item.product.uid,
      vin: item.product.vin,
      maker: item.product.maker,
      model: item.product.model,
      detail_model: item.product.detail_model,
      status: item.sales.status,
      price: item.sales.price,
      user: item.user.name,
      created: item.created,
      response: item.response_at,
    });
  });
  return result;
};

export const getOfferTableData = (data: OfferData[]) => {
  const result: any[] = [];

  data.forEach((item: OfferData) => {
    result.push({
      id: item.id,
      year: item.year,
      maker: item.maker,
      model: item.model,
      price: item.price,
      details: item.details,
      user: item.user.name,
      created: item.created,
      response: item.response_at,
    });
  });
  return result;
};

export const exportCSVFile = (
  headers: { label: string; key: string }[],
  data: { [key: string]: any }[],
  fileName: string
) => {
  let csvData = "";
  for (const header of headers) {
    if (csvData !== "") {
      csvData += ",";
    }

    if (header.label === "ID") {
      csvData += "id";
    } else {
      csvData += header.label;
    }
  }
  csvData += "\n";

  for (let i = 0; i < data.length; i++) {
    let row = "";
    for (const header of headers) {
      if (Object.keys(data[i]).includes(header.key)) {
        if (row !== "") {
          row += ",";
        }
        if (/,/.test(data[i][header.key])) {
          row += '"' + data[i][header.key] + '"';
        } else {
          row += data[i][header.key];
        }
      }
    }
    csvData += row + "\n";
  }
  const blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
