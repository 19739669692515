// Components
import { Theme } from "@mui/material";
import StatusMark from "./StatusMark";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
    height: 215,
    margin: 15,
    "& > div.X, & > div.W, & > div.C, & > div.A, & > div.U, & > div.T": {
      width: 13,
      height: 13,
      fontSize: 10,
    },
  },
  image: {
    height: "inherit",
    fill: theme.palette.text.primary,
  },
  front_fender_l: {
    position: "absolute",
    top: "8.5%",
    left: "40%",
    transform: "translate(-50%, -50%)",
  },
  door_f_l: {
    position: "absolute",
    top: "42.5%",
    left: "43.5%",
    transform: "translate(-50%, -50%)",
  },
  door_r_l: {
    position: "absolute",
    top: "62.5%",
    left: "43.5%",
    transform: "translate(-50%, -50%)",
  },
  rear_fender_l: {
    position: "absolute",
    top: "87.5%",
    left: "52.5%",
    transform: "translate(-50%, -50%)",
  },
  rocker_panel_l: {
    position: "absolute",
    top: "47.5%",
    left: "18.5%",
    transform: "translate(-50%, -50%)",
  },
  pillar_a_l: {
    position: "absolute",
    top: "39.5%",
    left: "85%",
    transform: "translate(-50%, -50%)",
  },
  pillar_b_l: {
    position: "absolute",
    top: "55%",
    left: "82.5%",
    transform: "translate(-50%, -50%)",
  },
  pillar_c_l: {
    position: "absolute",
    top: "67.5%",
    left: "92.5%",
    transform: "translate(-50%, -50%)",
  },
});

const LeftSideImage = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1312 2312"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 2312) scale(0.1, -0.1)">
        <path
          d="M3760 22914 c-66 -13 -182 -49 -241 -75 -71 -31 -135 -42 -321 -59
-76 -7 -147 -20 -200 -37 -119 -38 -180 -36 -293 10 -124 50 -202 67 -322 67
-160 0 -235 -22 -335 -99 l-47 -36 -1 -250 c0 -304 20 -2328 23 -2332 2 -1
180 -9 397 -18 408 -16 554 -25 546 -33 -3 -2 -60 -8 -128 -13 -551 -40 -1179
-236 -1558 -487 -116 -77 -253 -202 -375 -341 -83 -95 -250 -369 -281 -461
-28 -81 -36 -208 -32 -508 l3 -297 46 -70 c26 -38 59 -95 74 -125 36 -73 125
-210 187 -287 134 -169 532 -434 859 -572 154 -65 234 -89 337 -101 64 -7 94
-16 121 -34 46 -32 120 -43 366 -56 187 -9 287 -25 286 -45 -2 -21 -66 -26
-436 -35 -483 -12 -565 -22 -590 -70 -27 -52 -35 -215 -46 -930 -14 -951 -6
-7802 10 -8110 18 -342 39 -474 83 -504 17 -12 38 -14 93 -9 76 6 97 -3 67
-29 -30 -26 -83 -39 -229 -53 -128 -13 -150 -18 -211 -49 -38 -19 -136 -58
-218 -86 -202 -70 -278 -107 -388 -187 -51 -37 -135 -97 -187 -133 -171 -118
-387 -358 -511 -567 -86 -144 -113 -297 -113 -643 0 -235 8 -321 41 -421 8
-26 24 -55 35 -65 26 -23 59 -87 59 -112 0 -87 429 -509 630 -619 36 -20 99
-56 140 -81 41 -25 96 -55 121 -68 71 -35 391 -142 524 -175 221 -54 244 -61
256 -75 18 -21 5 -39 -59 -82 -67 -46 -113 -95 -142 -152 -61 -120 -66 -335
-9 -396 14 -15 51 -33 91 -44 37 -11 99 -29 137 -40 37 -11 74 -27 81 -35 8
-10 17 -63 21 -133 11 -157 25 -253 59 -402 16 -69 45 -219 66 -335 35 -200
45 -244 94 -378 14 -41 24 -102 31 -200 11 -171 28 -259 74 -394 20 -56 40
-139 46 -185 17 -134 24 -152 79 -202 62 -55 252 -170 391 -235 196 -92 362
-141 654 -193 163 -29 185 -30 540 -35 518 -7 852 13 1017 63 44 13 152 35
241 48 386 60 1599 180 1832 180 l150 1 82 -43 c113 -60 170 -69 343 -54 255
22 492 75 660 147 47 20 110 41 140 46 89 17 185 87 295 220 121 145 205 298
205 374 0 29 24 81 40 87 28 9 58 203 130 844 49 434 74 613 106 739 43 172
122 371 184 461 81 117 333 421 485 584 116 125 978 1140 1119 1318 72 91 191
236 265 321 98 114 142 175 166 226 22 49 56 95 107 150 95 101 135 158 148
207 8 29 19 42 47 56 26 12 48 37 77 85 23 37 73 104 111 150 92 109 133 170
141 214 5 27 14 38 36 47 20 7 36 26 53 63 14 28 25 57 25 63 0 25 32 71 59
85 19 9 32 26 36 44 9 42 113 212 180 296 70 87 168 283 190 378 15 67 32 108
83 202 33 62 41 119 53 390 17 403 -4 2345 -32 2965 -11 247 -15 283 -47 445
-11 55 -32 206 -47 335 -42 376 -74 510 -180 745 -35 77 -66 158 -70 180 -12
76 -15 83 -47 102 -34 20 -43 35 -97 157 -61 135 -208 338 -430 591 -140 161
-649 669 -836 835 -387 343 -844 701 -994 777 -59 30 -118 73 -226 168 -102
89 -141 117 -225 157 -41 20 -165 96 -275 169 -240 159 -303 197 -375 231 -30
14 -84 50 -119 81 -90 77 -156 114 -276 157 -130 46 -149 59 -180 120 -43 84
-95 314 -120 530 -11 98 -45 269 -71 358 -42 143 -55 220 -65 381 -6 89 -16
170 -25 195 -50 133 -101 399 -164 841 -51 365 -74 483 -110 565 -20 45 -31
91 -36 150 -8 102 -25 152 -65 203 -37 46 -59 97 -59 136 0 46 -28 119 -70
187 -21 34 -46 88 -56 120 -22 78 -55 140 -163 314 -158 252 -198 345 -217
503 -5 42 -10 51 -38 68 -29 17 -35 28 -45 77 -26 132 -162 355 -341 557 -173
196 -607 547 -710 575 -20 5 -78 37 -130 71 -88 56 -148 87 -510 262 -149 72
-287 158 -370 232 -71 63 -159 119 -224 144 -29 11 -106 24 -176 31 -124 12
-932 23 -980 14z m1134 -377 c125 -126 167 -155 259 -176 69 -16 489 -218 588
-283 46 -30 116 -72 154 -93 316 -174 796 -610 902 -818 40 -81 105 -238 163
-397 55 -150 108 -263 153 -329 20 -29 39 -69 43 -89 12 -77 25 -103 65 -127
43 -28 69 -70 69 -113 0 -29 43 -106 74 -131 24 -20 41 -57 56 -121 7 -30 39
-113 70 -185 117 -265 164 -453 259 -1038 40 -241 64 -354 91 -412 17 -37 23
-79 30 -194 11 -174 25 -255 78 -461 23 -85 48 -191 56 -235 26 -129 146 -887
146 -918 l0 -28 -307 6 c-170 3 -668 10 -1108 15 -827 10 -1888 30 -1893 35
-2 2 19 8 46 14 48 10 136 49 377 167 298 145 651 500 805 809 153 308 251
1008 171 1229 -11 32 -26 100 -32 150 -19 161 -108 380 -175 431 -23 17 -34
34 -34 51 0 27 -26 84 -38 84 -22 0 -90 72 -96 100 -24 123 -625 568 -1019
755 -196 93 -520 181 -1133 305 -136 28 -248 50 -250 50 -2 0 -4 305 -4 678
l0 677 113 23 c238 49 257 84 257 475 l0 217 471 0 471 0 122 -123z m-2399 18
c93 -31 125 -82 125 -200 0 -152 41 -264 115 -315 55 -37 121 -57 250 -75 60
-9 120 -23 133 -31 62 -41 72 -147 72 -823 l0 -513 -127 6 c-288 15 -476 28
-618 44 l-150 17 0 890 c0 931 3 990 43 1015 21 13 92 6 157 -15z m1012 7 c35
-22 45 -177 18 -269 -27 -90 -80 -119 -240 -129 -167 -12 -319 29 -359 97 -35
60 -10 176 47 213 82 54 484 120 534 88z m-822 -2162 c629 -31 906 -66 1404
-181 213 -49 267 -67 330 -114 19 -14 47 -25 62 -25 96 0 474 -192 619 -315
25 -21 65 -47 90 -58 119 -52 396 -333 526 -532 275 -424 313 -877 120 -1448
-101 -299 -146 -376 -322 -548 -208 -201 -324 -283 -564 -394 -196 -92 -246
-110 -339 -124 -45 -7 -72 -18 -90 -35 -30 -28 -88 -46 -151 -46 -60 0 -105
-14 -151 -46 -31 -21 -57 -28 -131 -36 -51 -5 -104 -15 -118 -23 -70 -40 -110
-54 -155 -57 -28 -2 -52 2 -54 7 -8 19 87 55 513 196 463 152 608 215 813 348
204 133 494 466 538 618 9 32 32 83 51 114 18 31 40 85 49 120 20 85 38 464
30 659 -10 269 -25 328 -154 595 -48 100 -101 191 -139 241 -154 204 -447 422
-777 580 -174 83 -271 120 -520 198 -257 80 -352 102 -475 111 -52 4 -135 17
-185 30 -144 37 -275 49 -660 65 -389 16 -529 33 -542 66 -8 21 1 27 50 35 63
10 104 10 332 -1z m680 -611 c213 -20 459 -79 750 -180 171 -58 270 -101 300
-129 11 -10 40 -22 65 -27 30 -6 48 -16 55 -30 20 -38 35 -50 74 -57 143 -22
445 -405 552 -697 42 -115 69 -233 69 -303 0 -67 -39 -250 -80 -377 -30 -91
-73 -179 -116 -239 -14 -19 -28 -48 -31 -65 -8 -40 -205 -236 -338 -335 -100
-75 -270 -165 -485 -257 -701 -300 -1842 -237 -2528 139 -245 134 -576 461
-670 661 -73 154 -117 364 -110 527 5 114 9 124 158 435 86 179 237 338 465
490 517 344 1203 507 1870 444z m-671 -3402 c15 -10 30 -26 33 -35 3 -9 23
-59 45 -111 29 -68 38 -102 33 -120 -4 -14 -11 -865 -15 -1891 -5 -1254 -12
-1888 -19 -1934 -16 -103 -38 -162 -72 -191 -31 -25 -31 -25 -239 -25 -208 0
-208 0 -239 25 -34 29 -56 88 -71 190 -24 168 -32 781 -25 2125 7 1428 16
1945 34 1963 19 19 94 25 301 24 180 -2 209 -4 234 -20z m5026 -207 c225 -11
352 -25 390 -43 32 -16 65 -75 84 -154 13 -49 56 -330 56 -364 0 -3 -44 0 -98
7 -154 20 -453 42 -867 64 -675 36 -748 40 -895 51 -268 19 -374 41 -409 85
-19 24 -21 41 -21 148 0 123 11 189 33 203 7 4 44 11 82 15 101 9 1402 0 1645
-12z m-2124 -3 c6 -5 35 -367 30 -374 -1 -1 -224 -9 -496 -17 -1267 -40 -1412
-46 -1768 -72 -122 -9 -225 -14 -227 -12 -3 3 -11 64 -17 135 -14 154 -10 167
74 209 180 91 329 120 693 134 256 10 1701 8 1711 -3z m3097 -225 c135 -70
374 -220 867 -547 446 -296 597 -402 673 -472 33 -30 165 -140 295 -245 388
-314 586 -493 902 -814 391 -399 650 -717 650 -799 0 -9 13 -24 29 -33 55 -31
187 -265 217 -385 9 -34 40 -123 69 -197 127 -321 171 -646 200 -1475 6 -147
4 -183 -7 -199 -21 -27 -86 -35 -289 -35 l-177 -1 -7 187 c-4 104 -5 264 -3
358 6 192 -1 240 -55 380 -39 100 -53 178 -62 335 -9 153 -31 283 -70 409 -19
61 -39 139 -45 174 -22 122 -32 153 -70 217 -47 79 -55 100 -64 169 -9 66 -27
103 -77 158 -21 22 -42 59 -48 80 -26 95 -171 275 -430 533 -107 107 -249 236
-336 305 -82 65 -193 155 -245 200 -156 133 -263 211 -595 432 -533 355 -997
637 -1301 790 l-214 108 0 187 c0 210 6 238 52 238 15 0 79 -26 141 -58z
m-2053 -451 c647 -15 854 -27 1116 -61 268 -36 467 -68 475 -78 3 -4 15 -115
27 -247 12 -132 31 -332 41 -445 23 -235 35 -450 56 -980 32 -787 52 -1024 95
-1122 30 -68 40 -236 50 -848 6 -311 14 -589 20 -619 5 -29 16 -58 25 -65 24
-17 34 -78 35 -193 0 -121 19 -112 -173 -83 -242 35 -789 44 -2837 43 -1984 0
-2343 -6 -2432 -39 -26 -10 -49 -16 -52 -13 -3 2 0 774 5 1714 5 941 11 2002
12 2359 l2 650 50 14 c28 8 106 18 175 22 170 11 2744 3 3310 -9z m2227 -428
c120 -75 269 -164 332 -197 81 -43 119 -69 130 -89 10 -18 31 -34 54 -42 57
-20 205 -111 397 -246 332 -232 706 -526 743 -583 10 -14 41 -37 70 -51 93
-44 419 -342 569 -518 43 -51 70 -92 78 -122 12 -42 57 -95 81 -95 15 0 49
-62 49 -90 0 -31 39 -112 80 -166 19 -25 38 -68 50 -115 10 -41 30 -101 45
-134 48 -107 66 -168 76 -267 5 -53 19 -130 30 -170 39 -145 79 -355 104 -552
14 -111 34 -256 45 -324 11 -67 20 -154 20 -194 0 -59 -23 -248 -35 -284 -2
-7 -54 -3 -164 13 -347 50 -1880 256 -2285 307 -340 43 -432 57 -442 70 -26
36 -77 355 -104 646 -5 63 -19 286 -30 495 -23 427 -39 656 -75 1020 -49 498
-74 982 -78 1455 -2 324 2 370 33 370 5 0 108 -62 227 -137z m-6185 -3183 c61
-21 69 -37 79 -157 21 -261 31 -1102 26 -2091 -5 -987 -14 -1299 -37 -1328
-22 -26 -109 -36 -356 -41 l-251 -5 -6 1043 c-8 1217 -7 2006 2 2311 5 198 8
219 27 245 11 15 32 31 45 35 41 12 430 2 471 -12z m6668 -980 c217 -27 544
-70 725 -94 182 -24 458 -61 615 -81 157 -20 488 -65 735 -100 248 -36 590
-82 761 -104 367 -47 449 -64 469 -95 8 -12 19 -53 25 -91 11 -79 13 -302 3
-384 l-6 -53 -101 6 c-56 3 -265 13 -466 21 -404 17 -793 37 -1180 60 -443 26
-671 35 -1200 45 -561 11 -671 19 -740 53 -93 45 -104 101 -115 594 l-7 283
44 -5 c23 -3 221 -28 438 -55z m-2480 -329 c733 -15 1254 -31 1314 -41 28 -5
83 -16 121 -24 39 -9 120 -21 181 -27 l111 -12 6 -210 c4 -116 7 -274 7 -353
0 -246 23 -587 55 -809 24 -170 62 -687 90 -1250 18 -359 41 -661 55 -718 6
-23 17 -121 24 -217 18 -213 60 -1145 53 -1164 -8 -19 -168 -69 -423 -131
-126 -31 -262 -66 -304 -79 -95 -30 -250 -46 -449 -46 -258 0 -295 10 -637
171 -211 98 -387 174 -454 194 -48 14 -266 108 -410 175 -80 38 -169 73 -197
78 -92 16 -112 35 -348 322 -334 406 -596 607 -1002 769 -372 148 -735 242
-1163 301 -192 27 -281 45 -335 69 -37 16 -43 24 -54 67 -26 104 -32 544 -21
1549 11 967 17 1183 38 1236 23 59 120 99 332 137 63 12 126 23 140 26 43 8
2730 -3 3270 -13z m2220 -716 c189 -24 705 -53 1610 -90 378 -16 1240 -72
1250 -81 3 -3 5 -92 5 -197 0 -144 -8 -273 -33 -517 -54 -527 -80 -685 -133
-790 -17 -34 -34 -92 -44 -154 -17 -110 -31 -144 -180 -451 -64 -134 -116
-227 -143 -257 -31 -37 -42 -59 -47 -95 -9 -73 -18 -97 -44 -109 -28 -15 -58
-58 -67 -99 -11 -49 -42 -103 -65 -110 -14 -5 -32 -32 -54 -78 -39 -86 -104
-175 -243 -331 -196 -219 -335 -335 -492 -410 -80 -39 -80 -39 -555 -152 -443
-106 -574 -134 -591 -127 -36 14 -49 118 -124 1013 -69 818 -94 1091 -115
1240 -17 126 -31 431 -50 1165 -17 645 -17 645 -7 645 4 0 59 -7 122 -15z
m3614 -163 c9 -14 -13 -1392 -24 -1512 -12 -130 -23 -173 -56 -213 -27 -32
-44 -86 -44 -138 0 -56 -29 -140 -66 -190 -40 -55 -64 -108 -64 -144 0 -16
-18 -44 -50 -80 -27 -30 -68 -92 -91 -137 -98 -195 -288 -487 -439 -678 -51
-63 -107 -144 -125 -180 -35 -69 -58 -96 -89 -101 -10 -2 -22 -11 -26 -19 -61
-133 -135 -241 -277 -409 -48 -56 -94 -115 -102 -130 -8 -14 -18 -29 -24 -32
-25 -17 -108 25 -287 144 -74 49 -137 91 -139 93 -2 2 108 117 245 256 252
256 281 289 347 410 20 36 65 111 99 165 35 55 110 193 168 307 69 136 121
226 152 262 32 37 49 66 53 92 12 88 37 168 86 274 218 476 289 874 289 1613
0 161 3 307 6 324 l7 31 223 0 c122 0 225 -4 228 -8z m-9914 -2056 l0 -394
-97 -8 c-103 -7 -512 1 -550 12 l-23 6 0 389 0 389 335 0 335 0 0 -394z m623
-361 c325 -60 564 -120 847 -215 129 -43 267 -88 305 -99 50 -14 80 -30 100
-52 17 -17 66 -49 110 -71 44 -22 112 -64 152 -94 157 -117 398 -373 525 -557
66 -97 229 -425 246 -497 26 -108 44 -336 39 -485 -11 -320 -93 -577 -262
-824 -42 -63 -86 -133 -96 -157 -14 -32 -42 -61 -109 -113 -107 -85 -128 -106
-146 -148 -16 -37 -51 -64 -149 -118 -38 -21 -106 -63 -150 -92 -90 -61 -337
-188 -495 -256 -196 -83 -304 -119 -450 -149 -80 -16 -197 -42 -260 -58 -361
-90 -970 -172 -1272 -172 -205 1 -278 21 -278 76 0 43 88 149 152 182 36 19
181 33 353 34 312 1 1085 106 1434 195 176 44 470 153 621 230 118 60 367 266
490 406 89 100 120 148 131 201 6 23 28 65 49 93 54 70 86 145 120 277 44 177
63 346 64 573 0 289 -20 390 -122 592 -123 245 -246 406 -381 496 -23 15 -51
43 -62 62 -12 19 -28 35 -35 35 -24 0 -75 29 -124 70 -84 70 -190 131 -378
220 -249 118 -310 136 -991 295 -106 25 -207 53 -225 61 -61 31 -92 75 -69 97
13 14 78 6 316 -38z m-513 -520 c265 -28 554 -99 800 -196 151 -60 362 -176
570 -314 173 -114 254 -182 274 -229 7 -17 43 -67 80 -111 86 -102 113 -149
126 -213 5 -28 29 -102 54 -164 51 -130 71 -240 64 -359 -4 -59 -10 -87 -25
-107 -31 -42 -49 -82 -63 -139 -36 -156 -354 -496 -647 -695 -152 -102 -555
-279 -735 -323 -173 -42 -289 -49 -763 -49 -497 0 -548 4 -771 65 -220 60
-317 99 -539 217 -218 116 -294 172 -427 308 -203 209 -326 395 -399 604 -53
151 -31 412 52 612 39 96 85 168 173 273 42 50 87 113 101 141 19 40 42 65
101 110 186 139 574 346 766 408 236 76 544 145 738 165 134 13 323 12 470 -4z
m7625 -945 c130 -68 388 -252 444 -315 36 -42 32 -70 -15 -90 -24 -10 -35 -23
-42 -49 -14 -48 -41 -85 -137 -186 -44 -47 -148 -164 -230 -260 -81 -96 -197
-224 -256 -285 -68 -69 -115 -127 -127 -155 -24 -54 -81 -125 -274 -341 -80
-90 -166 -189 -190 -220 -23 -31 -101 -119 -171 -195 -70 -76 -154 -177 -186
-224 -57 -84 -241 -430 -241 -454 0 -7 -11 -23 -25 -36 -22 -21 -31 -22 -100
-17 -179 13 -211 -32 -226 -328 -11 -208 -23 -310 -49 -415 -30 -116 -49 -253
-75 -519 -13 -134 -26 -245 -28 -248 -3 -2 -124 -13 -268 -24 -493 -36 -532
-46 -821 -206 -59 -33 -133 -68 -165 -77 -32 -9 -96 -37 -143 -61 -108 -55
-374 -151 -705 -254 -447 -139 -754 -219 -1150 -301 -78 -16 -188 -43 -245
-59 -155 -45 -703 -103 -980 -105 -122 -1 -139 1 -177 22 -24 12 -50 33 -59
47 -41 63 -46 105 -54 555 -11 515 -19 666 -65 1195 -69 794 -73 844 -55 849
8 3 87 21 176 40 213 48 404 100 455 127 23 11 105 45 181 75 533 208 983 553
1251 959 129 196 178 326 244 640 59 284 63 351 29 487 -36 144 -47 239 -37
337 5 45 11 84 14 87 3 3 146 -52 319 -121 425 -172 472 -193 708 -305 229
-109 308 -137 425 -149 207 -21 739 60 1348 205 127 30 390 91 582 135 448
103 835 199 950 237 50 17 95 30 101 31 7 1 38 -12 69 -29z m-7138 -2885 c19
-75 31 -207 53 -590 26 -449 43 -683 81 -1140 44 -524 43 -735 -3 -735 -7 0
-26 14 -43 30 -24 24 -46 33 -96 41 -111 18 -262 87 -414 190 -87 58 -118 91
-143 149 -25 57 -66 237 -92 410 -12 74 -41 216 -65 315 -24 99 -50 214 -59
255 -14 68 -165 875 -180 961 -9 50 7 85 46 105 28 15 69 18 273 20 163 2 287
10 385 22 80 11 168 19 195 18 l50 -1 12 -50z m5413 -1709 c0 -8 -21 -55 -46
-105 -98 -196 -232 -295 -564 -414 -283 -102 -415 -102 -621 2 -182 92 -200
94 -403 46 -127 -30 -231 -42 -240 -27 -4 7 4 14 21 17 102 21 248 79 588 233
411 186 471 201 910 236 365 29 355 29 355 12z"
        />
        <path
          d="M2703 19509 c-141 -18 -486 -119 -631 -184 -34 -15 -68 -38 -76 -50
-8 -12 -30 -25 -48 -29 -89 -17 -441 -350 -505 -478 -76 -151 -89 -481 -28
-694 69 -238 196 -392 475 -577 175 -115 263 -152 498 -211 292 -73 470 -97
717 -98 339 -1 604 52 888 178 120 53 191 95 316 189 211 158 338 288 360 370
6 22 27 60 46 84 47 59 66 125 72 259 10 201 -27 378 -108 515 -132 223 -289
367 -549 505 -122 65 -339 159 -394 172 -196 44 -839 75 -1033 49z m247 -246
c0 -47 -31 -566 -36 -590 -6 -33 -24 -29 -83 22 -64 55 -198 143 -379 251 -74
43 -150 93 -170 110 l-35 30 34 26 c43 33 175 88 309 128 95 28 202 45 318 49
39 1 42 -1 42 -26z m503 16 c68 -10 113 -27 334 -129 115 -54 166 -110 99
-110 -46 0 -297 -138 -511 -280 -38 -26 -91 -59 -117 -74 l-48 -28 0 316 0
316 86 0 c47 0 118 -5 157 -11z m-1430 -386 c18 -9 59 -42 92 -73 33 -32 91
-72 130 -91 71 -35 428 -269 421 -276 -5 -5 -719 15 -821 23 -44 4 -95 12
-112 20 -28 11 -33 18 -33 48 0 66 104 232 203 324 51 46 72 50 120 25z m2165
-3 c42 -26 123 -111 169 -177 41 -59 63 -132 54 -177 -6 -25 -13 -32 -41 -38
-98 -21 -731 -27 -827 -8 -2 0 -3 6 -3 13 0 26 396 296 565 386 48 25 43 25
83 1z m-1522 -613 c-148 -95 -211 -141 -271 -198 -46 -44 -88 -75 -116 -84
-24 -9 -83 -40 -130 -70 -165 -104 -191 -107 -264 -32 -84 86 -215 293 -215
339 0 11 11 23 27 29 45 17 264 26 638 27 274 1 346 -2 331 -11z m1519 3 c228
-13 241 -18 239 -97 -2 -74 -70 -199 -155 -287 -63 -64 -70 -68 -100 -62 -19
3 -81 34 -139 68 -100 58 -519 330 -569 369 -23 19 -19 19 255 19 154 0 365
-5 469 -10z m-745 -337 c52 -39 160 -113 240 -165 162 -106 200 -139 181 -161
-18 -23 -214 -97 -337 -128 -61 -15 -157 -31 -213 -35 l-101 -7 0 343 0 342
68 -59 c37 -33 110 -91 162 -130z m-504 135 c-3 -18 -8 -125 -11 -238 -8 -249
-19 -338 -45 -364 -15 -16 -32 -19 -84 -18 -166 2 -536 141 -536 200 0 24 22
44 90 79 79 42 294 178 445 281 72 50 134 90 138 91 4 0 6 -14 3 -31z"
        />
        <path
          d="M7520 12662 l-85 -17 -3 -603 -2 -602 354 0 354 0 6 103 c22 409 12
1055 -17 1084 -32 33 -117 45 -322 49 -159 3 -217 0 -285 -14z m314 -217 c27
-27 31 -83 31 -415 0 -336 -6 -390 -42 -404 -23 -9 -62 20 -78 57 -24 58 -35
186 -35 412 0 312 10 348 102 364 4 0 13 -6 22 -14z"
        />
        <path
          d="M8082 7199 c-97 -11 -144 -28 -167 -62 -34 -53 -43 -328 -28 -925 l6
-272 354 0 353 0 0 33 c1 17 5 200 10 406 9 406 1 686 -23 743 -19 46 -70 66
-196 78 -112 11 -194 11 -309 -1z m213 -263 c33 -33 39 -111 32 -390 -8 -301
-30 -404 -90 -420 -30 -7 -48 13 -58 66 -14 74 -21 476 -11 608 9 109 13 126
32 142 30 25 66 23 95 -6z"
        />
        <path
          d="M2395 6489 c-102 -6 -147 -14 -300 -58 -405 -117 -643 -241 -841
-436 -139 -137 -220 -273 -265 -440 -28 -104 -31 -322 -6 -435 28 -127 75
-234 127 -294 29 -34 51 -70 58 -97 19 -73 122 -162 324 -278 234 -135 474
-225 642 -242 117 -11 189 -23 266 -44 156 -43 389 -39 653 10 433 80 759 225
983 437 203 192 315 408 343 659 12 107 1 190 -40 313 -37 112 -80 188 -124
222 -23 17 -39 42 -51 77 -22 68 -99 143 -241 237 -235 155 -553 294 -767 335
-145 28 -555 46 -761 34z m620 -260 c126 -25 271 -74 399 -133 91 -42 98 -48
80 -60 -10 -7 -100 -64 -199 -126 -99 -63 -231 -146 -293 -187 -62 -40 -120
-73 -128 -73 -30 0 -37 54 -42 334 l-4 278 43 -7 c24 -4 89 -16 144 -26z
m-530 -9 c16 -17 20 -46 26 -168 9 -173 11 -452 4 -452 -19 0 -529 336 -619
408 l-69 56 74 34 c209 96 360 140 485 141 69 1 84 -2 99 -19z m1345 -417 c67
-71 171 -207 196 -255 25 -48 15 -80 -31 -103 -38 -19 -60 -20 -447 -20 -438
0 -462 3 -408 46 38 30 117 69 139 69 11 0 49 25 83 56 113 101 362 274 394
274 6 0 40 -30 74 -67z m-2145 -7 c47 -24 195 -118 330 -208 l244 -163 -377
-3 c-341 -3 -527 5 -577 24 -23 9 -15 39 40 149 52 104 122 202 162 227 44 28
85 22 178 -26z m2274 -605 c35 -6 69 -15 75 -21 19 -19 -105 -240 -201 -358
-44 -54 -47 -54 -213 40 -221 125 -485 305 -485 331 0 8 39 14 120 18 159 9
623 1 704 -10z m-1764 -8 c11 -7 15 -13 8 -13 -6 0 -147 -90 -314 -201 -233
-155 -307 -199 -324 -195 -58 15 -252 271 -262 345 -10 74 29 80 492 78 278
-1 385 -5 400 -14z m330 -245 c-12 -189 -36 -422 -48 -478 -11 -48 -16 -55
-38 -58 -57 -7 -347 64 -491 120 -93 35 -98 49 -35 103 39 35 603 415 614 415
3 0 2 -46 -2 -102z m386 46 c50 -41 82 -59 126 -69 21 -4 37 -16 45 -33 13
-26 36 -43 195 -145 72 -46 227 -176 220 -183 -9 -9 -443 -135 -491 -142 -112
-17 -141 9 -156 138 -11 96 -14 453 -3 463 11 12 20 8 64 -29z"
        />
      </g>
    </svg>
  );
};

// 2. 프론트펜더      3. 도어       6. 리어펜더
// 8. 라커패널       14. 필러
const LeftSide = ({ data, ...props }: any) => {
  const classes = useStyles(styles);

  return (
    <>
      <div
        className={
          props.className !== undefined
            ? `${classes.container} ${props.className}`
            : `${classes.container}`
        }
      >
        <LeftSideImage className={classes.image} />
        {data.inspection !== undefined &&
          isNotNull(data.inspection.front_fender_l) && (
            <StatusMark
              className={classes.front_fender_l}
              status={data.inspection.front_fender_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.door_f_l) && (
            <StatusMark
              className={classes.door_f_l}
              status={data.inspection.door_f_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.door_r_l) && (
            <StatusMark
              className={classes.door_r_l}
              status={data.inspection.door_r_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.rear_fender_l) && (
            <StatusMark
              className={classes.rear_fender_l}
              status={data.inspection.rear_fender_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.rocker_panel_l) && (
            <StatusMark
              className={classes.rocker_panel_l}
              status={data.inspection.rocker_panel_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.pillar_a_l) && (
            <StatusMark
              className={classes.pillar_a_l}
              status={data.inspection.pillar_a_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.pillar_b_l) && (
            <StatusMark
              className={classes.pillar_b_l}
              status={data.inspection.pillar_b_l}
            />
          )}
        {data.inspection !== undefined &&
          isNotNull(data.inspection.pillar_c_l) && (
            <StatusMark
              className={classes.pillar_c_l}
              status={data.inspection.pillar_c_l}
            />
          )}
      </div>
    </>
  );
};

export default LeftSide;
