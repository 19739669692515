import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch } from "@/common/lib/redux/store";
import { setConfirmText } from "@/common/lib/redux/actions/commonAction";
// Components
import { Theme, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Button } from "@/common/components";
import { CarIcon, TruckIcon, BusIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { isEqual } from "lodash";

const styles = (theme: Theme) => ({
  title: {
    paddingBottom: 10,
    borderBottom: "1px solid " + theme.palette.text.primary,
    display: "flex",
    alignItems: "flex-start",
    "& > svg": {
      fontSize: "3.85rem",
      color: theme.palette.primary.main,
      marginRight: 10,
    },
    "& > div": {
      "& > .MuiTypography-body1 > span": {
        fontSize: "1.5rem",
        fontWeight: "bold",
        userSelect: "none",
        "& > span": {
          fontSize: "1.15rem",
        },
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.975rem",
        userSelect: "none",
        lineHeight: "1.35",
      },
      "& > .MuiTypography-caption": {
        display: "block",
        color: theme.palette.error.main,
        fontSize: "0.875rem",
        fontWeight: "bold",
        userSelect: "none",
      },
    },
  },
  table: {
    width: 540,
    margin: "75px auto 50px",
    aspectRatio: 1,
    borderCollapse: "collapse",
    borderSpacing: 0,
    "& td": {
      border: "1px solid " + theme.palette.divider,
      padding: 30,
      "&:hover, &.active": {
        backgroundColor: colors.blue[1],
        "& svg": {
          fill: theme.palette.primary.main,
        },
        "& span": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  item: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    userSelect: "none",
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      aspectRatio: 1,
      backgroundColor: "white",
      border: "1px solid " + theme.palette.divider,
      borderRadius: "50%",
      overflow: "hidden",
      "& > svg": {
        width: "85%",
        aspectRatio: 1,
        fill: colors.gray[3],
      },
    },
    "& > span": {
      fontSize: "1.2rem",
      fontWeight: 500,
      color: colors.gray[6],
      marginTop: 10,
    },
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 75,
    "& > button": {
      width: 175,
      height: 60,
      margin: "0 30px",
      borderRadius: 30,
      fontSize: "1.125rem",
      "&.prev::before": {
        content: "'<'",
        paddingRight: 8,
      },
      "&.next::after": {
        content: "'>'",
        paddingLeft: 8,
      },
    },
  },
  bottom: {
    paddingTop: 10,
    borderTop: "1px solid " + theme.palette.text.primary,
    "& > .MuiTypography-body2": {
      fontSize: "0.975rem",
      userSelect: "none",
    },
    "& > .MuiTypography-caption": {
      display: "block",
      color: theme.palette.error.main,
      fontSize: "0.875rem",
      fontWeight: "bold",
      userSelect: "none",
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
  prev: Function;
  next: Function;
}

const RegisterTypeContainer = ({ value, dispatch, prev, next }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const _dispatch = useTypedDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(
    value.product.type !== "" ? true : false
  );

  const handleOnClick = (type: string) => {
    const initData = {
      product: {
        uid: "",
        type: value.product.type,
        year: 0,
        maker: "",
        model: "",
        detail_model: "",
        mileage: 0,
        vin: value.product.vin,
        is_restamp: false,
        mission: "",
        steering: "LHD",
        exhaust: 0,
        horse_power: null,
        fuel: "",
        width: 0,
        height: 0,
        length: 0,
        color: "",
        door: 0,
      },
      media: {
        image: [],
        youtube: "",
        dropbox: "",
      },
      sales: {
        price: 0,
        discount: null,
        discount_created: null,
        discount_expired: null,
        status: "WAIT",
        location_country: "",
        location_region: "",
        advert_text: "",
        advert_explain: "",
        memo: "",
      },
      condition: {
        engine: 0,
        mission: 0,
        brake: 0,
        steering: 0,
        exhaust: 0,
        is_4WD: "",
        is_audio: "",
        is_heater: "",
        is_aircon: "",
        is_window: "",
      },
      inspection: {
        corrosion: "L",
        hole: "N",
        crack: "N",
        accident_history: "",
        simple_repair: "",
        bonnet: "",
        front_fender_l: "",
        front_fender_r: "",
        door_f_l: "",
        door_f_r: "",
        door_r_l: "",
        door_r_r: "",
        trunk_lid: "",
        radiator_support: "",
        rear_fender_l: "",
        rear_fender_r: "",
        roof_panel: "",
        rocker_panel_l: "",
        rocker_panel_r: "",
        front_panel: "",
        cross_member: "",
        inside_panel_l: "",
        inside_panel_r: "",
        trunk_floor: "",
        rear_panel: "",
        side_member_f_l: "",
        side_member_f_r: "",
        side_member_r_l: "",
        side_member_r_r: "",
        wheelhouse_f_l: "",
        wheelhouse_f_r: "",
        wheelhouse_r_l: "",
        wheelhouse_r_r: "",
        pillar_a_l: "",
        pillar_a_r: "",
        pillar_b_l: "",
        pillar_b_r: "",
        pillar_c_l: "",
        pillar_c_r: "",
        package_tray: "",
        dash_panel: "",
        floor_panel: "",
      },
      special_point: undefined,
      car_product: undefined,
      truck_product: undefined,
      bus_product: undefined,
      car_option: undefined,
      truck_option: undefined,
      bus_option: undefined,
    };

    if (isDisabled) {
      if (isEqual(value, initData)) {
        dispatch({
          ...value,
          product: {
            ...value.product,
            type: type,
          },
        });
      } else {
        _dispatch(
          setConfirmText({
            title: t("DIALOG.TITLE.CONFIRM_CHANGE_TYPE"),
            content: t("DIALOG.CONTENT.INIT_VEHICLE_DATA"),
            onApply: () => {
              dispatch({
                ...initData,
                product: {
                  ...initData.product,
                  type: type,
                },
              });
            },
            onClose: () => {},
          })
        );
      }
    } else {
      setIsDisabled(true);
      dispatch({
        ...value,
        product: {
          ...value.product,
          type: type,
        },
      });
    }
  };

  return (
    <>
      <div className={classes.title}>
        <HelpIcon />
        <div>
          <Typography variant="body1">
            <Markup content={t("REGISTER.TYPE.TITLE")} />
          </Typography>
          <Typography variant="body2">
            {t("REGISTER.TYPE.CONTENT_1")}
          </Typography>
          <Typography variant="caption">
            {t("REGISTER.TYPE.CONTENT_2")}
          </Typography>
        </div>
      </div>
      <table className={classes.table}>
        <colgroup>
          <col style={{ width: "50%" }} />
          <col style={{ width: "50%" }} />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <td
              className={value.product.type === "CAR" ? "active" : undefined}
              onClick={() => handleOnClick("CAR")}
            >
              <div className={classes.item}>
                <div>
                  <CarIcon />
                </div>
                <span>{t("VEHICLE.TYPE.CAR")}</span>
              </div>
            </td>
            <td
              className={value.product.type === "TRUCK" ? "active" : undefined}
              onClick={() => handleOnClick("TRUCK")}
            >
              <div className={classes.item}>
                <div>
                  <TruckIcon />
                </div>
                <span>{t("VEHICLE.TYPE.TRUCK")}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td
              className={value.product.type === "BUS" ? "active" : undefined}
              onClick={() => handleOnClick("BUS")}
            >
              <div className={classes.item}>
                <div>
                  <BusIcon />
                </div>
                <span>{t("VEHICLE.TYPE.BUS")}</span>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className={classes.buttonDiv}>
        <Button variant="contained" className="prev" onClick={() => prev()}>
          {t("BUTTON.PREV")}
        </Button>
        <Button variant="contained" className="next" onClick={() => next()}>
          {t("BUTTON.NEXT")}
        </Button>
      </div>
      <div className={classes.bottom}>
        <Typography variant="body2">{t("REGISTER.BOTTOM_1")}</Typography>
        <Typography variant="caption">{t("REGISTER.BOTTOM_2")}</Typography>
      </div>
    </>
  );
};

export default RegisterTypeContainer;
