// Components
import BasePage from "@/browser/pages/common/BasePage";
import MyInfoContainer from "@/browser/components/user/MyInfoContainer";

const MyInfoPage = () => {
  return (
    <BasePage>
      <MyInfoContainer />
    </BasePage>
  );
};

export default MyInfoPage;
