/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Markup } from "interweave";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import {
  IconButton,
  InputAdornment,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CollectionsIcon from "@mui/icons-material/Collections";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Image, Input, PhoneInput } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { checkUserDuplicate, postUserInfo } from "@/common/lib/api/user";
import { checkGroupDuplicate, getGroupInfo } from "@/common/lib/api/group";
// Libs
import {
  isNull,
  isNotNull,
  getDateFormat,
  getTokenData,
  resizeImage,
  validatePassword,
} from "@/common/lib/common";
import { emailRegex, phoneRegex } from "@/common/lib/regex";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
  },
  content: {
    display: "flex",
    width: 1473,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: 1473,
    height: 600,
    "& > .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 500,
      height: 200,
      margin: "0 auto",
      padding: 45,
      "& > div.title": {
        display: "flex",
        marginBottom: 15,
        "& > svg": {
          fontSize: "3.5rem",
          color: theme.palette.primary.main,
          marginRight: 10,
        },
        "& > div": {
          userSelect: "none",
          "& > .MuiTypography-body1": {
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.825rem",
          },
        },
      },
      "& > div.content": {
        "& > button": {
          margin: "0 10px",
        },
      },
    },
  },
  groupContent: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: 1473,
    padding: "12.5px 45px",
    marginBottom: 30,
    background: theme.palette.primary.main,
    userSelect: "none",
    "& > div.left": {
      display: "flex",
      alignItems: "center",
      "& > div:first-child": {
        width: 50,
        height: 50,
        marginRight: 10,
        "& > .MuiSvgIcon-root": {
          width: "100%",
          height: "100%",
          color: "white",
        },
        "& > img": {
          width: "100%",
          aspectRatio: "1",
          borderRadius: "50%",
          objectFit: "cover",
          padding: 5,
        },
      },
      "& > div:last-child": {
        "& > .MuiTypography-root": {
          fontSize: 14,
          fontWeight: 500,
          color: "white",
        },
        "& > div": {
          display: "flex",
          alignItems: "flex-end",
          "& > .MuiTypography-body1": {
            fontSize: 17,
            fontWeight: 700,
            color: "white",
          },
          "& > .MuiTypography-body2": {
            fontSize: 14,
            fontWeight: 500,
            color: "white",
            marginLeft: 35,
          },
        },
      },
    },
    "& > div.right": {
      "& > .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 500,
        color: "white",
        textAlign: "right",
      },
    },
  },
  imageContent: {
    width: 235,
    marginLeft: 45,
    marginRight: 45,
    "& > div": {
      position: "relative",
      width: "100%",
      "& > .MuiSvgIcon-root": {
        width: "100%",
        height: "100%",
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& > img": {
        width: "100%",
        aspectRatio: "1",
        borderRadius: "50%",
        objectFit: "cover",
        padding: 20,
      },
      "& > .MuiIconButton-root": {
        position: "absolute",
        bottom: 5,
        right: 5,
        border: "3.5px solid rgba(0, 0, 0, 0.54)",
        "& > svg": {
          fontSize: "1.5rem",
        },
        "&:hover": {
          borderColor: theme.palette.primary.main,
          "& > svg": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  mainContent: {
    width: 640,
    padding: 20,
    marginRight: 45,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: 3,
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: 3,
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: 10,
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > div.subContent": {
        display: "flex",
        "& > div:first-child": {
          width: 275,
          "& > p": {
            userSelect: "none",
            fontSize: 15,
            fontWeight: "bold",
            "&.require-value::after": {
              content: "'*'",
              color: theme.palette.error.main,
              padding: 3,
            },
            "&.unique-value::after": {
              content: "'**'",
              color: theme.palette.error.main,
              padding: 3,
            },
          },
          "& > .MuiFormControl-root": {
            marginBottom: 10,
            "&.good": {
              "& > div": {
                color: theme.palette.primary.main,
              },
              "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
              },
            },
            "&.bad": {
              "& > div": {
                color: theme.palette.error.main,
              },
              "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
                color: theme.palette.error.main,
              },
            },
          },
        },
        "& > div:last-child": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          width: 240,
          marginLeft: 45,
          marginBottom: 5,
          "& > p": {
            userSelect: "none",
            fontSize: 14,
            fontWeight: "bold",
          },
          "& > ul": {
            margin: 0,
            marginBottom: 10,
            paddingLeft: 20,
            userSelect: "none",
            "& > li": {
              fontSize: 13,
              "&::marker": {
                fontSize: 12,
              },
              "&.bad": {
                color: theme.palette.error.main,
                fontWeight: "bold",
                "&:not(.special)::after": {
                  content: "'X'",
                  padding: "0 3px",
                },
              },
              "&.good": {
                color: theme.palette.primary.main,
                fontWeight: "bold",
                "&:not(.special)::after": {
                  content: "'O'",
                  padding: "0 3px",
                },
              },
              "&.special": {
                listStyleType: "none",
              },
            },
          },
        },
      },
      "& > button": {
        marginTop: 15,
        padding: ".5em 1em",
        fontSize: "1.125rem",
        fontWeight: "bold",
      },
    },
  },
  noticeContent: {
    width: 463,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > ul": {
      margin: 0,
      paddingLeft: 30,
      userSelect: "none",
      "& > li": {
        fontSize: 13,
        margin: "5px 0",
        "&::marker": {
          fontSize: 12,
        },
        "& > span > span": {
          color: theme.palette.error.main,
          fontWeight: "bold",
          padding: "0 3px",
        },
        "&.listStyleNone": {
          fontSize: "0.8rem",
          listStyleType: "none",
        },
      },
    },
  },
});

const RegisterContainer = () => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const [guid, setGuid] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState(false);
  const [groupNameHelperText, setGroupNameHelperText] = useState<string | null>(
    ""
  );
  const [groupData, setGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });

  const [image, setImage] = useState<any>();
  const [userID, setUserID] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isCheckID, setIsCheckID] = useState(false);
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isCheckPhone, setIsCheckPhone] = useState(false);

  const [errorID, setErrorID] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [helperTextID, setHelperTextID] = useState<string | null>("");
  const [helperTextEmail, setHelperTextEmail] = useState<string | null>("");
  const [helperTextPhone, setHelperTextPhone] = useState<string | null>("");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const groupInfoQuery = useQuery(
    ["groupInfo", guid],
    () => {
      if (guid !== undefined) {
        dispatch(loadingOn());
        return getGroupInfo(guid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const checkGroupMutation = useMutation(checkGroupDuplicate, {
    onSuccess: (response) => {
      if (response.data.guid !== null) {
        setGroupNameHelperText(t("GROUP.MSG.FOUND_GROUP"));
      } else {
        setGroupNameHelperText(t("GROUP.MSG.NOT_FOUND_GROUP"));
        setGroupNameError(true);
      }
      setGuid(response.data.guid);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkIDMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== null) {
        setHelperTextID(t("USER.MSG.ALREADY_EXIST_ID"));
        setErrorID(true);
      } else {
        setHelperTextID(t("USER.MSG.AVAILABLE_ID"));
      }
      setIsCheckID(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkEmailMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== null) {
        setHelperTextEmail(t("USER.MSG.ALREADY_EXIST_EMAIL"));
        setErrorEmail(true);
      } else {
        setHelperTextEmail(t("USER.MSG.AVAILABLE_EMAIL"));
      }
      setIsCheckEmail(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkPhoneMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== null) {
        setHelperTextPhone(t("USER.MSG.ALREADY_EXIST_PHONE"));
        setErrorPhone(true);
      } else {
        setHelperTextPhone(t("USER.MSG.AVAILABLE_PHONE"));
      }
      setIsCheckPhone(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const postUserInfoMutation = useMutation(postUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_POST_USER_INFO"), {
        variant: "success",
      });
      navigate("/group/info");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const InputProps = (key: string) => {
    const onClick = () => {
      if (key === "id") {
        if (isNotNull(userID)) {
          if (userID.length >= 8) {
            dispatch(loadingOn());
            checkIDMutation.mutate({ data: { username: userID } });
          } else {
            setErrorID(true);
            setHelperTextID(t("USER.MSG.INPUT_AT_LEAST_CHAR", { length: 8 }));
          }
        } else {
          setErrorID(true);
          setHelperTextID(t("USER.MSG.INPUT_ID"));
        }
      } else if (key === "email") {
        if (isNotNull(email)) {
          if (emailRegex.test(email)) {
            dispatch(loadingOn());
            checkEmailMutation.mutate({ data: { email: email } });
          } else {
            setErrorEmail(true);
            setHelperTextEmail(t("USER.MSG.INVALID_EMAIL"));
          }
        } else {
          setErrorEmail(true);
          setHelperTextEmail(t("USER.MSG.INPUT_EMAIL"));
        }
      } else if (key === "phone") {
        if (isNotNull(phone)) {
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber !== undefined && phoneNumber.isValid()) {
            dispatch(loadingOn());
            checkPhoneMutation.mutate({ data: { phone: phone } });
          } else {
            setErrorPhone(true);
            setHelperTextPhone(t("USER.MSG.INVALID_PHONE"));
          }
        } else {
          setErrorPhone(true);
          setHelperTextPhone(t("USER.MSG.INPUT_PHONE"));
        }
      }
    };

    return {
      endAdornment: (
        <InputAdornment position="end">
          <CheckIcon onClick={() => onClick()} />
        </InputAdornment>
      ),
    };
  };
  const getClassName = (code: number) => {
    let result = "";

    if (isNotNull(password) && isNotNull(passwordConfirm)) {
      if (password === passwordConfirm) {
        if (errorCode & code) {
          return "bad";
        } else {
          return "good";
        }
      }
    }
    return result;
  };
  const handleOnSearch = async () => {
    if (isNull(groupName)) {
      setGroupNameError(true);
      setGroupNameHelperText(t("GROUP.MSG.INPUT_GROUP_NAME"));
    } else {
      checkGroupMutation.mutateAsync({ data: { name: groupName } });
    }
  };
  const handlePasswordOnBlur = () => {
    if (isNotNull(password) && isNotNull(passwordConfirm)) {
      if (password === passwordConfirm) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
  };
  const handleOnSubmit = () => {
    dispatch(
      setConfirmText({
        title: t("DIALOG.TITLE.CONFIRM_CREATE_STAFF"),
        content: t("DIALOG.CONTENT.NOTICE_CREATE_STAFF"),
        onApply: async () => {
          if (!isCheckID) {
            enqueueSnackbar(t("USER.MSG.CHECK_EXIST_ID"), {
              variant: "warning",
            });
            return;
          }
          if (!isCheckEmail) {
            enqueueSnackbar(t("USER.MSG.CHECK_EXIST_EMAIL"), {
              variant: "warning",
            });
            return;
          }
          if (!isCheckPhone) {
            enqueueSnackbar(t("USER.MSG.CHECK_EXIST_PHONE"), {
              variant: "warning",
            });
            return;
          }

          if (errorID) {
            enqueueSnackbar(t("USER.MSG.CHECK_ID"), {
              variant: "warning",
            });
            return;
          }
          if (errorEmail) {
            enqueueSnackbar(t("USER.MSG.CHECK_EMAIL"), {
              variant: "warning",
            });
            return;
          }
          if (errorPhone) {
            enqueueSnackbar(t("USER.MSG.CHECK_PHONE"), {
              variant: "warning",
            });
            return;
          }
          if (error || errorCode !== 0) {
            enqueueSnackbar(t("USER.MSG.CHECK_PASSWORD"), { variant: "error" });
            return;
          }

          // 필수값 확인
          if (
            isNull(userID) ||
            isNull(userName) ||
            isNull(password) ||
            isNull(email) ||
            isNull(phone) ||
            isNull(groupData["id"])
          ) {
            enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
              variant: "error",
            });
            return;
          }

          const asyncFunction = async () => {
            const sendData: { [key: string]: any } = {};
            sendData["name"] = userName;
            sendData["username"] = userID;
            sendData["password"] = password;
            sendData["image"] = undefined;
            sendData["email"] = email;
            sendData["phone"] = phone.replaceAll(" ", "");
            sendData["nationality"] = "S2";
            sendData["group"] = groupData["id"];
            sendData["is_staff"] = true;

            if (isNotNull(image)) {
              const fileData = await resizeImage(
                image,
                320,
                240,
                100,
                0,
                "blob"
              );
              const blobData = fileData as BlobPart;
              sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
                type: "image/jpg",
              });
            }

            dispatch(loadingOn());
            postUserInfoMutation.mutateAsync({ data: sendData });
          };
          asyncFunction();
        },
        onClose: () => {},
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  useEffect(() => {
    if (
      tokenData["guid"] !== undefined &&
      !tokenData["is_superuser"] &&
      (tokenData["is_manager"] || tokenData["is_staff"])
    ) {
      setGuid(tokenData["guid"]);
    }
  }, [tokenData]);

  useEffect(() => {
    if (isNotNull(guid)) {
      groupInfoQuery.refetch();
    }
  }, [guid]);

  useEffect(() => {
    setGroupNameError(false);
    setGroupNameHelperText("");
  }, [groupName]);

  useEffect(() => {
    if (groupInfoQuery.isSuccess) {
      if (isNull(groupData["id"])) {
        if (groupInfoQuery.data !== undefined) {
          const result = groupInfoQuery.data.data;

          if (tokenData["is_superuser"]) {
            dispatch(
              setConfirmText({
                title: groupName,
                content: t("STAFF.MSG.CREATE_STAFF"),
                onApply: async () => {
                  const recvData: { [key: string]: any } = {};
                  recvData["id"] = result["id"];
                  recvData["name"] = result["name"];
                  recvData["manager"] = {
                    id: result["manager"]["id"],
                    name: result["manager"]["name"],
                    username: result["manager"]["username"],
                    image: result["manager"]["image"],
                    email: result["manager"]["email"],
                    phone: result["manager"]["phone"],
                  };
                  recvData["image"] = result["image"];
                  recvData["address1"] = isNotNull(result["address1"])
                    ? result["address1"]
                    : "";
                  recvData["address2"] = isNotNull(result["address2"])
                    ? result["address2"]
                    : "";
                  recvData["address3"] = isNotNull(result["address3"])
                    ? result["address3"]
                    : "";
                  recvData["zip_code"] = isNotNull(result["zip_code"])
                    ? result["zip_code"]
                    : "";
                  recvData["country"] = isNotNull(result["country"])
                    ? result["country"]
                    : "";
                  recvData["city"] = isNotNull(result["city"])
                    ? result["city"]
                    : "";
                  recvData["state"] = isNotNull(result["state"])
                    ? result["state"]
                    : "";
                  recvData["count_product"] = result["count_product"];
                  recvData["limit_product"] = result["limit_product"];
                  recvData["count_staff"] = result["count_staff"];
                  recvData["limit_staff"] = result["limit_staff"];
                  recvData["created"] = result["created"];
                  recvData["expired"] = result["expired"];

                  setGroupData(recvData);
                },
                onClose: () => {
                  setGuid("");
                },
              })
            );
          } else {
            const recvData: { [key: string]: any } = {};
            recvData["id"] = result["id"];
            recvData["name"] = result["name"];
            recvData["manager"] = {
              id: result["manager"]["id"],
              name: result["manager"]["name"],
              username: result["manager"]["username"],
              image: result["manager"]["image"],
              email: result["manager"]["email"],
              phone: result["manager"]["phone"],
            };
            recvData["image"] = result["image"];
            recvData["address1"] = isNotNull(result["address1"])
              ? result["address1"]
              : "";
            recvData["address2"] = isNotNull(result["address2"])
              ? result["address2"]
              : "";
            recvData["address3"] = isNotNull(result["address3"])
              ? result["address3"]
              : "";
            recvData["zip_code"] = isNotNull(result["zip_code"])
              ? result["zip_code"]
              : "";
            recvData["country"] = isNotNull(result["country"])
              ? result["country"]
              : "";
            recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
            recvData["state"] = isNotNull(result["state"])
              ? result["state"]
              : "";
            recvData["count_product"] = result["count_product"];
            recvData["limit_product"] = result["limit_product"];
            recvData["count_staff"] = result["count_staff"];
            recvData["limit_staff"] = result["limit_staff"];
            recvData["created"] = result["created"];
            recvData["expired"] = result["expired"];

            setGroupData(recvData);
          }
        } else {
          setGuid("");
        }
      }
    }
  }, [groupInfoQuery.isLoading]);

  useEffect(() => {
    if (isNotNull(password) && isNotNull(passwordConfirm)) {
      if (password === passwordConfirm) {
        setErrorCode(validatePassword(password));
      } else {
        setErrorCode(0);
      }
    } else {
      setErrorCode(0);
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    setIsCheckID(false);
    setErrorID(false);
    setHelperTextID("");
  }, [userID]);

  useEffect(() => {
    setIsCheckEmail(false);
    setErrorEmail(false);
    setHelperTextEmail("");
  }, [email]);

  useEffect(() => {
    setIsCheckPhone(false);
    setErrorPhone(false);
    setHelperTextPhone("");
  }, [phone]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <AddCircleOutlineIcon />
          <Typography>{t("STAFF.TITLE_REGISTER")}</Typography>
        </div>
      </div>
      {isNull(groupData["id"]) ? (
        <div className={classes.searchContainer}>
          <Paper>
            <div className="title">
              <SearchIcon />
              <div>
                <Typography variant="body1">
                  {t("STAFF.SEARCH_GROUP")}
                </Typography>
                <Typography variant="body2">
                  {t("STAFF.MSG.INPUT_GROUP_NAME")}
                </Typography>
              </div>
            </div>
            <div className="content">
              <Input
                label={t("STAFF.GROUP_NAME")}
                value={groupName}
                dispatch={setGroupName}
                error={groupNameError}
                helperText={groupNameHelperText}
                inputProps={{
                  maxLength: 32,
                }}
                disabled={!tokenData["is_superuser"]}
              />
              <Button
                variant="outlined"
                onClick={() => handleOnSearch()}
                disabled={!tokenData["is_superuser"]}
              >
                {t("BUTTON.INQUIRE")}
              </Button>
            </div>
          </Paper>
        </div>
      ) : (
        <>
          <div className={classes.groupContent}>
            <div className="left">
              <div>
                {isNull(groupData["image"]) ? (
                  <AccountCircleIcon />
                ) : (
                  <Image alt={""} src={groupData["image"]} />
                )}
              </div>
              <div>
                <Typography>Group Info</Typography>
                <div>
                  <Typography variant="body1">{groupData["name"]}</Typography>
                  <Typography variant="body2">
                    {t("GROUP.MANAGER", {
                      manager: groupData["manager"]["username"],
                    })}
                  </Typography>
                  <Typography variant="body2">
                    {t("GROUP.CREATED_DATE", {
                      date: getDateFormat(groupData["created"], "yyyy-MM-dd"),
                    })}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="right">
              <Typography>
                {t("GROUP.STAFF_COUNT", {
                  staff: groupData["count_staff"].toLocaleString(),
                  limit: groupData["limit_staff"].toLocaleString(),
                })}
              </Typography>
              <Typography>
                {t("GROUP.PRODUCT_COUNT", {
                  product: groupData["count_product"].toLocaleString(),
                  limit: groupData["limit_product"].toLocaleString(),
                })}
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.imageContent}>
              <div>
                {isNull(image) ? (
                  <AccountCircleIcon
                    onDoubleClick={() => {
                      document.getElementById("imageUpload")?.click();
                    }}
                  />
                ) : (
                  <Image
                    alt={""}
                    src={image}
                    onDoubleClick={() => {
                      document.getElementById("imageUpload")?.click();
                    }}
                  />
                )}
                <IconButton
                  onClick={() => {
                    document.getElementById("imageUpload")?.click();
                  }}
                >
                  <CollectionsIcon />
                </IconButton>
                <input
                  type="file"
                  id="imageUpload"
                  name="imageUpload"
                  accept="image/jpg, image/gif, image/png, image/jpeg"
                  style={{ display: "none" }}
                  onChange={async (e: any) => {
                    const resizeFile = await resizeImage(
                      e.target.files[0],
                      320,
                      240,
                      75,
                      0,
                      "file"
                    );
                    setImage(resizeFile);
                  }}
                />
              </div>
            </div>
            <div className={classes.mainContent}>
              <Typography component="h1">Staff Info</Typography>
              <div>
                <div className="subContent">
                  <div>
                    <Typography className="unique-value">ID</Typography>
                    <Input
                      className={isCheckID ? (!errorID ? "good" : "bad") : ""}
                      variant="standard"
                      value={userID}
                      dispatch={setUserID}
                      error={errorID}
                      helperText={helperTextID}
                      fullWidth
                      InputProps={InputProps("id")}
                      inputProps={{
                        maxLength: 32,
                      }}
                    />
                    <Typography className="require-value">Password</Typography>
                    <Input
                      type="password"
                      variant="standard"
                      placeholder={t("USER.NEW_PASSWORD")}
                      error={error || errorCode !== 0}
                      value={password}
                      dispatch={(value: string) => {
                        setPassword(String(value).trim());
                      }}
                      onBlur={() => handlePasswordOnBlur()}
                      fullWidth
                      inputProps={{
                        maxLength: 32,
                      }}
                    />
                    <Typography className="require-value">
                      Confirm Password
                    </Typography>
                    <Input
                      type="password"
                      variant="standard"
                      placeholder={t("USER.NEW_PASSWORD_CONFIRM")}
                      error={error || errorCode !== 0}
                      helperText={error ? t("USER.MSG.PASSWORD_ERROR") : ""}
                      value={passwordConfirm}
                      dispatch={(value: string) => {
                        setPasswordConfirm(String(value).trim());
                      }}
                      onBlur={() => handlePasswordOnBlur()}
                      fullWidth
                      inputProps={{
                        maxLength: 32,
                      }}
                    />
                  </div>
                  <div>
                    <Typography style={{ marginBottom: 5 }}>
                      {t("USER.PASSWORD_CONDITION.TITLE")}
                    </Typography>
                    <ul>
                      <li className={getClassName(1)}>
                        {t("USER.PASSWORD_CONDITION.CONTENT_1")}
                      </li>
                      <li className={getClassName(2)}>
                        {t("USER.PASSWORD_CONDITION.CONTENT_2")}
                      </li>
                      <li className={getClassName(4)}>
                        {t("USER.PASSWORD_CONDITION.CONTENT_3")}
                      </li>
                      <li className={getClassName(8)}>
                        {t("USER.PASSWORD_CONDITION.CONTENT_4")}
                      </li>
                      <li className={`${getClassName(8)} special`}>
                        `~!@#$%^&*()-_=+{}[]:;'"
                      </li>
                    </ul>
                  </div>
                </div>
                <Typography className="require-value">Name</Typography>
                <Input
                  variant="standard"
                  value={userName}
                  dispatch={setUserName}
                  fullWidth
                  inputProps={{
                    maxLength: 32,
                  }}
                />
                <Typography className="unique-value">Email Address</Typography>
                <Input
                  className={isCheckEmail ? (!errorEmail ? "good" : "bad") : ""}
                  variant="standard"
                  value={email}
                  dispatch={setEmail}
                  error={errorEmail}
                  helperText={helperTextEmail}
                  placeholder="example@autoskorea.com"
                  fullWidth
                  InputProps={InputProps("email")}
                  inputProps={{
                    maxLength: 64,
                  }}
                />
                <Typography className="unique-value">Phone Number</Typography>
                <PhoneInput
                  className={isCheckPhone ? (!errorPhone ? "good" : "bad") : ""}
                  variant="standard"
                  value={phone}
                  dispatch={(value: string) => {
                    if (isNull(value) || phoneRegex.test(value)) {
                      if (
                        isNotNull(value) &&
                        value[0] !== "+" &&
                        value[0] !== " "
                      ) {
                        setPhone("+" + value);
                      } else {
                        setPhone(String(value).trim());
                      }
                    }
                  }}
                  placeholder="Please enter only digits except '-'"
                  error={errorPhone}
                  helperText={helperTextPhone}
                  fullWidth
                  InputProps={InputProps("phone")}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
                <Button
                  variant="contained"
                  onClick={() => handleOnSubmit()}
                  fullWidth
                >
                  {t("BUTTON.REGISTER")}
                </Button>
              </div>
            </div>
            <div className={classes.noticeContent}>
              <Typography component="h1">{t("GROUP.NOTICE")}</Typography>
              <ul>
                <li>
                  <Markup content={t("GROUP.MSG.REGISTER_NOTICE_1")} />
                </li>
                <li>
                  <Markup content={t("GROUP.MSG.REGISTER_NOTICE_2")} />
                </li>
                <li className="listStyleNone">
                  {t("GROUP.MSG.REGISTER_NOTICE_3")}
                </li>
                <li>{t("GROUP.MSG.REGISTER_NOTICE_4")}</li>
                <li>{t("GROUP.MSG.REGISTER_NOTICE_5")}</li>
                <li className="listStyleNone">
                  {t("GROUP.MSG.REGISTER_NOTICE_6")}
                </li>
                <li>{t("GROUP.MSG.REGISTER_NOTICE_7")}</li>
                <li>{t("GROUP.MSG.REGISTER_NOTICE_8")}</li>
                <li>{t("GROUP.MSG.REGISTER_NOTICE_9")}</li>
                <li>{t("GROUP.MSG.REGISTER_NOTICE_10")}</li>
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterContainer;
