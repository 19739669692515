import classnames from "classnames";
// Common Header, Footer
import { Footer, Header, Menu } from "@/browser/components/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = () => ({
  main: {
    width: "100%",
    minHeight: 900,
    margin: "0 auto",
  },
  menu: {
    width: 280,
  },
  content: {
    marginLeft: 280,
  },
});

const BasePage = ({ isHeader, isFooter, ...props }: { [key: string]: any }) => {
  const classes = useStyles(styles);

  return (
    <>
      <main>
        <Menu />
        <div className={classes.content}>
          {isHeader && <Header />}
          <div className={classnames(classes.main, props.className)}>
            {props.children}
          </div>
          {isFooter && <Footer />}
        </div>
      </main>
    </>
  );
};

BasePage.defaultProps = {
  isHeader: true,
  isFooter: true,
};

export default BasePage;
