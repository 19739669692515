import { AnyAction } from "redux";

import { REFETCH_ON, REFETCH_OFF } from "../types/vehicleManageType";

interface stateType {
  refetch: boolean;
}

const initialState: stateType = {
  refetch: false,
};

const vehicleManageReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case REFETCH_ON:
      return {
        ...state,
        refetch: true,
      };
    case REFETCH_OFF:
      return {
        ...state,
        refetch: false,
      };
    default:
      return state;
  }
};

export default vehicleManageReducer;
