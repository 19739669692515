const CarIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1, -0.1)">
        <path
          d="M1041 3654 c-30 -8 -76 -27 -104 -42 -43 -23 -566 -492 -755 -675
-95 -94 -94 -88 -90 -463 3 -306 4 -321 25 -367 75 -162 235 -267 409 -267 58
0 64 2 68 23 2 12 9 49 15 82 16 87 64 178 136 253 141 150 355 223 560 192
274 -41 478 -229 518 -476 l12 -74 727 0 728 0 0 33 c0 48 25 133 59 202 120
240 431 372 721 303 25 -5 83 -28 130 -51 157 -74 283 -229 311 -382 6 -33 13
-70 15 -82 4 -23 6 -23 172 -23 184 0 220 9 274 66 52 55 58 87 58 287 0 101
-4 209 -10 238 -26 139 -135 282 -267 351 -123 65 -153 70 -503 75 -278 5
-319 7 -350 23 -19 10 -208 174 -419 365 -232 210 -403 356 -432 371 -110 55
-89 54 -1059 53 -749 0 -904 -3 -949 -15z m789 -489 l0 -305 -707 0 -708 0 35
34 c104 100 591 536 611 546 47 26 82 28 422 29 l347 1 0 -305z m1095 283 c22
-11 527 -463 650 -579 5 -5 -300 -9 -758 -9 l-767 0 0 305 0 306 423 -3 c370
-3 426 -5 452 -20z"
        />
        <path
          d="M1097 2229 c-66 -15 -145 -59 -200 -109 -171 -156 -172 -413 -2 -568
226 -206 602 -142 727 124 126 270 -90 567 -411 563 -42 -1 -93 -5 -114 -10z"
        />
        <path
          d="M3770 2221 c-202 -65 -321 -224 -308 -412 25 -348 491 -506 762 -258
201 183 160 489 -84 632 -97 56 -261 73 -370 38z"
        />
      </g>
    </svg>
  );
};

export default CarIcon;
