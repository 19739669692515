const BusIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 512) scale(0.1, -0.1)">
        <path
          d="M323 3835 c-54 -27 -97 -78 -113 -136 -6 -21 -10 -411 -10 -1030 l0
-995 28 -55 c32 -63 87 -105 149 -115 l41 -6 -1 93 c-1 159 51 288 159 394
167 166 418 203 628 93 81 -43 182 -146 224 -228 43 -87 66 -196 58 -282 l-6
-68 973 0 972 0 -3 76 c-9 164 39 287 157 405 110 110 226 159 376 159 304 0
550 -259 533 -562 l-5 -78 127 0 c70 0 142 5 161 11 51 18 97 55 122 102 22
41 22 50 25 432 2 232 -1 443 -8 520 -11 127 -130 900 -151 980 -31 118 -121
223 -237 278 l-67 32 -2040 3 -2041 2 -51 -25z m947 -625 l0 -430 -427 2 -428
3 -3 370 c-3 396 0 429 45 464 25 20 37 21 419 21 l394 0 0 -430z m1078 3 l-3
-428 -427 -3 -428 -2 0 430 0 430 430 0 430 0 -2 -427z m1070 0 l-3 -428 -427
-3 -428 -2 0 430 0 430 430 0 430 0 -2 -427z m1015 411 c41 -21 85 -62 103
-96 22 -43 164 -933 164 -1030 l0 -38 -372 0 -373 0 -162 162 -163 163 0 427
0 428 385 0 c335 0 390 -2 418 -16z"
        />
        <path
          d="M835 1960 c-261 -86 -342 -423 -148 -618 91 -91 230 -129 353 -98
281 72 377 407 178 622 -90 98 -253 138 -383 94z"
        />
        <path
          d="M3867 1970 c-108 -28 -201 -106 -251 -210 -28 -58 -31 -75 -31 -155
1 -77 5 -99 29 -151 35 -77 112 -154 191 -191 51 -23 73 -27 145 -27 118 0
185 27 266 108 92 92 129 211 104 332 -35 170 -173 290 -343 299 -40 3 -89 0
-110 -5z"
        />
      </g>
    </svg>
  );
};

export default BusIcon;
