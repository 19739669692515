import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react";
// Components
import {
  default as ReactDatePicker,
  ReactDatePickerProps,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import { IconButton, InputAdornment, Theme } from "@mui/material";
import { Input } from "@/common/components";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    "& > div": {
      fontSize: "0.875em",
      fontWeight: "bold",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.primary.main,
      },
    },
    "& > button": {
      fontSize: "0.875em",
      padding: 0,
      margin: "0.15em 0.5em",
      "&:hover": {
        "& > svg": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
});

interface propType extends ReactDatePickerProps {
  label: string;
}

interface CustomHeaderType extends ReactDatePickerCustomHeaderProps {
  showMonth: boolean;
  setShowMonth: Dispatch<SetStateAction<boolean>>;
}

const CustomHeader = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  showMonth,
  setShowMonth,
}: CustomHeaderType) => {
  const classes = useStyles(styles);

  return (
    <div className={classes.header}>
      <IconButton
        onClick={() => decreaseMonth()}
        disabled={prevMonthButtonDisabled}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <div onClick={() => setShowMonth(!showMonth)}>
        {String(monthDate.getFullYear()).padStart(4, "0")}{" "}
        {String(monthDate.getMonth() + 1).padStart(2, "0")}
      </div>
      <IconButton
        onClick={() => increaseMonth()}
        disabled={nextMonthButtonDisabled}
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
};

const CustomInput = forwardRef((props: any, ref) => {
  return (
    <Input
      ref={ref}
      variant="standard"
      isClearable
      InputProps={{
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            <CalendarMonthIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});

const DatePicker = ({
  className,
  label,
  onChange,
  isClearable,
  ...props
}: propType) => {
  const classes = useStyles(styles);

  const [openToDate, setOpenToDate] = useState<Date>();
  const [showMonth, setShowMonth] = useState<boolean>(false);
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState<boolean>(true);

  useEffect(() => {
    setShouldCloseOnSelect(!showMonth);
  }, [showMonth]);

  return (
    <ReactDatePicker
      showMonthYearPicker={showMonth}
      renderCustomHeader={(props) => {
        return (
          <CustomHeader
            showMonth={showMonth}
            setShowMonth={setShowMonth}
            {...props}
          />
        );
      }}
      popperProps={{
        strategy: "fixed",
      }}
      customInput={
        <CustomInput
          label={label}
          value={props.selected}
          onClear={() => {
            onChange(null, undefined);
          }}
          isClearable={isClearable}
        />
      }
      onChange={(date, evt) => {
        if (showMonth) {
          setShowMonth(false);
          setOpenToDate(date !== null ? date : undefined);
        } else {
          onChange(date, evt);
          setOpenToDate(undefined);
        }
      }}
      onCalendarClose={() => {
        setOpenToDate(undefined);
        setShowMonth(false);
        setShouldCloseOnSelect(true);
      }}
      openToDate={openToDate}
      shouldCloseOnSelect={shouldCloseOnSelect}
      className={
        className !== undefined
          ? `${className} ${classes.datePicker}`
          : classes.datePicker
      }
      {...props}
    />
  );
};

export default DatePicker;
