/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  forwardRef,
  RefObject,
  useEffect,
  useState,
  useRef,
  ReactElement,
  HTMLInputTypeAttribute,
} from "react";
// Components
import {
  InputAdornment,
  TextField as MuiTextField,
  Theme,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { getType, isNotNull } from "@/common/lib/common";
import { numericRegex, integerRegex } from "@/common/lib/regex";

const styles = (theme: Theme, color: string) => ({
  root: {
    "& > .MuiInputBase-root": {
      width: "inherit",
      height: "inherit",
      fontSize: "inherit",
      borderRadius: "0.35em !important",
      "&.MuiInputBase-adornedStart > input": {
        paddingLeft: ".3em",
      },
      "&.MuiInputBase-adornedEnd > input": {
        paddingRight: ".3em",
      },
      "& > .MuiInputBase-input": {
        "&::-ms-clear": {
          display: "none",
          width: 0,
          height: 0,
        },
        "&::-ms-reveal": {
          display: "none",
          width: 0,
          height: 0,
        },
        "&::-webkit-search-decoration": {
          display: "none",
        },
        "&::-webkit-search-cancel-button": {
          display: "none",
        },
        "&::-webkit-search-results-button": {
          display: "none",
        },
        "&::-webkit-search-results-decoration": {
          display: "none",
        },
      },
      "& > .MuiInputAdornment-root": {
        margin: "0 !important",
        userSelect: "none",
        "& > .MuiSvgIcon-root": {
          "&:hover": {
            cursor: "pointer",
            color: eval(`theme.palette.${color}.main`),
          },
        },
      },
      "& > .MuiInputAdornment-outlined": {
        padding: ".6em 0",
      },
      "& > .MuiInputAdornment-filled": {
        padding: ".8em 0 .4em",
      },
      "& > .MuiInputAdornment-standard": {
        padding: ".8em 0 .4em",
      },
      "&::before": {
        borderBottom: "1px solid " + theme.palette.text.primary,
      },
      "&::after": {
        borderBottom: "1px solid " + eval(`theme.palette.${color}.main`),
      },
      "&:not(.Mui-error).Mui-focused": {
        color: eval(`theme.palette.${color}.main`),
        "& > .MuiInputAdornment-root": {
          "& > .MuiTypography-root": {
            color: eval(`theme.palette.${color}.main`),
          },
        },
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
        "& > .MuiInputAdornment-root": {
          "& > .MuiTypography-root": {
            color: theme.palette.error.main,
          },
        },
      },
    },
    "& > .MuiOutlinedInput-root": {
      "& > .MuiOutlinedInput-input": {
        padding: ".6em 1em",
      },
      "& > .MuiOutlinedInput-notchedOutline": {
        fontSize: "inherit",
        padding: "0 .5em",
        "& > legend": {
          fontSize: "inherit",
        },
        "& span": {
          fontSize: "inherit",
          "&.notranslate": {
            padding: 0,
          },
          "&:not(.notranslate)": {
            padding: "0.25em",
          },
        },
      },
      "&:not(.Mui-disabled)": {
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.text.primary,
        },
      },
      "&.Mui-focused": {
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: eval(`theme.palette.${color}.main`) + "!important",
        },
      },
      "&.Mui-error": {
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main + "!important",
        },
      },
    },
    "& > .MuiFilledInput-root": {
      marginTop: 0,
      padding: 0,
      "& > .MuiFilledInput-input": {
        padding: ".8em 1em .4em",
      },
    },
    "& > .MuiInput-root": {
      marginTop: 0,
      padding: 0,
      "& > .MuiInput-input": {
        padding: ".8em 0 .4em",
      },
    },
    "& > .MuiInputBase-multiline": {
      padding: 0,
    },
    "& > .MuiInputLabel-root": {
      height: "inherit",
      fontSize: "inherit",
      "&:not(.Mui-disabled)": {
        color: theme.palette.text.primary,
      },
      "&.Mui-focused:not(.Mui-error)": {
        color: eval(`theme.palette.${color}.main`),
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    "& > .MuiInputLabel-outlined": {
      transform: "translate(1em, .6em) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(1em, -.6em) scale(0.75)",
      },
    },
    "& > .MuiInputLabel-filled": {
      transform: "translate(1em, .6em) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(1em, 0) scale(0.75)",
      },
    },
    "& > .MuiInputLabel-standard": {
      transform: "translate(0, .6em) scale(1)",
      "&.MuiInputLabel-shrink": {
        transform: "translate(0, 0) scale(0.75)",
      },
    },
    "&.Size-tiny": {
      fontSize: "0.625rem",
      "& .MuiSvgIcon-root": {
        fontSize: "0.9375rem",
      },
    },
    "&.Size-small": {
      fontSize: "0.75rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.125rem",
      },
    },
    "&.Size-medium": {
      fontSize: "0.875rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.3125rem",
      },
    },
    "&.Size-large": {
      fontSize: "1.125rem",
      "& .MuiSvgIcon-root": {
        fontSize: "1.6875rem",
      },
    },
    "&.Size-huge": {
      fontSize: "1.375rem",
      "& .MuiSvgIcon-root": {
        fontSize: "2.0625rem",
      },
    },
    "&&:hover": {
      "& > .MuiInputLabel-root:not(.Mui-error):not(.Mui-disabled)": {
        color: eval(`theme.palette.${color}.main`),
      },
      "& > .MuiInputLabel-root.Mui-error:not(.Mui-disabled)": {
        color: theme.palette.error.main,
      },
      "& > .MuiInputBase-root:not(.Mui-error):not(.Mui-disabled)": {
        color: eval(`theme.palette.${color}.main`),
        "& > .MuiInputBase-input": {
          color: eval(`theme.palette.${color}.main`),
        },
        "& > .MuiInputAdornment-root": {
          "& > .MuiTypography-root": {
            color: eval(`theme.palette.${color}.main`),
          },
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: eval(`theme.palette.${color}.main`),
        },
        "&::before": {
          borderBottom: "1px solid " + eval(`theme.palette.${color}.main`),
        },
        "& > .MuiSvgIcon-root": {
          cursor: "pointer",
          color: eval(`theme.palette.${color}.main`),
        },
      },
      "& > .MuiInputBase-root.Mui-error:not(.Mui-disabled)": {
        color: theme.palette.error.main,
        "& > .MuiInputBase-input": {
          color: theme.palette.error.main,
        },
        "& > .MuiInputAdornment-root": {
          "& > .MuiTypography-root": {
            color: theme.palette.error.main,
          },
        },
        "& > .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.error.main,
        },
        "&::before": {
          borderBottom: "1px solid " + theme.palette.error.main,
        },
        "& > .MuiSvgIcon-root": {
          cursor: "pointer",
          color: theme.palette.error.main,
        },
      },
    },
  },
});

const Input = forwardRef<RefObject<ReactElement>, any>(
  (
    {
      type,
      size = "medium",
      color = "primary",
      value,
      dispatch,
      onBlur,
      onClear,
      isClearable = false,
      className,
      InputProps,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(ref);
    const theme = useTheme();
    const classes = useStyles(styles(theme, color));
    const [inputType, setInputType] = useState<
      HTMLInputTypeAttribute | undefined
    >(isNotNull(type) ? type : "text");
    const [inputValue, setInputValue] = useState<any>(
      value !== undefined
        ? type === "integer" || type === "float"
          ? value !== 0 && value !== null
            ? value
            : ""
          : value
        : ""
    );
    const [showPassword, setShowPassword] = useState<Boolean>(false);

    const onClose = (evt: any) => {
      evt.stopPropagation();

      if (onClear !== undefined && getType(onClear) === "Function") {
        onClear();
      }
      dispatch !== undefined ? dispatch("") : setInputValue("");
    };
    const onShowPassword = () => {
      setShowPassword((value) => !value);
    };
    const handleOnChange = (_value: any) => {
      if (type === "integer") {
        const newValue = String(_value)
          .replaceAll(",", "")
          .replace(/^0+([0-9]+)/, "$1");
        if (newValue === "") {
          setInputValue("");
          if (dispatch !== undefined) {
            dispatch(0);
          }
        } else if (integerRegex.test(newValue)) {
          setInputValue(parseInt(newValue));
          if (dispatch !== undefined) {
            dispatch(parseInt(newValue));
          }
        }
      } else if (type === "float") {
        const newValue = String(_value)
          .replaceAll(",", "")
          .replace(/^0+([0-9]+)/, "$1");
        if (newValue === "") {
          setInputValue("");
          if (dispatch !== undefined) {
            dispatch(0);
          }
        } else if (/^[0-9]+\.0?$/.test(newValue)) {
          setInputValue(_value);
          if (dispatch !== undefined) {
            dispatch(_value);
          }
        } else if (numericRegex.test(newValue)) {
          setInputValue(parseFloat(newValue));
          if (dispatch !== undefined) {
            dispatch(parseFloat(newValue));
          }
        }
      } else {
        setInputValue(_value);
        if (dispatch !== undefined) {
          dispatch(_value);
        }
      }
    };
    const handleOnBlur = (_value: any) => {
      if (onBlur !== undefined && getType(onBlur) === "Function") {
        if (type === "integer") {
          const newValue = String(_value)
            .replaceAll(",", "")
            .replace(/^0+([0-9]+)/, "$1");
          if (newValue === "") {
            onBlur(0);
          } else if (integerRegex.test(newValue)) {
            onBlur(parseInt(newValue));
          }
        } else if (type === "float") {
          const newValue = String(_value)
            .replaceAll(",", "")
            .replace(/^0+([0-9]+)/, "$1");
          if (newValue === "") {
            onBlur(0);
          } else if (numericRegex.test(newValue)) {
            onBlur(parseFloat(newValue));
          }
        } else {
          onBlur(_value);
        }
      }
    };

    const getValue = (_value: any) => {
      if (type === "integer") {
        if (_value === null) {
          return "";
        }
        const newValue = String(_value)
          .replaceAll(",", "")
          .replace(/^0+([0-9]+)/, "$1");
        if (newValue === "" || Number(newValue) === 0) {
          return "";
        }
        return Number(newValue).toLocaleString();
      } else if (type === "float") {
        if (_value === null) {
          return "";
        }
        const newValue = String(_value)
          .replaceAll(",", "")
          .replace(/^0+([0-9]+)/, "$1");
        if (newValue === "" || Number(newValue) === 0) {
          return "";
        } else if (/^[0-9]+\.0?$/.test(newValue)) {
          return _value;
        }
        return Number(_value).toLocaleString();
      }
      return _value;
    };
    const getClassName = () => {
      const results: string[] = [];
      if (size === "tiny") {
        results.push("Size-tiny");
      } else if (size === "small") {
        results.push("Size-small");
      } else if (size === "medium") {
        results.push("Size-medium");
      } else if (size === "large") {
        results.push("Size-large");
      } else if (size === "huge") {
        results.push("Size-huge");
      }
      return results.join(" ");
    };
    const defaultInputProps = {
      endAdornment:
        type === "password" ? (
          <InputAdornment position="end">
            {showPassword ? (
              <VisibilityOff onClick={onShowPassword} />
            ) : (
              <Visibility onClick={onShowPassword} />
            )}
          </InputAdornment>
        ) : (value !== undefined
            ? type === "integer" || type === "float"
              ? value !== 0
              : isNotNull(value)
            : type === "integer" || type === "float"
            ? inputValue !== 0
            : isNotNull(inputValue)) &&
          (type === "search" || isClearable) ? (
          <InputAdornment position="end">
            <CloseIcon onClick={onClose} />
          </InputAdornment>
        ) : undefined,
    };

    useEffect(() => {
      if (type === "text" || type === "integer" || type === "float") {
        setInputType("text");
      } else if (type === "password") {
        if (showPassword) {
          setInputType("text");
        } else {
          setInputType("password");
        }
      } else if (type === "search") {
        setInputType("search");
      }
    }, [type, showPassword]);

    return (
      <MuiTextField
        type={inputType}
        value={getValue(value !== undefined ? value : inputValue)}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={(e) => handleOnBlur(e.target.value)}
        InputProps={
          InputProps === undefined
            ? defaultInputProps
            : Object.assign(defaultInputProps, InputProps)
        }
        classes={{
          root: classes.root,
        }}
        className={
          className === undefined
            ? getClassName()
            : `${getClassName()} ${className}`
        }
        color={color}
        inputRef={inputRef}
        {...props}
      />
    );
  }
);

export default Input;
