import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import {
  setErrorText,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Component
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
  IconButton,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@/common/components";
// Pages
import AuthVerify from "./pages/auth/AuthVerify";
import LoginPage from "./pages/auth/LoginPage";
import MyInfoPage from "./pages/user/MyInfoPage";
import RegisterPage from "./pages/vehicle/RegisterPage";
import BookingPage from "./pages/vehicle/BookingPage";
import OfferPage from "./pages/vehicle/OfferPage";
import ManagePage from "./pages/vehicle/ManagePage";
import GroupRegisterPage from "./pages/group/RegisterPage";
import GroupListPage from "./pages/group/ListPage";
import GroupInfoPage from "./pages/group/InfoPage";
import UserRegisterPage from "./pages/user/RegisterPage";
import UserUpdatePage from "./pages/user/UpdatePage";
import UserListPage from "./pages/user/ListPage";
import ManagerRegisterPage from "./pages/manager/RegisterPage";
import ManagerUpdatePage from "./pages/manager/UpdatePage";
import StaffRegisterPage from "./pages/staff/RegisterPage";
import StaffUpdatePage from "./pages/staff/UpdatePage";
import StaffListPage from "./pages/staff/ListPage";
import ColorPage from "./pages/index/ColorPage";
import TestPage from "./pages/index/TestPage";
import NotFoundPage from "./pages/common/NotFoundPage";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "12px 45px 16px",
    borderRadius: 10,
    userSelect: "none",
  },
  dialogTitle: {
    padding: "16px 24px 8px",
    textAlign: "center",
  },
  dialogContent: {
    padding: "10px 24px 12px",
    textAlign: "center",
    "& > p > span > span": {
      fontWeight: "bold",
      color: theme.palette.error.main,
    },
    "& > p > span > b": {
      color: theme.palette.primary.main,
      lineHeight: "1.85",
    },
  },
  dialogActions: {
    "& > button": {
      fontSize: "1rem",
    },
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 2,
    "& > svg": {
      fontSize: "1.75rem",
    },
  },
  circularRoot: {
    width: "175px !important",
    height: "175px !important",
  },
  circularCircle: {
    color: theme.palette.primary.main,
  },
});

const BrowserApp = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { errorTitle, errorContent, errorOnClose } = useTypedSelector(
    (state) => state.common
  );
  const { confirmTitle, confirmContent, confirmOnApply, confirmOnClose } =
    useTypedSelector((state) => state.common);
  const { loading } = useTypedSelector((state) => state.common);
  const dispatch = useTypedDispatch();

  const handleOnClose = () => {
    dispatch(
      setErrorText({
        title: "",
        content: "",
        onClose: () => {},
      })
    );
    errorOnClose();
  };
  const handleConfirmOnApply = () => {
    dispatch(
      setConfirmText({
        title: "",
        content: "",
        onApply: () => {},
        onClose: () => {},
      })
    );
    confirmOnApply();
  };
  const handleConfirmOnClose = () => {
    dispatch(
      setConfirmText({
        title: "",
        content: "",
        onApply: () => {},
        onClose: () => {},
      })
    );
    confirmOnClose();
  };

  return (
    <>
      <Router>
        <AuthVerify />
        <Routes>
          {["/", "/login"].map((loginPath) => (
            <Route element={<LoginPage />} path={loginPath} key={loginPath} />
          ))}
          <Route element={<MyInfoPage />} path="/myinfo" />
          <Route element={<RegisterPage />} path="/vehicle/register" />
          <Route element={<RegisterPage />} path="/vehicle/update/:uid" />
          {["/index", "/vehicle/manage"].map((path) => (
            <Route element={<ManagePage />} path={path} key={path} />
          ))}
          <Route element={<BookingPage />} path="/vehicle/booking" />
          <Route element={<OfferPage />} path="/vehicle/offer" />
          <Route element={<GroupRegisterPage />} path="/group/register" />
          <Route element={<GroupRegisterPage />} path="/group/update/:guid" />
          <Route element={<GroupListPage />} path="/group/list" />
          <Route element={<GroupInfoPage />} path="/group/info" />
          <Route element={<UserRegisterPage />} path="/user/register" />
          <Route element={<UserUpdatePage />} path="/user/update/:puid" />
          <Route element={<UserListPage />} path="/user/list" />
          <Route element={<ManagerRegisterPage />} path="/manager/register" />
          <Route element={<ManagerUpdatePage />} path="/manager/update/:puid" />
          <Route element={<StaffRegisterPage />} path="/staff/register" />
          <Route element={<StaffUpdatePage />} path="/staff/update/:puid" />
          <Route element={<StaffListPage />} path="/staff/list" />
          <Route element={<ColorPage />} path="/color" />
          <Route element={<TestPage />} path="/test" />
          <Route element={<NotFoundPage />} path="*" />
        </Routes>
      </Router>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={errorTitle !== "" || errorContent !== "" ? true : false}
        onClose={handleOnClose}
      >
        <IconButton className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Markup content={errorTitle} />
        </DialogTitle>
        <Divider />
        {isNotNull(errorContent) && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText>
              <Markup content={errorContent} />
            </DialogContentText>
          </DialogContent>
        )}
      </Dialog>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={confirmTitle !== "" || confirmContent !== "" ? true : false}
        onClose={handleConfirmOnClose}
        aria-labelledby="confirm-title"
        aria-describedby="confirm-description"
      >
        <DialogTitle id="confirm-title" classes={{ root: classes.dialogTitle }}>
          <Markup content={confirmTitle} />
        </DialogTitle>
        {isNotNull(confirmContent) && (
          <DialogContent classes={{ root: classes.dialogContent }}>
            <DialogContentText id="confirm-description">
              <Markup content={confirmContent} />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={handleConfirmOnApply}>{t("OPTION.YES")}</Button>
          <Button onClick={handleConfirmOnClose}>{t("OPTION.NO")}</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress
          classes={{
            root: classes.circularRoot,
            circle: classes.circularCircle,
          }}
          thickness={2.75}
        />
      </Backdrop>
    </>
  );
};

export default BrowserApp;
