/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import ListDataContainer from "./ListDataContainer";
import ListPagination from "./ListPagination";
import { Paper, Theme, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Button, Image, Input } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { getUserList } from "@/common/lib/api/user";
import { checkGroupDuplicate, getGroupInfo } from "@/common/lib/api/group";
// Libs
import {
  isNull,
  isNotNull,
  getDateFormat,
  getTokenData,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
    "& > div.content": {
      display: "flex",
      alignItems: "center",
      "&::before": {
        display: "inline-block",
        content: "''",
        width: 15,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
      },
      "& > div": {
        "& > .MuiTypography-body1": {
          fontSize: "1.15rem",
          userSelect: "none",
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.975rem",
          userSelect: "none",
          "& > span > span": {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
        },
        "& > .MuiTypography-caption": {
          display: "block",
          color: theme.palette.error.main,
          fontSize: "0.875rem",
          fontWeight: "bold",
          userSelect: "none",
        },
      },
    },
  },
  content: {
    width: 1473,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: 1473,
    height: 600,
    "& > .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 500,
      height: 200,
      margin: "0 auto",
      padding: 45,
      "& > div.title": {
        display: "flex",
        marginBottom: 15,
        "& > svg": {
          fontSize: "3.5rem",
          color: theme.palette.primary.main,
          marginRight: 10,
        },
        "& > div": {
          userSelect: "none",
          "& > .MuiTypography-body1": {
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.825rem",
          },
        },
      },
      "& > div.content": {
        "& > button": {
          margin: "0 10px",
        },
      },
    },
  },
  groupContent: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: 1473,
    padding: "12.5px 45px",
    marginBottom: 30,
    background: theme.palette.primary.main,
    userSelect: "none",
    "& > div.left": {
      display: "flex",
      alignItems: "center",
      "& > div:first-child": {
        width: 50,
        height: 50,
        marginRight: 10,
        "& > .MuiSvgIcon-root": {
          width: "100%",
          height: "100%",
          color: "white",
        },
        "& > img": {
          width: "100%",
          aspectRatio: "1",
          borderRadius: "50%",
          objectFit: "cover",
          padding: 5,
        },
      },
      "& > div:last-child": {
        "& > .MuiTypography-root": {
          fontSize: 14,
          fontWeight: 500,
          color: "white",
        },
        "& > div": {
          display: "flex",
          alignItems: "flex-end",
          "& > .MuiTypography-body1": {
            fontSize: 17,
            fontWeight: 700,
            color: "white",
          },
          "& > .MuiTypography-body2": {
            fontSize: 14,
            fontWeight: 500,
            color: "white",
            marginLeft: 35,
          },
        },
      },
    },
    "& > div.right": {
      "& > .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 500,
        color: "white",
        textAlign: "right",
      },
    },
  },
});

const ListContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();

  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const [guid, setGuid] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState(false);
  const [groupNameHelperText, setGroupNameHelperText] = useState<string | null>(
    ""
  );
  const [groupData, setGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });

  const [data, setData] = useState<UserData[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [params, setParams] = useState<{ [key: string]: any }>({});

  const [init, setInit] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [pageDot, setPageDot] = useState<number>(1);

  const groupInfoQuery = useQuery(
    ["groupInfo", guid],
    () => {
      if (guid !== undefined) {
        dispatch(loadingOn());
        return getGroupInfo(guid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const checkGroupMutation = useMutation(checkGroupDuplicate, {
    onSuccess: (response) => {
      if (response.data.guid !== null) {
        setGroupNameHelperText(t("GROUP.MSG.FOUND_GROUP"));
      } else {
        setGroupNameHelperText(t("GROUP.MSG.NOT_FOUND_GROUP"));
        setGroupNameError(true);
      }
      setGuid(response.data.guid);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const userListQuery = useQuery(
    "userList",
    () => {
      dispatch(loadingOn());
      let listParams = {
        ...params,
        guid: guid,
      };
      return getUserList(listParams);
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSuccess: (response) => {
        setData(response.data.results);
        setTotalCount(response.data.count);
        setInit(true);
      },
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );

  const handleOnSearch = async () => {
    if (isNull(groupName)) {
      setGroupNameError(true);
      setGroupNameHelperText(t("GROUP.MSG.INPUT_GROUP_NAME"));
    } else {
      checkGroupMutation.mutateAsync({ data: { name: groupName } });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  useEffect(() => {
    if (
      tokenData["guid"] !== undefined &&
      !tokenData["is_superuser"] &&
      (tokenData["is_manager"] || tokenData["is_staff"])
    ) {
      setGuid(tokenData["guid"]);
    }
  }, [tokenData]);

  useEffect(() => {
    if (isNotNull(guid)) {
      groupInfoQuery.refetch();
      userListQuery.refetch();
    }
  }, [guid]);

  useEffect(() => {
    setGroupNameError(false);
    setGroupNameHelperText("");
  }, [groupName]);

  useEffect(() => {
    if (groupInfoQuery.isSuccess) {
      if (isNull(groupData["id"])) {
        if (groupInfoQuery.data !== undefined) {
          const result = groupInfoQuery.data.data;

          const recvData: { [key: string]: any } = {};
          recvData["id"] = result["id"];
          recvData["name"] = result["name"];
          recvData["manager"] = {
            id: result["manager"]["id"],
            name: result["manager"]["name"],
            username: result["manager"]["username"],
            image: result["manager"]["image"],
            email: result["manager"]["email"],
            phone: result["manager"]["phone"],
          };
          recvData["image"] = result["image"];
          recvData["address1"] = isNotNull(result["address1"])
            ? result["address1"]
            : "";
          recvData["address2"] = isNotNull(result["address2"])
            ? result["address2"]
            : "";
          recvData["address3"] = isNotNull(result["address3"])
            ? result["address3"]
            : "";
          recvData["zip_code"] = isNotNull(result["zip_code"])
            ? result["zip_code"]
            : "";
          recvData["country"] = isNotNull(result["country"])
            ? result["country"]
            : "";
          recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
          recvData["state"] = isNotNull(result["state"]) ? result["state"] : "";
          recvData["count_product"] = result["count_product"];
          recvData["limit_product"] = result["limit_product"];
          recvData["count_staff"] = result["count_staff"];
          recvData["limit_staff"] = result["limit_staff"];
          recvData["created"] = result["created"];
          recvData["expired"] = result["expired"];

          setGroupData(recvData);
        } else {
          setGuid("");
        }
      }
    }
  }, [groupInfoQuery.isLoading]);

  useEffect(() => {
    setPageDot(Math.floor((page - 1) / (300 / pageSize)) + 1);
  }, [page]);

  useEffect(() => {
    if (init) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [page, pageSize, params]);

  useEffect(() => {
    if (init) {
      userListQuery.refetch();
    }
  }, [pageDot, pageSize, params]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <ViewListIcon />
          <Typography>{t("STAFF.TITLE_LIST")}</Typography>
        </div>
      </div>
      {isNull(groupData["id"]) ? (
        <div className={classes.searchContainer}>
          <Paper>
            <div className="title">
              <SearchIcon />
              <div>
                <Typography variant="body1">
                  {t("STAFF.SEARCH_GROUP")}
                </Typography>
                <Typography variant="body2">
                  {t("STAFF.MSG.INPUT_GROUP_NAME")}
                </Typography>
              </div>
            </div>
            <div className="content">
              <Input
                label={t("STAFF.GROUP_NAME")}
                value={groupName}
                dispatch={setGroupName}
                error={groupNameError}
                helperText={groupNameHelperText}
                inputProps={{
                  maxLength: 32,
                }}
                disabled={!tokenData["is_superuser"]}
              />
              <Button
                variant="outlined"
                onClick={() => handleOnSearch()}
                disabled={!tokenData["is_superuser"]}
              >
                {t("BUTTON.INQUIRE")}
              </Button>
            </div>
          </Paper>
        </div>
      ) : (
        <>
          <div className={classes.groupContent}>
            <div className="left">
              <div>
                {isNull(groupData["image"]) ? (
                  <AccountCircleIcon />
                ) : (
                  <Image alt={""} src={groupData["image"]} />
                )}
              </div>
              <div>
                <Typography>Group Info</Typography>
                <div>
                  <Typography variant="body1">{groupData["name"]}</Typography>
                  <Typography variant="body2">
                    {t("GROUP.MANAGER", {
                      manager: groupData["manager"]["username"],
                    })}
                  </Typography>
                  <Typography variant="body2">
                    {t("GROUP.CREATED_DATE", {
                      date: getDateFormat(groupData["created"], "yyyy-MM-dd"),
                    })}
                  </Typography>
                </div>
              </div>
            </div>
            <div className="right">
              <Typography>
                {t("GROUP.STAFF_COUNT", {
                  staff: groupData["count_staff"].toLocaleString(),
                  limit: groupData["limit_staff"].toLocaleString(),
                })}
              </Typography>
              <Typography>
                {t("GROUP.PRODUCT_COUNT", {
                  product: groupData["count_product"].toLocaleString(),
                  limit: groupData["limit_product"].toLocaleString(),
                })}
              </Typography>
            </div>
          </div>
          <div className={classes.content}>
            <ListDataContainer
              data={data}
              setData={setData}
              page={page}
              pageSize={pageSize}
              totalCount={totalCount}
              params={params}
              setParams={setParams}
            />
            <ListPagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalCount={totalCount}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ListContainer;
