/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ColumnDef } from "@tanstack/react-table";
// Components
import { Button, Image } from "@/common/components";
import BookingDataTable from "./BookingDataTable";
// API
import { onError } from "@/common/lib/api/common";
import { patchBookingRequestInfo } from "@/common/lib/api/bookingRequest";
// Libs
import {
  getAutowiniCommission,
  getAutowiniBusCommission,
  getDateFormat,
  getBookingRequestTableData,
} from "@/common/lib/common";

interface propType {
  data: BookingRequestData[];
  page: number;
  pageSize: number;
  totalCount: number;
  status: string;
  params: { [key: string]: any };
  setParams: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

interface DataType {
  id: number;
  photo: File | string;
  uid: string;
  vin: string;
  maker: string;
  model: string;
  detail_model: string;
  status: string;
  price: number;
  user: string;
  created: Date | null | undefined;
  response: Date | null | undefined;
}

const IndeterminateCheckbox = ({
  indeterminate,
  className,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      name="tbl_cbx"
      type="checkbox"
      ref={ref}
      className={className !== undefined ? className : undefined}
      {...rest}
    />
  );
};

const BookingDataContainer = ({
  data,
  page,
  pageSize,
  totalCount,
  status,
  params,
  setParams,
}: propType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [tableData, setTableData] = useState<DataType[]>([]);

  const patchBookingRequestMutation = useMutation(patchBookingRequestInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.204.SUCCESS_DELETE_REQUEST"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
  });

  const columns: ColumnDef<DataType>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "photo",
      header: () => <span>{t("MANAGE.COLUMN.PHOTO")}</span>,
      cell: (info: any) => (
        <Image
          src={info.getValue}
          style={{ display: "block", width: "100%" }}
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "uid",
      header: "ID",
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "vin",
      header: () => <span>{t("MANAGE.COLUMN.VIN")}</span>,
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "maker",
      header: () => <span>{t("MANAGE.COLUMN.MAKER")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "model",
      header: () => <span>{t("MANAGE.COLUMN.MODEL")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "detail_model",
      header: () => <span>{t("MANAGE.COLUMN.DETAIL_MODEL")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "price",
      header: () => <span>{t("MANAGE.COLUMN.PRICE")}</span>,
      cell: (info: any) => {
        const getADPrice = (value: number) => {
          return info.row.original["type"] === "CAR" ||
            info.row.original["type"] === "TRUCK"
            ? value + getAutowiniCommission(value)
            : value + getAutowiniBusCommission(value);
        };

        return (
          <>
            <p>{Number(info.getValue()).toLocaleString()}</p>
            <span>
              ( {getADPrice(Number(info.getValue())).toLocaleString()} )
            </span>
          </>
        );
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "status",
      header: () => <span>{t("MANAGE.COLUMN.STATUS")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "user",
      header: () => <span>{t("MANAGE.COLUMN.USER")}</span>,
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "created",
      header: () => <span>{t("MANAGE.COLUMN.CREATED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "response",
      header: () => <span>{t("MANAGE.COLUMN.RESPONSE")}</span>,
      cell: (info: any) => {
        if (info.getValue() === null) {
          return <span>{t("MANAGE.TABLE.WAIT")}</span>;
        } else if (new Date(info.getValue()) > new Date(2999, 12, 31)) {
          return <span>{t("MANAGE.TABLE.NOT")}</span>;
        } else if (new Date(info.getValue()) < new Date(2000, 1, 1)) {
          return <span>{t("MANAGE.TABLE.DENY")}</span>;
        } else {
          return (
            <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
          );
        }
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      id: "deny",
      header: () => <span>{t("MANAGE.COLUMN.DENY")}</span>,
      cell: (info: any) => {
        return (
          <Button
            variant="outlined"
            onClick={() => {
              patchBookingRequestMutation.mutateAsync({
                id: info.row.original["id"],
                data: {
                  response_at: new Date("1999-12-31T23:59:59.999+09:00"),
                },
              });
            }}
          >
            Deny
          </Button>
        );
      },
      enableSorting: false,
    },
  ];

  useEffect(() => {
    setTableData(getBookingRequestTableData(data));
  }, [data]);

  return (
    <BookingDataTable
      data={tableData}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      status={status}
      params={params}
      setParams={setParams}
    />
  );
};

export default BookingDataContainer;
