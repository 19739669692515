/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import BasePage from "@/browser/pages/common/BasePage";
import ManageContainer from "@/browser/components/vehicle/manage/ManageContainer";
// Libs
import { isNotNull, parseJWT } from "@/common/lib/common";

const ManagePage = () => {
  const [display, setDisplay] = useState(false);
  const { loginToken } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    if (isNotNull(loginToken)) {
      const data = parseJWT(loginToken);
      const accessTokenExpired = data.exp * 1000 - Date.now();
      if (accessTokenExpired > 0) {
        setDisplay(true);
      }
    }
  }, [loginToken]);

  return display ? (
    <BasePage>
      <ManageContainer />
    </BasePage>
  ) : (
    <></>
  );
};

export default ManagePage;
