const DropboxIcon = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 813 768"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 768) scale(0.1,-0.1)">
        <path
          d="M2665 6955 c-11 -7 -87 -57 -170 -111 -82 -53 -188 -122 -235 -152
-159 -104 -319 -207 -407 -263 -48 -31 -187 -121 -308 -199 -121 -79 -249
-162 -285 -185 -36 -23 -137 -89 -225 -147 -88 -57 -192 -124 -232 -148 -40
-25 -72 -48 -73 -51 0 -4 33 -33 73 -65 194 -156 698 -562 1127 -907 74 -60
141 -111 148 -114 7 -3 160 87 340 199 180 113 485 303 677 423 193 120 379
236 415 259 36 23 170 107 297 186 128 80 233 148 233 152 0 6 -99 91 -294
251 -39 33 -82 69 -96 81 -23 20 -293 246 -591 496 -69 58 -180 151 -247 208
-67 56 -123 102 -125 101 -1 0 -11 -6 -22 -14z"
        />
        <path
          d="M5414 6911 c-38 -32 -190 -158 -339 -281 -361 -300 -651 -540 -817
-678 -76 -63 -138 -118 -138 -122 0 -4 28 -24 63 -45 151 -93 565 -350 597
-370 19 -13 100 -63 180 -112 153 -95 449 -277 869 -537 139 -86 259 -155 265
-153 7 3 90 69 186 147 96 79 195 159 220 178 25 20 47 39 50 42 8 8 98 82
160 130 32 25 113 90 180 145 131 108 212 173 336 274 138 113 178 146 191
158 10 9 -26 36 -160 124 -292 192 -549 360 -617 404 -36 23 -150 98 -255 166
-104 69 -251 164 -325 213 -74 48 -229 149 -345 225 -115 75 -215 140 -221
144 -6 3 -42 -20 -80 -52z"
        />
        <path
          d="M1980 4479 c-58 -44 -114 -88 -125 -97 -19 -16 -225 -178 -341 -267
-69 -54 -422 -330 -553 -433 -59 -46 -134 -104 -168 -130 -34 -26 -62 -49 -63
-52 0 -5 130 -92 420 -280 55 -36 265 -174 468 -306 491 -324 790 -520 944
-620 l127 -83 78 65 c43 36 98 82 122 102 24 21 60 51 80 69 20 17 55 46 78
65 23 18 108 89 189 158 81 69 165 140 187 158 23 18 61 50 86 71 26 22 111
94 191 161 80 67 156 132 170 143 14 12 47 40 75 62 71 57 100 84 95 88 -5 3
-60 39 -160 102 -31 20 -289 179 -713 439 -82 50 -212 130 -290 178 -78 48
-188 116 -245 150 -56 35 -120 74 -142 88 -21 14 -106 66 -187 115 -81 50
-163 100 -183 112 l-36 22 -104 -80z"
        />
        <path
          d="M5925 4460 c-171 -105 -298 -183 -630 -385 -115 -71 -266 -163 -335
-205 -69 -42 -260 -159 -425 -259 -374 -228 -415 -254 -415 -263 0 -3 21 -24
48 -45 51 -43 464 -385 792 -657 113 -93 270 -224 350 -290 80 -66 152 -125
160 -132 13 -11 22 -10 50 10 19 14 100 67 180 119 167 108 790 515 1025 669
88 57 207 135 265 173 372 243 440 289 433 295 -4 4 -30 24 -58 46 -27 21 -52
41 -55 44 -5 5 -93 76 -610 489 -140 112 -275 221 -300 241 -140 114 -310 250
-312 249 -2 0 -75 -45 -163 -99z"
        />
        <path
          d="M4074 3057 c-11 -13 -474 -398 -579 -482 -55 -44 -141 -115 -295
-245 -25 -21 -148 -124 -275 -228 l-230 -191 -45 26 c-25 14 -52 31 -60 38
-14 12 -432 294 -457 309 -10 6 -13 -35 -13 -191 l0 -198 357 -215 c197 -118
400 -240 453 -271 52 -31 327 -196 611 -366 283 -171 525 -315 536 -321 17 -9
47 5 199 97 285 171 386 231 439 263 28 17 230 138 450 270 220 132 434 260
475 285 41 25 155 93 253 152 l177 105 0 199 c0 156 -3 197 -12 191 -24 -14
-392 -256 -473 -310 -44 -30 -88 -54 -98 -54 -10 0 -115 82 -235 182 -119 100
-284 239 -367 308 -82 69 -233 195 -335 280 -102 85 -187 157 -190 160 -14 16
-263 220 -269 220 -3 -1 -11 -6 -17 -13z"
        />
      </g>
    </svg>
  );
};

export default DropboxIcon;
