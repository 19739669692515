import React from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Theme, Typography } from "@mui/material";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import KeyIcon from "@mui/icons-material/Key";
import { Dropzone } from "@/common/components";
import { ChassisIcon, EngineIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  table: {
    width: 900,
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    margin: "45px auto",
    userSelect: "none",
    "& td.title": {
      border: "1px solid " + theme.palette.divider,
      padding: "20px 35px",
      "& > div": {
        display: "flex",
        alignItems: "flex-start",
        "& > svg": {
          fontSize: "5rem",
          color: theme.palette.primary.main,
          marginLeft: 15,
          marginRight: 25,
        },
        "& > div": {
          width: "100%",
          userSelect: "none",
          "& > .MuiTypography-body1": {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: theme.palette.text.primary,
            "& > span": {
              fontSize: "1.15rem",
            },
          },
          "& > .MuiTypography-body2": {
            fontSize: "1rem",
            lineHeight: "1.35",
            "& > span": {
              color: theme.palette.primary.main,
              fontSize: "0.85rem",
            },
          },
          "& > .MuiTypography-caption": {
            display: "block",
            color: theme.palette.error.main,
            fontSize: "0.875rem",
            fontWeight: "bold",
          },
        },
      },
    },
    "& td.content": {
      border: "1px solid " + theme.palette.divider,
      padding: "50px 35px 35px",
      "& > .imageTypeTitle": {
        fontSize: "1.45rem",
        fontWeight: "bold",
        color: theme.palette.primary.main,
        textAlign: "center",
        paddingBottom: 15,
        margin: "0 200px 20px",
        borderBottom: "1px solid " + theme.palette.primary.main,
      },
      "& > .imageTypeUl": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 15px",
        padding: 0,
        listStyle: "none",
        "& > li": {
          display: "inline-block",
          width: "13.5%",
          textAlign: "center",
          cursor: "default",
          userSelect: "none",
          "& > svg:not(.MuiSvgIcon-root)": {
            width: 40,
            height: 40,
            fill: theme.palette.text.primary,
          },
          "& > svg.MuiSvgIcon-root": {
            width: 40,
            height: 40,
            color: theme.palette.text.primary,
          },
          "& > .MuiTypography-caption": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 45.72,
            fontSize: "1.25rem",
            fontWeight: 800,
            wordSpacing: "-0.75px",
            color: theme.palette.text.primary,
          },
          "& > .MuiTypography-body1": {
            fontSize: "0.925rem",
            fontWeight: "bold",
            wordSpacing: "-0.75px",
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.825rem",
            fontWeight: 500,
            wordSpacing: "-0.75px",
            color: theme.palette.text.secondary,
          },
        },
      },
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const ImageUploader = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <table className={classes.table}>
      <colgroup>
        <col style={{ width: "100%" }} />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td className="title">
            <div>
              <InsertPhotoIcon />
              <div>
                <Typography variant="body1">
                  <Markup content={t("REGISTER.MEDIA.IMAGE.TITLE")} />
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.CONTENT_1")}
                </Typography>
                <Typography variant="caption">
                  {t("REGISTER.MEDIA.IMAGE.CONTENT_2")}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="content">
            <Typography className="imageTypeTitle">
              {t("REGISTER.MEDIA.IMAGE.RECOMMEND")}
            </Typography>
            <ul className="imageTypeUl">
              <li>
                <DirectionsCarIcon />
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.EXTERIOR")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.ABOUT", { counts: "8~12" })}
                </Typography>
              </li>
              <li>
                <AirlineSeatReclineNormalIcon />
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.SEAT")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.ABOUT", { counts: "8~16" })}
                </Typography>
              </li>
              <li>
                <ChassisIcon />
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.UNDER")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.ABOUT", { counts: "8~10" })}
                </Typography>
              </li>
              <li>
                <EngineIcon />
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.ENGINE")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.ABOUT", { counts: "6" })}
                </Typography>
              </li>
              <li>
                <KeyIcon />
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.OPTION")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.ABOUT", { counts: "8~10" })}
                </Typography>
              </li>
              <li>
                <Typography variant="caption">VIN</Typography>
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.VIN")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.REQUIRED")}
                </Typography>
              </li>
              <li>
                <Typography variant="caption">Km.</Typography>
                <Typography variant="body1">
                  {t("REGISTER.MEDIA.IMAGE.MILEAGE")}
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.MEDIA.IMAGE.REQUIRED")}
                </Typography>
              </li>
            </ul>
            <Dropzone
              value={value.media.image}
              dispatch={(files: File[]) => {
                dispatch({
                  ...value,
                  media: {
                    ...value.media,
                    image: files,
                  },
                });
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ImageUploader;
