/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { useTypedSelector } from "@/common/lib/redux/store";
// Components
import BasePage from "@/browser/pages/common/BasePage";
import ListContainer from "@/browser/components/staff/list/ListContainer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
} from "@mui/material";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNull, isNotNull, parseJWT } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  dialog: {
    maxWidth: 900,
    width: "fit-content",
    height: "fit-content",
    padding: "12px 45px 16px",
    borderRadius: 10,
    border: "2.5px solid " + theme.palette.logo.blue,
    userSelect: "none",
  },
  logo: {
    width: 250,
    fill: theme.palette.logo.blue,
  },
  text: {
    fontWeight: "bold",
    "& > span > span": {
      color: theme.palette.logo.blue,
    },
  },
});

const ListPage = () => {
  const classes = useStyles(styles);
  const { loginToken } = useTypedSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState(false);
  const [isVerify, setIsVerify] = useState(false);

  useEffect(() => {
    if (isNotNull(loginToken)) {
      const tokenData = parseJWT(loginToken);
      if (tokenData["is_superuser"] || tokenData["is_manager"]) {
        setIsVerify(true);
      }
      setConfirm(true);
    }
  }, []);

  return (
    <>
      <BasePage>{isVerify && <ListContainer />}</BasePage>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={confirm && (!isVerify || isNull(loginToken))}
        onClose={() => navigate("/")}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <CompanyLogoIcon className={classes.logo} />
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.text}>
            <Markup content={t("DIALOG.CONTENT.MANAGER_ONLY_PAGE")} />
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ListPage;
