/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { RadioGroup, Theme, Typography } from "@mui/material";
import { Checkbox, Radio } from "@/common/components";
import KeyIcon from "@mui/icons-material/Key";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import { optionList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  table: {
    width: 950,
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    margin: "45px auto",
    userSelect: "none",
    "& th": {
      backgroundColor: colors.gray[1],
      fontSize: "1.15rem",
      fontWeight: 500,
      textAlign: "center",
    },
    "& th, & td": {
      padding: "12.5px 15px",
      border: "1px solid " + theme.palette.divider,
      wordBreak: "keep-all",
      "&.require-value::after": {
        content: "'*'",
        color: theme.palette.error.main,
        padding: 3,
      },
    },
    "& td.title": {
      border: "1px solid " + theme.palette.divider,
      padding: "15px 35px",
      "& > div": {
        display: "flex",
        alignItems: "flex-start",
        "& > svg": {
          fontSize: "5rem",
          color: theme.palette.primary.main,
          marginLeft: 15,
          marginRight: 25,
        },
        "& > div": {
          width: "100%",
          userSelect: "none",
          "& > .MuiTypography-body1 > span": {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: theme.palette.text.primary,
            "& > span": {
              fontSize: "1.15rem",
            },
          },
          "& > .MuiTypography-body2": {
            fontSize: "1rem",
            lineHeight: "1.35",
            "& > span": {
              color: theme.palette.primary.main,
              fontSize: "0.85rem",
            },
          },
          "& > .MuiTypography-caption": {
            display: "block",
            color: theme.palette.error.main,
            fontSize: "0.875rem",
            fontWeight: "bold",
          },
        },
      },
    },
  },
  optionList: {
    margin: 0,
    padding: "0 20px",
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      display: "inline-block",
      width: "25%",
      "& > div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "10px 12.5px",
      },
    },
  },
  checkboxGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
  radioGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
  formControl: {
    cursor: "pointer",
    "& > .MuiTypography-root": {
      fontSize: 15,
      userSelect: "none",
    },
  },
  checkbox: {
    padding: 6,
    "& > .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  radio: {
    padding: 3,
    "& > span > svg": {
      fontSize: 16,
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const OptionContainer = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const carInitOption = {
    normal_sunroof: false,
    panoramic_sunroof: false,
    hid_headlamp: false,
    led_headlamp: false,
    alloy_wheel: false,
    steering_remocon: false,
    power_steering: false,
    heat_steering: false,
    ecm: false,
    afm: false,
    power_window: false,
    power_tailgate: false,
    power_doorlock: false,
    paddle_shift: false,
    rear_spoiler: false,
    roof_rack: false,
    leather_seat: false,
    heat_front_seat: false,
    heat_rear_seat: false,
    power_driver_seat: false,
    power_passenger_seat: false,
    power_rear_seat: false,
    memory_driver_seat: false,
    memory_passenger_seat: false,
    ventilated_driver_seat: false,
    ventilated_passenger_seat: false,
    ventilated_rear_seat: false,
    smart_key: false,
    button_boot: false,
    aircon: false,
    auto_aircon: false,
    navigation: false,
    radio: false,
    cd_player: false,
    dvd_player: false,
    usb_terminal: false,
    bluetooth: false,
    cruise_control: false,
    auto_slide_door: false,
    adaptive_cruise_control: false,
    hud: false,
    wireless_doorlock: false,
    blackbox: false,
    driver_airbag: false,
    passenger_airbag: false,
    side_airbag: false,
    curtain_airbag: false,
    knee_airbag: false,
    abs: false,
    aeb: false,
    ldws: false,
    tcs: false,
    esp: false,
    tpms: false,
    bsd: false,
    lsd: false,
    ecs: false,
    front_camera: false,
    rear_camera: false,
    around_view_camera: false,
    front_sensor: false,
    rear_sensor: false,
    spare_tire: false,
    eps: false,
  };
  const truckInitOption = {
    sunroof: false,
    hid_headlamp: false,
    led_headlamp: false,
    alloy_wheel: false,
    steering_remocon: false,
    power_steering: false,
    heat_steering: false,
    ecm: false,
    afm: false,
    greaser: false,
    pto: false,
    tachometer: false,
    roof_spoiler: false,
    power_liftgate: false,
    power_captilt: false,
    leather_seat: false,
    heat_seat: false,
    power_seat: false,
    memory_seat: false,
    ventilated_seat: false,
    smart_key: false,
    button_boot: false,
    aircon: false,
    auto_aircon: false,
    navigation: false,
    radio: false,
    cd_player: false,
    dvd_player: false,
    usb_terminal: false,
    bluetooth: false,
    tv: false,
    hud: false,
    wireless_doorlock: false,
    blackbox: false,
    refrigerator: false,
    purifier: false,
    pre_heater: false,
    pre_aircon: false,
    heat_bed: false,
    driver_airbag: false,
    passenger_airbag: false,
    side_airbag: false,
    curtain_airbag: false,
    knee_airbag: false,
    abs: false,
    aeb: false,
    ldws: false,
    tcs: false,
    esp: false,
    tpms: false,
    bsd: false,
    lsd: false,
    ecs: false,
    front_camera: false,
    rear_camera: false,
    around_view_camera: false,
    front_sensor: false,
    rear_sensor: false,
    spare_tire: false,
    eps: false,
    retarder: false,
    ehs: false,
    air_brake: false,
  };
  const busInitOption = {
    sunroof: false,
    hid_headlamp: false,
    led_headlamp: false,
    alloy_wheel: false,
    steering_remocon: false,
    power_steering: false,
    heat_steering: false,
    ecm: false,
    afm: false,
    greaser: false,
    pto: false,
    tachometer: false,
    roof_spoiler: false,
    leather_seat: false,
    heat_seat: false,
    power_seat: false,
    memory_seat: false,
    ventilated_seat: false,
    smart_key: false,
    button_boot: false,
    aircon: false,
    auto_aircon: false,
    navigation: false,
    radio: false,
    cd_player: false,
    dvd_player: false,
    usb_terminal: false,
    bluetooth: false,
    tv: false,
    hud: false,
    wireless_doorlock: false,
    blackbox: false,
    refrigerator: false,
    purifier: false,
    pre_heater: false,
    pre_aircon: false,
    karaoke: false,
    amplifier: false,
    driver_airbag: false,
    passenger_airbag: false,
    side_airbag: false,
    curtain_airbag: false,
    knee_airbag: false,
    abs: false,
    aeb: false,
    ldws: false,
    tcs: false,
    esp: false,
    tpms: false,
    bsd: false,
    lsd: false,
    ecs: false,
    front_camera: false,
    rear_camera: false,
    around_view_camera: false,
    front_sensor: false,
    rear_sensor: false,
    spare_tire: false,
    eps: false,
    retarder: false,
    ehs: false,
    air_brake: false,
  };

  const getCheckboxValue = (optionList: Option[]) => {
    if (value === undefined) {
      return false;
    }

    const option: string[] = [];
    if (value.product.type === "CAR") {
      if (value.car_option === undefined) {
        return false;
      }

      Object.entries(value.car_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (value.product.type === "TRUCK") {
      if (value.truck_option === undefined) {
        return false;
      }

      Object.entries(value.truck_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (value.product.type === "BUS") {
      if (value.bus_option === undefined) {
        return false;
      }

      Object.entries(value.bus_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    }

    for (let idx = 0; idx < optionList.length; idx++) {
      if (option.indexOf(optionList[idx].value) !== -1) {
        return true;
      }
    }
    return false;
  };
  const getRadioGroupValue = (optionList: Option[]) => {
    if (value === undefined) {
      return false;
    }

    const option: string[] = [];
    if (value.product.type === "CAR") {
      if (value.car_option === undefined) {
        return false;
      }

      Object.entries(value.car_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (value.product.type === "TRUCK") {
      if (value.truck_option === undefined) {
        return false;
      }

      Object.entries(value.truck_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    } else if (value.product.type === "BUS") {
      if (value.bus_option === undefined) {
        return false;
      }

      Object.entries(value.bus_option).forEach(([key, value]) => {
        if (value) {
          option.push(key);
        }
      });
    }

    for (let idx = 0; idx < optionList.length; idx++) {
      if (option.indexOf(optionList[idx].value) !== -1) {
        return optionList[idx].value;
      }
    }
    return "";
  };

  return (
    <table className={classes.table}>
      <colgroup>
        <col style={{ width: "100%" }} />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td className="title">
            <div>
              <KeyIcon />
              <div>
                <Typography variant="body1">
                  <Markup content={t("REGISTER.INFO.OPTION.TITLE")} />
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.INFO.OPTION.CONTENT_1")}
                </Typography>
                <Typography variant="caption">
                  {t("REGISTER.INFO.OPTION.CONTENT_2")}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>{t("VEHICLE.OPTION.INEXTERIOR")}</th>
        </tr>
        <tr>
          <td>
            <ul className={classes.optionList}>
              {Object.keys(optionList[value.product.type]["INEXTERIOR"]).map(
                (option, idx) => {
                  return (
                    <li key={idx}>
                      <div>
                        <Checkbox
                          id={"option-inex-" + idx}
                          label={
                            <Markup content={t("VEHICLE.OPTION." + option)} />
                          }
                          checked={getCheckboxValue(
                            optionList[value.product.type]["INEXTERIOR"][option]
                          )}
                          dispatch={(_value: boolean) => {
                            if (value.product.type === "CAR") {
                              const carOption: CarOption =
                                value.car_option !== undefined
                                  ? value.car_option
                                  : carInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["INEXTERIOR"][
                                    option
                                  ][0].value;
                                if (Object.keys(carOption).includes(key)) {
                                  carOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  carOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                car_option: carOption,
                              });
                            } else if (value.product.type === "TRUCK") {
                              const truckOption: TruckOption =
                                value.truck_option !== undefined
                                  ? value.truck_option
                                  : truckInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["INEXTERIOR"][
                                    option
                                  ][0].value;
                                if (Object.keys(truckOption).includes(key)) {
                                  truckOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  truckOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                truck_option: truckOption,
                              });
                            } else if (value.product.type === "BUS") {
                              const busOption: BusOption =
                                value.bus_option !== undefined
                                  ? value.bus_option
                                  : busInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["INEXTERIOR"][
                                    option
                                  ][0].value;
                                if (Object.keys(busOption).includes(key)) {
                                  busOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  busOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                bus_option: busOption,
                              });
                            }
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        {value.product.type === "CAR" &&
                          ["SUNROOF"].includes(option) && (
                            <RadioGroup
                              row
                              className={classes.radioGroup}
                              value={getRadioGroupValue(
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ]
                              )}
                              onChange={(_e, _value: string) => {
                                const carOption: CarOption =
                                  value.car_option !== undefined
                                    ? value.car_option
                                    : carInitOption;
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    carOption[_option.value] = true;
                                  } else {
                                    carOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  car_option: carOption,
                                });
                              }}
                            >
                              {optionList[value.product.type]["INEXTERIOR"][
                                option
                              ].map((detail_option, idx) => {
                                return (
                                  <Radio
                                    key={idx}
                                    label={
                                      <Markup content={detail_option.label} />
                                    }
                                    value={detail_option.value}
                                    formControlClassName={classes.formControl}
                                    radioClassName={classes.radio}
                                  />
                                );
                              })}
                            </RadioGroup>
                          )}
                        {["HEADLAMP"].includes(option) && (
                          <RadioGroup
                            row
                            className={classes.radioGroup}
                            value={getRadioGroupValue(
                              optionList[value.product.type]["INEXTERIOR"][
                                option
                              ]
                            )}
                            onChange={(_e, _value: string) => {
                              if (value.product.type === "CAR") {
                                const carOption: CarOption =
                                  value.car_option !== undefined
                                    ? value.car_option
                                    : carInitOption;
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    carOption[_option.value] = true;
                                  } else {
                                    carOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  car_option: carOption,
                                });
                              } else if (value.product.type === "TRUCK") {
                                const truckOption: TruckOption =
                                  value.truck_option !== undefined
                                    ? value.truck_option
                                    : truckInitOption;
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    truckOption[_option.value] = true;
                                  } else {
                                    truckOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  truck_option: truckOption,
                                });
                              } else if (value.product.type === "BUS") {
                                const busOption: BusOption =
                                  value.bus_option !== undefined
                                    ? value.bus_option
                                    : busInitOption;
                                optionList[value.product.type]["INEXTERIOR"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    busOption[_option.value] = true;
                                  } else {
                                    busOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  bus_option: busOption,
                                });
                              }
                            }}
                          >
                            {optionList[value.product.type]["INEXTERIOR"][
                              option
                            ].map((detail_option, idx) => {
                              return (
                                <Radio
                                  key={idx}
                                  label={
                                    <Markup content={detail_option.label} />
                                  }
                                  value={detail_option.value}
                                  formControlClassName={classes.formControl}
                                  radioClassName={classes.radio}
                                />
                              );
                            })}
                          </RadioGroup>
                        )}
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </td>
        </tr>
        <tr>
          <th>{t("VEHICLE.OPTION.SEAT")}</th>
        </tr>
        <tr>
          <td>
            <ul className={classes.optionList}>
              {Object.keys(optionList[value.product.type]["SEAT"]).map(
                (option, idx) => {
                  return (
                    <li key={idx}>
                      <div>
                        <Checkbox
                          id={"option-seat-" + idx}
                          label={
                            <Markup content={t("VEHICLE.OPTION." + option)} />
                          }
                          checked={getCheckboxValue(
                            optionList[value.product.type]["SEAT"][option]
                          )}
                          dispatch={(_value: boolean) => {
                            if (value.product.type === "CAR") {
                              const carOption: CarOption =
                                value.car_option !== undefined
                                  ? value.car_option
                                  : carInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["SEAT"][
                                    option
                                  ][0].value;
                                if (Object.keys(carOption).includes(key)) {
                                  carOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["SEAT"][
                                  option
                                ].forEach((_option) => {
                                  carOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                car_option: carOption,
                              });
                            } else if (value.product.type === "TRUCK") {
                              const truckOption: TruckOption =
                                value.truck_option !== undefined
                                  ? value.truck_option
                                  : truckInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["SEAT"][
                                    option
                                  ][0].value;
                                if (Object.keys(truckOption).includes(key)) {
                                  truckOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["SEAT"][
                                  option
                                ].forEach((_option) => {
                                  truckOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                truck_option: truckOption,
                              });
                            } else if (value.product.type === "BUS") {
                              const busOption: BusOption =
                                value.bus_option !== undefined
                                  ? value.bus_option
                                  : busInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["SEAT"][
                                    option
                                  ][0].value;
                                if (Object.keys(busOption).includes(key)) {
                                  busOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["SEAT"][
                                  option
                                ].forEach((_option) => {
                                  busOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                bus_option: busOption,
                              });
                            }
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        {value.product.type === "CAR" &&
                          [
                            "HEAT_SEAT",
                            "POWER_SEAT",
                            "VENT_SEAT",
                            "MEMORY_SEAT",
                          ].includes(option) && (
                            <div className={classes.checkboxGroup}>
                              {optionList[value.product.type]["SEAT"][
                                option
                              ].map((detail_option, idx) => {
                                return (
                                  <Checkbox
                                    key={idx}
                                    id={"option-" + detail_option.value}
                                    label={
                                      <Markup content={detail_option.label} />
                                    }
                                    checked={getCheckboxValue([
                                      optionList[value.product.type]["SEAT"][
                                        option
                                      ][idx],
                                    ])}
                                    dispatch={(_value: boolean) => {
                                      if (value.product.type === "CAR") {
                                        const carOption: CarOption =
                                          value.car_option !== undefined
                                            ? value.car_option
                                            : carInitOption;
                                        const key: string =
                                          optionList[value.product.type][
                                            "SEAT"
                                          ][option][idx].value;
                                        if (
                                          Object.keys(carOption).includes(key)
                                        ) {
                                          carOption[key] = _value;
                                        }
                                        dispatch({
                                          ...value,
                                          car_option: carOption,
                                        });
                                      } else if (
                                        value.product.type === "TRUCK"
                                      ) {
                                        const truckOption: TruckOption =
                                          value.truck_option !== undefined
                                            ? value.truck_option
                                            : truckInitOption;
                                        const key: string =
                                          optionList[value.product.type][
                                            "SEAT"
                                          ][option][idx].value;
                                        if (
                                          Object.keys(truckOption).includes(key)
                                        ) {
                                          truckOption[key] = _value;
                                        }
                                        dispatch({
                                          ...value,
                                          truck_option: truckOption,
                                        });
                                      } else if (value.product.type === "BUS") {
                                        const busOption: BusOption =
                                          value.bus_option !== undefined
                                            ? value.bus_option
                                            : busInitOption;
                                        const key: string =
                                          optionList[value.product.type][
                                            "SEAT"
                                          ][option][idx].value;
                                        if (
                                          Object.keys(busOption).includes(key)
                                        ) {
                                          busOption[key] = _value;
                                        }
                                        dispatch({
                                          ...value,
                                          bus_option: busOption,
                                        });
                                      }
                                    }}
                                    formControlClassName={classes.formControl}
                                    checkboxClassName={classes.checkbox}
                                  />
                                );
                              })}
                            </div>
                          )}
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </td>
        </tr>
        <tr>
          <th>{t("VEHICLE.OPTION.CONVINIENCE")}</th>
        </tr>
        <tr>
          <td>
            <ul className={classes.optionList}>
              {Object.keys(optionList[value.product.type]["CONVINIENCE"]).map(
                (option, idx) => {
                  return (
                    <li key={idx}>
                      <div>
                        <Checkbox
                          id={"option-conv-" + idx}
                          label={
                            <Markup content={t("VEHICLE.OPTION." + option)} />
                          }
                          checked={getCheckboxValue(
                            optionList[value.product.type]["CONVINIENCE"][
                              option
                            ]
                          )}
                          dispatch={(_value: boolean) => {
                            if (value.product.type === "CAR") {
                              const carOption: CarOption =
                                value.car_option !== undefined
                                  ? value.car_option
                                  : carInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["CONVINIENCE"][
                                    option
                                  ][0].value;
                                if (Object.keys(carOption).includes(key)) {
                                  carOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  carOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                car_option: carOption,
                              });
                            } else if (value.product.type === "TRUCK") {
                              const truckOption: TruckOption =
                                value.truck_option !== undefined
                                  ? value.truck_option
                                  : truckInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["CONVINIENCE"][
                                    option
                                  ][0].value;
                                if (Object.keys(truckOption).includes(key)) {
                                  truckOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  truckOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                truck_option: truckOption,
                              });
                            } else if (value.product.type === "BUS") {
                              const busOption: BusOption =
                                value.bus_option !== undefined
                                  ? value.bus_option
                                  : busInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["CONVINIENCE"][
                                    option
                                  ][0].value;
                                if (Object.keys(busOption).includes(key)) {
                                  busOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  busOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                bus_option: busOption,
                              });
                            }
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        {value.product.type === "CAR" &&
                          ["CRUISE_CONTROL"].includes(option) && (
                            <RadioGroup
                              row
                              className={classes.radioGroup}
                              value={getRadioGroupValue(
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ]
                              )}
                              onChange={(_e, _value: string) => {
                                const carOption: CarOption =
                                  value.car_option !== undefined
                                    ? value.car_option
                                    : carInitOption;
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    carOption[_option.value] = true;
                                  } else {
                                    carOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  car_option: carOption,
                                });
                              }}
                            >
                              {optionList[value.product.type]["CONVINIENCE"][
                                option
                              ].map((detail_option, idx) => {
                                return (
                                  <Radio
                                    key={idx}
                                    label={
                                      <Markup content={detail_option.label} />
                                    }
                                    value={detail_option.value}
                                    formControlClassName={classes.formControl}
                                    radioClassName={classes.radio}
                                  />
                                );
                              })}
                            </RadioGroup>
                          )}
                        {["AIRCON"].includes(option) && (
                          <RadioGroup
                            row
                            className={classes.radioGroup}
                            value={getRadioGroupValue(
                              optionList[value.product.type]["CONVINIENCE"][
                                option
                              ]
                            )}
                            onChange={(_e, _value: string) => {
                              if (value.product.type === "CAR") {
                                const carOption: CarOption =
                                  value.car_option !== undefined
                                    ? value.car_option
                                    : carInitOption;
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    carOption[_option.value] = true;
                                  } else {
                                    carOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  car_option: carOption,
                                });
                              } else if (value.product.type === "TRUCK") {
                                const truckOption: TruckOption =
                                  value.truck_option !== undefined
                                    ? value.truck_option
                                    : truckInitOption;
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    truckOption[_option.value] = true;
                                  } else {
                                    truckOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  truck_option: truckOption,
                                });
                              } else if (value.product.type === "BUS") {
                                const busOption: BusOption =
                                  value.bus_option !== undefined
                                    ? value.bus_option
                                    : busInitOption;
                                optionList[value.product.type]["CONVINIENCE"][
                                  option
                                ].forEach((_option) => {
                                  if (_option.value === _value) {
                                    busOption[_option.value] = true;
                                  } else {
                                    busOption[_option.value] = false;
                                  }
                                });
                                dispatch({
                                  ...value,
                                  bus_option: busOption,
                                });
                              }
                            }}
                          >
                            {optionList[value.product.type]["CONVINIENCE"][
                              option
                            ].map((detail_option, idx) => {
                              return (
                                <Radio
                                  key={idx}
                                  label={
                                    <Markup content={detail_option.label} />
                                  }
                                  value={detail_option.value}
                                  formControlClassName={classes.formControl}
                                  radioClassName={classes.radio}
                                />
                              );
                            })}
                          </RadioGroup>
                        )}
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </td>
        </tr>
        <tr>
          <th>{t("VEHICLE.OPTION.SAFETY")}</th>
        </tr>
        <tr>
          <td>
            <ul className={classes.optionList}>
              {Object.keys(optionList[value.product.type]["SAFETY"]).map(
                (option, idx) => {
                  return (
                    <li key={idx}>
                      <div>
                        <Checkbox
                          id={"option-safe-" + idx}
                          label={
                            <Markup content={t("VEHICLE.OPTION." + option)} />
                          }
                          checked={getCheckboxValue(
                            optionList[value.product.type]["SAFETY"][option]
                          )}
                          dispatch={(_value: boolean) => {
                            if (value.product.type === "CAR") {
                              const carOption: CarOption =
                                value.car_option !== undefined
                                  ? value.car_option
                                  : carInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["SAFETY"][
                                    option
                                  ][0].value;
                                if (Object.keys(carOption).includes(key)) {
                                  carOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["SAFETY"][
                                  option
                                ].forEach((_option) => {
                                  carOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                car_option: carOption,
                              });
                            } else if (value.product.type === "TRUCK") {
                              const truckOption: TruckOption =
                                value.truck_option !== undefined
                                  ? value.truck_option
                                  : truckInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["SAFETY"][
                                    option
                                  ][0].value;
                                if (Object.keys(truckOption).includes(key)) {
                                  truckOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["SAFETY"][
                                  option
                                ].forEach((_option) => {
                                  truckOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                truck_option: truckOption,
                              });
                            } else if (value.product.type === "BUS") {
                              const busOption: BusOption =
                                value.bus_option !== undefined
                                  ? value.bus_option
                                  : busInitOption;
                              if (_value) {
                                const key: string =
                                  optionList[value.product.type]["SAFETY"][
                                    option
                                  ][0].value;
                                if (Object.keys(busOption).includes(key)) {
                                  busOption[key] = _value;
                                }
                              } else {
                                optionList[value.product.type]["SAFETY"][
                                  option
                                ].forEach((_option) => {
                                  busOption[_option.value] = false;
                                });
                              }
                              dispatch({
                                ...value,
                                bus_option: busOption,
                              });
                            }
                          }}
                          formControlClassName={classes.formControl}
                          checkboxClassName={classes.checkbox}
                        />
                        {["AIRBAG", "CAMERA", "PARK_SENSOR"].includes(
                          option
                        ) && (
                          <div className={classes.checkboxGroup}>
                            {optionList[value.product.type]["SAFETY"][
                              option
                            ].map((detail_option, idx) => {
                              return (
                                <Checkbox
                                  key={idx}
                                  id={"option-" + detail_option.value}
                                  label={
                                    <Markup content={detail_option.label} />
                                  }
                                  checked={getCheckboxValue([
                                    optionList[value.product.type]["SAFETY"][
                                      option
                                    ][idx],
                                  ])}
                                  dispatch={(_value: boolean) => {
                                    if (value.product.type === "CAR") {
                                      const carOption: CarOption =
                                        value.car_option !== undefined
                                          ? value.car_option
                                          : carInitOption;
                                      const key: string =
                                        optionList[value.product.type][
                                          "SAFETY"
                                        ][option][idx].value;
                                      if (
                                        Object.keys(carOption).includes(key)
                                      ) {
                                        carOption[key] = _value;
                                      }
                                      dispatch({
                                        ...value,
                                        car_option: carOption,
                                      });
                                    } else if (value.product.type === "TRUCK") {
                                      const truckOption: TruckOption =
                                        value.truck_option !== undefined
                                          ? value.truck_option
                                          : truckInitOption;
                                      const key: string =
                                        optionList[value.product.type][
                                          "SAFETY"
                                        ][option][idx].value;
                                      if (
                                        Object.keys(truckOption).includes(key)
                                      ) {
                                        truckOption[key] = _value;
                                      }
                                      dispatch({
                                        ...value,
                                        truck_option: truckOption,
                                      });
                                    } else if (value.product.type === "BUS") {
                                      const busOption: BusOption =
                                        value.bus_option !== undefined
                                          ? value.bus_option
                                          : busInitOption;
                                      const key: string =
                                        optionList[value.product.type][
                                          "SAFETY"
                                        ][option][idx].value;
                                      if (
                                        Object.keys(busOption).includes(key)
                                      ) {
                                        busOption[key] = _value;
                                      }
                                      dispatch({
                                        ...value,
                                        bus_option: busOption,
                                      });
                                    }
                                  }}
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OptionContainer;
