import { createTheme } from "@mui/material";
import { colors, darkColors } from "./palette";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    logo: {
      lightBlue: colors.blue[7],
      blue: colors.blue[9],
      darkBlue: colors.blue[12],
    },
    header: {
      main: colors.blue[12],
      text: colors.gray[1],
    },
    footer: {
      main: colors.blue[12],
      text: "#E9A85B",
    },
    primary: {
      soft: colors.blue[0],
      light: colors.blue[5],
      main: colors.blue[7],
      dark: colors.blue[9],
      hard: colors.blue[12],
      contrastText: "#FFF",
    },
    success: {
      soft: colors.teal[0],
      light: colors.teal[5],
      main: colors.teal[7],
      dark: colors.teal[9],
      hard: colors.teal[12],
      contrastText: "#FFF",
    },
    error: {
      soft: colors.red[0],
      light: colors.red[5],
      main: colors.red[7],
      dark: colors.red[9],
      hard: colors.red[12],
      contrastText: "#FFF",
    },
    grey: {
      50: colors.gray[0],
      100: colors.gray[1],
      200: colors.gray[2],
      300: colors.gray[3],
      400: colors.gray[4],
      500: colors.gray[5],
      600: colors.gray[6],
      700: colors.gray[7],
      800: colors.gray[8],
      900: colors.gray[9],
      A100: colors.gray[1],
      A200: colors.gray[2],
      A400: colors.gray[4],
      A700: colors.gray[7],
    },
    background: {
      default: colors.gray[0],
      paper: colors.gray[0],
    },
  },
  typography: {
    fontFamily: '"Noto Sans", "Noto Sans KR", "Roboto", sans-serif;',
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    logo: {
      lightBlue: "#FFF",
      blue: "#FFF",
      darkBlue: "#FFF",
    },
    header: {
      main: colors.blue[12],
      text: colors.gray[1],
    },
    footer: {
      main: colors.blue[12],
      text: "#E9A85B",
    },
    primary: {
      soft: darkColors.blue[0],
      light: darkColors.blue[5],
      main: darkColors.blue[7],
      dark: darkColors.blue[9],
      hard: darkColors.blue[12],
      contrastText: "#FFF",
    },
    success: {
      soft: darkColors.teal[0],
      light: darkColors.teal[5],
      main: darkColors.teal[7],
      dark: darkColors.teal[9],
      hard: darkColors.teal[12],
      contrastText: "#FFF",
    },
    error: {
      soft: darkColors.red[0],
      light: darkColors.red[5],
      main: darkColors.red[7],
      dark: darkColors.red[9],
      hard: darkColors.red[12],
      contrastText: "#FFF",
    },
    grey: {
      50: darkColors.gray[9],
      100: darkColors.gray[8],
      200: darkColors.gray[7],
      300: darkColors.gray[6],
      400: darkColors.gray[5],
      500: darkColors.gray[4],
      600: darkColors.gray[3],
      700: darkColors.gray[2],
      800: darkColors.gray[1],
      900: darkColors.gray[0],
      A100: darkColors.gray[1],
      A200: darkColors.gray[2],
      A400: darkColors.gray[4],
      A700: darkColors.gray[7],
    },
    background: {
      default: darkColors.gray[9],
      paper: darkColors.gray[9],
    },
  },
  typography: {
    fontFamily: '"Noto Sans", "Noto Sans KR", "Roboto", sans-serif;',
  },
});
