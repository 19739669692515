/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import classnames from "classnames";
// Components
import {
  SortingState,
  PaginationState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";
import { Divider, Menu, MenuItem, Theme, Typography } from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Checkbox, Input } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// API
import { onError } from "@/common/lib/api/common";
import { deleteUserInfo } from "@/common/lib/api/user";
// Libs
import { usePrevious } from "@/common/lib/hooks";

const styles = (theme: Theme) => ({
  containerControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 50,
    userSelect: "none",
  },
  containerHeader: {
    width: "100%",
    height: "fit-content",
    borderTop: "1px solid " + theme.palette.divider,
    borderLeft: "2px solid " + theme.palette.divider,
    borderRight: "2px solid " + theme.palette.divider,
    overflow: "hidden",
  },
  containerRow: {
    width: "100%",
    height: "fit-content",
    maxHeight: 897.5,
    borderLeft: "2px solid " + theme.palette.divider,
    borderRight: "2px solid " + theme.palette.divider,
    borderBottom: "1px solid " + theme.palette.divider,
    overflow: "auto",
  },
  noItem: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "12.5px 15px",
    textAlign: "center",
  },
  tableHeader: {
    width: "max-content",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    borderLeft: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
    "& > thead > tr": {
      height: 50,
    },
    "& th": {
      position: "relative",
      background: colors.blue[7],
      color: "white",
      fontSize: "0.925rem",
      fontWeight: 600,
      textAlign: "center",
      padding: "0 12.5px",
      width: 115,
      "& .MuiSvgIcon-root": {
        position: "absolute",
        top: "calc(50% - 9px)",
        right: 4.5,
        fontSize: 18,
      },
      "&.select": {
        width: 45,
      },
      "&.photo": {
        width: 95,
      },
      "&.name": {
        width: 165,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.username": {
        width: 165,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.puid": {
        width: 145,
      },
      "&.password": {
        width: 165,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.email": {
        width: 350,
      },
      "&.phone": {
        width: 165,
      },
      "&.nationality": {
        width: 165,
      },
      "&.address1": {
        width: 350,
      },
      "&.address2": {
        width: 350,
      },
      "&.address3": {
        width: 350,
      },
      "&.state": {
        width: 185,
      },
      "&.city": {
        width: 185,
      },
      "&.country": {
        width: 185,
      },
      "&.zip_code": {
        width: 145,
      },
      "&.created, &.expired": {
        width: 160,
      },
      "&.status": {
        cursor: "pointer",
      },
    },
    "& th:first-child": {
      borderRight: "1px solid white",
    },
    "& th:not(:first-child):not(:last-child)": {
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
    },
    "& th:last-child": {
      borderLeft: "1px solid white",
    },
  },
  tableRow: {
    width: "max-content",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    tableLayout: "fixed",
    "& > tbody > tr": {
      height: 56.5,
      "&:not(:last-child):hover": {
        backgroundColor: "rgba(0, 0, 0, 0.06) !important",
      },
    },
    "& > tbody > tr:nth-child(2n)": {
      backgroundColor: colors.gray[1],
    },
    "& > tbody > tr:last-child": {
      height: 31.5,
      backgroundColor: colors.blue[7],
    },
    "& td": {
      fontSize: "0.875rem",
      border: "1px solid " + theme.palette.divider,
      width: 115,
      padding: "0 12.5px",
      "&.select": {
        width: 45,
      },
      "&.photo": {
        width: 95,
        padding: "0 10px",
      },
      "&.name": {
        width: 165,
        padding: "0 17.5px",
      },
      "&.username": {
        width: 165,
        padding: "0 17.5px",
      },
      "&.puid": {
        width: 145,
        padding: "0 17.5px",
        textAlign: "center",
        "& > span": {
          cursor: "copy",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
      },
      "&.password": {
        width: 165,
        padding: "0 17.5px",
        textAlign: "center",
      },
      "&.email": {
        width: 350,
      },
      "&.phone": {
        width: 165,
      },
      "&.nationality": {
        width: 165,
      },
      "&.address1": {
        width: 350,
        maxWidth: 350,
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "&.address2": {
        width: 350,
        maxWidth: 350,
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "&.address3": {
        width: 350,
        maxWidth: 350,
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "&.state": {
        width: 185,
      },
      "&.city": {
        width: 185,
      },
      "&.country": {
        width: 185,
      },
      "&.zip_code": {
        width: 145,
      },
      "&.created, &.expired": {
        width: 160,
      },
      "&.status": {
        textAlign: "center",
      },
      "&.modify": {
        textAlign: "center",
        "& > button": {
          fontSize: "0.8rem",
          padding: "0 .8em",
          minWidth: "unset",
        },
      },
    },
  },
  controlItemList: {
    display: "flex",
    "& > div:first-child": {
      marginRight: 5,
    },
    "& > div:not(:first-child):not(:last-child)": {
      margin: "0 5px",
    },
    "& > div:last-child": {
      marginLeft: 5,
    },
  },
  controlItem: {
    display: "flex",
    alignItems: "center",
    padding: 7.5,
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: "pointer",
    "& > svg": {
      fontSize: "1.65rem",
      marginRight: "0.25rem",
    },
    "& > .MuiTypography-root": {
      fontSize: "0.95rem",
      fontWeight: 500,
    },
    "&:hover": {
      background: theme.palette.primary.soft,
      color: theme.palette.primary.light,
    },
  },
  searchItem: {
    display: "flex",
    alignItems: "center",
    marginRight: 2,
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: "pointer",
    "& > svg": {
      fontSize: "1.65rem",
      marginRight: "0.25rem",
    },
    "& .MuiFormControl-root": {
      width: 375,
    },
    "& .MuiInput-input": {
      padding: "0.4em 0 !important",
    },
  },
  menuPaper: {
    height: 250,
  },
  menuList: {
    padding: ".45em 0",
  },
  menuItem: {
    padding: ".3em .6em",
  },
  formControl: {
    cursor: "pointer",
    userSelect: "none",
    "& > .MuiTypography-root": {
      fontSize: 14,
      "&.Mui-disabled": {
        color: theme.palette.text.primary,
      },
    },
    "&.Mui-disabled": {
      cursor: "pointer",
      "& + div": {
        "&::before": {
          color: theme.palette.text.primary,
        },
        "&::after": {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  checkbox: {
    padding: ".3em",
    "& > .MuiSvgIcon-root": {
      fontSize: 18,
    },
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
});

const ListDataTable = ({
  data,
  columns,
  page,
  pageSize,
  totalCount,
  params,
  setParams,
}: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: (page - 1) % (300 / pageSize),
    pageSize: pageSize,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOpen, setColumnOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const prevSearchText = usePrevious(searchText);

  const table = useReactTable({
    data: data,
    columns: columns,
    pageCount: totalCount,
    state: {
      sorting: sorting,
      rowSelection: rowSelection,
      pagination: pagination,
      columnVisibility: columnVisibility,
    },
    enableMultiSort: true,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    maxMultiSortColCount: 3,
    isMultiSortEvent: () => {
      return true;
    },
  });
  const tableRef = useRef<HTMLTableElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<OverlayScrollbarsComponentRef>(null);
  const columnRef = useRef<HTMLDivElement>(null);

  const deleteUserMutation = useMutation(deleteUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.204.SUCCESS_DELETE_USER_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 56.5,
    overscan: 10,
    getScrollElement: () => tableRef.current,
  });

  useEffect(() => {
    setPagination({
      pageIndex: (page - 1) % (300 / pageSize),
      pageSize: pageSize,
    });
    headerRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [data, page, pageSize]);

  const getCheckboxLabel = (value: string) => {
    const label = t("MANAGE.COLUMN." + value.toUpperCase());

    const notFoundValueRegex = /^MANAGE\.COLUMN\./i;
    if (notFoundValueRegex.test(label)) {
      return value.toUpperCase();
    } else {
      return label.replaceAll(/<br\s*\/>/gi, " ");
    }
  };
  const handleOnScroll = (e: any) => {
    if (headerRef.current !== null) {
      headerRef.current.scrollLeft = e.target.scrollLeft;
    }
    if (rowRef.current !== null) {
      const instance = rowRef.current.osInstance();
      if (instance !== null) {
        instance.elements().content.scrollLeft = e.target.scrollLeft;
      }
    }
  };
  const handleOnSearch = () => {
    if (prevSearchText !== searchText) {
      setParams({
        ...params,
        search_text: searchText,
      });
    }
  };
  const handleOnDelete = async () => {
    if (Object.keys(rowSelection).length === 0) {
      enqueueSnackbar(t("MANAGE.MSG.NOT_SELECT_ITEM"), {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      const puidList: string[] = [];
      Object.keys(rowSelection).forEach((index: string) => {
        puidList.push(data[index]["puid"]);
      });
      await deleteUserMutation.mutateAsync({
        puid: puidList.join(";"),
      });
      setRowSelection({});
    }
  };

  return (
    <>
      <div className={classes.containerControl}>
        <div className={classes.controlItemList}>
          <div
            ref={columnRef}
            className={classes.controlItem}
            onClick={() => setColumnOpen(true)}
          >
            <FilterAltIcon />
            <Typography>{t("MANAGE.TABLE.CHANGE_COLUMN")}</Typography>
          </div>
          <div className={classes.controlItem} onClick={() => handleOnDelete()}>
            <DeleteIcon />
            <Typography>{t("MANAGE.TABLE.DELETE_USER")}</Typography>
          </div>
        </div>
        <div className={classes.searchItem}>
          <SearchIcon />
          <Input
            fullWidth
            isClearable
            variant="standard"
            placeholder="ex. User Name, PUID..."
            value={searchText}
            dispatch={setSearchText}
            onBlur={handleOnSearch}
            inputProps={{ maxLength: 1000 }}
          />
        </div>
      </div>
      <div
        ref={headerRef}
        className={classes.containerHeader}
        onScroll={handleOnScroll}
      >
        <table className={classes.tableHeader}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={classnames(`${header.id}`)}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <NorthIcon />,
                          desc: <SouthIcon />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      {rows.length > 0 ? (
        <OverlayScrollbarsComponent
          ref={rowRef}
          className={classes.containerRow}
          options={{
            scrollbars: {
              autoHide: "leave",
            },
          }}
          events={{
            scroll: (_, event) => {
              handleOnScroll(event);
            },
          }}
          defer
        >
          <table ref={tableRef} className={classes.tableRow}>
            <tbody>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index];
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className={cell.column.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                <td colSpan={columns.length} />
              </tr>
            </tbody>
          </table>
        </OverlayScrollbarsComponent>
      ) : (
        <div className={classes.containerRow}>
          <div className={classes.noItem}>
            {t("MANAGE.TABLE.NO_RESULT_ITEM")}
          </div>
        </div>
      )}
      <Menu
        open={columnOpen}
        onClose={() => setColumnOpen(false)}
        anchorEl={columnRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList,
        }}
      >
        <MenuItem
          key={"column_menu_all"}
          onClick={table.getToggleAllColumnsVisibilityHandler()}
          classes={{
            root: classes.menuItem,
          }}
        >
          <Checkbox
            disabled
            label={"ALL"}
            checked={table.getIsAllColumnsVisible()}
            formControlClassName={classes.formControl}
            checkboxClassName={classes.checkbox}
          />
        </MenuItem>
        <Divider style={{ margin: ".3em 0" }} />
        {table
          .getAllLeafColumns()
          .filter((item) => item.getCanHide())
          .map((column, idx) => {
            return (
              <MenuItem
                key={"column_menu_" + idx}
                onClick={column.getToggleVisibilityHandler()}
                classes={{
                  root: classes.menuItem,
                }}
              >
                <Checkbox
                  disabled
                  label={getCheckboxLabel(column.id)}
                  checked={column.getIsVisible()}
                  formControlClassName={classes.formControl}
                  checkboxClassName={classes.checkbox}
                />
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default ListDataTable;
