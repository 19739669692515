import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Input } from "@/common/components";
import { Theme, Typography } from "@mui/material";
import { DropboxIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    width: 900,
    margin: "45px auto",
    padding: "0 25px 15px",
    border: "1px solid " + theme.palette.divider,
    borderTop: "2px solid " + theme.palette.text.primary,
    userSelect: "none",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > svg": {
        height: 100,
        fill: "#0061FE",
        marginLeft: 13.735,
        marginRight: 23.735,
      },
      "& > div": {
        width: "100%",
        "& > .MuiTypography-body1": {
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: theme.palette.text.primary,
          userSelect: "none",
          "& > span": {
            fontSize: "1.15rem",
          },
        },
        "& > .MuiTypography-body2": {
          fontSize: "1rem",
          userSelect: "none",
          lineHeight: "1.35",
        },
        "& > .MuiTypography-caption": {
          display: "block",
          color: theme.palette.error.main,
          fontSize: "0.875rem",
          fontWeight: "bold",
          userSelect: "none",
        },
      },
    },
    "& > .MuiTextField-root": {
      width: "100%",
      alignItems: "unset",
      "& > .MuiFormHelperText-root": {
        fontSize: "0.825rem",
      },
    },
    "& > span": {
      fontSize: "0.925rem",
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const DropboxContainer = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [dropboxHelpText, setDropboxHelpText] = useState<string | null>("");

  const checkDropboxLink = (e: any) => {
    if (e.target !== undefined && isNotNull(e.target.value)) {
      const linkRegex = /^(?:https?:\/\/)?www\.dropbox\.com\/.+$/;
      if (linkRegex.test(e.target.value)) {
        setDropboxHelpText("");
      } else {
        setDropboxHelpText(t("REGISTER.MEDIA.DROPBOX.INCORRECT_LINK"));
      }
    }
  };

  useEffect(() => {
    setDropboxHelpText("");
  }, [value.media.dropbox]);

  return (
    <div className={classes.container}>
      <div>
        <DropboxIcon />
        <div>
          <Typography variant="body1">
            <Markup content={t("REGISTER.MEDIA.DROPBOX.TITLE")} />
          </Typography>
          <Typography variant="body2">
            {t("REGISTER.MEDIA.DROPBOX.CONTENT_1")}
          </Typography>
          <Typography variant="caption">
            {t("REGISTER.MEDIA.DROPBOX.CONTENT_2")}
          </Typography>
        </div>
      </div>
      <Input
        variant="standard"
        value={value.media.dropbox}
        dispatch={(_value: string) => {
          dispatch({
            ...value,
            media: {
              ...value.media,
              dropbox: _value,
            },
          });
        }}
        onBlur={checkDropboxLink}
        error={dropboxHelpText !== "" ? true : false}
        helperText={dropboxHelpText}
        placeholder={t("REGISTER.MEDIA.DROPBOX.INPUT_LINK")}
      />
      <span className={classes.example}>
        {
          "Ex. https://www.dropbox.com/sh/i3joe4973n4zehn/AAAs0o7uxPZoJ_C2L_Lbt1sSa?dl=0"
        }
      </span>
    </div>
  );
};

export default DropboxContainer;
