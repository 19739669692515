/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ColumnDef } from "@tanstack/react-table";
// Components
import { Button } from "@/common/components";
import OfferDataTable from "./OfferDataTable";
// API
import { onError } from "@/common/lib/api/common";
import { patchOfferInfo } from "@/common/lib/api/offer";
// Libs
import { getDateFormat, getOfferTableData } from "@/common/lib/common";

interface propType {
  data: OfferData[];
  page: number;
  pageSize: number;
  totalCount: number;
  status: string;
  params: { [key: string]: any };
  setParams: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

interface DataType {
  id: number;
  year: string;
  maker: string;
  model: string;
  price: number;
  details: string;
  user: string;
  created: Date | null | undefined;
  response: Date | null | undefined;
}

const IndeterminateCheckbox = ({
  indeterminate,
  className,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      name="tbl_cbx"
      type="checkbox"
      ref={ref}
      className={className !== undefined ? className : undefined}
      {...rest}
    />
  );
};

const OfferDataContainer = ({
  data,
  page,
  pageSize,
  totalCount,
  status,
  params,
  setParams,
}: propType) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [tableData, setTableData] = useState<DataType[]>([]);

  const patchOfferMutation = useMutation(patchOfferInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.204.SUCCESS_DELETE_REQUEST"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
  });

  const columns: ColumnDef<DataType>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "year",
      header: () => <span>{t("MANAGE.COLUMN.YEAR")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "maker",
      header: () => <span>{t("MANAGE.COLUMN.MAKER")}</span>,
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "model",
      header: () => <span>{t("MANAGE.COLUMN.MODEL")}</span>,
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "price",
      header: () => <span>{t("MANAGE.COLUMN.PRICE")}</span>,
      cell: (info: any) => (
        <span>USD {Number(info.getValue()).toLocaleString()}</span>
      ),
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "details",
      header: () => <span>{t("MANAGE.COLUMN.DETAILS")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "user",
      header: () => <span>{t("MANAGE.COLUMN.USER")}</span>,
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "created",
      header: () => <span>{t("MANAGE.COLUMN.CREATED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "response",
      header: () => <span>{t("MANAGE.COLUMN.RESPONSE")}</span>,
      cell: (info: any) => {
        if (info.getValue() === null) {
          return <span>{t("MANAGE.TABLE.WAIT")}</span>;
        } else if (new Date(info.getValue()) > new Date(2999, 12, 31)) {
          return <span>{t("MANAGE.TABLE.NOT")}</span>;
        } else if (new Date(info.getValue()) < new Date(2000, 1, 1)) {
          return <span>{t("MANAGE.TABLE.DENY")}</span>;
        } else {
          return (
            <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
          );
        }
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      id: "deny",
      header: () => <span>{t("MANAGE.COLUMN.DENY")}</span>,
      cell: (info: any) => {
        return (
          <Button
            variant="outlined"
            onClick={() => {
              patchOfferMutation.mutateAsync({
                id: info.row.original["id"],
                data: {
                  response_at: new Date("1999-12-31T23:59:59.999+09:00"),
                },
              });
            }}
          >
            Deny
          </Button>
        );
      },
      enableSorting: false,
    },
  ];

  useEffect(() => {
    setTableData(getOfferTableData(data));
  }, [data]);

  return (
    <OfferDataTable
      data={tableData}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      status={status}
      params={params}
      setParams={setParams}
    />
  );
};

export default OfferDataContainer;
