/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { cloneDeep } from "lodash";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import {
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import {
  Button,
  Image,
  Input,
  NationAutocomplete,
  PhoneInput,
} from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import {
  checkUserDuplicate,
  getUserInfo,
  patchUserInfo,
} from "@/common/lib/api/user";
import { getGroupInfo } from "@/common/lib/api/group";
// Libs
import {
  isNull,
  isNotNull,
  getDateFormat,
  getTokenData,
  resizeImage,
  validatePassword,
} from "@/common/lib/common";
import { emailRegex, phoneRegex } from "@/common/lib/regex";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
  },
  content: {
    display: "flex",
    width: 1473,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: 1473,
    height: 600,
    "& > .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 500,
      height: 200,
      margin: "0 auto",
      padding: 45,
      "& > div.title": {
        display: "flex",
        marginBottom: 15,
        "& > svg": {
          fontSize: "3.5rem",
          color: theme.palette.primary.main,
          marginRight: 10,
        },
        "& > div": {
          userSelect: "none",
          "& > .MuiTypography-body1": {
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.825rem",
          },
        },
      },
      "& > div.content": {
        "& > button": {
          margin: "0 10px",
        },
      },
    },
  },
  groupContent: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: 1473,
    padding: "12.5px 45px",
    marginBottom: 30,
    background: theme.palette.primary.main,
    userSelect: "none",
    "& > div.left": {
      display: "flex",
      alignItems: "center",
      "& > div:first-child": {
        width: 50,
        height: 50,
        marginRight: 10,
        "& > .MuiSvgIcon-root": {
          width: "100%",
          height: "100%",
          color: "white",
        },
        "& > img": {
          width: "100%",
          aspectRatio: "1",
          borderRadius: "50%",
          objectFit: "cover",
          padding: 20,
        },
      },
      "& > div:last-child": {
        "& > .MuiTypography-root": {
          fontSize: 14,
          fontWeight: 500,
          color: "white",
        },
        "& > div": {
          display: "flex",
          alignItems: "flex-end",
          "& > .MuiTypography-body1": {
            fontSize: 17,
            fontWeight: 700,
            color: "white",
          },
          "& > .MuiTypography-body2": {
            fontSize: 14,
            fontWeight: 500,
            color: "white",
            marginLeft: 35,
          },
        },
      },
    },
    "& > div.right": {
      "& > .MuiTypography-root": {
        fontSize: 14,
        fontWeight: 500,
        color: "white",
        textAlign: "right",
      },
    },
  },
  imageContent: {
    width: 235,
    marginLeft: 45,
    marginRight: 45,
    "& > div": {
      position: "relative",
      width: "100%",
      "& > .MuiSvgIcon-root": {
        width: "100%",
        height: "100%",
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& > img": {
        width: "100%",
        aspectRatio: "1",
        borderRadius: "50%",
        objectFit: "cover",
        padding: 20,
      },
      "& > .MuiIconButton-root": {
        position: "absolute",
        bottom: 5,
        right: 5,
        border: "3.5px solid rgba(0, 0, 0, 0.54)",
        "& > svg": {
          fontSize: "1.5rem",
        },
        "&:hover": {
          borderColor: theme.palette.primary.main,
          "& > svg": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  infoContent: {
    width: 400,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: 3,
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: 3,
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: 10,
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > span": {
        display: "flex",
        justifyContent: "flex-end",
        userSelect: "none",
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: theme.palette.grey[700],
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline",
          color: theme.palette.primary.main,
        },
      },
    },
  },
  locationContent: {
    width: 650,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > div.subcontent": {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
          width: "45%",
          "& > p": {
            userSelect: "none",
            fontSize: 15,
            fontWeight: "bold",
          },
          "& > .MuiFormControl-root": {
            marginBottom: 15,
          },
        },
      },
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
      },
      "& > .MuiFormControl-root": {
        marginBottom: 15,
      },
      "& > button": {
        marginTop: 15,
        padding: ".5em 1em",
        fontSize: "1.125rem",
        fontWeight: "bold",
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  paper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: 400,
    padding: 36,
    borderRadius: 10,
    "& > h1": {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 5,
    },
    "& > h2": {
      fontSize: 15,
      fontWeight: "bold",
      marginBottom: 5,
    },
    "& > ul": {
      margin: 0,
      marginBottom: 10,
      paddingLeft: 20,
      userSelect: "none",
      "& > li": {
        fontSize: 14,
        "&::marker": {
          fontSize: 12,
        },
        "&.bad": {
          color: theme.palette.error.main,
          fontWeight: "bold",
          "&:not(.special)::after": {
            content: "'X'",
            padding: "0 3px",
          },
        },
        "&.good": {
          color: theme.palette.primary.main,
          fontWeight: "bold",
          "&:not(.special)::after": {
            content: "'O'",
            padding: "0 3px",
          },
        },
        "&.special": {
          listStyleType: "none",
        },
      },
    },
    "& > div": {
      marginBottom: 15,
      fontSize: 15,
      padding: "0 10px",
    },
    "& > hr": {
      borderStyle: "dotted",
      borderColor: "rgba(0, 0, 0, 0.38)",
      margin: "5px 0 15px",
    },
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    "& > svg": {
      fontSize: "1.85rem",
    },
  },
});

interface propType {
  isVerify: boolean;
  setIsVerify: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateContainer = ({ isVerify, setIsVerify }: propType) => {
  const params = useParams();
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const [initUserData, setInitUserData] = useState<{ [key: string]: any }>({
    puid: "",
    name: "",
    username: "",
    image: undefined,
    email: "",
    phone: "",
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    nationality: "",
  });
  const [userData, setUserData] = useState<{ [key: string]: any }>({
    puid: "",
    name: "",
    username: "",
    image: undefined,
    email: "",
    phone: "",
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    nationality: "",
  });
  const [guid, setGuid] = useState("");
  const [groupData, setGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isCheckPhone, setIsCheckPhone] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [helperTextEmail, setHelperTextEmail] = useState<string | null>("");
  const [helperTextPhone, setHelperTextPhone] = useState<string | null>("");

  const [modalOpen, setModalOpen] = useState(false);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [error, setError] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  const userInfoQuery = useQuery(
    ["userInfo", params.puid],
    () => {
      if (params.puid !== undefined) {
        dispatch(loadingOn());
        return getUserInfo(params.puid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const groupInfoQuery = useQuery(
    ["groupInfo", guid],
    () => {
      if (guid !== undefined) {
        dispatch(loadingOn());
        return getGroupInfo(guid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const checkEmailMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== null) {
        setHelperTextEmail(t("USER.MSG.ALREADY_EXIST_EMAIL"));
        setErrorEmail(true);
      } else {
        setHelperTextEmail(t("USER.MSG.AVAILABLE_EMAIL"));
      }
      setIsCheckEmail(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkPhoneMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== null) {
        setHelperTextPhone(t("USER.MSG.ALREADY_EXIST_PHONE"));
        setErrorPhone(true);
      } else {
        setHelperTextPhone(t("USER.MSG.AVAILABLE_PHONE"));
      }
      setIsCheckPhone(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const passwordMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_CHANGE_PASSWORD"), {
        variant: "success",
      });
      setModalOpen(false);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const patchUserInfoMutation = useMutation(patchUserInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_USER_INFO"), {
        variant: "success",
      });
      navigate("/index");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const InputProps = (key: string) => {
    const onClick = () => {
      if (key === "email") {
        if (isNotNull(email)) {
          if (emailRegex.test(email)) {
            dispatch(loadingOn());
            checkEmailMutation.mutate({ data: { email: email } });
          } else {
            setErrorEmail(true);
            setHelperTextEmail(t("USER.MSG.INVALID_EMAIL"));
          }
        } else {
          setErrorEmail(true);
          setHelperTextEmail(t("USER.MSG.INPUT_EMAIL"));
        }
      } else if (key === "phone") {
        if (isNotNull(phone)) {
          const phoneNumber = parsePhoneNumberFromString(phone);
          if (phoneNumber !== undefined && phoneNumber.isValid()) {
            dispatch(loadingOn());
            checkPhoneMutation.mutate({ data: { phone: phone } });
          } else {
            setErrorPhone(true);
            setHelperTextPhone(t("USER.MSG.INVALID_PHONE"));
          }
        } else {
          setErrorPhone(true);
          setHelperTextPhone(t("USER.MSG.INPUT_PHONE"));
        }
      }
    };

    return {
      endAdornment: (
        <InputAdornment position="end">
          <CheckIcon onClick={() => onClick()} />
        </InputAdornment>
      ),
    };
  };
  const getClassName = (code: number) => {
    let result = "";

    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        if (errorCode & code) {
          return "bad";
        } else {
          return "good";
        }
      }
    }
    return result;
  };
  const handlePasswordOnBlur = () => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(false);
    }
  };
  const handlePasswordOnClick = () => {
    if (isNull(password)) {
      enqueueSnackbar(t("USER.MSG.INPUT_CURRENT_PASSWORD"), {
        variant: "warning",
      });
      return;
    }
    if (isNull(newPassword)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD"), { variant: "warning" });
      return;
    }
    if (isNull(newPasswordConfirm)) {
      enqueueSnackbar(t("USER.MSG.INPUT_NEW_PASSWORD_CONFIRM"), {
        variant: "warning",
      });
      return;
    }
    if (error || errorCode !== 0) {
      enqueueSnackbar(t("USER.MSG.NOT_VALID_PASSWORD"), { variant: "error" });
      return;
    }

    if (userData["puid"] !== undefined) {
      dispatch(loadingOn());
      passwordMutation.mutate({
        puid: userData["puid"],
        data: {
          password: password,
          newPassword: newPassword,
        },
      });
    }
  };
  const handleOnSubmit = () => {
    dispatch(
      setConfirmText({
        title: t("DIALOG.TITLE.CONFIRM_MODIFY"),
        content: t("DIALOG.CONTENT.NOTICE_MODIFY"),
        onApply: async () => {
          if (userData["puid"] !== undefined) {
            const sendData: { [key: string]: any } = {};
            Object.keys(userData).forEach((key: string) => {
              if (!["id", "username", "nationality"].includes(key)) {
                if (initUserData[key] !== userData[key]) {
                  sendData[key] = userData[key];
                }
              }
            });

            if (userData["email"] !== email) {
              if (!isCheckEmail) {
                enqueueSnackbar(t("USER.MSG.CHECK_EXIST_EMAIL"), {
                  variant: "warning",
                });
                return;
              }

              if (errorEmail) {
                enqueueSnackbar(t("USER.MSG.CHECK_EMAIL"), {
                  variant: "warning",
                });
                return;
              }
              // 필수값 확인
              if (isNull(email)) {
                enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
                  variant: "error",
                });
                return;
              }
              sendData["email"] = email;
            }
            if (userData["phone"] !== phone) {
              if (!isCheckPhone) {
                enqueueSnackbar(t("USER.MSG.CHECK_EXIST_PHONE"), {
                  variant: "warning",
                });
                return;
              }

              if (errorPhone) {
                enqueueSnackbar(t("USER.MSG.CHECK_PHONE"), {
                  variant: "warning",
                });
                return;
              }
              // 필수값 확인
              if (isNull(phone)) {
                enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
                  variant: "error",
                });
                return;
              }
              sendData["phone"] = phone.replaceAll(" ", "");
            }

            if (Object.keys(sendData).includes("image")) {
              const fileData = await resizeImage(
                sendData["image"],
                320,
                240,
                100,
                0,
                "blob"
              );
              const blobData = fileData as BlobPart;
              sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
                type: "image/jpg",
              });
            }

            dispatch(loadingOn());
            patchUserInfoMutation.mutateAsync({
              puid: userData["puid"],
              data: sendData,
            });
          }
        },
        onClose: () => {},
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  useEffect(() => {
    if (isNotNull(params.puid) && isNotNull(tokenData["id"])) {
      userInfoQuery.refetch();
    }
  }, [tokenData, params.puid]);

  useEffect(() => {
    if (isNotNull(guid)) {
      groupInfoQuery.refetch();
    }
  }, [guid]);

  useEffect(() => {
    if (userInfoQuery.isSuccess) {
      if (userInfoQuery.data !== undefined) {
        const result = userInfoQuery.data.data;

        if (result["nationality"] === "S0") {
          if (tokenData["is_superuser"]) {
            setIsVerify(true);
          } else {
            setIsVerify(false);
            return;
          }
        } else if (
          result["nationality"] === "S1" ||
          result["nationality"] === "S2"
        ) {
          if (
            tokenData["is_superuser"] ||
            (tokenData["is_manager"] &&
              tokenData["group"] === result["group"]["id"])
          ) {
            setIsVerify(true);
          } else {
            setIsVerify(false);
            return;
          }
        }

        if (isVerify) {
          const recvData: { [key: string]: any } = {};
          recvData["puid"] = result["puid"];
          recvData["name"] = result["name"];
          recvData["username"] = result["username"];
          recvData["image"] = result["image"];
          recvData["email"] = result["email"];
          recvData["phone"] = result["phone"];
          recvData["address1"] = isNotNull(result["address1"])
            ? result["address1"]
            : "";
          recvData["address2"] = isNotNull(result["address2"])
            ? result["address2"]
            : "";
          recvData["address3"] = isNotNull(result["address3"])
            ? result["address3"]
            : "";
          recvData["zip_code"] = isNotNull(result["zip_code"])
            ? result["zip_code"]
            : "";
          recvData["country"] = isNotNull(result["country"])
            ? result["country"]
            : "";
          recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
          recvData["state"] = isNotNull(result["state"]) ? result["state"] : "";
          recvData["nationality"] = result["nationality"];

          setInitUserData(cloneDeep(recvData));
          setUserData(cloneDeep(recvData));

          setEmail(result["email"]);
          setPhone(result["phone"]);

          setGuid(
            isNotNull(result["group"]["guid"]) ? result["group"]["guid"] : ""
          );
        }
      }
    }
  }, [userInfoQuery.isLoading]);

  useEffect(() => {
    if (groupInfoQuery.isSuccess) {
      if (isNull(groupData["id"])) {
        if (groupInfoQuery.data !== undefined) {
          const result = groupInfoQuery.data.data;

          const recvData: { [key: string]: any } = {};
          recvData["id"] = result["id"];
          recvData["name"] = result["name"];
          recvData["manager"] = {
            id: result["manager"]["id"],
            name: result["manager"]["name"],
            username: result["manager"]["username"],
            image: result["manager"]["image"],
            email: result["manager"]["email"],
            phone: result["manager"]["phone"],
          };
          recvData["image"] = result["image"];
          recvData["address1"] = isNotNull(result["address1"])
            ? result["address1"]
            : "";
          recvData["address2"] = isNotNull(result["address2"])
            ? result["address2"]
            : "";
          recvData["address3"] = isNotNull(result["address3"])
            ? result["address3"]
            : "";
          recvData["zip_code"] = isNotNull(result["zip_code"])
            ? result["zip_code"]
            : "";
          recvData["country"] = isNotNull(result["country"])
            ? result["country"]
            : "";
          recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
          recvData["state"] = isNotNull(result["state"]) ? result["state"] : "";
          recvData["count_product"] = result["count_product"];
          recvData["limit_product"] = result["limit_product"];
          recvData["count_staff"] = result["count_staff"];
          recvData["limit_staff"] = result["limit_staff"];
          recvData["created"] = result["created"];
          recvData["expired"] = result["expired"];

          setGroupData(recvData);
        } else {
          setGuid("");
        }
      }
    }
  }, [groupInfoQuery.isLoading]);

  useEffect(() => {
    if (isNotNull(newPassword) && isNotNull(newPasswordConfirm)) {
      if (newPassword === newPasswordConfirm) {
        setErrorCode(validatePassword(newPassword));
      } else {
        setErrorCode(0);
      }
    } else {
      setErrorCode(0);
    }
  }, [newPassword, newPasswordConfirm]);

  useEffect(() => {
    setIsCheckEmail(false);
    setErrorEmail(false);
    setHelperTextEmail("");
  }, [email]);

  useEffect(() => {
    setIsCheckPhone(false);
    setErrorPhone(false);
    setHelperTextPhone("");
  }, [phone]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <div className="title">
            <EditIcon />
            <Typography>{t("STAFF.TITLE_UPDATE")}</Typography>
          </div>
        </div>
        <div className={classes.groupContent}>
          <div className="left">
            <div>
              {isNull(groupData["image"]) ? (
                <AccountCircleIcon />
              ) : (
                <Image alt={""} src={groupData["image"]} />
              )}
            </div>
            <div>
              <Typography>Group Info</Typography>
              <div>
                <Typography variant="body1">{groupData["name"]}</Typography>
                <Typography variant="body2">
                  {t("GROUP.MANAGER", {
                    manager: groupData["manager"]["username"],
                  })}
                </Typography>
                <Typography variant="body2">
                  {t("GROUP.CREATED_DATE", {
                    date: getDateFormat(groupData["created"], "yyyy-MM-dd"),
                  })}
                </Typography>
              </div>
            </div>
          </div>
          <div className="right">
            <Typography>
              {t("GROUP.STAFF_COUNT", {
                staff: groupData["count_staff"].toLocaleString(),
                limit: groupData["limit_staff"].toLocaleString(),
              })}
            </Typography>
            <Typography>
              {t("GROUP.PRODUCT_COUNT", {
                product: groupData["count_product"].toLocaleString(),
                limit: groupData["limit_product"].toLocaleString(),
              })}
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.imageContent}>
            <div>
              {isNull(userData["image"]) ? (
                <AccountCircleIcon
                  onDoubleClick={() => {
                    document.getElementById("imageUpload")?.click();
                  }}
                />
              ) : (
                <Image
                  alt={""}
                  src={userData["image"]}
                  onDoubleClick={() => {
                    document.getElementById("imageUpload")?.click();
                  }}
                />
              )}
              <IconButton
                onClick={() => {
                  document.getElementById("imageUpload")?.click();
                }}
              >
                <CollectionsIcon />
              </IconButton>
              <input
                type="file"
                id="imageUpload"
                name="imageUpload"
                accept="image/jpg, image/gif, image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={async (e: any) => {
                  const resizeFile = await resizeImage(
                    e.target.files[0],
                    320,
                    240,
                    75,
                    0,
                    "file"
                  );
                  setUserData({
                    ...userData,
                    image: resizeFile,
                  });
                }}
              />
            </div>
          </div>
          <div className={classes.infoContent}>
            <Typography component="h1">User Info</Typography>
            <div>
              <Typography>Position</Typography>
              <Input
                disabled
                variant="standard"
                value={
                  userData["nationality"] === "S0"
                    ? "Super User"
                    : userData["nationality"] === "S1"
                    ? "Manager"
                    : userData["nationality"] === "S2"
                    ? "Staff"
                    : "User"
                }
                fullWidth
              />
              <Typography>Personal Unique ID</Typography>
              <Input
                disabled
                variant="standard"
                value={userData["puid"]}
                fullWidth
              />
              <Typography className="require-value">Name</Typography>
              <Input
                variant="standard"
                value={userData["name"]}
                dispatch={(value: string) => {
                  setUserData({
                    ...userData,
                    name: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 32,
                }}
              />
              <Typography className="unique-value">Email Address</Typography>
              <Input
                className={isCheckEmail ? (!errorEmail ? "good" : "bad") : ""}
                variant="standard"
                value={email}
                dispatch={setEmail}
                error={errorEmail}
                helperText={helperTextEmail}
                placeholder="example@autoskorea.com"
                fullWidth
                InputProps={InputProps("email")}
                inputProps={{
                  maxLength: 64,
                }}
              />
              <Typography className="unique-value">Phone Number</Typography>
              <PhoneInput
                className={isCheckPhone ? (!errorPhone ? "good" : "bad") : ""}
                variant="standard"
                value={phone}
                dispatch={(value: string) => {
                  if (isNull(value) || phoneRegex.test(value)) {
                    if (
                      isNotNull(value) &&
                      value[0] !== "+" &&
                      value[0] !== " "
                    ) {
                      setPhone("+" + value);
                    } else {
                      setPhone(String(value).trim());
                    }
                  }
                }}
                placeholder="Please enter only digits except '-'"
                error={errorPhone}
                helperText={helperTextPhone}
                fullWidth
                InputProps={InputProps("phone")}
                inputProps={{
                  maxLength: 20,
                }}
              />
              <Typography variant="caption" onClick={() => setModalOpen(true)}>
                {t("USER.PASSWORD_CHANGE")}
              </Typography>
            </div>
          </div>
          <div className={classes.locationContent}>
            <Typography component="h1">User Location</Typography>
            <div>
              <Typography>Address</Typography>
              <Input
                variant="standard"
                value={userData["address1"]}
                dispatch={(value: string) => {
                  setUserData({
                    ...userData,
                    address1: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
              <Typography>Address 2</Typography>
              <Input
                variant="standard"
                value={userData["address2"]}
                dispatch={(value: string) => {
                  setUserData({
                    ...userData,
                    address2: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
              <Typography>Address 3</Typography>
              <Input
                variant="standard"
                value={userData["address3"]}
                dispatch={(value: string) => {
                  setUserData({
                    ...userData,
                    address3: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
            </div>
            <div>
              <div className="subcontent">
                <div>
                  <Typography>City</Typography>
                  <Input
                    variant="standard"
                    value={userData["city"]}
                    dispatch={(value: string) => {
                      setUserData({
                        ...userData,
                        city: value,
                      });
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </div>
                <div>
                  <Typography>State</Typography>
                  <Input
                    variant="standard"
                    value={userData["state"]}
                    dispatch={(value: string) => {
                      setUserData({
                        ...userData,
                        state: value,
                      });
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </div>
              </div>
              <div className="subcontent">
                <div>
                  <Typography>Country</Typography>
                  <NationAutocomplete
                    variant="standard"
                    value={userData["country"]}
                    dispatch={(value: string) => {
                      setUserData({
                        ...userData,
                        country: value,
                      });
                    }}
                    fullWidth
                  />
                </div>
                <div>
                  <Typography>Zip Code</Typography>
                  <Input
                    variant="standard"
                    value={userData["zip_code"]}
                    dispatch={(value: string) => {
                      setUserData({
                        ...userData,
                        zip_code: value,
                      });
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 16,
                    }}
                  />
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => handleOnSubmit()}
                fullWidth
              >
                {t("BUTTON.REGISTER")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal className={classes.modal} open={modalOpen} disableAutoFocus={true}>
        <Paper classes={{ root: classes.paper }} square={true}>
          <Typography component="h1">{t("USER.PASSWORD_CHANGE")}</Typography>
          <Typography component="h2">
            {t("USER.PASSWORD_CONDITION.TITLE")}
          </Typography>
          <ul>
            <li className={getClassName(1)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_1")}
            </li>
            <li className={getClassName(2)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_2")}
            </li>
            <li className={getClassName(4)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_3")}
            </li>
            <li className={getClassName(8)}>
              {t("USER.PASSWORD_CONDITION.CONTENT_4")}
            </li>
            <li className={`${getClassName(8)} special`}>
              `~!@#$%^&*()-_=+{}[]:;'"
            </li>
          </ul>
          <Divider />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.CURRENT_PASSWORD")}
            value={password}
            dispatch={setPassword}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD")}
            error={error || errorCode !== 0}
            value={newPassword}
            dispatch={setNewPassword}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Input
            type="password"
            variant="standard"
            placeholder={t("USER.NEW_PASSWORD_CONFIRM")}
            error={error || errorCode !== 0}
            helperText={error ? t("USER.MSG.PASSWORD_ERROR") : ""}
            value={newPasswordConfirm}
            dispatch={setNewPasswordConfirm}
            onBlur={() => handlePasswordOnBlur()}
            inputProps={{
              maxLength: 32,
            }}
          />
          <Divider />
          <Button variant="contained" onClick={() => handlePasswordOnClick()}>
            {t("BUTTON.MODIFY")}
          </Button>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              setNewPassword("");
              setNewPasswordConfirm("");
              setError(false);
              setErrorCode(0);
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Paper>
      </Modal>
    </>
  );
};

export default UpdateContainer;
