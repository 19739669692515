import { Dispatch, SetStateAction, useEffect, useState } from "react";
// Components
import { Dropdown } from "@/common/components";
import { IconButton, Theme } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";
import { managementViewList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 15,
    userSelect: "none",
    "& > span": {
      margin: "0 10px",
      "& > span.of": {
        margin: "0 7.5px",
      },
      "& > span.pages": {
        fontSize: "0.85rem",
      },
    },
    "& > .MuiIconButton-root": {
      margin: "0 5px",
      borderRadius: "unset",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  dropdownFormControl: {
    marginLeft: 10,
    width: 135,
    fontSize: "14px !important",
  },
  dropdownMenuItem: {
    fontSize: "14px !important",
  },
});

interface propType {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  totalCount: number;
}

const ManagePagination = ({
  page = 1,
  setPage,
  pageSize = 30,
  setPageSize,
  totalCount = 0,
}: propType) => {
  const classes = useStyles(styles);

  const [totalPage, setTotalPage] = useState<number>(
    Math.floor(totalCount / pageSize) + 1
  );

  useEffect(() => {
    setTotalPage(Math.floor(totalCount / pageSize) + 1);
  }, [pageSize, totalCount]);

  return (
    <div className={classes.pagination}>
      {totalCount === 0 ? (
        <span>
          0<span className="of">of</span>1{" "}
          <span className="pages">Page(s)</span>
        </span>
      ) : (
        <span>
          {page}
          <span className="of">of</span>
          {totalPage} <span className="pages">Page(s)</span>
        </span>
      )}
      <IconButton onClick={() => setPage(1)} disabled={page === 1}>
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      <IconButton onClick={() => setPage(page - 1)} disabled={page === 1}>
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton
        onClick={() => setPage(page + 1)}
        disabled={page === totalPage}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        onClick={() => setPage(totalPage)}
        disabled={page === totalPage}
      >
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
      <Dropdown
        value={pageSize}
        options={managementViewList}
        dispatch={(value: number) => {
          setPageSize(isNotNull(value) ? value : 30);
        }}
        formControlClassName={classes.dropdownFormControl}
        menuItemClassName={classes.dropdownMenuItem}
      />
    </div>
  );
};

export default ManagePagination;
