// Components
import { Theme } from "@mui/material";
import StatusButton from "./StatusButton";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
    height: 275,
    margin: 15,
  },
  image: {
    height: "inherit",
    fill: theme.palette.text.primary,
  },
  radiator_support: {
    position: "absolute",
    top: "1%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  front_panel: {
    position: "absolute",
    top: "7.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  cross_member: {
    position: "absolute",
    top: "31.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  inside_panel_l: {
    position: "absolute",
    top: "16.5%",
    left: "13.75%",
    transform: "translate(-50%, -50%)",
  },
  inside_panel_r: {
    position: "absolute",
    top: "16.5%",
    left: "86.25%",
    transform: "translate(-50%, -50%)",
  },
  side_member_f_l: {
    position: "absolute",
    top: "22.5%",
    left: "30%",
    transform: "translate(-50%, -50%)",
  },
  side_member_f_r: {
    position: "absolute",
    top: "22.5%",
    left: "70%",
    transform: "translate(-50%, -50%)",
  },
  side_member_r_l: {
    position: "absolute",
    top: "87.5%",
    left: "13%",
    transform: "translate(-50%, -50%)",
  },
  side_member_r_r: {
    position: "absolute",
    top: "87.5%",
    left: "87%",
    transform: "translate(-50%, -50%)",
  },
  wheelhouse_f_l: {
    position: "absolute",
    top: "25.75%",
    left: "13.75%",
    transform: "translate(-50%, -50%)",
  },
  wheelhouse_f_r: {
    position: "absolute",
    top: "25.75%",
    left: "86.25%",
    transform: "translate(-50%, -50%)",
  },
  wheelhouse_r_l: {
    position: "absolute",
    top: "77.75%",
    left: "12.75%",
    transform: "translate(-50%, -50%)",
  },
  wheelhouse_r_r: {
    position: "absolute",
    top: "77.75%",
    left: "87.25%",
    transform: "translate(-50%, -50%)",
  },
  dash_panel: {
    position: "absolute",
    top: "37.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  floor_panel: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  truck_floor: {
    position: "absolute",
    top: "85.5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  rear_panel: {
    position: "absolute",
    top: "96%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  package_tray: {
    position: "absolute",
    top: "75%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const RearSideImage = ({ ...props }: any) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1192 2251"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(0, 2251) scale(0.1, -0.1)">
        <path
          d="M5200 22499 c-969 -11 -1373 -28 -1720 -75 -714 -95 -1300 -266
-1788 -519 -362 -189 -632 -395 -745 -570 -83 -130 -220 -423 -232 -496 -11
-73 -126 -280 -259 -469 -33 -47 -117 -144 -188 -216 l-128 -130 -10 -285
c-54 -1539 -88 -3092 -91 -4183 l-2 -560 129 -175 c299 -405 428 -604 537
-828 l69 -142 -16 -733 c-23 -1054 -45 -2482 -77 -5018 l-11 -905 -330 -334
-330 -334 5 -1506 c5 -1505 8 -1653 42 -1881 13 -88 136 -701 256 -1275 67
-319 160 -694 215 -865 49 -155 86 -215 190 -309 166 -152 476 -300 818 -391
224 -59 703 -152 1001 -194 596 -84 1024 -96 3425 -96 2401 0 2829 12 3425 96
298 42 777 135 1001 194 342 91 652 239 818 391 104 94 141 154 190 309 55
171 148 546 215 865 120 574 243 1187 256 1275 34 228 37 376 42 1881 l5 1506
-330 334 -330 334 -11 905 c-32 2536 -54 3964 -77 5018 l-16 733 69 142 c109
224 238 423 537 828 l129 175 -2 560 c-3 1091 -37 2644 -91 4183 l-10 285
-128 130 c-71 72 -155 169 -188 216 -133 189 -248 396 -259 469 -12 73 -149
366 -232 496 -171 265 -692 591 -1281 801 -446 159 -1082 288 -1587 324 -339
23 -621 30 -1940 43 -176 2 -610 1 -965 -4z m2815 -308 c571 -48 1019 -135
1458 -282 132 -45 219 -78 451 -175 l70 -29 -280 -9 c-153 -5 -1843 -9 -3754
-9 -1911 0 -3601 4 -3754 9 l-280 9 70 29 c332 138 499 197 749 265 413 111
883 181 1380 204 28 1 883 1 1900 1 1325 -1 1890 -5 1990 -13z m-6380 -752
c265 -21 2181 -31 5065 -26 2870 5 3622 13 3685 37 38 15 94 -20 177 -109 139
-150 281 -383 362 -593 111 -287 284 -576 457 -762 36 -39 71 -80 76 -91 38
-72 58 -468 98 -1895 31 -1111 36 -1352 42 -2120 l6 -785 -83 -90 c-163 -176
-371 -483 -521 -770 -85 -162 -88 -179 -100 -490 -13 -370 -1 -1422 72 -5950
l12 -740 309 -315 308 -315 0 -1115 c0 -1108 -11 -1915 -30 -2145 -15 -188
-329 -1613 -432 -1959 -74 -251 -164 -364 -373 -469 -80 -40 -384 -162 -389
-156 -2 2 -9 202 -16 444 -6 242 -13 446 -15 452 -6 19 -8764 19 -8770 1 -2
-7 -9 -211 -15 -453 -7 -242 -14 -442 -16 -444 -5 -6 -309 116 -389 156 -209
105 -299 218 -373 469 -103 346 -417 1771 -432 1959 -19 230 -30 1037 -30
2145 l0 1115 308 315 309 315 12 740 c73 4528 85 5580 72 5950 -12 311 -15
328 -100 490 -150 287 -358 594 -521 770 l-83 90 6 785 c6 768 11 1009 42
2120 40 1427 60 1823 98 1895 5 11 40 52 76 91 173 186 346 475 457 762 80
207 220 439 357 588 69 75 143 128 167 118 8 -4 60 -10 115 -15z m8465 -20484
l0 -285 -4140 0 -4140 0 0 285 0 285 4140 0 4140 0 0 -285z m-890 -532 l315
-7 -165 -22 c-645 -89 -586 -87 -3400 -87 -2816 0 -2775 -1 -3400 87 l-165 24
145 2 c1002 18 5907 20 6670 3z"
        />
        <path
          d="M2405 21330 c-467 -7 -691 -18 -738 -36 -26 -10 -30 -97 -23 -491 l6
-373 -187 0 c-277 0 -573 -17 -599 -35 -15 -9 -26 -32 -34 -67 -21 -96 -29
-577 -30 -1674 l0 -1082 68 -13 c53 -11 222 -14 840 -12 425 0 779 4 787 7 13
5 15 181 15 1406 l0 1400 101 0 102 0 -7 -42 c-19 -116 -26 -623 -26 -1898 l0
-1405 -77 76 c-94 92 -146 128 -266 184 -442 206 -948 200 -1369 -17 -185 -94
-302 -197 -370 -323 -33 -60 -33 -60 -33 -205 l0 -145 35 -70 c82 -165 311
-333 565 -415 173 -55 248 -65 500 -65 199 0 245 3 342 23 272 55 494 164 612
300 l46 53 7 -38 c3 -21 10 -140 14 -265 l7 -226 -654 -5 c-418 -3 -683 -9
-734 -16 -251 -36 -392 -104 -558 -266 -84 -81 -107 -127 -107 -215 0 -155 41
-267 137 -370 97 -105 259 -196 391 -219 66 -12 656 -30 1012 -32 135 0 265
-4 290 -8 31 -6 -15 -9 -145 -10 -238 -1 -486 -17 -579 -36 -78 -16 -261 -102
-338 -160 -113 -83 -153 -184 -146 -366 6 -157 21 -190 147 -312 109 -106 147
-125 298 -151 291 -50 702 -56 4253 -56 3551 0 3962 6 4253 56 151 26 189 45
298 151 126 122 141 155 147 312 7 182 -33 283 -146 366 -77 58 -260 144 -338
160 -93 19 -341 35 -579 36 -130 1 -176 4 -145 10 25 4 155 8 290 8 356 2 946
20 1012 32 132 23 294 114 391 219 96 103 137 215 137 370 0 88 -23 134 -107
215 -166 162 -307 230 -558 266 -51 7 -316 13 -734 16 l-654 5 7 226 c4 125
11 244 14 265 l7 38 46 -53 c118 -136 340 -245 612 -300 97 -20 143 -23 342
-23 182 0 249 4 320 18 183 37 355 107 500 202 94 61 204 179 245 260 l35 70
0 145 c0 145 0 145 -33 205 -68 126 -185 229 -370 323 -421 217 -927 223
-1369 17 -120 -56 -172 -92 -265 -184 l-78 -76 0 1405 c0 1275 -7 1782 -26
1898 l-7 42 102 0 101 0 0 -1400 c0 -1225 2 -1401 15 -1406 8 -3 362 -7 787
-7 618 -2 787 1 841 12 l67 13 0 1082 c-1 1097 -9 1578 -30 1674 -8 35 -19 58
-34 67 -26 18 -322 35 -598 35 l-187 0 2 425 c2 358 0 427 -12 434 -22 14 -89
22 -284 32 -222 13 -6812 20 -7572 9z m7613 -477 l-3 -218 -4055 0 -4055 0 -3
218 -2 217 4060 0 4060 0 -2 -217z m-1984 -494 c8 -9 -21 -1870 -30 -1878 -5
-5 -141 -52 -301 -106 l-293 -97 0 -1199 0 -1199 -1450 0 -1450 0 0 1199 0
1199 -292 97 c-161 54 -297 101 -302 106 -8 7 -38 1866 -31 1879 4 6 4144 5
4149 -1z m-5774 -1379 l0 -1180 -595 0 -595 0 0 1180 0 1180 595 0 595 0 0
-1180z m1372 223 l3 -958 265 -69 c146 -37 281 -72 301 -77 l37 -9 6 -528 c3
-290 9 -740 12 -1000 l6 -472 -658 2 -659 3 -3 2020 c-1 1111 0 2026 3 2033 3
9 82 12 345 12 l340 0 2 -957z m5343 -1078 l0 -2030 -659 -3 -658 -2 6 472 c3
260 9 710 12 1000 l6 528 37 9 c20 5 155 40 301 77 l265 69 3 958 2 957 343
-2 342 -3 0 -2030z m1875 855 l0 -1180 -595 0 -595 0 0 1180 0 1180 595 0 595
0 0 -1180z m-8953 -1840 c71 -8 99 -17 195 -67 136 -69 276 -161 314 -206 15
-17 36 -52 47 -76 16 -37 17 -49 7 -88 -18 -72 -45 -116 -103 -169 -182 -166
-513 -263 -795 -234 -320 33 -572 153 -669 318 -34 57 -33 106 2 178 76 155
301 298 525 334 131 20 348 25 477 10z m8628 -14 c221 -45 427 -181 500 -330
35 -72 36 -121 2 -178 -97 -165 -349 -285 -669 -318 -282 -29 -613 68 -795
234 -58 53 -85 97 -103 169 -10 39 -9 51 7 88 29 65 65 103 161 167 144 96
303 172 381 182 94 12 432 3 516 -14z m-150 -1505 c143 -7 208 -14 264 -31
168 -48 301 -132 341 -214 19 -41 20 -50 9 -83 -30 -93 -204 -210 -355 -238
-182 -35 -455 -38 -4674 -38 -4219 0 -4492 3 -4674 38 -151 28 -325 145 -355
238 -30 91 78 199 274 272 142 54 174 56 855 65 856 10 8074 3 8315 -9z
m-1089 -1150 l810 -7 49 -24 c64 -31 174 -136 209 -199 25 -47 26 -52 12 -79
-25 -49 -105 -130 -149 -151 -60 -28 -159 -39 -469 -51 -740 -29 -6836 -29
-7576 0 -310 12 -409 23 -469 51 -44 21 -124 102 -149 151 -14 27 -13 32 12
79 34 61 145 168 205 197 38 19 69 22 234 28 571 19 5319 23 7281 5z"
        />
        <path
          d="M3553 13610 c-369 -4 -732 -10 -805 -14 -254 -14 -545 -92 -787 -212
-149 -74 -193 -104 -317 -219 l-94 -86 0 -1527 c1 -1509 9 -2146 30 -2271 18
-103 45 -154 120 -224 37 -34 103 -84 147 -110 284 -170 596 -235 1308 -269
389 -19 5221 -19 5610 0 452 22 760 60 962 118 186 54 378 156 493 261 75 70
102 121 120 224 21 125 29 762 30 2271 l0 1527 -94 86 c-124 115 -168 145
-317 219 -153 76 -359 146 -534 181 -114 23 -168 28 -450 36 -414 12 -4548 19
-5422 9z m5387 -279 c393 -24 567 -53 752 -127 248 -99 313 -147 347 -259 56
-179 63 -475 51 -2022 -5 -692 -10 -1333 -10 -1425 l0 -168 -101 -80 c-151
-121 -365 -215 -574 -254 -215 -39 -345 -41 -3445 -41 -3100 0 -3230 2 -3445
41 -209 39 -423 133 -574 254 l-101 80 0 168 c0 92 -5 733 -10 1425 -12 1545
-5 1843 50 2021 35 112 89 153 324 251 235 97 459 128 1049 145 449 12 5467 5
5687 -9z"
        />
        <path
          d="M2738 8519 c-457 -7 -529 -12 -774 -54 -235 -40 -379 -98 -411 -166
-28 -57 -36 -325 -30 -993 l5 -660 24 -17 c45 -34 203 -109 262 -124 149 -37
364 -57 826 -77 424 -19 6216 -19 6640 0 462 20 677 40 826 77 59 15 217 90
262 124 l24 17 5 660 c6 668 -2 936 -30 993 -30 64 -171 123 -377 161 -249 44
-334 50 -901 60 -634 11 -5678 10 -6351 -1z m5892 -259 c875 -14 990 -21 1232
-67 l198 -38 10 -30 c21 -60 31 -359 28 -855 l-3 -504 -165 -28 c-204 -34
-349 -45 -745 -58 -1636 -52 -6111 -39 -6895 20 -74 6 -209 23 -300 38 l-165
28 -3 504 c-3 496 7 796 28 855 10 30 11 30 203 67 232 46 397 55 1137 67 729
12 4737 12 5440 1z"
        />
        <path
          d="M4315 6119 c-489 -6 -502 -7 -595 -31 -119 -31 -193 -67 -274 -134
-73 -60 -124 -120 -157 -184 -20 -38 -24 -60 -24 -140 0 -85 3 -101 29 -154
75 -153 272 -277 507 -320 168 -31 460 -36 2159 -36 1699 0 1991 5 2159 36
235 43 432 167 507 320 26 53 29 69 29 154 0 80 -4 102 -24 140 -33 64 -84
124 -157 184 -82 67 -155 103 -274 134 -93 23 -107 24 -670 32 -664 9 -2484 9
-3215 -1z m3754 -288 c101 -14 182 -54 241 -117 49 -53 58 -85 37 -130 -30
-62 -131 -120 -272 -155 -134 -34 -388 -38 -2115 -38 -1727 0 -1981 4 -2115
38 -187 46 -301 134 -281 216 10 40 79 108 145 143 47 26 78 34 157 42 161 16
168 17 2184 15 1432 -1 1957 -5 2019 -14z"
        />
        <path
          d="M1150 5690 c-30 -5 -106 -29 -169 -55 -249 -100 -414 -232 -494 -395
-29 -59 -32 -75 -35 -181 -3 -89 0 -131 13 -175 99 -345 601 -580 1172 -550
302 17 525 85 751 229 40 26 74 47 77 47 3 0 5 -75 5 -167 l0 -168 -317 8
c-444 11 -1389 5 -1403 -9 -19 -19 -30 -599 -30 -1687 l0 -1059 628 8 c345 3
737 9 872 13 l245 6 5 895 c4 762 6 814 8 350 1 -300 5 -704 9 -897 l6 -353
3467 0 3467 0 6 353 c4 193 8 597 9 897 2 464 4 412 8 -350 l5 -895 245 -6
c135 -4 527 -10 873 -13 l627 -8 0 1059 c0 1088 -11 1668 -30 1687 -14 14
-959 20 -1403 9 l-317 -8 0 168 c0 92 2 167 5 167 3 0 37 -21 77 -47 226 -144
449 -212 751 -229 571 -30 1073 205 1172 550 13 44 16 86 13 175 -3 106 -6
122 -35 181 -80 163 -245 295 -494 395 -152 62 -213 69 -538 62 -207 -4 -291
-9 -381 -25 -406 -73 -631 -235 -729 -524 l-18 -53 -3313 0 -3313 0 -18 53
c-82 241 -244 386 -532 477 -168 53 -287 67 -602 71 -159 1 -315 -1 -345 -6z
m470 -260 c319 -28 489 -87 622 -215 55 -53 98 -132 98 -181 0 -87 -121 -234
-252 -308 -137 -76 -347 -126 -532 -126 -197 0 -431 54 -580 133 -122 65 -221
182 -233 277 -7 50 30 129 93 199 167 185 409 253 784 221z m9098 -16 c97 -20
159 -46 244 -101 128 -84 226 -221 215 -303 -12 -95 -111 -212 -233 -277 -149
-79 -383 -133 -580 -133 -185 0 -395 50 -532 126 -131 74 -252 221 -252 308 0
118 165 272 353 331 218 69 578 92 785 49z m-1578 -2104 l0 -1550 -3180 0
-3180 0 0 1550 0 1550 3180 0 3180 0 0 -1550z m-6960 -435 l0 -1115 -595 0
-595 0 0 1115 0 1115 595 0 595 0 0 -1115z m8750 0 l0 -1115 -595 0 -595 0 0
1115 0 1115 595 0 595 0 0 -1115z"
        />
        <path
          d="M5385 4489 c-116 -9 -498 -77 -666 -119 -742 -185 -1308 -576 -1426
-982 -74 -257 21 -605 219 -800 276 -273 912 -559 1458 -658 255 -45 423 -55
990 -55 567 0 735 10 990 55 546 99 1182 385 1458 658 198 195 293 543 219
800 -80 275 -354 537 -782 747 -319 157 -547 226 -1040 316 l-230 41 -545 3
c-300 1 -590 -2 -645 -6z m1265 -284 c300 -49 834 -209 1067 -320 276 -131
505 -337 603 -540 60 -123 65 -152 40 -226 -48 -141 -144 -293 -245 -388 -161
-150 -540 -333 -913 -440 -409 -117 -820 -175 -1242 -175 -422 0 -833 58
-1242 175 -373 107 -752 290 -913 440 -101 95 -197 247 -245 388 -25 74 -20
103 40 226 98 203 327 409 603 540 226 108 727 259 1042 316 88 15 171 17 705
18 499 1 622 -2 700 -14z"
        />
      </g>
    </svg>
  );
};

// 5. 라디에이터서포트  9. 프론트패널     10. 크로스멤버
// 11. 인사이드패널    12. 사이드멤버     13. 휠하우스
// 15. 대쉬패널        16. 플로어패널    17. 트렁크플로어
// 18. 리어패널        19. 패키지트레이
const RearSide = ({ value, dispatch, ...props }: any) => {
  const classes = useStyles(styles);
  return (
    <>
      <div
        className={
          props.className !== undefined
            ? `${classes.container} ${props.className}`
            : `${classes.container}`
        }
      >
        <RearSideImage className={classes.image} />
        <StatusButton
          className={classes.radiator_support}
          part="radiator_support"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.front_panel}
          part="front_panel"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.cross_member}
          part="cross_member"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.inside_panel_l}
          part="inside_panel_l"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.inside_panel_r}
          part="inside_panel_r"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.side_member_f_l}
          part="side_member_f_l"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.side_member_f_r}
          part="side_member_f_r"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.side_member_r_l}
          part="side_member_r_l"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.side_member_r_r}
          part="side_member_r_r"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.wheelhouse_f_l}
          part="wheelhouse_f_l"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.wheelhouse_f_r}
          part="wheelhouse_f_r"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.wheelhouse_r_l}
          part="wheelhouse_r_l"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.wheelhouse_r_r}
          part="wheelhouse_r_r"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.dash_panel}
          part="dash_panel"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.floor_panel}
          part="floor_panel"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.truck_floor}
          part="truck_floor"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.rear_panel}
          part="rear_panel"
          value={value}
          dispatch={dispatch}
        />
        <StatusButton
          className={classes.package_tray}
          part="package_tray"
          value={value}
          dispatch={dispatch}
        />
      </div>
    </>
  );
};

export default RearSide;
