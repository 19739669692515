import React from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Theme, Typography } from "@mui/material";
import DescriptionSharpIcon from "@mui/icons-material/DescriptionSharp";
import { Button } from "@/common/components";
import { InfoContainer, OptionContainer } from "./info";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  title: {
    display: "flex",
    alignItems: "flex-start",
    paddingBottom: 10,
    borderBottom: "1px solid " + theme.palette.text.primary,
    "& > svg": {
      fontSize: "3.85rem",
      color: theme.palette.primary.main,
      marginRight: 10,
    },
    "& > div": {
      width: "100%",
      "& > .MuiTypography-body1 > span": {
        fontSize: "1.5rem",
        fontWeight: "bold",
        userSelect: "none",
        "& > span": {
          fontSize: "1.15rem",
        },
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.975rem",
        userSelect: "none",
        lineHeight: "1.35",
        "& > span": {
          color: theme.palette.primary.main,
          fontSize: "0.85rem",
        },
      },
      "& > .MuiTypography-caption": {
        display: "block",
        color: theme.palette.error.main,
        fontSize: "0.875rem",
        fontWeight: "bold",
        userSelect: "none",
      },
    },
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 75,
    "& > button": {
      width: 175,
      height: 60,
      margin: "0 30px",
      borderRadius: 30,
      fontSize: "1.125rem",
      "&.prev::before": {
        content: "'<'",
        paddingRight: 8,
      },
      "&.next::after": {
        content: "'>'",
        paddingLeft: 8,
      },
    },
  },
  bottom: {
    paddingTop: 10,
    borderTop: "1px solid " + theme.palette.text.primary,
    "& > .MuiTypography-body2": {
      fontSize: "0.975rem",
      userSelect: "none",
    },
    "& > .MuiTypography-caption": {
      display: "block",
      color: theme.palette.error.main,
      fontSize: "0.875rem",
      fontWeight: "bold",
      userSelect: "none",
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
  prev: Function;
  next: Function;
}

const RegisterInfoContainer = ({ value, dispatch, prev, next }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.title}>
        <DescriptionSharpIcon />
        <div>
          <Typography variant="body1">
            <Markup content={t("REGISTER.INFO.TITLE")} />
          </Typography>
          <Typography variant="body2">
            {t("REGISTER.INFO.CONTENT_1")}
          </Typography>
          <Typography variant="caption">
            {t("REGISTER.INFO.CONTENT_2")}
          </Typography>
        </div>
      </div>
      <InfoContainer value={value} dispatch={dispatch} />
      {isNotNull(value.product.type) && (
        <OptionContainer value={value} dispatch={dispatch} />
      )}
      <div className={classes.buttonDiv}>
        <Button variant="contained" className="prev" onClick={() => prev()}>
          {t("BUTTON.PREV")}
        </Button>
        <Button variant="contained" className="next" onClick={() => next()}>
          {t("BUTTON.NEXT")}
        </Button>
      </div>
      <div className={classes.bottom}>
        <Typography variant="body2">{t("REGISTER.BOTTOM_1")}</Typography>
        <Typography variant="caption">{t("REGISTER.BOTTOM_2")}</Typography>
      </div>
    </>
  );
};

export default RegisterInfoContainer;
