/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
import classnames from "classnames";
// Components
import ManageDataTable from "./ManageDataTable";
import {
  keyframes,
  ClickAwayListener,
  Dialog,
  Divider,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Theme,
  Tooltip,
  Typography,
  RadioGroup,
} from "@mui/material";
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Image,
  Input,
  Radio,
} from "@/common/components";
import {
  LeftSide,
  FrontSide,
  RightSide,
  RearSide,
  StatusMark,
} from "@/browser/components/vehicle/manage/inspection";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// Libs
import {
  isNull,
  isNotNull,
  openNewTab,
  getAutowiniCommission,
  getAutowiniBusCommission,
  getDateFormat,
  getVehicleTableData,
} from "@/common/lib/common";
import { optionList } from "@/common/lib/varList";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// API
import { onError } from "@/common/lib/api/common";
import { getUserList } from "@/common/lib/api/user";
import { patchVehicleInfo } from "@/common/lib/api/vehicle";

interface propType {
  data: VehicleData[];
  setData: Dispatch<SetStateAction<VehicleData[]>>;
  page: number;
  pageSize: number;
  totalCount: number;
  status: string;
  params: { [key: string]: any };
}

interface DataType {
  photo: File | string;
  id: string;
  type: string;
  vin: string;
  maker: string;
  model: string;
  detail_model: string;
  status: string;
  price: number;
  discount: number | null;
  discount_created: Date | null | undefined;
  discount_expired: Date | null | undefined;
  mileage: number;
  mission: string;
  fuel: string;
  color: string;
  steering: string;
  drive: string | null | undefined;
  accident_history: string;
  simple_repair: string;
  inspection: VehicleInspection;
  option: string[];
  memo: string;
  created: Date | null | undefined;
  modified: Date | null | undefined;
  sales_on: Date | null | undefined;
  sold_out: Date | null | undefined;
  price_update: Date | null | undefined;
  purchase_client: string | null | undefined;
  purchase_price: number | null | undefined;
}

const fadein = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 0.9;
    transform: none;
  }
`;

const styles = (theme: Theme) => ({
  price: {
    cursor: "pointer",
    "& > p": {
      margin: 0,
      fontWeight: 500,
      lineHeight: "1",
    },
    "& > span": {
      fontSize: "0.775rem",
      color: theme.palette.error.light,
    },
    "&:hover": {
      "& > p": {
        textDecoration: "underline",
      },
      "& > span": {
        textDecoration: "underline " + theme.palette.error.light,
      },
    },
  },
  priceDialog: {
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      paddingTop: 20,
      "& > table": {
        width: "65%",
        margin: "5px auto",
        tableLayout: "fixed",
        "& th": {
          userSelect: "none",
        },
        "& td": {
          textAlign: "center",
          userSelect: "none",
          "& > .MuiTextField-root": {
            width: 107.5,
            padding: "0 5px",
            fontSize: "1rem",
            "& .MuiInputAdornment-root": {
              padding: 0,
              "& > p": {
                lineHeight: "unset",
                fontWeight: 500,
                color: theme.palette.text.primary,
              },
            },
            "& .MuiInputBase-input": {
              fontWeight: 500,
              padding: "0 7.5px",
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "1.85rem",
            },
          },
          "& > p": {
            margin: 0,
            fontWeight: 500,
          },
          "& > span": {
            fontSize: "0.875rem",
            color: theme.palette.primary.main,
          },
        },
      },
      "& > .MuiTypography-body1": {
        marginTop: 10,
        marginBottom: 5,
        fontSize: "0.925rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
      "& > .MuiTypography-body2": {
        marginTop: 5,
        marginBottom: 10,
        fontSize: "0.875rem",
        color: theme.palette.error.main,
      },
      "& > .MuiTypography-root": {
        textAlign: "center",
        userSelect: "none",
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
  discount: {
    cursor: "pointer",
    "& > p": {
      margin: 0,
      fontWeight: 500,
      lineHeight: "1",
    },
    "& > span": {
      fontSize: "0.775rem",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  discountDialog: {
    "& .MuiPaper-root": {
      width: 825,
      maxWidth: "unset",
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      paddingTop: 20,
      "& > table": {
        width: "90%",
        margin: "0 auto",
        tableLayout: "fixed",
        "& th": {
          fontSize: "0.95rem",
          fontWeight: 600,
          userSelect: "none",
        },
        "& td": {
          fontSize: "0.95rem",
          fontWeight: 500,
          userSelect: "none",
          "& > .MuiTextField-root": {
            fontSize: "0.95rem",
            "& .MuiInputAdornment-root": {
              padding: 0,
              "& > p": {
                lineHeight: "unset",
                fontSize: "0.95rem",
                fontWeight: 500,
                color: theme.palette.text.primary,
              },
            },
            "& .MuiInputBase-input": {
              fontWeight: 500,
              padding: "0 3px",
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "1.5rem",
            },
          },
          "&.datePicker > div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > span": {
              margin: "0 12.5px",
            },
          },
          "&.price": {
            padding: "5px 10px",
          },
          "&:not(.price)": {
            textAlign: "center",
          },
        },
      },
      "& > .MuiTypography-body1": {
        marginTop: 10,
        marginBottom: 5,
        fontSize: "0.925rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
      },
      "& > .MuiTypography-body2": {
        marginTop: 5,
        marginBottom: 10,
        fontSize: "0.875rem",
        color: theme.palette.error.main,
      },
      "& > .MuiTypography-root": {
        textAlign: "center",
        userSelect: "none",
      },
      "& > div.top": {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        width: "90%",
        padding: "0 25px",
        margin: "5px auto 10px",
        userSelect: "none",
        "& > .MuiTypography-body1": {
          fontSize: "1rem",
          fontWeight: 600,
          color: theme.palette.primary.main,
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.9rem",
          fontWeight: 500,
          color: theme.palette.error.main,
        },
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
  status: {
    cursor: "pointer",
    "& > p": {
      margin: 0,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  statusDialog: {
    "& .MuiPaper-root": {
      width: 600,
      maxWidth: "unset",
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      padding: 20,
      "& > table": {
        width: "65%",
        margin: "0 auto 5px",
        tableLayout: "fixed",
        userSelect: "none",
        "& td": {
          textAlign: "center",
          "& > .MuiFormControl-root": {
            width: 135,
            fontSize: "1rem",
            "& .MuiSelect-select": {
              padding: 0,
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "2rem",
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& > .MuiTypography-root": {
        fontSize: "0.925rem",
        fontWeight: 500,
        color: theme.palette.error.main,
        textAlign: "center",
        margin: "5px 0 10px",
        userSelect: "none",
      },
      "& > div.status_ON": {
        textAlign: "center",
        userSelect: "none",
        "& > .MuiTypography-body1": {
          fontSize: "0.95rem",
          "& > span.REZ": {
            "& > span": {
              color: theme.palette.primary.main,
            },
          },
          "& > span.DONE": {
            "& > span": {
              color: theme.palette.primary.main,
            },
          },
          "& > span.NOT": {
            "& > span": {
              color: theme.palette.error.main,
            },
          },
        },
        "& > .MuiTypography-body2": {
          marginTop: 3,
          marginBottom: 5,
          fontSize: "0.925rem",
          "& > span.ON": {
            "& > span": {
              color: colors.blue[7],
            },
          },
          "& > span.REZ": {
            "& > span:first-child": {
              color: theme.palette.error.main,
            },
            "& > span:last-child": {
              color: theme.palette.primary.main,
            },
          },
          "& > span.DONE": {
            "& > span:first-child": {
              color: theme.palette.error.main,
            },
            "& > span:last-child": {
              color: colors.teal[7],
            },
          },
          "& > span.NOT": {
            "& > span": {
              color: colors.red[7],
            },
          },
        },
        "& > .MuiTypography-root": {
          fontWeight: 500,
          "& > span > span": {
            fontSize: "1rem",
            fontWeight: 600,
          },
        },
      },
      "& > div.status_REZ": {
        textAlign: "center",
        userSelect: "none",
        "& > div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiInput-input": {
            padding: ".4em 0",
          },
          "& .MuiButton-root": {
            padding: "4px 16px",
            transition: "unset",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          },
        },
        "& > .MuiTypography-body1": {
          fontSize: "0.95rem",
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.925rem",
          marginTop: 5,
        },
        "& > .MuiTypography-caption": {
          "&.safe": {
            color: theme.palette.primary.main,
          },
          "&.error": {
            color: theme.palette.error.main,
          },
        },
        "& > .MuiTypography-root": {
          fontWeight: 500,
          "& > span": {
            fontSize: "1rem",
            fontWeight: 600,
          },
          "& > span.WAIT": {
            color: colors.gray[8],
          },
          "& > span.ON": {
            color: colors.blue[7],
          },
          "& > span.REZ": {
            color: colors.cyan[6],
          },
          "& > span.DONE": {
            color: colors.teal[7],
          },
          "& > span.NOT": {
            color: colors.red[7],
          },
          "& > span.EMP": {
            color: theme.palette.error.main,
          },
          "& > span.USER": {
            color: theme.palette.primary.main,
          },
        },
      },
      "& > div.status_DONE": {
        textAlign: "center",
        userSelect: "none",
        "& > .MuiTypography-body1": {
          fontSize: "0.95rem",
          "& > span > span": {
            color: theme.palette.primary.main,
          },
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.925rem",
          marginTop: 5,
          "& > span > span": {
            color: colors.teal[7],
          },
        },
        "& > .MuiTypography-caption": {
          fontSize: "0.85rem",
        },
        "& > .MuiTypography-root": {
          fontWeight: 500,
          "& > span": {
            fontSize: "1rem",
            fontWeight: 600,
          },
        },
      },
      "& > div.status_NOT": {
        textAlign: "center",
        userSelect: "none",
        "& > .MuiTypography-body1": {
          fontSize: "0.95rem",
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.925rem",
          "& > span > span": {
            color: colors.red[7],
          },
        },
        "& > .MuiTypography-root": {
          fontWeight: 500,
          "& > span": {
            fontSize: "1rem",
            fontWeight: 600,
          },
        },
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
  statusResultDialog: {
    "& .MuiPaper-root": {
      width: 500,
      maxWidth: "unset",
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      padding: 20,
      userSelect: "none",
      "& > table": {
        width: "75%",
        margin: "0 auto 10px",
        tableLayout: "fixed",
        userSelect: "none",
        fontSize: "0.925rem",
        "& td": {
          textAlign: "center",
          "& > .MuiFormControl-root": {
            width: 135,
            fontSize: "1rem",
            "& .MuiSelect-select": {
              padding: 0,
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "2rem",
              color: theme.palette.primary.main,
            },
          },
        },
      },
      "& > .MuiTypography-root": {
        fontSize: "0.925rem",
        fontWeight: 500,
        color: theme.palette.error.main,
        textAlign: "center",
        margin: "5px 0 10px",
      },
      "& > div.result": {
        "& > .MuiTypography-root": {
          textAlign: "center",
        },
        "& > .MuiTypography-body1": {
          fontSize: "0.875rem",
          fontWeight: 500,
        },
        "& > .MuiTypography-body2": {
          fontSize: "1.35rem",
          fontWeight: "bold",
          color: theme.palette.primary.main,
        },
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
  inspection: {
    cursor: "pointer",
    "& > p": {
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textWrap: "nowrap",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  inspectionDialog: {
    "& .MuiPaper-root": {
      width: 675,
      maxWidth: "unset",
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      padding: 20,
      "& > div:first-child": {
        display: "flex",
        justifyContent: "center",
        padding: "15px 0",
        position: "relative",
        "& > div:not(.background, .alert)": {
          display: "flex",
          alignItems: "center",
          margin: "0 10px",
          "& > .MuiTypography-root": {
            fontSize: "0.925rem",
            marginLeft: 5,
          },
        },
        "& > div.background": {
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(3px)",
        },
        "& > div.alert": {
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          width: "fit-content",
          height: "fit-content",
          padding: "20px 25px",
          background: "white",
          borderRadius: 15,
          opacity: 0.9,
          animation: `${fadein} 1500ms ${theme.transitions.easing.easeInOut}`,
          "&.noData": {
            border: "3px solid " + theme.palette.error.main,
            color: theme.palette.error.main,
          },
          "&.accidentFree": {
            border: "3px solid " + theme.palette.primary.main,
            color: theme.palette.primary.main,
          },
          "& > p.title": {
            margin: 0,
            marginBottom: 5,
            fontSize: "1.15rem",
            fontWeight: "bold",
            textAlign: "center",
          },
          "& > p.subtitle": {
            margin: 0,
            fontSize: "0.85rem",
            textAlign: "center",
          },
        },
      },
      "& > div.details": {
        padding: 15,
        borderTop: "1px solid " + theme.palette.divider,
        borderBottom: "1px solid " + theme.palette.divider,
        "& > table": {
          "& th": {
            fontSize: 14,
            textAlign: "left",
          },
          "& td": {
            fontSize: 14,
          },
        },
      },
      "& > div:last-child": {
        display: "flex",
        justifyContent: "center",
        margin: "15px 0",
        "& > div": {
          display: "flex",
          alignItems: "center",
          margin: "0 7.5px",
          "& > .MuiTypography-root": {
            fontSize: "0.925rem",
            marginLeft: 5,
          },
        },
      },
    },
  },
  option: {
    cursor: "pointer",
    "& > p": {
      margin: 0,
      overflow: "hidden",
      textOverflow: "ellipsis",
      textWrap: "nowrap",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
  optionDialog: {
    "& .MuiPaper-root": {
      width: 825,
      maxWidth: "unset",
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      userSelect: "none",
      padding: "25px 40px",
      position: "relative",
      "& > div.title": {
        padding: "10px 0",
        "& > p": {
          fontSize: 15,
          fontWeight: 700,
          color: theme.palette.text.primary,
          textAlign: "center",
          cursor: "default",
          userSelect: "none",
        },
      },
      "& > ul.optionList": {
        width: "100%",
        padding: "15px 25px",
        margin: 0,
        listStyle: "none",
        userSelect: "none",
        "& > li": {
          display: "inline-block",
          width: "25%",
          "& > div": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "5px 0px",
            "& > p": {
              fontSize: 14,
              cursor: "default",
              "&.disabled": {
                color: theme.palette.text.disabled,
              },
            },
          },
        },
      },
    },
  },
  memo: {
    position: "relative",
    width: "100%",
    height: "1.65em",
    "&.disabled": {
      color: theme.palette.text.secondary,
    },
    "& > div": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      zIndex: 3,
    },
    "& > textarea:nth-of-type(1)": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      border: "1px solid " + theme.palette.text.disabled,
      padding: "0 5px",
      resize: "none",
      overflow: "hidden",
      transition: "height 200ms ease-in-out",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      zIndex: 2,
      "&:focus-visible": {
        height: "4.75em",
        outline: theme.palette.primary.main + " auto 1px",
        zIndex: 3,
        "& ~ span": {
          opacity: 1,
          zIndex: 3,
        },
      },
    },
    "& > textarea:nth-of-type(2)": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "inherit",
      height: "inherit",
      border: "1px solid " + theme.palette.text.disabled,
      padding: "0 5px",
      resize: "none",
      overflow: "hidden",
      transition: "height 200ms ease-in-out",
      fontSize: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      zIndex: 1,
      opacity: 0,
    },
    "& > span": {
      pointerEvents: "none",
      position: "absolute",
      right: 10,
      bottom: "-2.85em",
      color: theme.palette.text.secondary,
      transition: "opacity 200ms ease-in-out",
      zIndex: 2,
      opacity: 0,
    },
  },
  menu: {
    width: 87.5,
    fontSize: "0.875rem",
    "&.on": {
      background: theme.palette.primary.soft,
      color: theme.palette.primary.main,
    },
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  formControl: {
    cursor: "pointer",
    userSelect: "none",
    "& > .MuiTypography-root": {
      fontSize: "0.95rem",
    },
    "&.Mui-disabled": {
      "& + div": {
        "&::before": {
          color: theme.palette.divider,
        },
        "&::after": {
          color: theme.palette.divider,
        },
      },
    },
  },
  checkbox: {
    padding: 5,
    "& > .MuiSvgIcon-root": {
      fontSize: "1.15rem",
    },
  },
  optionFormControl: {
    cursor: "pointer",
    "& > .MuiTypography-root": {
      fontSize: 14,
      userSelect: "none",
    },
    "&.Mui-disabled": {
      "& .MuiButtonBase-root": {
        color: theme.palette.grey[600],
      },
      "& .MuiTypography-root": {
        color: theme.palette.grey[600],
      },
      "& + div": {
        "&::before": {
          color: theme.palette.grey[600],
        },
        "&::after": {
          color: theme.palette.grey[600],
        },
      },
    },
  },
  optionCheckbox: {
    padding: 3,
    "& > .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  optionCheckboxGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
  optionRadio: {
    padding: 3,
    "& > span > svg": {
      fontSize: 16,
    },
  },
  optionRadioGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
  },
});

const PriceCell = (
  info: any,
  data: VehicleData[],
  setData: Dispatch<SetStateAction<VehicleData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(info.row.original["price"]);
  const [editable, setEditable] = useState<boolean>(false);

  const patchVehicleMutation = useMutation(patchVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_VEHICLE_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: VehicleData) => {
        if (info.row.original["id"] === item.product.uid) {
          item.sales.price = Number(value);
          item.sales.price_update_at = new Date();
        }
      });
      setData(data_Copy);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const getADPrice = useCallback((value: number) => {
    return info.row.original["type"] === "CAR" ||
      info.row.original["type"] === "TRUCK"
      ? value + getAutowiniCommission(value)
      : value + getAutowiniBusCommission(value);
  }, []);

  const handleOnApply = () => {
    if (editable) {
      if (value === info.row.original["price"]) {
        enqueueSnackbar(t("MANAGE.MSG.EQUAL_PRICE"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      patchVehicleMutation.mutate({
        uid: info.row.original["id"],
        data: {
          sales: {
            price: value,
          },
        },
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setEditable(false);
      setValue(info.row.original["price"]);
    }
  };

  return (
    <>
      <div className={classes.price} onDoubleClick={() => setEditable(true)}>
        <p>{Number(info.row.original["price"]).toLocaleString()}</p>
        <span>
          ( {getADPrice(Number(info.row.original["price"])).toLocaleString()} )
        </span>
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.priceDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_PRICE")}</DialogTitle>
        <DialogContent>
          <table>
            <thead>
              <tr>
                <th>{t("MANAGE.TABLE.CURRENT_PRICE")}</th>
                <th></th>
                <th>{t("MANAGE.TABLE.MODIFY_PRICE")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="clPrice">
                  <p>USD {info.row.original["price"].toLocaleString()}</p>
                  <span>
                    ( USD{" "}
                    {getADPrice(
                      Number(info.row.original["price"])
                    ).toLocaleString()}{" "}
                    )
                  </span>
                </td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td>
                  <Input
                    type="integer"
                    variant="standard"
                    value={Number(value)}
                    dispatch={setValue}
                    inputProps={{
                      maxLength: 7,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <span>
                    ( USD {getADPrice(Number(value)).toLocaleString()} )
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography variant="body1">
            {t("MANAGE.MSG.NOTICE_PRICE")}
          </Typography>
          <Typography variant="body2">
            {t("MANAGE.MSG.NOTICE_MODIFY")}
          </Typography>
          <div className="actions">
            <Button variant="contained" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.MODIFY")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const DiscountCell = (
  info: any,
  data: VehicleData[],
  setData: Dispatch<SetStateAction<VehicleData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(info.row.original["discount"]);
  const [startDate, setStartDate] = useState<Date | null>(
    info.row.original["discount_created"] !== null
      ? new Date(info.row.original["discount_created"])
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    info.row.original["discount_expired"] !== null
      ? new Date(info.row.original["discount_expired"])
      : null
  );
  const [editable, setEditable] = useState<boolean>(false);

  const patchVehicleMutation = useMutation(patchVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_VEHICLE_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const checkDateRange = useCallback(
    (
      type: string,
      value: Date | null,
      compare: Date | null,
      dispatch: React.Dispatch<React.SetStateAction<Date | null>>
    ) => {
      if (isNull(value) || isNull(compare)) {
        dispatch(value);
      } else {
        let isCorrectRange = true;

        const checkValue = value;
        const checkCompare = compare;
        if (checkValue === null || checkCompare === null) {
          isCorrectRange = false;
        } else {
          if (type === "MIN") {
            if (checkValue > checkCompare) {
              enqueueSnackbar(t("MANAGE.MSG.COMPARE_MIN_VALUE"), {
                variant: "warning",
              });
              isCorrectRange = false;
            }
          } else if (type === "MAX") {
            if (checkValue < checkCompare) {
              enqueueSnackbar(t("MANAGE.MSG.COMPARE_MAX_VALUE"), {
                variant: "warning",
              });
              isCorrectRange = false;
            }
          }
        }

        if (isCorrectRange) {
          dispatch(checkValue);
        } else {
          dispatch(null);
        }
      }
    },
    []
  );

  const getCommision = useCallback((value: number) => {
    return info.row.original["type"] === "CAR" ||
      info.row.original["type"] === "TRUCK"
      ? getAutowiniCommission(value)
      : getAutowiniBusCommission(value);
  }, []);
  const getADPrice = useCallback((value: number) => {
    return value + getCommision(value);
  }, []);
  const getUTCDate = useCallback((value: Date | null) => {
    if (value === null) {
      return null;
    }

    const date = new Date(value);
    const offset = new Date().getTimezoneOffset(); // Offset (KST : -540)

    const utc_date = new Date(date.getTime() + offset * 60 * 1000);

    return utc_date;
  }, []);

  const handleOnApply = () => {
    if (editable) {
      if (value === null || startDate === null || endDate === null) {
        enqueueSnackbar(t("MANAGE.MSG.INPUT_DISCOUNT"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      } else if (
        value === info.row.original["discount"] &&
        endDate === info.row.original["discount_expired"]
      ) {
        enqueueSnackbar(t("MANAGE.MSG.EQUAL_DISCOUNT"), {
          variant: "error",
          autoHideDuration: 3000,
        });
        return;
      }

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: VehicleData) => {
        if (info.row.original["id"] === item.product.uid) {
          item.sales.discount = Number(value);
          item.sales.discount_created = getUTCDate(startDate);
          item.sales.discount_expired = getUTCDate(endDate);
        }
      });
      setData(data_Copy);

      patchVehicleMutation.mutate({
        uid: info.row.original["id"],
        data: {
          sales: {
            discount: value,
            discount_created: startDate,
            discount_expired: endDate,
          },
        },
      });
    }
  };
  const handleOnBlur = (value: number) => {
    if (value > getCommision(info.row.original["price"])) {
      setValue("");
      enqueueSnackbar(t("MANAGE.MSG.NOT_INPUT_MAX_DISCOUNT"), {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      if (isNotNull(value) && isNull(startDate)) {
        setStartDate(new Date());
      }
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setValue(info.row.original["discount"]);
      setStartDate(new Date(info.row.original["discount_created"]));
      setEndDate(new Date(info.row.original["discount_expired"]));
      setEditable(false);
    }
  };
  const handleOnClear = () => {
    setValue("");
    setStartDate(null);
    setEndDate(null);

    const data_Copy = JSON.parse(JSON.stringify(data));
    data_Copy.forEach((item: VehicleData) => {
      if (info.row.original["id"] === item.product.uid) {
        item.sales.discount = null;
        item.sales.discount_created = null;
        item.sales.discount_expired = null;
      }
    });
    setData(data_Copy);

    patchVehicleMutation.mutate({
      uid: info.row.original["id"],
      data: {
        sales: {
          discount: null,
          discount_created: null,
          discount_expired: null,
        },
      },
    });
  };

  return (
    <>
      <div className={classes.discount} onDoubleClick={() => setEditable(true)}>
        <p>
          {Number(info.row.original["discount"]) !== 0
            ? Number(info.row.original["discount"]).toLocaleString()
            : "-"}
        </p>
        {(info.row.original["discount_created"] !== null ||
          info.row.original["discount_expired"] !== null) && (
          <span>
            ({" "}
            {getDateFormat(info.row.original["discount_created"], "yyyy-MM-dd")}{" "}
            ~{" "}
            {getDateFormat(info.row.original["discount_expired"], "yyyy-MM-dd")}{" "}
            )
          </span>
        )}
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.discountDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_DISCOUNT")}</DialogTitle>
        <DialogContent>
          <div className="top">
            <Typography variant="body1">
              {t("MANAGE.TABLE.PRODUCT_PRICE")} : USD{" "}
              {getADPrice(Number(info.row.original["price"])).toLocaleString()}
            </Typography>
            <Typography variant="body2">
              {t("MANAGE.TABLE.MAX_DISCOUNT")} : USD{" "}
              {getCommision(
                Number(info.row.original["price"])
              ).toLocaleString()}
            </Typography>
          </div>
          <table>
            <colgroup>
              <col style={{ width: "8.5%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "8.5%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "1.5%" }} />
              <col style={{ width: "41.5%" }} />
            </colgroup>
            <thead>
              <tr>
                <th></th>
                <th>{t("MANAGE.TABLE.DISCOUNT")}</th>
                <th></th>
                <th>{t("MANAGE.TABLE.DISCOUNT_PRICE")}</th>
                <th></th>
                <th>{t("MANAGE.TABLE.DISCOUNT_PERIOD")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("MANAGE.TABLE.CURRENT_DISCOUNT")}</td>
                <td className="price">
                  USD {Number(info.row.original["discount"]).toLocaleString()}
                </td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td className="price">
                  USD{" "}
                  {(
                    getADPrice(Number(info.row.original["price"])) -
                    Number(info.row.original["discount"])
                  ).toLocaleString()}
                </td>
                <td></td>
                <td className="datePicker">
                  <div>
                    {getDateFormat(
                      info.row.original["discount_created"],
                      "yyyy-MM-dd"
                    )}
                    <span>~</span>
                    {getDateFormat(
                      info.row.original["discount_expired"],
                      "yyyy-MM-dd"
                    )}
                  </div>
                </td>
              </tr>
              <tr>
                <td>{t("MANAGE.TABLE.NEW_DISCOUNT")}</td>
                <td className="price">
                  <Input
                    type="integer"
                    variant="standard"
                    value={Number(value)}
                    dispatch={setValue}
                    inputProps={{
                      maxLength: 6,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    }}
                    onBlur={(value: number) => handleOnBlur(value)}
                  />
                </td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td className="price">
                  USD{" "}
                  {(
                    getADPrice(Number(info.row.original["price"])) -
                    Number(value)
                  ).toLocaleString()}
                </td>
                <td></td>
                <td className="datePicker">
                  <div>
                    <DatePicker
                      label="From"
                      minDate={new Date()}
                      maxDate={endDate}
                      selected={startDate}
                      onChange={(date) => {
                        checkDateRange("MIN", date, endDate, setStartDate);
                      }}
                      isClearable
                      dateFormat="yyyy-MM-dd"
                    />
                    <span>~</span>
                    <DatePicker
                      label="To"
                      minDate={startDate === null ? new Date() : startDate}
                      selected={endDate}
                      onChange={(date) => {
                        if (date !== null) {
                          date.setHours(23, 59, 59, 999);
                        }
                        checkDateRange("MAX", date, startDate, setEndDate);
                      }}
                      isClearable
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography variant="body1">
            {t("MANAGE.MSG.NOTICE_DISCOUNT")}
          </Typography>
          <Typography variant="body2">
            {t("MANAGE.MSG.NOTICE_MODIFY")}
          </Typography>
          <div className="actions">
            <Button variant="contained" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.DISCOUNT_APPLY")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClear()}>
              {t("MANAGE.TABLE.DISCOUNT_DELETE")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const StatusCell = (
  info: any,
  data: VehicleData[],
  setData: Dispatch<SetStateAction<VehicleData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(info.row.original["status"]);

  const [puid, setPuid] = useState<string | null>("");
  const [searchText, setSearchText] = useState<string>("");
  const [searchResult, setSearchResult] = useState<UserData[]>([]);
  const [isCheckPuid, setIsCheckPuid] = useState<boolean>(false);
  const [isExistPuid, setIsExistPuid] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string | null>("");

  const [price, setPrice] = useState<string>("");
  const [editable, setEditable] = useState<boolean>(false);

  const getUserListMutation = useMutation(getUserList, {
    onSuccess: (response) => {
      if (response.data.count > 0) {
        setSearchResult(response.data.results);
      } else {
        setHelperText(t("MANAGE.MSG.NOT_FOUND_PUID"));
      }
    },
    onError: (error) => {
      onError(error);
    },
  });
  const patchVehicleMutation = useMutation(patchVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_VEHICLE_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: VehicleData) => {
        if (info.row.original["id"] === item.product.uid) {
          // 여기서는 디비에서 저장된 내용을 setData에 적용시켜야 함
          if (item.sales.status === "WAIT" && value === "ON") {
            item.sales.sales_on_at = new Date();
          } else if (item.sales.status !== "DONE" && value === "DONE") {
            item.sales.sold_out_at = new Date();
          }

          if (item.sales.status === "REZ" && value !== "DONE") {
            item.sales.purchase_client = null;
          } else if (item.sales.status === "DONE" && value !== "DONE") {
            item.sales.sold_out_at = null;
            item.sales.purchase_client = null;
            item.sales.purchase_price = null;
          }

          item.sales.status = value;
          if (value === "REZ") {
            item.sales.purchase_client = puid;
          } else if (value === "DONE") {
            item.sales.purchase_price = Number(price);
          }
        }
      });
      setData(data_Copy);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const getPuid = useCallback(() => {
    let puid = info.row.original["purchase_client"];
    if (isNull(puid)) {
      puid = t("MANAGE.TABLE.NOT_LOGIN_USER");
    }
    return puid;
  }, [info.row.original["purchase_client"]]);
  const getOptions = useCallback(() => {
    const options: Option[] = [];
    if (info.row.original["status"] === "WAIT") {
      options.push(
        ...["WAIT", "ON"].map((status: string) => {
          return { label: t("VEHICLE.STATUS." + status), value: status };
        })
      );
    } else if (info.row.original["status"] === "ON") {
      options.push(
        ...["ON", "REZ", "NOT"].map((status: string) => {
          return { label: t("VEHICLE.STATUS." + status), value: status };
        })
      );
    } else if (info.row.original["status"] === "REZ") {
      options.push(
        ...["ON", "REZ", "DONE"].map((status: string) => {
          return { label: t("VEHICLE.STATUS." + status), value: status };
        })
      );
    } else if (info.row.original["status"] === "DONE") {
      options.push(
        ...["ON", "DONE"].map((status: string) => {
          return { label: t("VEHICLE.STATUS." + status), value: status };
        })
      );
    } else if (info.row.original["status"] === "NOT") {
      options.push(
        ...["ON", "NOT"].map((status: string) => {
          return { label: t("VEHICLE.STATUS." + status), value: status };
        })
      );
    }
    return options;
  }, [info.row.original["status"]]);

  const handleOnSearch = () => {
    if (searchText === "") {
      setHelperText(t("MANAGE.MSG.INPUT_PUID"));
    } else {
      if (searchText.length < 4) {
        setHelperText(t("USER.MSG.INPUT_AT_LEAST_CHAR", { length: 4 }));
      } else {
        getUserListMutation.mutate({
          search_text: searchText,
        });
      }
    }
  };
  const handleOnApply = () => {
    if (editable) {
      if (value === info.row.original["status"]) {
        enqueueSnackbar(t("MANAGE.MSG.STATUS"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      if (value === "REZ") {
        if (!isCheckPuid) {
          enqueueSnackbar(t("MANAGE.MSG.CHECK_PUID"), {
            variant: "info",
            autoHideDuration: 3000,
          });
          return;
        }
        if (!isExistPuid) {
          enqueueSnackbar(t("MANAGE.MSG.NOT_EXIST_PUID"), {
            variant: "error",
            autoHideDuration: 3000,
          });
          return;
        }
      } else if (value === "DONE") {
        if (price === "") {
          enqueueSnackbar(t("MANAGE.MSG_INPUT_SALE_PRICE"), {
            variant: "error",
            autoHideDuration: 3000,
          });
          return;
        }
      }

      const sendData: any = {
        sales: {
          status: value,
        },
      };
      if (value === "REZ") {
        sendData["sales"]["purchase_client"] = puid;
      } else if (value === "DONE") {
        sendData["sales"]["purchase_price"] = Number(price);
      }

      patchVehicleMutation.mutate({
        uid: info.row.original["id"],
        data: sendData,
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setValue(info.row.original["status"]);

      setPuid("");
      setPrice("");

      setIsCheckPuid(false);
      setIsExistPuid(false);
      setHelperText("");

      setEditable(false);
    }
  };

  useEffect(() => {
    setPuid("");
    setPrice("");
    setSearchText("");

    setIsCheckPuid(false);
    setIsExistPuid(false);
    setHelperText("");
  }, [value]);

  return (
    <>
      <div className={classes.status} onDoubleClick={() => setEditable(true)}>
        <p>{t("VEHICLE.STATUS." + info.row.original["status"])}</p>
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.statusDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_STATUS")}</DialogTitle>
        <DialogContent>
          <table>
            <thead>
              <tr>
                <th>
                  <Markup content={t("MANAGE.TABLE.CURRENT_STATUS")} />
                </th>
                <th></th>
                <th>
                  <Markup content={t("MANAGE.TABLE.MODIFY_STATUS")} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("VEHICLE.STATUS." + info.row.original["status"])}</td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td>
                  <Dropdown
                    variant="standard"
                    value={value}
                    dispatch={setValue}
                    options={getOptions()}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {value === "ON" && info.row.original["status"] !== "ON" && (
            <div className="status_ON">
              {info.row.original["status"] === "WAIT" && (
                <Typography variant="body2">
                  <Markup className="ON" content={t("MANAGE.MSG.CHANGE_ON")} />
                </Typography>
              )}
              {info.row.original["status"] === "REZ" && (
                <>
                  <Typography variant="body1">
                    <Markup
                      className="REZ"
                      content={t("MANAGE.MSG.NOTICE_REZ", { user: getPuid() })}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <Markup
                      className="REZ"
                      content={t("MANAGE.MSG.CANCEL_REZ")}
                    />
                  </Typography>
                </>
              )}
              {info.row.original["status"] === "DONE" && (
                <>
                  <Typography variant="body1">
                    <Markup
                      className="DONE"
                      content={t("MANAGE.MSG.NOTICE_DONE", { user: getPuid() })}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <Markup
                      className="DONE"
                      content={t("MANAGE.MSG.CANCEL_DONE")}
                    />
                  </Typography>
                </>
              )}
              {info.row.original["status"] === "NOT" && (
                <>
                  <Typography variant="body1">
                    <Markup
                      className="NOT"
                      content={t("MANAGE.MSG.NOTICE_NOT")}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <Markup
                      className="ON"
                      content={t("MANAGE.MSG.CHANGE_ON")}
                    />
                  </Typography>
                </>
              )}
            </div>
          )}
          {value === "REZ" && info.row.original["status"] !== "REZ" && (
            <div className="status_REZ">
              <div>
                <Input
                  placeholder={t("MANAGE.TABLE.PUID")}
                  variant="standard"
                  value={searchText}
                  dispatch={(value: string) => {
                    if (
                      puid !== null &&
                      puid !== "" &&
                      isCheckPuid &&
                      isExistPuid
                    ) {
                      if (window.confirm("수정하시겠습니까?")) {
                        setPuid("");
                        setSearchText("");

                        setIsCheckPuid(false);
                        setIsExistPuid(false);
                        setHelperText("");
                      }
                    } else {
                      setSearchText(value);
                    }
                  }}
                  disabled={puid === null}
                  onClick={() => {
                    if (
                      puid !== null &&
                      puid !== "" &&
                      isCheckPuid &&
                      isExistPuid
                    ) {
                      if (window.confirm("수정하시겠습니까?")) {
                        setPuid("");
                        setSearchText("");

                        setIsCheckPuid(false);
                        setIsExistPuid(false);
                        setHelperText("");
                      }
                    }
                  }}
                />
                <Button
                  variant="standard"
                  onClick={() => handleOnSearch()}
                  disabled={puid === null}
                >
                  {t("MANAGE.TABLE.SEARCH")}
                </Button>
              </div>
              {helperText && (
                <Typography
                  variant="caption"
                  className={isExistPuid ? "safe" : "error"}
                >
                  {helperText}
                </Typography>
              )}
              <Typography variant="body2">
                {t("MANAGE.MSG.INPUT_PUID")}
              </Typography>
              <Checkbox
                label={t("MANAGE.MSG.NOT_LOGIN_USER")}
                checked={puid === null}
                dispatch={() => {
                  if (puid === null) {
                    setPuid("");
                    setSearchText("");

                    setIsCheckPuid(false);
                    setIsExistPuid(false);
                    setHelperText("");
                  } else {
                    setPuid(null);
                    setSearchText("");

                    setIsCheckPuid(true);
                    setIsExistPuid(true);
                    setHelperText("");
                  }
                }}
                formControlClassName={classes.formControl}
                checkboxClassName={classes.checkbox}
              />
            </div>
          )}
          {value === "DONE" && info.row.original["status"] !== "DONE" && (
            <div className="status_DONE">
              <Typography variant="body1">
                <Markup
                  content={t("MANAGE.MSG.NOTICE_REZ", { user: getPuid() })}
                />
              </Typography>
              <div>
                <Input
                  type="integer"
                  placeholder={t("MANAGE.TABLE.SALE_PRICE")}
                  variant="standard"
                  value={price}
                  dispatch={setPrice}
                  inputProps={{ maxLength: 9 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        USD
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Typography variant="caption">
                {t("MANAGE.MSG.INPUT_SALE_PRICE")}
              </Typography>
              <Typography variant="body2">
                <Markup content={t("MANAGE.MSG.CHANGE_DONE")} />
              </Typography>
            </div>
          )}
          {value === "NOT" && info.row.original["status"] !== "NOT" && (
            <div className="status_NOT">
              <Typography variant="body2">
                <Markup content={t("MANAGE.MSG.CHANGE_NOT")} />
              </Typography>
            </div>
          )}
          <Typography>{t("MANAGE.MSG.NOTICE_MODIFY")}</Typography>
          <div className="actions">
            <Button variant="outlined" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.MODIFY")}
            </Button>
            <Button variant="outlined" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={searchResult.length > 0}
        onClose={() => {
          setSearchResult([]);

          setIsCheckPuid(false);
          setIsExistPuid(false);
        }}
        classes={{
          root: classes.statusResultDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.SEARCH_RESULT")}</DialogTitle>
        <DialogContent>
          {searchResult.length === 1 ? (
            <>
              <div className="result">
                <Typography variant="body1">
                  {searchResult[0]["name"]}
                </Typography>
                <Typography variant="body2">
                  {searchResult[0]["puid"]}
                </Typography>
              </div>
              <Typography>{t("MANAGE.MSG.CHECK_RESULT")}</Typography>
              <div className="actions">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setPuid(
                      searchResult[0]["puid"] ? searchResult[0]["puid"] : ""
                    );
                    setSearchText(
                      searchResult[0]["puid"] ? searchResult[0]["puid"] : ""
                    );
                    setSearchResult([]);

                    setIsCheckPuid(true);
                    setIsExistPuid(true);
                    setHelperText(t("MANAGE.MSG.FOUND_PUID"));
                  }}
                >
                  {t("MANAGE.TABLE.APPLY")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSearchResult([]);

                    setIsCheckPuid(false);
                    setIsExistPuid(false);
                  }}
                >
                  {t("MANAGE.TABLE.CANCEL")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th>{t("MANAGE.TABLE.NAME")}</th>
                    <th>{t("MANAGE.TABLE.UID")}</th>
                    <th>{t("MANAGE.TABLE.SELECT")}</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResult.map((result: UserData, idx: number) => {
                    return (
                      <tr key={"search_result_" + idx}>
                        <td>{result["name"]}</td>
                        <td>{result["puid"]}</td>
                        <td>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setPuid(result["puid"] ? result["puid"] : "");
                              setSearchText(
                                result["puid"] ? result["puid"] : ""
                              );
                              setSearchResult([]);

                              setIsCheckPuid(true);
                              setIsExistPuid(true);
                              setHelperText(t("MANAGE.MSG.FOUND_PUID"));
                            }}
                          >
                            {t("MANAGE.TABLE.SELECT")}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Typography>{t("MANAGE.MSG.SELECT_RESULT")}</Typography>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const InspectionCell = (info: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const [data, setData] = useState<{ [key: string]: string[] }>({
    X: [],
    W: [],
    C: [],
    A: [],
    U: [],
    T: [],
  });
  const [isData, setIsData] = useState<boolean>(false);
  const [isAccident, setIsAccident] = useState<boolean>(false);

  const getText = useCallback(() => {
    if (
      isNull(info.row.original["inspection"]["accident_history"]) ||
      isNull(info.row.original["inspection"]["simple_repair"])
    ) {
      return "-";
    }

    if (Object.values(data).every((value) => value.length === 0)) {
      return t("MANAGE.TABLE.NO_ACCIDENT");
    } else {
      const resultText: string[] = [];
      for (const key of Object.keys(data)) {
        if (data[key].length !== 0) {
          resultText.push(key + " : " + data[key].length);
        }
      }
      return resultText.join(" | ");
    }
  }, [data]);
  const getOptionText = useCallback(
    (valueList: string[]) => {
      const resultText: string[] = [];
      for (const value of valueList) {
        resultText.push(t("VEHICLE.INSPECTION." + value.toUpperCase()));
      }
      return resultText.join(", ");
    },
    [data]
  );

  useEffect(() => {
    if (
      isNotNull(info.row.original["inspection"]["accident_history"]) &&
      isNotNull(info.row.original["inspection"]["simple_repair"])
    ) {
      setIsData(true);

      let isAccident = false;
      const result: { [key: string]: string[] } = {
        X: [],
        W: [],
        C: [],
        A: [],
        U: [],
        T: [],
      };
      for (const [key, value] of Object.entries(
        info.row.original["inspection"]
      )) {
        if (
          [
            "corrosion",
            "hole",
            "crack",
            "accident_history",
            "simple_repair",
          ].includes(key)
        ) {
          continue;
        }

        if (isNotNull(value)) {
          isAccident = true;
        }

        if (value === "X") {
          result["X"].push(key);
        } else if (value === "W") {
          result["W"].push(key);
        } else if (value === "C") {
          result["C"].push(key);
        } else if (value === "A") {
          result["A"].push(key);
        } else if (value === "U") {
          result["U"].push(key);
        } else if (value === "T") {
          result["T"].push(key);
        }
      }

      setData(result);
      if (isAccident) {
        setIsAccident(true);
      } else {
        setIsAccident(false);
      }
    } else {
      setIsData(false);
    }
  }, [info.row.original["inspection"]]);

  return (
    <>
      <div className={classes.inspection} onDoubleClick={() => setOpen(true)}>
        <p>{getText()}</p>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          root: classes.inspectionDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_INSPECTION")}</DialogTitle>
        <DialogContent>
          <div>
            <LeftSide data={info.row.original} />
            <FrontSide data={info.row.original} />
            <RightSide data={info.row.original} />
            <RearSide data={info.row.original} />
            {(!isData || !isAccident) && (
              <>
                <div className="background" />
                {!isData && (
                  <div className="alert noData">
                    <p className="title">{t("MSG.NO_INSPECTION_VEHICLE")}</p>
                    <p className="subtitle">
                      <Markup content={t("MSG.CHECK_INSPECTION")} />
                    </p>
                  </div>
                )}
                {isData && !isAccident && (
                  <div className="alert accidentFree">
                    <p className="title">{t("MSG.NO_ACCIDENT_VEHICLE")}</p>
                    <p className="subtitle">
                      <Markup content={t("MSG.CHECK_INSPECTION")} />
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          {Object.values(data).some((value) => value.length > 0) && (
            <div className="details">
              <table>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "80%" }} />
                </colgroup>
                <tbody>
                  {data["X"].length > 0 && (
                    <tr>
                      <th>{t("VEHICLE.INSPECTION.EXCHANGE")}</th>
                      <td>{getOptionText(data["X"])}</td>
                    </tr>
                  )}
                  {data["W"].length > 0 && (
                    <tr>
                      <th>{t("VEHICLE.INSPECTION.PLATE_WELDING")}</th>
                      <td>{getOptionText(data["W"])}</td>
                    </tr>
                  )}
                  {data["C"].length > 0 && (
                    <tr>
                      <th>{t("VEHICLE.INSPECTION.CORROSION")}</th>
                      <td>{getOptionText(data["C"])}</td>
                    </tr>
                  )}
                  {data["A"].length > 0 && (
                    <tr>
                      <th>{t("VEHICLE.INSPECTION.SCRATCH")}</th>
                      <td>{getOptionText(data["A"])}</td>
                    </tr>
                  )}
                  {data["U"].length > 0 && (
                    <tr>
                      <th>{t("VEHICLE.INSPECTION.UNEVEN")}</th>
                      <td>{getOptionText(data["U"])}</td>
                    </tr>
                  )}
                  {data["T"].length > 0 && (
                    <tr>
                      <th>{t("VEHICLE.INSPECTION.DAMAGE")}</th>
                      <td>{getOptionText(data["T"])}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <div>
            <div>
              <StatusMark status="X" />
              <Typography>{t("VEHICLE.INSPECTION.EXCHANGE")}</Typography>
            </div>
            <div>
              <StatusMark status="W" />
              <Typography>{t("VEHICLE.INSPECTION.PLATE_WELDING")}</Typography>
            </div>
            <div>
              <StatusMark status="C" />
              <Typography>{t("VEHICLE.INSPECTION.CORROSION")}</Typography>
            </div>
            <div>
              <StatusMark status="A" />
              <Typography>{t("VEHICLE.INSPECTION.SCRATCH")}</Typography>
            </div>
            <div>
              <StatusMark status="U" />
              <Typography>{t("VEHICLE.INSPECTION.UNEVEN")}</Typography>
            </div>
            <div>
              <StatusMark status="T" />
              <Typography>{t("VEHICLE.INSPECTION.DAMAGE")}</Typography>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const OptionCell = (info: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const getText = useCallback(() => {
    if (info.row.original["option"].length === 0) {
      return "-";
    }

    const result: string[] = [];
    ["INEXTERIOR", "SEAT", "CONVINIENCE", "SAFETY"].forEach((part) => {
      Object.entries(optionList[info.row.original["type"]][part]).forEach(
        ([key, value]) => {
          value.forEach((option) => {
            if (info.row.original["option"].includes(option.value)) {
              result.push(
                value.length > 1
                  ? t("VEHICLE.OPTION." + key).replace("<br>", " ") +
                      "(" +
                      option.label.replace("<br>", " ") +
                      ")"
                  : option.label.replace("<br>", " ")
              );
            }
          });
        }
      );
    });

    return result.join(", ");
  }, [info.row.original["option"]]);

  const getCheckboxValue = useCallback(
    (optionList: Option[]) => {
      if (info.row.original["option"] === undefined) {
        return false;
      }

      for (let idx = 0; idx < optionList.length; idx++) {
        if (info.row.original["option"].indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      }
      return false;
    },
    [info.row.original["option"]]
  );
  const getRadioGroupValue = useCallback(
    (optionList: Option[]) => {
      if (info.row.original["option"] === undefined) {
        return false;
      }

      for (let idx = 0; idx < optionList.length; idx++) {
        if (info.row.original["option"].indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      }
      return "";
    },
    [info.row.original["option"]]
  );

  return (
    <>
      <div className={classes.option} onDoubleClick={() => setOpen(true)}>
        <p>{getText()}</p>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          root: classes.optionDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_OPTION")}</DialogTitle>
        <DialogContent>
          <Divider />
          <div className="title">
            <Typography>{t("VEHICLE.OPTION.INEXTERIOR")}</Typography>
          </div>
          <Divider />
          <ul className="optionList">
            {Object.keys(
              optionList[info.row.original["type"]]["INEXTERIOR"]
            ).map((option, idx) => {
              return (
                <li key={idx}>
                  <div style={{ flexDirection: "column" }}>
                    <Checkbox
                      disabled
                      label={<Markup content={t("VEHICLE.OPTION." + option)} />}
                      checked={getCheckboxValue(
                        optionList[info.row.original["type"]]["INEXTERIOR"][
                          option
                        ]
                      )}
                      formControlClassName={classes.optionFormControl}
                      checkboxClassName={classes.optionCheckbox}
                    />
                    {info.row.original["type"] === "CAR" &&
                      ["SUNROOF"].includes(option) && (
                        <RadioGroup
                          row
                          className={classes.optionRadioGroup}
                          value={getRadioGroupValue(
                            optionList[info.row.original["type"]]["INEXTERIOR"][
                              option
                            ]
                          )}
                        >
                          {optionList[info.row.original["type"]]["INEXTERIOR"][
                            option
                          ].map((detail_option, idx) => {
                            return (
                              <Radio
                                key={idx}
                                disabled
                                label={<Markup content={detail_option.label} />}
                                value={detail_option.value}
                                formControlClassName={classes.optionFormControl}
                                radioClassName={classes.optionRadio}
                              />
                            );
                          })}
                        </RadioGroup>
                      )}
                    {["HEADLAMP"].includes(option) && (
                      <RadioGroup
                        row
                        className={classes.optionRadioGroup}
                        value={getRadioGroupValue(
                          optionList[info.row.original["type"]]["INEXTERIOR"][
                            option
                          ]
                        )}
                      >
                        {optionList[info.row.original["type"]]["INEXTERIOR"][
                          option
                        ].map((detail_option, idx) => {
                          return (
                            <Radio
                              key={idx}
                              disabled
                              label={<Markup content={detail_option.label} />}
                              value={detail_option.value}
                              formControlClassName={classes.optionFormControl}
                              radioClassName={classes.optionRadio}
                            />
                          );
                        })}
                      </RadioGroup>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          <Divider />
          <div className="title">
            <Typography>{t("VEHICLE.OPTION.SEAT")}</Typography>
          </div>
          <Divider />
          <ul className="optionList">
            {Object.keys(optionList[info.row.original["type"]]["SEAT"]).map(
              (option, idx) => {
                return (
                  <li key={idx}>
                    <div>
                      <Checkbox
                        disabled
                        label={
                          <Markup content={t("VEHICLE.OPTION." + option)} />
                        }
                        checked={getCheckboxValue(
                          optionList[info.row.original["type"]]["SEAT"][option]
                        )}
                        formControlClassName={classes.optionFormControl}
                        checkboxClassName={classes.optionCheckbox}
                      />
                      {info.row.original["type"] === "CAR" &&
                        [
                          "HEAT_SEAT",
                          "POWER_SEAT",
                          "VENT_SEAT",
                          "MEMORY_SEAT",
                        ].includes(option) && (
                          <div className={classes.optionCheckboxGroup}>
                            {optionList[info.row.original["type"]]["SEAT"][
                              option
                            ].map((detail_option, idx) => {
                              return (
                                <Checkbox
                                  key={idx}
                                  disabled
                                  label={
                                    <Markup content={detail_option.label} />
                                  }
                                  checked={getCheckboxValue([
                                    optionList[info.row.original["type"]][
                                      "SEAT"
                                    ][option][idx],
                                  ])}
                                  formControlClassName={
                                    classes.optionFormControl
                                  }
                                  checkboxClassName={classes.optionCheckbox}
                                />
                              );
                            })}
                          </div>
                        )}
                    </div>
                  </li>
                );
              }
            )}
          </ul>
          <Divider />
          <div className="title">
            <Typography>{t("VEHICLE.OPTION.CONVINIENCE")}</Typography>
          </div>
          <Divider />
          <ul className="optionList">
            {Object.keys(
              optionList[info.row.original["type"]]["CONVINIENCE"]
            ).map((option, idx) => {
              return (
                <li key={idx}>
                  <div>
                    <Checkbox
                      disabled
                      label={<Markup content={t("VEHICLE.OPTION." + option)} />}
                      checked={getCheckboxValue(
                        optionList[info.row.original["type"]]["CONVINIENCE"][
                          option
                        ]
                      )}
                      formControlClassName={classes.optionFormControl}
                      checkboxClassName={classes.optionCheckbox}
                    />
                    {info.row.original["type"] === "CAR" &&
                      ["CRUISE_CONTROL"].includes(option) && (
                        <RadioGroup
                          row
                          className={classes.optionRadioGroup}
                          value={getRadioGroupValue(
                            optionList[info.row.original["type"]][
                              "CONVINIENCE"
                            ][option]
                          )}
                        >
                          {optionList[info.row.original["type"]]["CONVINIENCE"][
                            option
                          ].map((detail_option, idx) => {
                            return (
                              <Radio
                                key={idx}
                                disabled
                                label={<Markup content={detail_option.label} />}
                                value={detail_option.value}
                                formControlClassName={classes.optionFormControl}
                                radioClassName={classes.optionRadio}
                              />
                            );
                          })}
                        </RadioGroup>
                      )}
                    {["AIRCON"].includes(option) && (
                      <RadioGroup
                        row
                        className={classes.optionRadioGroup}
                        value={getRadioGroupValue(
                          optionList[info.row.original["type"]]["CONVINIENCE"][
                            option
                          ]
                        )}
                      >
                        {optionList[info.row.original["type"]]["CONVINIENCE"][
                          option
                        ].map((detail_option, idx) => {
                          return (
                            <Radio
                              key={idx}
                              disabled
                              label={<Markup content={detail_option.label} />}
                              value={detail_option.value}
                              formControlClassName={classes.optionFormControl}
                              radioClassName={classes.optionRadio}
                            />
                          );
                        })}
                      </RadioGroup>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          <Divider />
          <div className="title">
            <Typography>{t("VEHICLE.OPTION.SAFETY")}</Typography>
          </div>
          <Divider />
          <ul className="optionList">
            {Object.keys(optionList[info.row.original["type"]]["SAFETY"]).map(
              (option, idx) => {
                return (
                  <li key={idx}>
                    <div>
                      <Checkbox
                        disabled
                        label={
                          <Markup content={t("VEHICLE.OPTION." + option)} />
                        }
                        checked={getCheckboxValue(
                          optionList[info.row.original["type"]]["SAFETY"][
                            option
                          ]
                        )}
                        formControlClassName={classes.optionFormControl}
                        checkboxClassName={classes.optionCheckbox}
                      />
                      {["AIRBAG", "CAMERA", "PARK_SENSOR"].includes(option) && (
                        <div className={classes.optionCheckboxGroup}>
                          {optionList[info.row.original["type"]]["SAFETY"][
                            option
                          ].map((detail_option, idx) => {
                            return (
                              <Checkbox
                                key={idx}
                                disabled
                                label={<Markup content={detail_option.label} />}
                                checked={getCheckboxValue([
                                  optionList[info.row.original["type"]][
                                    "SAFETY"
                                  ][option][idx],
                                ])}
                                formControlClassName={classes.optionFormControl}
                                checkboxClassName={classes.optionCheckbox}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </li>
                );
              }
            )}
          </ul>
          <Divider />
        </DialogContent>
      </Dialog>
    </>
  );
};

const MemoCell = (info: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputSizeRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState<string>(info.row.original["memo"]);
  const [editable, setEditable] = useState<boolean>(false);

  const handleOnBlur = () => {
    if (editable) {
      patchVehicleMutation.mutate({
        uid: info.row.original["id"],
        data: {
          sales: {
            memo: value,
          },
        },
      });
      setEditable(false);
    }
  };

  const patchVehicleMutation = useMutation(patchVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_VEHICLE_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
  });

  useEffect(() => {
    const lines = Math.ceil(
      inputSizeRef.current!.scrollHeight / inputSizeRef.current!.clientHeight
    );
    if (lines >= 4 || value.length > 100) {
      setValue(value.slice(0, -1));
    }
  }, [value]);

  useEffect(() => {
    if (editable) {
      inputRef.current!.focus();
    }
  }, [editable]);

  return (
    <ClickAwayListener onClickAway={() => handleOnBlur()}>
      <div className={classnames(classes.memo, { disabled: !editable })}>
        <div onDoubleClick={() => setEditable(true)} />
        <textarea
          name="main_memo"
          ref={inputRef}
          wrap="hard"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!editable}
          spellCheck="false"
          placeholder="Space for notes"
        />
        <textarea
          name="sub_memo"
          ref={inputSizeRef}
          wrap="hard"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!editable}
          spellCheck="false"
        />
        <span>{value.length}/100 Byte</span>
      </div>
    </ClickAwayListener>
  );
};

const IndeterminateCheckbox = ({
  indeterminate,
  className,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      name="tbl_cbx"
      type="checkbox"
      ref={ref}
      className={className !== undefined ? className : undefined}
      {...rest}
    />
  );
};

const ManageDataContainer = ({
  data,
  setData,
  page,
  pageSize,
  totalCount,
  status,
  params,
}: propType) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<DataType[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const columns: ColumnDef<DataType>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "photo",
      header: () => <span>{t("MANAGE.COLUMN.PHOTO")}</span>,
      cell: (info: any) => (
        <Image
          src={info.getValue}
          style={{ display: "block", width: "100%" }}
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "id",
      header: "ID",
      cell: (info: any) => {
        return (
          <span
            onClick={() =>
              openNewTab("WEB", "vehicle/detail/" + info.getValue())
            }
          >
            {info.getValue()}
          </span>
        );
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "vin",
      header: () => <span>{t("MANAGE.COLUMN.VIN")}</span>,
      cell: (info: any) => {
        const handleCopyOnClick = () => {
          navigator.clipboard.writeText(info.getValue()).then(
            () => {
              enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
            },
            () => {
              enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
            }
          );
        };
        return (
          <span onClick={() => handleCopyOnClick()}>{info.getValue()}</span>
        );
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "maker",
      header: () => <span>{t("MANAGE.COLUMN.MAKER")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "model",
      header: () => <span>{t("MANAGE.COLUMN.MODEL")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "detail_model",
      header: () => <span>{t("MANAGE.COLUMN.DETAIL_MODEL")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "price",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.PRICE")}</span>
        </Tooltip>
      ),
      cell: (info: any) => PriceCell(info, data, setData),
      enableMultiSort: true,
    },
    {
      accessorKey: "discount",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.DISCOUNT")}</span>
        </Tooltip>
      ),
      cell: (info: any) => DiscountCell(info, data, setData),
      enableMultiSort: true,
    },
    {
      accessorKey: "status",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.STATUS")}</span>
        </Tooltip>
      ),
      cell: (info: any) => StatusCell(info, data, setData),
      enableMultiSort: true,
    },
    {
      accessorKey: "memo",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.MEMO")}</span>
        </Tooltip>
      ),
      cell: (info: any) => MemoCell(info),
      enableSorting: false,
    },
    {
      accessorKey: "mileage",
      header: () => <span>{t("MANAGE.COLUMN.MILEAGE")}</span>,
      cell: (info: any) => (
        <span>{info.row.original["mileage"].toLocaleString()} km</span>
      ),
      enableMultiSort: true,
    },
    {
      accessorKey: "mission",
      header: () => <span>{t("MANAGE.COLUMN.MISSION")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "fuel",
      header: () => <span>{t("MANAGE.COLUMN.FUEL")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "color",
      header: () => <span>{t("MANAGE.COLUMN.COLOR")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "steering",
      header: () => <span>{t("MANAGE.COLUMN.STEERING")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "drive",
      header: () => <span>{t("MANAGE.COLUMN.DRIVE")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "accident_history",
      header: () => <Markup content={t("MANAGE.COLUMN.ACCIDENT_HISTORY")} />,
      cell: (info: any) => (
        <span>
          {isNotNull(info.row.original["accident_history"])
            ? info.row.original["accident_history"]
            : "-"}
        </span>
      ),
      enableMultiSort: true,
    },
    {
      accessorKey: "simple_repair",
      header: () => <Markup content={t("MANAGE.COLUMN.SIMPLE_REPAIR")} />,
      cell: (info: any) => (
        <span>
          {isNotNull(info.row.original["simple_repair"])
            ? info.row.original["simple_repair"]
            : "-"}
        </span>
      ),
      enableMultiSort: true,
    },
    {
      accessorKey: "inspection",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_DETAIL")} arrow>
          <span>{t("MANAGE.COLUMN.INSPECTION")}</span>
        </Tooltip>
      ),
      cell: (info: any) => InspectionCell(info),
      enableSorting: false,
    },
    {
      accessorKey: "option",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_DETAIL")} arrow>
          <span>{t("MANAGE.COLUMN.OPTION")}</span>
        </Tooltip>
      ),
      cell: (info: any) => OptionCell(info),
      enableSorting: false,
    },
    {
      accessorKey: "created",
      header: () => <span>{t("MANAGE.COLUMN.CREATED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "modified",
      header: () => <span>{t("MANAGE.COLUMN.MODIFIED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "sales_on",
      header: () => <span>{t("MANAGE.COLUMN.SALES_ON")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "sold_out",
      header: () => <span>{t("MANAGE.COLUMN.SOLD_OUT")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "price_update",
      header: () => <Markup content={t("MANAGE.COLUMN.PRICE_UPDATE")} />,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      id: "modify",
      header: () => <span>{t("MANAGE.COLUMN.MODIFY")}</span>,
      cell: (info: any) => {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() =>
                openNewTab(
                  "MANAGEMENT",
                  "vehicle/update/" + info.row.original["id"]
                )
              }
            >
              Edit
            </Button>
          </>
        );
      },
      enableSorting: false,
    },
  ];

  useEffect(() => {
    setTableData(getVehicleTableData(data));
  }, [data]);

  return (
    <ManageDataTable
      data={tableData}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      status={status}
      params={params}
    />
  );
};

export default ManageDataContainer;
