import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import {
  Dialog,
  DialogActions,
  DialogContent,
  Modal,
  Paper,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileSharpIcon from "@mui/icons-material/UploadFileSharp";
import { StarIcon } from "@/common/icons";
import { Button, Dropdown, Image } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { isNotNull } from "@/common/lib/common";
import { specialPointList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  container: {
    width: 1075,
    margin: "45px auto",
    userSelect: "none",
  },
  title: {
    padding: "0px 15px 15px",
    marginBottom: 10,
    borderBottom: "1px solid " + theme.palette.text.primary,
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    "& > svg": {
      width: "3rem",
      height: "3rem",
      fill: theme.palette.primary.main,
      marginRight: 15,
    },
    "& > div": {
      "& > .MuiTypography-body1 > span": {
        fontSize: "1.375rem",
        fontWeight: "bold",
        "& > span": {
          fontSize: "1.15rem",
        },
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.925rem",
        lineHeight: "1.35",
        "& > span": {
          color: theme.palette.primary.main,
          fontSize: "0.85rem",
        },
      },
    },
  },
  content: {
    position: "relative",
    display: "flex",
    padding: "15px 25px",
    "& > img": {
      width: 320,
      aspectRatio: "1.33",
      borderRadius: 25,
    },
    "& > button": {
      position: "absolute",
      top: 29,
      left: 303,
      zIndex: 2,
      margin: -8,
      padding: 4,
      "& > svg": {
        fontSize: "2.35rem",
        color: "white",
      },
    },
    "& > div.image": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 320,
      aspectRatio: "1.33",
      borderRadius: 25,
      border: "2px dashed " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      "& > svg": {
        fontSize: "2.15rem",
        marginBottom: 5,
      },
      "& > span": {
        fontSize: "1.25rem",
        fontWeight: 500,
      },
    },
    "& > div.content": {
      width: 600,
      margin: "30px 45px",
      "& > div:last-child": {
        marginTop: 15,
        marginLeft: 15,
        "& > .title": {
          fontSize: "1.35rem",
          fontWeight: 500,
          wordSpacing: "-0.75px",
          marginBottom: 10,
          "& > span > span": {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
        },
        "& > .content": {
          fontSize: "0.95rem",
          color: theme.palette.text.secondary,
          wordSpacing: "-0.75px",
        },
      },
    },
  },
  dropdownFormControl: {
    width: 225,
    fontSize: "0.95rem !important",
  },
  dropdownMenuItem: {
    fontSize: "0.95rem !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  paper: {
    position: "relative",
    width: 810,
    borderRadius: 10,
    padding: 24,
    "& > div.title": {
      backgroundColor: theme.palette.primary.main,
      padding: "12.5px 0",
      textAlign: "center",
      color: "white",
      "& > .MuiTypography-body1": {
        fontSize: "1.65rem",
        fontWeight: "bold",
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.975rem",
        fontWeight: 500,
      },
    },
    "& > div.content": {
      margin: "16px -4px",
      paddingLeft: 15,
      maxHeight: 600,
      overflowX: "hidden",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: 15,
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
        backgroundClip: "padding-box",
        border: "3.5px solid transparent",
      },
    },
  },
  imageList: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    "& > li": {
      position: "relative",
      display: "inline-block",
      width: "20%",
      padding: 8,
      boxSizing: "border-box",
      "& > img": {
        position: "relative",
        width: "100%",
        aspectRatio: "1.33",
        borderRadius: 5,
      },
      "& > div.border": {
        position: "absolute",
        zIndex: 2,
        width: "calc(100% - 16px)",
        aspectRatio: "1.33",
        borderRadius: 5,
        border: "5px solid " + theme.palette.primary.main,
        cursor: "pointer",
        opacity: 0,
      },
      "&.on > div.border": {
        opacity: 1,
      },
    },
  },
  noItem: {
    width: "100%",
    aspectRatio: "4.5",
    padding: 7.5,
    background: theme.palette.primary.main,
    borderRadius: 5,
    cursor: "pointer",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      border: "2.5px dashed white",
      borderRadius: 5,
      color: "white",
      "& > svg": {
        fontSize: "1.5rem",
        marginBottom: 2.5,
      },
      "& > span": {
        fontSize: "0.95rem",
      },
    },
  },
  closeButton: {
    position: "absolute",
    top: 24,
    right: 24,
    zIndex: 2,
    "& > svg": {
      fontSize: "2.35rem",
      color: "white",
    },
  },
  dialog: {
    padding: 24,
    borderRadius: 10,
  },
  dialogContent: {
    padding: 0,
    color: "white",
    textAlign: "center",
    "& > .MuiTypography-body1": {
      fontSize: "1.65rem",
      fontWeight: "bold",
      color: "white",
      padding: "12.5px 0",
      marginBottom: 20,
      backgroundColor: theme.palette.primary.main,
    },
    "& > img": {
      width: "85%",
    },
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    padding: 0,
    marginTop: 5,
    "& > button": {
      fontSize: "1.4rem",
      fontWeight: "bold",
      margin: "10px 15px",
      padding: "0 15px",
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const SpecialPoint = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectIdx, setSelectIdx] = useState(-1);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.title}>
          <StarIcon />
          <div>
            <Typography variant="body1">
              <Markup content={t("REGISTER.DETAIL.SPECIAL_POINT.TITLE")} />
            </Typography>
            <Typography variant="body2">
              {t("REGISTER.DETAIL.SPECIAL_POINT.CONTENT")}
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          {value.special_point !== undefined &&
          value.special_point.image !== null &&
          isNotNull(value.special_point.image) ? (
            <>
              <Image
                src={value.media.image[value.special_point.image]}
                onClick={() => setModalOpen(true)}
              />
              <IconButton
                onClick={() => {
                  value.special_point !== undefined
                    ? dispatch({
                        ...value,
                        special_point: {
                          ...value.special_point,
                          image: null,
                        },
                      })
                    : dispatch({
                        ...value,
                        special_point: {
                          image: null,
                          type: "",
                        },
                      });
                  setSelectIdx(-1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <div className="image" onClick={() => setModalOpen(true)}>
              <UploadFileSharpIcon />
              <span>{t("REGISTER.DETAIL.SPECIAL_POINT.SELECT")}</span>
            </div>
          )}
          <div className="content">
            <Dropdown
              id={"special-point-type"}
              labelId={"special-point-type-label"}
              label={t("REGISTER.DETAIL.SPECIAL_POINT.LABEL")}
              value={
                value.special_point !== undefined
                  ? value.special_point.type
                  : ""
              }
              options={specialPointList}
              dispatch={(_value: string) =>
                value.special_point !== undefined
                  ? dispatch({
                      ...value,
                      special_point: {
                        ...value.special_point,
                        type: _value,
                      },
                    })
                  : dispatch({
                      ...value,
                      special_point: {
                        image: null,
                        type: _value,
                      },
                    })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
            <div>
              {value.special_point !== undefined &&
              isNotNull(value.special_point.type) ? (
                <>
                  <Typography className="title">
                    <Markup
                      content={t("REGISTER.DETAIL.SPECIAL_POINT.MSG.TITLE", {
                        point: t(
                          "VEHICLE.SPECIAL_POINT." + value.special_point.type
                        ),
                      })}
                    />
                  </Typography>
                  <Typography className="content">
                    <Markup
                      content={t(
                        "REGISTER.DETAIL.SPECIAL_POINT.MSG." +
                          value.special_point.type
                      )}
                    />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="title">
                    {t("REGISTER.DETAIL.SPECIAL_POINT.MSG.NO_SELECT")}
                  </Typography>
                  <Typography className="content">
                    {t("REGISTER.DETAIL.SPECIAL_POINT.CONTENT")}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        disableAutoFocus={true}
      >
        <Paper className={classes.paper}>
          <IconButton
            className={classes.closeButton}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
          <div className="title">
            <Typography variant="body1">
              {t("REGISTER.DETAIL.SPECIAL_POINT.SELECT")}
            </Typography>
            <Typography variant="body2">
              {t("REGISTER.DETAIL.SPECIAL_POINT.CONTENT")}
            </Typography>
          </div>
          <div className="content">
            {value.media.image.length > 0 ? (
              <ul className={classes.imageList}>
                {value.media.image.map((file: File, idx: number) => {
                  return (
                    <li
                      key={idx}
                      className={
                        value.special_point !== undefined
                          ? value.special_point.image === idx
                            ? "on"
                            : undefined
                          : undefined
                      }
                      onClick={() => setSelectIdx(idx)}
                    >
                      <div className="border" />
                      <Image src={file} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div
                className={classes.noItem}
                onClick={() => {
                  value.special_point !== undefined
                    ? dispatch({
                        ...value,
                        special_point: {
                          ...value.special_point,
                          image: null,
                        },
                      })
                    : dispatch({
                        ...value,
                        special_point: {
                          image: null,
                          type: "",
                        },
                      });
                  setSelectIdx(-1);
                  setModalOpen(false);
                }}
              >
                <div>
                  <UploadFileSharpIcon />
                  <span>{t("REGISTER.DETAIL.SPECIAL_POINT.SELECT")}</span>
                </div>
              </div>
            )}
          </div>
        </Paper>
      </Modal>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={selectIdx !== -1 ? true : false}
        onClose={() => setSelectIdx(-1)}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography>{t("DIALOG.TITLE.CONFIRM_SELECT_IMAGE")}</Typography>
          <Image src={value.media.image[selectIdx]} />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            variant="contained"
            onClick={() => {
              value.special_point !== undefined
                ? dispatch({
                    ...value,
                    special_point: {
                      ...value.special_point,
                      image: selectIdx,
                    },
                  })
                : dispatch({
                    ...value,
                    special_point: {
                      image: selectIdx,
                      type: "",
                    },
                  });
              setSelectIdx(-1);
              setModalOpen(false);
            }}
          >
            {t("OPTION.YES")}
          </Button>
          <Button variant="contained" onClick={() => setSelectIdx(-1)}>
            {t("OPTION.NO")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SpecialPoint;
