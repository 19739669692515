import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Divider, RadioGroup, Rating, Theme, Typography } from "@mui/material";
import InsertDriveFileSharpIcon from "@mui/icons-material/InsertDriveFileSharp";
import { Radio } from "@/common/components";
import {
  AirconIcon,
  AudioIcon,
  BrakeIcon,
  ChassisIcon,
  EngineIcon,
  ExhuastIcon,
  HeaterIcon,
  MissionIcon,
  SteeringIcon,
  WindowIcon,
} from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  container: {
    width: 1075,
    margin: "45px auto",
    userSelect: "none",
  },
  title: {
    padding: "0px 15px 15px",
    marginBottom: 10,
    borderBottom: "1px solid " + theme.palette.text.primary,
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    "& > svg": {
      width: "3rem",
      height: "3rem",
      fill: theme.palette.primary.main,
      marginRight: 15,
    },
    "& > div": {
      "& > .MuiTypography-body1  > span": {
        fontSize: "1.375rem",
        fontWeight: "bold",
        "& > span": {
          fontSize: "1.15rem",
        },
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.925rem",
        lineHeight: "1.35",
        "& > span": {
          color: theme.palette.primary.main,
          fontSize: "0.85rem",
        },
      },
    },
  },
  notice: {
    textAlign: "right",
    "& > span": {
      fontSize: "0.9rem",
      color: theme.palette.text.primary,
    },
  },
  report: {
    display: "flex",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "20%",
      "& > svg": {
        width: 45,
        height: 45,
        margin: 7.5,
        fill: theme.palette.text.primary,
        "&.heater": {
          fill: colors.red[5],
        },
        "&.aircon": {
          fill: colors.blue[5],
        },
      },
      "& > p": {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 2.5,
      },
    },
  },
  divider: {
    margin: "10px 0",
  },
  radioGroup: {
    "& > label:first-child": {
      marginRight: "0.15rem",
    },
    "& > label:last-child": {
      marginLeft: "0.15rem",
    },
  },
  formControl: {
    cursor: "pointer",
    "& > .MuiTypography-root": {
      fontSize: "0.875rem",
      fontWeight: "bold",
      userSelect: "none",
    },
    "&:hover.error": {
      "& > .MuiRadio-root > span > svg": {
        color: theme.palette.error.main + "!important",
      },
      "& > .MuiTypography-root": {
        color: theme.palette.error.main + "!important",
      },
    },
  },
  radio: {
    padding: 3,
    "& > span > svg": {
      fontSize: "1.25rem",
    },
    "&.Mui-checked.error": {
      "& > span > svg": {
        color: theme.palette.error.main + "!important",
      },
      "& ~ .MuiTypography-root": {
        color: theme.palette.error.main + "!important",
      },
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const ConditionReport = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <InsertDriveFileSharpIcon />
        <div>
          <Typography variant="body1">
            <Markup content={t("REGISTER.DETAIL.CONDITION.TITLE")} />
          </Typography>
          <Typography variant="body2">
            {t("REGISTER.DETAIL.CONDITION.CONTENT")}
          </Typography>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.report}>
          <div>
            <EngineIcon />
            <Typography>{t("VEHICLE.CONDITION.ENGINE")}</Typography>
            <Rating
              value={value.condition.engine}
              precision={0.5}
              onChange={(_e, _value) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    engine: _value !== null ? _value : 0,
                  },
                });
              }}
            />
          </div>
          <div>
            <MissionIcon />
            <Typography>{t("VEHICLE.CONDITION.MISSION")}</Typography>
            <Rating
              value={value.condition.mission}
              precision={0.5}
              onChange={(_e, _value) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    mission: _value !== null ? _value : 0,
                  },
                });
              }}
            />
          </div>
          <div>
            <SteeringIcon />
            <Typography>{t("VEHICLE.CONDITION.STEERING")}</Typography>
            <Rating
              value={value.condition.steering}
              precision={0.5}
              onChange={(_e, _value) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    steering: _value !== null ? _value : 0,
                  },
                });
              }}
            />
          </div>
          <div>
            <BrakeIcon />
            <Typography>{t("VEHICLE.CONDITION.BRAKE")}</Typography>
            <Rating
              value={value.condition.brake}
              precision={0.5}
              onChange={(_e, _value) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    brake: _value !== null ? _value : 0,
                  },
                });
              }}
            />
          </div>
          <div>
            <ExhuastIcon />
            <Typography>{t("VEHICLE.CONDITION.EXHAUST")}</Typography>
            <Rating
              value={value.condition.exhaust}
              precision={0.5}
              onChange={(_e, _value) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    exhaust: _value !== null ? _value : 0,
                  },
                });
              }}
            />
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.report}>
          <div>
            <HeaterIcon className="heater" />
            <Typography>{t("VEHICLE.CONDITION.HEATER")}</Typography>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={value.condition.is_heater}
              onChange={(_e, _value: string) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    is_heater: _value,
                  },
                });
              }}
            >
              <Radio
                label={t("OPTION.OP")}
                value={"Y"}
                formControlClassName={classes.formControl}
                radioClassName={classes.radio}
              />
              <Radio
                label={t("OPTION.NO_OP")}
                value={"N"}
                formControlClassName={`${classes.formControl} error`}
                radioClassName={`${classes.radio} error`}
              />
            </RadioGroup>
          </div>
          <div>
            <AirconIcon className="aircon" />
            <Typography>{t("VEHICLE.CONDITION.AIRCON")}</Typography>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={value.condition.is_aircon}
              onChange={(_e, _value: string) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    is_aircon: _value,
                  },
                });
              }}
            >
              <Radio
                label={t("OPTION.OP")}
                value={"Y"}
                formControlClassName={classes.formControl}
                radioClassName={classes.radio}
              />
              <Radio
                label={t("OPTION.NO_OP")}
                value={"N"}
                formControlClassName={`${classes.formControl} error`}
                radioClassName={`${classes.radio} error`}
              />
            </RadioGroup>
          </div>
          <div>
            <AudioIcon />
            <Typography>{t("VEHICLE.CONDITION.AUDIO")}</Typography>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={value.condition.is_audio}
              onChange={(_e, _value: string) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    is_audio: _value,
                  },
                });
              }}
            >
              <Radio
                label={t("OPTION.OP")}
                value={"Y"}
                formControlClassName={classes.formControl}
                radioClassName={classes.radio}
              />
              <Radio
                label={t("OPTION.NO_OP")}
                value={"N"}
                formControlClassName={`${classes.formControl} error`}
                radioClassName={`${classes.radio} error`}
              />
            </RadioGroup>
          </div>
          <div>
            <WindowIcon />
            <Typography>{t("VEHICLE.CONDITION.WINDOW")}</Typography>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={value.condition.is_window}
              onChange={(_e, _value: string) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    is_window: _value,
                  },
                });
              }}
            >
              <Radio
                label={t("OPTION.OP")}
                value={"Y"}
                formControlClassName={classes.formControl}
                radioClassName={classes.radio}
              />
              <Radio
                label={t("OPTION.NO_OP")}
                value={"N"}
                formControlClassName={`${classes.formControl} error`}
                radioClassName={`${classes.radio} error`}
              />
            </RadioGroup>
          </div>
          <div>
            <ChassisIcon />
            <Typography>{t("VEHICLE.CONDITION.4WD")}</Typography>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={value.condition.is_4WD}
              onChange={(_e, _value: string) => {
                dispatch({
                  ...value,
                  condition: {
                    ...value.condition,
                    is_4WD: _value,
                  },
                });
              }}
            >
              <Radio
                label={t("OPTION.OP")}
                value={"Y"}
                formControlClassName={classes.formControl}
                radioClassName={classes.radio}
              />
              <Radio
                label={t("OPTION.NO_OP")}
                value={"N"}
                formControlClassName={`${classes.formControl} error`}
                radioClassName={`${classes.radio} error`}
              />
              <Radio
                label={t("OPTION.N/A")}
                value={"N/A"}
                formControlClassName={classes.formControl}
                radioClassName={classes.radio}
              />
            </RadioGroup>
          </div>
        </div>
        <Divider className={classes.divider} />
        <Typography className={classes.notice}>
          <Markup content={t("REGISTER.DETAIL.CONDITION.CRITERIA")} />
        </Typography>
      </div>
    </div>
  );
};

export default ConditionReport;
