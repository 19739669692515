// Components
import { Theme } from "@mui/material";
import { SNSIcon } from "@/common/components";
import { CompanyIcon } from "@/common/icons";
import WhatsAppFab from "./WhatsAppFab";
// Styles
import { useStyles } from "@/common/lib/style/hooks";

const styles = (theme: Theme) => ({
  companyIcon: {
    width: 400,
    fill: theme.palette.footer.text,
    marginRight: 50,
  },
  outerTop: {
    minWidth: 1600,
    borderTop: "1px solid " + theme.palette.divider,
  },
  innerTop: {
    width: 1450,
    marginLeft: 75,
    padding: "5px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  outerBottom: {
    minWidth: 1600,
    backgroundColor: theme.palette.footer.main,
    padding: "45px 0",
  },
  innerBottom: {
    display: "flex",
    width: "fit-content",
    alignItems: "flex-end",
    marginLeft: 300,
  },
  table: {
    height: "fit-content",
    "& td": {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.footer.text,
      padding: 3.5,
    },
    "& td, & th": {
      cursor: "default",
      userSelect: "none",
      whiteSpace: "nowrap",
    },
  },
  fnb: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      margin: "0 10px",
      cursor: "pointer",
      fontSize: 15,
      wordSpacing: "-0.75px",
      "& > a": {
        color: theme.palette.text.primary,
        textDecoration: "none",
      },
      "&:hover": {
        "& > a": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  sns: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
    listStyle: "none",
    "& > button": {
      margin: "0 10px",
      width: 45,
      height: 45,
      minHeight: 45,
      zIndex: 1,
      "& > svg": {
        width: 30,
        height: 30,
      },
    },
  },
});

const Footer = () => {
  const classes = useStyles(styles);

  return (
    <>
      <div className={classes.outerTop}>
        <div className={classes.innerTop}>
          <ul className={classes.fnb}>
            <li>
              <a className={classes.anchor} href="/profile">
                PROFILE
              </a>
            </li>
            <li>
              <a className={classes.anchor} href="/contact-us">
                CONTACT US
              </a>
            </li>
          </ul>
          <div className={classes.sns}>
            <WhatsAppFab />
            <SNSIcon sns="facebook" />
            <SNSIcon sns="youtube" />
            <SNSIcon sns="instagram" />
          </div>
        </div>
      </div>
      <div className={classes.outerBottom}>
        <div className={classes.innerBottom}>
          <CompanyIcon className={classes.companyIcon} />
          <table className={classes.table}>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "60%" }} />
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <td colSpan={3}>CEO: Kim Dongwook</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  ADDRESS: 1F - 107, 187, Gyeongin-ro, Michuhol-gu, Incheon,
                  Republic of Korea
                </td>
              </tr>
              <tr>
                <td>TEL: 032-565-5513</td>
                <td>FAX: 032-565-5514</td>
                <td>EMAIL: autoleaderost@gmail.com</td>
              </tr>
              <tr>
                <td colSpan={3}>
                  COPYRIGHT© 2022 AUTO LEADER CO.,LTD. ALL RIGHTS RESERVED.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Footer;
