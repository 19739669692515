/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { Markup } from "interweave";
import { cloneDeep } from "lodash";
// Redux
import {
  loadingOn,
  loadingOff,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch } from "@/common/lib/redux/store";
// Components
import { IconButton, InputAdornment, Theme, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import CollectionsIcon from "@mui/icons-material/Collections";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Image, Input, NationAutocomplete } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { checkUserDuplicate, patchUserInfo } from "@/common/lib/api/user";
import {
  checkGroupDuplicate,
  getGroupInfo,
  postGroupInfo,
  patchGroupInfo,
} from "@/common/lib/api/group";
// Libs
import { isNull, isNotNull, resizeImage } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
    "& > div.content": {
      display: "flex",
      alignItems: "center",
      "&::before": {
        display: "inline-block",
        content: "''",
        width: 15,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
      },
      "& > ul": {
        margin: 0,
        padding: 0,
        userSelect: "none",
        listStyleType: "none",
        "& > li": {
          fontSize: 15,
          marginBottom: 3,
          "&::marker": {
            fontSize: 12,
          },
          "& > span > span": {
            color: theme.palette.error.main,
            fontWeight: "bold",
            padding: "0 3px",
          },
          "&.listStyleNone": {
            fontSize: "0.8rem",
            listStyleType: "none",
            paddingLeft: 5,
          },
        },
      },
    },
  },
  content: {
    display: "flex",
    width: 1473,
  },
  imageContent: {
    width: 235,
    marginLeft: 45,
    marginRight: 45,
    "& > div": {
      position: "relative",
      width: "100%",
      "& > .MuiSvgIcon-root": {
        width: "100%",
        height: "100%",
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& > img": {
        width: "100%",
        aspectRatio: "1",
        borderRadius: "50%",
        objectFit: "cover",
        padding: 20,
      },
      "& > .MuiIconButton-root": {
        position: "absolute",
        bottom: 5,
        right: 5,
        border: "3.5px solid rgba(0, 0, 0, 0.54)",
        "& > svg": {
          fontSize: "1.5rem",
        },
        "&:hover": {
          borderColor: theme.palette.primary.main,
          "& > svg": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  infoContent: {
    width: 500,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
        "&.require-value::after": {
          content: "'*'",
          color: theme.palette.error.main,
          padding: 3,
        },
        "&.unique-value::after": {
          content: "'**'",
          color: theme.palette.error.main,
          padding: 3,
        },
      },
      "& > .MuiFormControl-root": {
        marginBottom: 10,
        "&.good": {
          "& > div": {
            color: theme.palette.primary.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.primary.main,
          },
        },
        "&.bad": {
          "& > div": {
            color: theme.palette.error.main,
          },
          "& .MuiFormHelperText-root, & .MuiSvgIcon-root": {
            color: theme.palette.error.main,
          },
        },
      },
      "& > div": {
        display: "flex",
        justifyContent: "flex-end",
        userSelect: "none",
        "& > span": {
          fontSize: "0.875rem",
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  locationContent: {
    width: 600,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > div.subcontent": {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
          width: "45%",
          "& > p": {
            userSelect: "none",
            fontSize: 15,
            fontWeight: "bold",
          },
          "& > .MuiFormControl-root": {
            marginBottom: 15,
          },
        },
      },
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
      },
      "& > .MuiFormControl-root": {
        marginBottom: 15,
      },
      "& > button": {
        marginTop: 15,
        padding: ".5em 1em",
        fontSize: "1.125rem",
        fontWeight: "bold",
      },
    },
  },
});

const RegisterContainer = () => {
  const params = useParams();
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [guid, setGuid] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState(false);
  const [groupNameHelperText, setGroupNameHelperText] = useState<string | null>(
    ""
  );

  const [managerPuid, setManagerPuid] = useState("");
  const [groupManager, setGroupManager] = useState("");
  const [groupManagerError, setGroupManagerError] = useState(false);
  const [groupManagerHelperText, setGroupManagerHelperText] = useState<
    string | null
  >("");

  const [initGroupData, setInitGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      puid: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });
  const [groupData, setGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      puid: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });

  const [isCheckGroupName, setIsCheckGroupName] = useState(false);
  const [isCheckGroupManager, setIsCheckGroupManager] = useState(false);

  const groupInfoQuery = useQuery(
    ["groupInfo", guid],
    () => {
      if (isNotNull(guid)) {
        dispatch(loadingOn());
        return getGroupInfo(guid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const checkUserMutation = useMutation(checkUserDuplicate, {
    onSuccess: (response) => {
      if (response.data.puid !== null) {
        setGroupManagerHelperText(t("USER.MSG.FOUND_USER"));
      } else {
        setGroupManagerHelperText(t("USER.MSG.NOT_FOUND_USER"));
        setGroupManagerError(true);
      }
      setManagerPuid(response.data.puid);
      setIsCheckGroupManager(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const checkGroupMutation = useMutation(checkGroupDuplicate, {
    onSuccess: (response) => {
      if (response.data.guid === null) {
        setGroupNameHelperText(t("GROUP.MSG.AVAILABLE_GROUP"));
      } else {
        setGroupNameHelperText(t("GROUP.MSG.ALREADY_EXIST_GROUP"));
        setGroupNameError(true);
      }
      setIsCheckGroupName(true);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const patchUserInfoMutation = useMutation(patchUserInfo, {
    onError: (error) => {
      onError(error);
    },
  });
  const postGroupInfoMutation = useMutation(postGroupInfo, {
    onSuccess: async (response) => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_POST_GROUP_INFO"), {
        variant: "success",
      });
      if (managerPuid !== undefined) {
        patchUserInfoMutation.mutateAsync({
          puid: managerPuid,
          data: { is_manager: true, group: response.data.id },
        });
      }
      navigate("/group/info");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const patchGroupInfoMutation = useMutation(patchGroupInfo, {
    onSuccess: (response) => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_GROUP_INFO"), {
        variant: "success",
      });
      if (
        managerPuid !== undefined &&
        managerPuid !== initGroupData["manager"]["puid"]
      ) {
        patchUserInfoMutation.mutateAsync({
          puid: initGroupData["manager"]["puid"],
          data: { is_manager: false, is_staff: true },
        });
        patchUserInfoMutation.mutateAsync({
          puid: managerPuid,
          data: {
            is_manager: true,
            is_staff: false,
            group: response.data.id,
          },
        });
      }
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const InputProps = (key: string) => {
    const onClick = () => {
      if (key === "groupname") {
        if (isNotNull(groupName)) {
          if (groupName !== initGroupData["name"]) {
            if (groupName.length >= 4) {
              dispatch(loadingOn());
              checkGroupMutation.mutate({ data: { name: groupName } });
            } else {
              setGroupNameError(true);
              setGroupNameHelperText(
                t("USER.MSG.INPUT_AT_LEAST_CHAR", { length: 4 })
              );
            }
          } else {
            setGroupNameError(false);
            setIsCheckGroupName(true);
            setGroupNameHelperText("기존과 같은 그룹 명입니다.");
          }
        } else {
          setGroupNameError(true);
          setGroupNameHelperText(t("GROUP.MSG.INPUT_GROUP_NAME"));
        }
      } else if (key === "groupmanager") {
        if (isNotNull(groupManager)) {
          if (groupManager !== initGroupData["manager"]["name"]) {
            dispatch(loadingOn());
            checkUserMutation.mutateAsync({ data: { username: groupManager } });
          } else {
            setIsCheckGroupManager(true);
            setGroupManagerError(false);
            setGroupManagerHelperText("기존과 같은 관리자입니다.");
          }
        } else {
          setGroupManagerError(true);
          setGroupManagerHelperText(t("GROUP.MSG.INPUT_GROUP_MANAGER"));
        }
      }
    };

    return {
      endAdornment: (
        <InputAdornment position="end">
          {key === "groupname" && <CheckIcon onClick={() => onClick()} />}
          {key === "groupmanager" && <SearchIcon onClick={() => onClick()} />}
        </InputAdornment>
      ),
    };
  };
  const handleOnSubmit = () => {
    dispatch(
      setConfirmText({
        title: isNull(managerPuid)
          ? t("DIALOG.TITLE.CONFIRM_CREATE_GROUP_NO_MANAGER")
          : t("DIALOG.TITLE.CONFIRM_CREATE_GROUP"),
        content: isNull(managerPuid)
          ? t("DIALOG.CONTENT.NOTICE_CREATE_GROUP_NO_MANAGER")
          : t("DIALOG.CONTENT.NOTICE_CREATE_GROUP"),
        onApply: async () => {
          if (isNotNull(groupName) && initGroupData["name"] !== groupName) {
            if (!isCheckGroupName) {
              enqueueSnackbar(t("GROUP.MSG.CHECK_EXIST_GROUP_NAME"), {
                variant: "warning",
              });
              return;
            }
          }

          if (groupNameError) {
            enqueueSnackbar(t("GROUP.MSG.CHECK_GROUP_NAME"), {
              variant: "warning",
            });
            return;
          }
          // 필수값 확인
          if (isNull(groupName)) {
            enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
              variant: "error",
            });
            return;
          }
          // 그룹 정보 수정인 경우에는 매니저가 필수로 있도록
          if (params.guid !== undefined) {
            if (isNull(managerPuid)) {
              enqueueSnackbar(t("MSG.CHECK_REQUIRE_VALUE"), {
                variant: "error",
              });
              return;
            }
          }

          const asyncFunction = async () => {
            if (params.guid !== undefined) {
              const sendData: { [key: string]: any } = {};
              Object.keys(groupData).forEach((key: string) => {
                if (
                  ![
                    "id",
                    "manager",
                    "count_product",
                    "limit_product",
                    "count_staff",
                    "limit_staff",
                    "created",
                    "expired",
                  ].includes(key)
                ) {
                  if (initGroupData[key] !== groupData[key]) {
                    sendData[key] = groupData[key];
                  }
                }
              });

              if (Object.keys(sendData).includes("image")) {
                const fileData = await resizeImage(
                  sendData["image"],
                  320,
                  240,
                  100,
                  0,
                  "blob"
                );
                const blobData = fileData as BlobPart;
                sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
                  type: "image/jpg",
                });
              }

              dispatch(loadingOn());
              patchGroupInfoMutation.mutateAsync({
                guid: params.guid,
                data: sendData,
              });
            } else {
              const sendData: { [key: string]: any } = {};
              sendData["name"] = groupName;
              sendData["image"] = undefined;
              sendData["address1"] = groupData["address1"];
              sendData["address2"] = groupData["address2"];
              sendData["address3"] = groupData["address3"];
              sendData["zip_code"] = groupData["zip_code"];
              sendData["country"] = groupData["country"];
              sendData["city"] = groupData["city"];
              sendData["state"] = groupData["state"];

              if (isNotNull(groupData["image"])) {
                const fileData = await resizeImage(
                  groupData["image"],
                  320,
                  240,
                  100,
                  0,
                  "blob"
                );
                const blobData = fileData as BlobPart;
                sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
                  type: "image/jpg",
                });
              }

              dispatch(loadingOn());
              postGroupInfoMutation.mutateAsync({ data: sendData });
            }
          };
          asyncFunction();
        },
        onClose: () => {},
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.guid !== undefined) {
      setGuid(params.guid);
    }
  }, []);

  useEffect(() => {
    if (isNotNull(guid)) {
      groupInfoQuery.refetch();
    }
  }, [guid]);

  useEffect(() => {
    if (groupInfoQuery.isSuccess) {
      if (groupInfoQuery.data !== undefined) {
        const result = groupInfoQuery.data.data;

        const recvData: { [key: string]: any } = {};
        recvData["id"] = result["id"];
        recvData["name"] = result["name"];
        recvData["manager"] = {
          id: result["manager"]["id"],
          puid: result["manager"]["puid"],
          name: result["manager"]["name"],
          username: result["manager"]["username"],
          image: result["manager"]["image"],
          email: result["manager"]["email"],
          phone: result["manager"]["phone"],
        };
        recvData["image"] = result["image"];
        recvData["address1"] = isNotNull(result["address1"])
          ? result["address1"]
          : "";
        recvData["address2"] = isNotNull(result["address2"])
          ? result["address2"]
          : "";
        recvData["address3"] = isNotNull(result["address3"])
          ? result["address3"]
          : "";
        recvData["zip_code"] = isNotNull(result["zip_code"])
          ? result["zip_code"]
          : "";
        recvData["country"] = isNotNull(result["country"])
          ? result["country"]
          : "";
        recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
        recvData["state"] = isNotNull(result["state"]) ? result["state"] : "";
        recvData["count_product"] = result["count_product"];
        recvData["limit_product"] = result["limit_product"];
        recvData["count_staff"] = result["count_staff"];
        recvData["limit_staff"] = result["limit_staff"];
        recvData["created"] = result["created"];
        recvData["expired"] = result["expired"];

        setInitGroupData(cloneDeep(recvData));
        setGroupData(cloneDeep(recvData));

        setGroupName(result["name"]);
        setManagerPuid(result["manager"]["puid"]);
        setGroupManager(result["manager"]["name"]);
      } else {
        setGuid("");
      }
    }
  }, [groupInfoQuery.isLoading]);

  useEffect(() => {
    setIsCheckGroupName(false);
    setGroupNameError(false);
    setGroupNameHelperText("");
  }, [groupName]);

  useEffect(() => {
    setIsCheckGroupManager(false);
    setGroupManagerError(false);
    setGroupManagerHelperText("");
  }, [groupManager]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <AddCircleOutlineIcon />
          {params.guid !== undefined ? (
            <Typography>{t("GROUP.TITLE_MODIFY")}</Typography>
          ) : (
            <Typography>{t("GROUP.TITLE_REGISTER")}</Typography>
          )}
        </div>
        <div className="content">
          <ul>
            <li>
              <Markup content={t("GROUP.MSG.REGISTER_NOTICE_1")} />
            </li>
            <li>
              <Markup content={t("GROUP.MSG.REGISTER_NOTICE_2")} />
            </li>
            <li className="listStyleNone">
              {t("GROUP.MSG.REGISTER_NOTICE_3")}
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.imageContent}>
          <div>
            {isNull(groupData["image"]) ? (
              <AccountCircleIcon
                onDoubleClick={() => {
                  document.getElementById("imageUpload")?.click();
                }}
              />
            ) : (
              <Image
                alt={""}
                src={groupData["image"]}
                onDoubleClick={() => {
                  document.getElementById("imageUpload")?.click();
                }}
              />
            )}
            <IconButton
              onClick={() => {
                document.getElementById("imageUpload")?.click();
              }}
            >
              <CollectionsIcon />
            </IconButton>
            <input
              type="file"
              id="imageUpload"
              name="imageUpload"
              accept="image/jpg, image/gif, image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={async (e: any) => {
                const resizeFile = await resizeImage(
                  e.target.files[0],
                  320,
                  240,
                  75,
                  0,
                  "file"
                );
                setGroupData({
                  ...groupData,
                  image: resizeFile,
                });
              }}
            />
          </div>
        </div>
        <div className={classes.infoContent}>
          <Typography component="h1">Group Info</Typography>
          <div>
            <Typography className="unique-value">Group Name</Typography>
            <Input
              className={
                isCheckGroupName ? (!groupNameError ? "good" : "bad") : ""
              }
              variant="standard"
              value={groupName}
              dispatch={setGroupName}
              error={groupNameError}
              helperText={groupNameHelperText}
              fullWidth
              InputProps={InputProps("groupname")}
              inputProps={{
                maxLength: 32,
              }}
            />
            <Typography>Group Manager</Typography>
            <Input
              className={
                isCheckGroupManager ? (!groupManagerError ? "good" : "bad") : ""
              }
              variant="standard"
              value={groupManager}
              dispatch={setGroupManager}
              error={groupManagerError}
              helperText={groupManagerHelperText}
              fullWidth
              InputProps={InputProps("groupmanager")}
              inputProps={{
                maxLength: 32,
              }}
              disabled={isNotNull(managerPuid)}
            />
            {isNotNull(managerPuid) && (
              <div>
                <span
                  onClick={() => {
                    setManagerPuid("");
                    setGroupManager("");
                  }}
                >
                  {t("BUTTON.MODIFY")}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={classes.locationContent}>
          <Typography component="h1">Group Location</Typography>
          <div>
            <Typography>Address</Typography>
            <Input
              variant="standard"
              value={groupData["address1"]}
              dispatch={(value: string) => {
                setGroupData({
                  ...groupData,
                  address1: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
            <Typography>Address 2</Typography>
            <Input
              variant="standard"
              value={groupData["address2"]}
              dispatch={(value: string) => {
                setGroupData({
                  ...groupData,
                  address2: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
            <Typography>Address 3</Typography>
            <Input
              variant="standard"
              value={groupData["address3"]}
              dispatch={(value: string) => {
                setGroupData({
                  ...groupData,
                  address3: value,
                });
              }}
              fullWidth
              inputProps={{
                maxLength: 80,
              }}
            />
          </div>
          <div>
            <div className="subcontent">
              <div>
                <Typography>City</Typography>
                <Input
                  variant="standard"
                  value={groupData["city"]}
                  dispatch={(value: string) => {
                    setGroupData({
                      ...groupData,
                      city: value,
                    });
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </div>
              <div>
                <Typography>State</Typography>
                <Input
                  variant="standard"
                  value={groupData["state"]}
                  dispatch={(value: string) => {
                    setGroupData({
                      ...groupData,
                      state: value,
                    });
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 64,
                  }}
                />
              </div>
            </div>
            <div className="subcontent">
              <div>
                <Typography>Country</Typography>
                <NationAutocomplete
                  variant="standard"
                  value={groupData["country"]}
                  dispatch={(value: string) => {
                    setGroupData({
                      ...groupData,
                      country: value,
                    });
                  }}
                  fullWidth
                />
              </div>
              <div>
                <Typography>Zip Code</Typography>
                <Input
                  variant="standard"
                  value={groupData["zip_code"]}
                  dispatch={(value: string) => {
                    setGroupData({
                      ...groupData,
                      zip_code: value,
                    });
                  }}
                  fullWidth
                  inputProps={{
                    maxLength: 16,
                  }}
                />
              </div>
            </div>
            <Button
              variant="contained"
              onClick={() => handleOnSubmit()}
              fullWidth
            >
              {t("BUTTON.REGISTER")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterContainer;
