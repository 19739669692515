/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  HTMLProps,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
// Components
import ListDataTable from "./ListDataTable";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { Button, Image, Input } from "@/common/components";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// Libs
import {
  openNewTab,
  getDateFormat,
  getGroupTableData,
} from "@/common/lib/common";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { patchGroupInfo } from "@/common/lib/api/group";

interface propType {
  data: GroupData[];
  setData: Dispatch<SetStateAction<GroupData[]>>;
  page: number;
  pageSize: number;
  totalCount: number;
  params: { [key: string]: any };
  setParams: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

interface DataType {
  photo: File | string;
  name: string;
  manager: UserData | null;
  guid: string;
  count_staff: number;
  count_product: number;
  limit_staff: number;
  limit_product: number;
  address1: string | undefined;
  address2: string | undefined;
  address3: string | undefined;
  state: string | undefined;
  city: string | undefined;
  country: string | undefined;
  zip_code: string | undefined;
  created: Date | null | undefined;
  expired: Date | null | undefined;
  status: string;
}

const styles = (theme: Theme) => ({
  limitStaffDialog: {
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      paddingTop: 20,
      "& > table": {
        width: "75%",
        margin: "5px auto",
        tableLayout: "fixed",
        "& th": {
          userSelect: "none",
        },
        "& td": {
          textAlign: "center",
          userSelect: "none",
          "& > .MuiTextField-root": {
            width: 150,
            padding: "0 5px",
            fontSize: "1rem",
            "& .MuiInputAdornment-root": {
              padding: 0,
              "& > p": {
                lineHeight: "unset",
                fontWeight: 500,
                color: theme.palette.text.primary,
              },
            },
            "& .MuiInputBase-input": {
              fontWeight: 500,
              padding: "0 7.5px",
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "1.85rem",
            },
          },
          "& > span": {
            margin: 0,
            fontWeight: 500,
          },
        },
      },
      "& > .MuiTypography-root": {
        marginTop: 5,
        marginBottom: 10,
        fontSize: "0.875rem",
        color: theme.palette.error.main,
        textAlign: "center",
        userSelect: "none",
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
  limitProductDialog: {
    "& .MuiPaper-root": {
      borderRadius: 10,
    },
    "& .MuiDialogTitle-root": {
      color: "white",
      background: theme.palette.primary.main,
    },
    "& .MuiDialogContent-root": {
      paddingTop: 20,
      "& > table": {
        width: "75%",
        margin: "5px auto",
        tableLayout: "fixed",
        "& th": {
          userSelect: "none",
        },
        "& td": {
          textAlign: "center",
          userSelect: "none",
          "& > .MuiTextField-root": {
            width: 150,
            padding: "0 5px",
            fontSize: "1rem",
            "& .MuiInputAdornment-root": {
              padding: 0,
              "& > p": {
                lineHeight: "unset",
                fontWeight: 500,
                color: theme.palette.text.primary,
              },
            },
            "& .MuiInputBase-input": {
              fontWeight: 500,
              padding: "0 7.5px",
            },
          },
          "& > div.arrow": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& > svg": {
              fontSize: "1.85rem",
            },
          },
          "& > span": {
            margin: 0,
            fontWeight: 500,
          },
        },
      },
      "& > .MuiTypography-root": {
        marginTop: 5,
        marginBottom: 10,
        fontSize: "0.875rem",
        color: theme.palette.error.main,
        textAlign: "center",
        userSelect: "none",
      },
      "& > div.actions": {
        display: "flex",
        justifyContent: "flex-end",
        "& > button:first-child": {
          marginRight: 10,
        },
        "& > button:last-child": {
          marginLeft: 10,
        },
      },
    },
  },
});

const StaffCell = (
  info: any,
  data: GroupData[],
  setData: Dispatch<SetStateAction<GroupData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(info.row.original["limit_staff"]);
  const [editable, setEditable] = useState<boolean>(false);

  const patchGroupMutation = useMutation(patchGroupInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_GROUP_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: GroupData) => {
        if (info.row.original["id"] === item.id) {
          item.limit_staff = Number(value);
        }
      });
      setData(data_Copy);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const handleOnApply = () => {
    if (editable) {
      if (value === info.row.original["limit_staff"]) {
        enqueueSnackbar(t("MANAGE.MSG.EQUAL_STAFF"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      patchGroupMutation.mutate({
        guid: info.row.original["guid"],
        data: {
          limit_staff: value,
        },
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setEditable(false);
      setValue(info.row.original["limit_staff"]);
    }
  };

  return (
    <>
      <div onDoubleClick={() => setEditable(true)}>
        <Markup
          content={t("MANAGE.TABLE.STAFF", {
            staff: Number(info.row.original["limit_staff"]).toLocaleString(),
          })}
        />
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.limitStaffDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_LIMIT_STAFF")}</DialogTitle>
        <DialogContent>
          <table>
            <thead>
              <tr>
                <th style={{ width: 175 }}>
                  {t("MANAGE.TABLE.CURRENT_LIMIT_STAFF")}
                </th>
                <th></th>
                <th style={{ width: 175 }}>
                  {t("MANAGE.TABLE.MODIFY_LIMIT_STAFF")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 175 }}>
                  <Markup
                    content={t("MANAGE.TABLE.STAFF", {
                      staff: Number(
                        info.row.original["limit_staff"]
                      ).toLocaleString(),
                    })}
                  />
                </td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td style={{ width: 175 }}>
                  <Input
                    type="integer"
                    variant="standard"
                    value={Number(value)}
                    dispatch={setValue}
                    inputProps={{
                      maxLength: 6,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("MANAGE.TABLE.PEOPLE")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Typography>{t("MANAGE.MSG.NOTICE_MODIFY")}</Typography>
          <div className="actions">
            <Button variant="contained" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.MODIFY")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ProductCell = (
  info: any,
  data: GroupData[],
  setData: Dispatch<SetStateAction<GroupData[]>>
) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState<string>(
    info.row.original["limit_product"]
  );
  const [editable, setEditable] = useState<boolean>(false);

  const patchGroupMutation = useMutation(patchGroupInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_GROUP_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      const data_Copy = JSON.parse(JSON.stringify(data));
      data_Copy.forEach((item: GroupData) => {
        if (info.row.original["id"] === item.id) {
          item.limit_product = Number(value);
        }
      });
      setData(data_Copy);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const handleOnApply = () => {
    if (editable) {
      if (value === info.row.original["limit_product"]) {
        enqueueSnackbar(t("MANAGE.MSG.EQUAL_PRODUCT"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        return;
      }

      patchGroupMutation.mutate({
        guid: info.row.original["guid"],
        data: {
          limit_product: value,
        },
      });
    }
  };
  const handleOnClose = () => {
    if (editable) {
      setEditable(false);
      setValue(info.row.original["limit_product"]);
    }
  };

  return (
    <>
      <div onDoubleClick={() => setEditable(true)}>
        <Markup
          content={t("MANAGE.TABLE.PRODUCT", {
            product: Number(
              info.row.original["limit_product"]
            ).toLocaleString(),
          })}
        />
      </div>
      <Dialog
        open={editable}
        onClose={() => handleOnClose()}
        classes={{
          root: classes.limitProductDialog,
        }}
      >
        <DialogTitle>{t("MANAGE.TABLE.TITLE_LIMIT_PRODUCT")}</DialogTitle>
        <DialogContent>
          <table>
            <thead>
              <tr>
                <th style={{ width: 175 }}>
                  {t("MANAGE.TABLE.CURRENT_LIMIT_PRODUCT")}
                </th>
                <th></th>
                <th style={{ width: 175 }}>
                  {t("MANAGE.TABLE.MODIFY_LIMIT_PRODUCT")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: 175 }}>
                  <Markup
                    content={t("MANAGE.TABLE.PRODUCT", {
                      product: Number(
                        info.row.original["limit_product"]
                      ).toLocaleString(),
                    })}
                  />
                </td>
                <td>
                  <div className="arrow">
                    <KeyboardDoubleArrowRightIcon />
                  </div>
                </td>
                <td style={{ width: 175 }}>
                  <Input
                    type="integer"
                    variant="standard"
                    value={Number(value)}
                    dispatch={setValue}
                    inputProps={{
                      maxLength: 9,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("MANAGE.TABLE.UNIT")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Typography>{t("MANAGE.MSG.NOTICE_MODIFY")}</Typography>
          <div className="actions">
            <Button variant="contained" onClick={() => handleOnApply()}>
              {t("MANAGE.TABLE.MODIFY")}
            </Button>
            <Button variant="contained" onClick={() => handleOnClose()}>
              {t("MANAGE.TABLE.CANCEL")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const IndeterminateCheckbox = ({
  indeterminate,
  className,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      name="tbl_cbx"
      type="checkbox"
      ref={ref}
      className={className !== undefined ? className : undefined}
      {...rest}
    />
  );
};

const ListDataContainer = ({
  data,
  setData,
  page,
  pageSize,
  totalCount,
  params,
  setParams,
}: propType) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<DataType[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const columns: ColumnDef<DataType>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "photo",
      header: () => <span>{t("MANAGE.COLUMN.PHOTO")}</span>,
      cell: (info: any) => (
        <Image
          src={info.getValue()}
          style={{ display: "block", width: "100%" }}
        />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: () => <span>{t("MANAGE.COLUMN.GROUP_NAME")}</span>,
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "manager",
      header: () => <span>{t("MANAGE.COLUMN.MANAGER")}</span>,
      cell: (info: any) => {
        return <span>{info.row.original["manager"]["name"]}</span>;
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "guid",
      header: () => <span>{t("MANAGE.COLUMN.GUID")}</span>,
      cell: (info: any) => {
        const handleCopyOnClick = () => {
          navigator.clipboard.writeText(info.getValue()).then(
            () => {
              enqueueSnackbar(t("MSG.COPY_SUCCESS"), { variant: "success" });
            },
            () => {
              enqueueSnackbar(t("MSG.COPY_FAILURE"), { variant: "error" });
            }
          );
        };
        return (
          <span onClick={() => handleCopyOnClick()}>{info.getValue()}</span>
        );
      },
      enableMultiSort: true,
      enableHiding: false,
    },
    {
      accessorKey: "count_staff",
      header: () => <span>{t("MANAGE.COLUMN.COUNT_STAFF")}</span>,
      cell: (info: any) => {
        return (
          <Markup
            content={t("MANAGE.TABLE.STAFF", {
              staff: info.getValue().toLocaleString(),
            })}
          />
        );
      },
      enableMultiSort: true,
    },
    {
      accessorKey: "count_product",
      header: () => <span>{t("MANAGE.COLUMN.COUNT_PRODUCT")}</span>,
      cell: (info: any) => {
        return (
          <Markup
            content={t("MANAGE.TABLE.PRODUCT", {
              product: info.getValue().toLocaleString(),
            })}
          />
        );
      },
      enableMultiSort: true,
    },
    {
      accessorKey: "limit_staff",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.LIMIT_STAFF")}</span>
        </Tooltip>
      ),
      cell: (info: any) => StaffCell(info, data, setData),
      enableMultiSort: true,
    },
    {
      accessorKey: "limit_product",
      header: () => (
        <Tooltip title={t("MANAGE.MSG.DOUBLE_CLICK_EDIT")} arrow>
          <span>{t("MANAGE.COLUMN.LIMIT_PRODUCT")}</span>
        </Tooltip>
      ),
      cell: (info: any) => ProductCell(info, data, setData),
      enableMultiSort: true,
    },
    {
      accessorKey: "address1",
      header: () => <span>{t("MANAGE.COLUMN.ADDRESS1")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "address2",
      header: () => <span>{t("MANAGE.COLUMN.ADDRESS2")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "address3",
      header: () => <span>{t("MANAGE.COLUMN.ADDRESS3")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "state",
      header: () => <span>{t("MANAGE.COLUMN.STATE")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "city",
      header: () => <span>{t("MANAGE.COLUMN.CITY")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "country",
      header: () => <span>{t("MANAGE.COLUMN.COUNTRY")}</span>,
      enableMultiSort: true,
    },
    {
      accessorKey: "zip_code",
      header: () => <span>{t("MANAGE.COLUMN.ZIP_CODE")}</span>,
      enableSorting: false,
    },
    {
      accessorKey: "created",
      header: () => <span>{t("MANAGE.COLUMN.CREATED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "expired",
      header: () => <span>{t("MANAGE.COLUMN.EXPIRED")}</span>,
      cell: (info: any) => (
        <span>{getDateFormat(info.getValue(), "yyyy-MM-dd hh:mm:ss")}</span>
      ),
      sortingFn: "datetime",
      enableMultiSort: true,
    },
    {
      accessorKey: "status",
      header: () => <span>{t("MANAGE.COLUMN.STATUS")}</span>,
      enableMultiSort: true,
    },
    {
      id: "modify",
      header: () => <span>{t("MANAGE.COLUMN.MODIFY")}</span>,
      cell: (info: any) => {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() =>
                openNewTab(
                  "MANAGEMENT",
                  "group/update/" + info.row.original["guid"]
                )
              }
            >
              Edit
            </Button>
          </>
        );
      },
      enableSorting: false,
    },
  ];

  useEffect(() => {
    setTableData(getGroupTableData(data));
  }, [data]);

  return (
    <ListDataTable
      data={tableData}
      columns={columns}
      page={page}
      pageSize={pageSize}
      totalCount={totalCount}
      params={params}
      setParams={setParams}
    />
  );
};

export default ListDataContainer;
