import axios from "axios";
// Redux
import { store } from "@/common/lib/redux/store";
// Libs
import { getTokenData, isNotNull } from "@/common/lib/common";

export const checkVehicleDuplicate = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/vehicles/duplication/`;
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: data["data"],
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getVehicleCount = async (params: { [key: string]: any } = {}) => {
  params = {
    ...params,
    search_type: "COUNT",
  };

  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const tokenData = getTokenData();
  if (tokenData.is_manager || tokenData.is_staff) {
    params["owner"] = tokenData.group;
  }

  const url = "/api/vehicles/";
  const result = await axios({
    method: "get",
    url: url,
    params: params,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getVehicleList = async (
  page: number = 1,
  size: number = 10,
  align: string = "-modified",
  params: { [key: string]: any } = {}
) => {
  params = {
    ...params,
    search_type: "MANAGEMENT",
    page: String(page),
    size: String(size),
    align: align,
  };

  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const tokenData = getTokenData();
  if (tokenData.is_manager || tokenData.is_staff) {
    params["owner"] = tokenData.group;
  }

  const url = "/api/vehicles/";
  const result = await axios({
    method: "get",
    url: url,
    params: params,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const getVehicleInfo = async (uid: string) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "application/json",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/vehicles/${uid}/`;
  const result = await axios({
    method: "get",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const postVehicleInfo = async (data: { data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));
  for (let idx = 0; idx < data["data"]["image"].length; idx++) {
    sendData.append("image", data["data"]["image"][idx]);
  }

  const url = "/api/vehicles/";
  const result = await axios({
    method: "post",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const patchVehicleInfo = async (data: { uid: string; data: any }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const sendData = new FormData();
  sendData.append("data", JSON.stringify(data["data"]));
  if (Object.keys(data["data"]).includes("image")) {
    for (let idx = 0; idx < data["data"]["image"].length; idx++) {
      sendData.append("image", data["data"]["image"][idx]);
    }
  }

  const url = `/api/vehicles/${data["uid"]}/`;
  const result = await axios({
    method: "patch",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    data: sendData,
    headers: headers,
    withCredentials: true,
  });
  return result;
};

export const deleteVehicleInfo = async (data: { uid: string }) => {
  const state = store.getState();
  const headers: { [key: string]: string } = {
    "Content-Type": "multipart/form-data",
  };
  if (isNotNull(state.auth.loginToken)) {
    headers["Authorization"] = `Bearer ${state.auth.loginToken}`;
  }

  const url = `/api/vehicles/${data["uid"]}/`;
  const result = await axios({
    method: "delete",
    url: url,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: headers,
    withCredentials: true,
  });
  return result;
};
