import { useMemo } from "react";
import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";

export const useStyles = (stylesElement: { [key: string]: any } | Function) => {
  const theme = useTheme();

  return useMemo(() => {
    const rawClasses: { [key: string]: any } =
      typeof stylesElement === "function"
        ? stylesElement(theme)
        : stylesElement;
    const procClasses: { [key: string]: any } = {};

    Object.entries(rawClasses).forEach(([key, value = {}]) => {
      procClasses[key] = css(value);
    });

    return procClasses;
  }, [stylesElement, theme]);
};
