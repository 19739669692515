/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Theme,
  Typography,
} from "@mui/material";
import { Image } from "@/common/components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HomeIcon from "@mui/icons-material/Home";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewListIcon from "@mui/icons-material/ViewList";
import WebIcon from "@mui/icons-material/Web";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import { CompanyLogoIcon } from "@/common/icons";
// API
import { onError } from "@/common/lib/api/common";
import { logoutAPI } from "@/common/lib/api/auth";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { checkLogin, getTokenData, isNull } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  menuPaper: {
    width: 280,
    height: "100%",
    minHeight: "100vh",
    background: theme.palette.primary.hard,
    padding: "40px 25px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  companyLogoIcon: {
    width: 200,
    fill: "white",
    cursor: "pointer",
    margin: "0 auto",
  },
  companyLogoTitle: {
    color: "white",
    fontSize: "1.15rem",
    fontWeight: "500",
    textAlign: "center",
    userSelect: "none",
    marginTop: 5,
  },
  userInfo: {
    width: "90%",
    border: "3.5px solid white",
    borderRadius: 20,
    marginTop: 25,
    marginBottom: 25,
    padding: 15,
    alignSelf: "center",
    textAlign: "center",
    "& > p": {
      color: "white",
      fontWeight: 500,
      userSelect: "none",
      "& > span > span": {
        fontSize: "1.1rem",
        lineHeight: "1.65",
      },
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderTop: "2px solid white",
      marginTop: 15,
      paddingTop: 15,
      userSelect: "none",
      "& > p": {
        color: "white",
        fontWeight: 500,
        cursor: "pointer",
      },
      "& > p:not(:first-child):before": {
        display: "inline-block",
        content: "''",
        width: 2,
        height: 11.5,
        backgroundColor: theme.palette.primary.contrastText,
        margin: "0 10px",
      },
      "& > p:hover": {
        textDecoration: "underline",
      },
    },
  },
  userIcon: {
    fontSize: 165,
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "50%",
    border: "2.5px solid white",
  },
  userImage: {
    width: 150,
    aspectRatio: "1",
    borderRadius: "50%",
    border: "2.5px solid white",
    objectFit: "cover",
    marginTop: 5,
    marginBottom: 10,
  },
  listButton: {
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 24px",
    "& > div": {
      display: "flex",
      "& > svg": {
        fontSize: "1.5rem",
        color: "white",
        marginRight: 15,
      },
      "& > p": {
        fontSize: "1.05rem",
        fontWeight: "500",
        color: "white",
      },
    },
    "& > svg": {
      color: "white",
      fontSize: "1.85rem",
    },
  },
  collapse: {
    "& .MuiListItemButton-root": {
      display: "flex",
      padding: "10px 36px",
      "& > svg:first-child": {
        fontSize: "1.35rem",
        color: "white",
        marginRight: 10,
      },
      "& > p": {
        fontSize: "0.95rem",
        fontWeight: "bold",
        color: "white",
      },
    },
    "& .MuiDivider-root": {
      border: "1px solid white",
      margin: "4px 28px",
    },
  },
});

const Menu = ({ ...props }: any) => {
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const { loginToken } = useTypedSelector((state) => state.auth);

  const [productOpen, setProductOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [groupOpen, setGroupOpen] = useState(false);
  const [pageOpen, setPageOpen] = useState(false);
  const [statOpen, setStatOpen] = useState(false);

  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const logoutMutation = useMutation(logoutAPI, {
    onSuccess: () => {
      dispatch(setLoginToken(""));
      navigate("/");
    },
    onError: (error) => {
      onError(error);
    },
  });

  const handleImageOnClick = () => {
    navigate("/index");
  };
  const handleLoginOnClick = () => {
    navigate("/login");
  };
  const handleLogoutOnClick = () => {
    logoutMutation.mutate();
  };

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  return (
    <Drawer
      variant="permanent"
      elevation={0}
      className={props.className}
      classes={{ paper: classes.menuPaper }}
    >
      <div style={{ display: "flex" }}>
        <CompanyLogoIcon
          className={classes.companyLogoIcon}
          onClick={handleImageOnClick}
        />
      </div>
      <Typography className={classes.companyLogoTitle}>
        No.1 Korean Used Car Exporter
      </Typography>
      <div className={classes.userInfo}>
        {isNull(tokenData.image) ? (
          <AccountCircleIcon className={classes.userIcon} />
        ) : (
          <Image alt="" src={tokenData.image} className={classes.userImage} />
        )}
        <Typography classes={{ root: classes.userTypography }}>
          {checkLogin() ? (
            <Markup content={t("MENU.WELCOME", { user: tokenData["name"] })} />
          ) : (
            <Markup content={t("MENU.WELCOME", { user: "guest" })} />
          )}
        </Typography>
        <div>
          {checkLogin() ? (
            <>
              <Typography onClick={() => navigate("/myinfo")}>
                {t("MENU.MYINFO")}
              </Typography>
              <Typography onClick={handleLogoutOnClick}>
                {t("MENU.LOGOUT")}
              </Typography>
            </>
          ) : (
            <>
              <Typography onClick={handleLoginOnClick}>
                {t("MENU.LOGIN")}
              </Typography>
              <Typography>{t("MENU.JOIN")}</Typography>
            </>
          )}
        </div>
      </div>
      <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            classes={{
              root: classes.listButton,
            }}
            onClick={() => navigate("/index")}
            dense
          >
            <div>
              <HomeIcon />
              <Typography>메인 페이지</Typography>
            </div>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            classes={{
              root: classes.listButton,
            }}
            onClick={() => setProductOpen(!productOpen)}
            dense
          >
            <div>
              <DirectionsCarIcon />
              <Typography>매물 관리</Typography>
            </div>
            {productOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemButton>
        </ListItem>
        <Collapse
          className={classes.collapse}
          in={productOpen}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/vehicle/register")}>
                <AddCircleOutlineIcon />
                <Typography>매물 등록</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/vehicle/manage")}>
                <ViewListIcon />
                <Typography>매물 관리</Typography>
              </ListItemButton>
            </ListItem>
            {tokenData["is_superuser"] && (
              <>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/vehicle/booking")}>
                    <ViewListIcon />
                    <Typography>예약 신청 내역</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/vehicle/offer")}>
                    <ViewListIcon />
                    <Typography>주문 및 오퍼 내역</Typography>
                  </ListItemButton>
                </ListItem>
              </>
            )}
          </List>
        </Collapse>
        {tokenData["is_superuser"] && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                classes={{
                  root: classes.listButton,
                }}
                onClick={() => setUserOpen(!userOpen)}
                dense
              >
                <div>
                  <PersonIcon />
                  <Typography>유저 관리</Typography>
                </div>
                {userOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse
              className={classes.collapse}
              in={userOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/user/register")}>
                    <AddCircleOutlineIcon />
                    <Typography>유저 등록</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/user/list")}>
                    <ViewListIcon />
                    <Typography>유저 목록 관리</Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        {(tokenData["is_superuser"] || tokenData["is_manager"]) && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                classes={{
                  root: classes.listButton,
                }}
                onClick={() => setGroupOpen(!groupOpen)}
                dense
              >
                <div>
                  <GroupsIcon />
                  <Typography>그룹 관리</Typography>
                </div>
                {groupOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse
              className={classes.collapse}
              in={groupOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {tokenData["is_superuser"] && (
                  <>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => navigate("/group/register")}
                      >
                        <AddCircleOutlineIcon />
                        <Typography>그룹 등록</Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => navigate("/manager/register")}
                      >
                        <AddCircleOutlineIcon />
                        <Typography>그룹 관리자 등록</Typography>
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/staff/register")}>
                    <AddCircleOutlineIcon />
                    <Typography>그룹 직원 등록</Typography>
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/group/info")}>
                    <ViewListIcon />
                    <Typography>그룹 정보 관리</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate("/staff/list")}>
                    <ViewListIcon />
                    <Typography>그룹 직원 관리</Typography>
                  </ListItemButton>
                </ListItem>
                {tokenData["is_superuser"] && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/group/list")}>
                      <ViewListIcon />
                      <Typography>그룹 목록 관리</Typography>
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {tokenData["is_superuser"] && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                classes={{
                  root: classes.listButton,
                }}
                onClick={() => setPageOpen(!pageOpen)}
                dense
              >
                <div>
                  <WebIcon />
                  <Typography>홈페이지 관리</Typography>
                </div>
                {pageOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse
              className={classes.collapse}
              in={pageOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ErrorOutlineIcon />
                    <Typography>공지사항 관리</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <HelpOutlineIcon />
                    <Typography>FAQ 관리</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <DirectionsBoatIcon />
                    <Typography>선적일정 관리</Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
        {(tokenData["is_superuser"] || tokenData["is_manager"]) && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                classes={{
                  root: classes.listButton,
                }}
                onClick={() => setStatOpen(!statOpen)}
                dense
              >
                <div>
                  <LeaderboardIcon />
                  <Typography>매출 통계</Typography>
                </div>
                {statOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
            </ListItem>
            <Collapse
              className={classes.collapse}
              in={statOpen}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem disablePadding>
                  <ListItemButton>
                    <CalendarMonthIcon />
                    <Typography>날짜 기준</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <DirectionsCarIcon />
                    <Typography>매물 기준</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <PersonIcon />
                    <Typography>유저 기준</Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <GroupsIcon />
                    <Typography>그룹 기준</Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Drawer>
  );
};

export default Menu;
