/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Markup } from "interweave";
// Components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  ClickAwayListener,
  Divider,
  Grow,
  InputAdornment,
  Paper,
  Popper,
  Theme,
  Typography,
  RadioGroup,
} from "@mui/material";
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Radio,
} from "@/common/components";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import {
  initList,
  yearList,
  productList,
  carCategoryList,
  truckCategoryList,
  truckSubCategoryList,
  busCategoryList,
  getTypeList,
  getYearList,
  getMakerList,
  getModelList,
  getDetailModelList,
  missionList,
  fuelList,
  colorList,
  optionList,
  statusList,
} from "@/common/lib/varList";
import {
  getStartDate,
  getEndDate,
  getUTCDate,
  isNotNull,
  isNull,
} from "@/common/lib/common";
import { usePrevious } from "@/common/lib/hooks";

interface propType {
  params: { [key: string]: any };
  setParams: Dispatch<SetStateAction<{ [key: string]: any }>>;
}

const styles = (theme: Theme) => ({
  accordion: {
    margin: "0 !important",
    "&::before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root": {
      display: "none",
    },
    "& .MuiAccordionDetails-root": {
      padding: "unset",
    },
  },
  card: {
    width: "100%",
    height: "fit-content",
    overflow: "visible",
    borderRadius: 0,
    marginBottom: 30,
    "& > .MuiCardContent-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "6px 24px",
      cursor: "pointer",
      "& > p": {
        width: "100%",
        fontSize: "1.5rem",
        fontWeight: "bold",
        textAlign: "center",
        color: theme.palette.primary.main,
      },
      "& > svg": {
        fontSize: "3rem",
        color: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor: colors.gray[1],
      },
    },
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderStyle: "hidden",
    userSelect: "none",
    "& tr": {
      height: 62.5,
    },
    "& th": {
      background: colors.gray[1],
      fontSize: "0.925rem",
      fontWeight: 500,
      textAlign: "center",
      border: "1px solid " + theme.palette.divider,
    },
    "& td": {
      fontSize: "0.925rem",
      textAlign: "center",
      border: "1px solid " + theme.palette.divider,
      padding: "0 25px",
      "& > .init": {
        "& > p": {
          margin: 0,
          lineHeight: "1",
        },
        "& > span": {
          fontSize: 12,
        },
      },
    },
  },
  priceContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "7.5px 0",
    "& > .MuiFormControl-root": {
      width: 125,
      margin: "0 15px",
    },
  },
  yearContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "7.5px 0",
    "& > .MuiFormControl-root": {
      width: 125,
      margin: "0 15px",
    },
  },
  dateContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "7.5px 0",
    "& > .react-datepicker-wrapper": {
      width: 130,
      margin: "0 15px",
    },
  },
  button: {
    width: 135,
    height: 42.5,
    margin: "0 25px",
    borderRadius: 10,
    fontSize: 17,
  },
  optionInput: {
    "& .MuiInput-input": {
      textAlign: "center",
      cursor: "pointer",
    },
  },
  optionContainer: {
    width: 725,
    height: "fit-content",
    padding: 20,
    borderRadius: 5,
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "white",
    "& > div.title": {
      padding: "7.5px 0",
      userSelect: "none",
      "& > .MuiTypography-root": {
        fontSize: 15,
        fontWeight: 500,
        color: theme.palette.primary.main,
        textAlign: "center",
        cursor: "default",
      },
    },
  },
  optionList: {
    margin: 0,
    padding: "7.5px 0",
    listStyle: "none",
    userSelect: "none",
    "& > li": {
      display: "inline-block",
      width: "25%",
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        padding: 5,
      },
    },
  },
  dropdownFormControl: {
    width: "100%",
    fontSize: "0.875rem !important",
  },
  dropdownMenuItem: {
    fontSize: "0.875rem !important",
  },
  radioGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
    "& > .MuiFormControlLabel-root:first-child": {
      marginRight: 5,
    },
    "& .MuiRadio-root": {
      padding: 3,
      "& > span > svg": {
        fontSize: 16,
      },
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
  checkboxGroup: {
    "&::before": {
      content: "'('",
      fontSize: 14,
    },
    "&::after": {
      content: "')'",
      paddingLeft: 3,
      fontSize: 14,
    },
    "& > .MuiFormControlLabel-root:first-child": {
      marginRight: 10,
    },
  },
  formControl: {
    cursor: "pointer",
    userSelect: "none",
    "& > .MuiTypography-root": {
      fontSize: 13,
    },
    "&.Mui-disabled": {
      "& + div": {
        "&::before": {
          color: theme.palette.divider,
        },
        "&::after": {
          color: theme.palette.divider,
        },
      },
    },
  },
  checkbox: {
    padding: 3,
    "& > .MuiSvgIcon-root": {
      fontSize: 17,
    },
  },
  mainFormControl: {
    cursor: "pointer",
    userSelect: "none",
    "& > .MuiTypography-root": {
      fontSize: 15,
    },
    "&.Mui-disabled": {
      "& + div": {
        "&::before": {
          color: theme.palette.divider,
        },
        "&::after": {
          color: theme.palette.divider,
        },
      },
    },
  },
  mainCheckbox: {
    padding: 3,
    "& > .MuiSvgIcon-root": {
      fontSize: 17,
    },
  },
  inputAdornment: {
    "& > .MuiTypography-root": {
      fontSize: "0.875rem",
    },
  },
});

const ManageSearchContainer = ({ params, setParams }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<boolean>(true);
  const [init, setInit] = useState<boolean>(false);
  const [optionOpen, setOptionOpen] = useState<boolean>(false);

  const anchorRef = useRef<HTMLInputElement>(null);

  const [type, setType] = useState<string>(
    Object.keys(params).includes("type") ? params["type"] : ""
  );
  const [status, setStatus] = useState<string[]>(
    Object.keys(params).includes("status") ? params["status"] : []
  );
  const [carCategory, setCarCategory] = useState<string>(
    Object.keys(params).includes("car_category") ? params["car_category"] : ""
  );
  const [truckCategory, setTruckCategory] = useState<string>(
    Object.keys(params).includes("truck_category")
      ? params["truck_category"]
      : ""
  );
  const [truckSubCategory, setTruckSubCategory] = useState<string[]>(
    Object.keys(params).includes("truck_sub_category")
      ? params["truck_sub_category"]
      : []
  );
  const [busCategory, setBusCategory] = useState<string>(
    Object.keys(params).includes("bus_category") ? params["bus_category"] : ""
  );

  const [maker, setMaker] = useState(
    Object.keys(params).includes("maker") ? params["maker"] : ""
  );
  const [model, setModel] = useState(
    Object.keys(params).includes("model") ? params["model"] : ""
  );
  const [detailModel, setDetailModel] = useState(
    Object.keys(params).includes("detail_model") ? params["detail_model"] : ""
  );
  const [makerList, setMakerList] = useState(getMakerList(type));
  const [modelList, setModelList] = useState(getModelList(type, maker));
  const [detailModelList, setDetailModelList] = useState(
    getDetailModelList(maker, model)
  );

  const [mission, setMission] = useState<string[]>(
    Object.keys(params).includes("mission") ? params["mission"] : []
  );
  const [fuel, setFuel] = useState<string[]>(
    Object.keys(params).includes("fuel") ? params["fuel"] : []
  );
  const [color, setColor] = useState<string[]>(
    Object.keys(params).includes("color") ? params["color"] : []
  );
  const [steering, setSteering] = useState<string[]>(
    Object.keys(params).includes("steering") ? params["steering"] : []
  );
  const [drive, setDrive] = useState<string[]>(
    Object.keys(params).includes("drive") ? params["drive"] : []
  );

  const [accidentHistory, setAccidentHistory] = useState<string[]>(
    Object.keys(params).includes("accident_history")
      ? params["accident_history"]
      : []
  );
  const [simpleRepair, setSimpleRepair] = useState<string[]>(
    Object.keys(params).includes("simple_repair") ? params["simple_repair"] : []
  );
  const [inspection, setInspection] = useState<string[]>(
    Object.keys(params).includes("inspection") ? params["inspection"] : []
  );

  const [priceType, setPriceType] = useState<string>(
    Object.keys(params).includes("price_type") ? params["price_type"] : ""
  );
  const [minPrice, setMinPrice] = useState<string>(
    Object.keys(params).includes("min_price") ? params["min_price"] : ""
  );
  const [maxPrice, setMaxPrice] = useState<string>(
    Object.keys(params).includes("max_price") ? params["max_price"] : ""
  );

  const [minYear, setMinYear] = useState<string>(
    Object.keys(params).includes("min_year") ? params["min_year"] : ""
  );
  const [maxYear, setMaxYear] = useState<string>(
    Object.keys(params).includes("max_year") ? params["max_year"] : ""
  );
  const [minYearList, setMinYearList] = useState<Option[]>(yearList);
  const [maxYearList, setMaxYearList] = useState<Option[]>(yearList);

  const [minMileage, setMinMileage] = useState<string>(
    Object.keys(params).includes("min_mileage") ? params["min_mileage"] : ""
  );
  const [maxMileage, setMaxMileage] = useState<string>(
    Object.keys(params).includes("max_mileage") ? params["max_mileage"] : ""
  );

  const [registStartDate, setRegistStartDate] = useState<Date | null>(null);
  const [registEndDate, setRegistEndDate] = useState<Date | null>(null);

  const [salesOnStartDate, setSalesOnStartDate] = useState<Date | null>(null);
  const [salesOnEndDate, setSalesOnEndDate] = useState<Date | null>(null);

  const [soldOutStartDate, setSoldOutStartDate] = useState<Date | null>(null);
  const [soldOutEndDate, setSoldOutEndDate] = useState<Date | null>(null);

  const [promotionStartDate, setPromotionStartDate] = useState<Date | null>(
    null
  );
  const [promotionEndDate, setPromotionEndDate] = useState<Date | null>(null);

  const [carOption, setCarOption] = useState<string[]>([]);
  const [truckOption, setTruckOption] = useState<string[]>([]);
  const [busOption, setBusOption] = useState<string[]>([]);

  const [searchText, setSearchText] = useState<string>(
    Object.keys(params).includes("search_text") ? params["search_text"] : ""
  );

  const prevType = usePrevious(type);

  const handleOnInit = () => {
    setParams({});
    setOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleOnSearch = () => {
    let searchParams: { [key: string]: any } = {};
    if (type !== "") {
      searchParams = {
        ...searchParams,
        type: type,
      };
    }
    if (carCategory !== "") {
      searchParams = {
        ...searchParams,
        car_category: carCategory,
      };
    }
    if (truckCategory !== "") {
      searchParams = {
        ...searchParams,
        truck_category: truckCategory,
      };
    }
    if (truckSubCategory.length > 0) {
      searchParams = {
        ...searchParams,
        truck_sub_category: truckSubCategory.join(","),
      };
    }
    if (busCategory !== "") {
      searchParams = {
        ...searchParams,
        bus_category: busCategory,
      };
    }
    if (maker !== "") {
      searchParams = {
        ...searchParams,
        maker: maker,
      };
    }
    if (maker !== "" && model !== "") {
      searchParams = {
        ...searchParams,
        model: model,
      };
    }
    if (maker !== "" && model !== "" && detailModel !== "") {
      searchParams = {
        ...searchParams,
        detail_model: detailModel,
      };
    }
    if (mission.length > 0) {
      searchParams = {
        ...searchParams,
        mission: mission.join(","),
      };
    }
    if (fuel.length > 0) {
      searchParams = {
        ...searchParams,
        fuel: fuel.join(","),
      };
    }
    if (color.length > 0) {
      searchParams = {
        ...searchParams,
        color: color.join(","),
      };
    }
    if (steering.length === 1) {
      searchParams = {
        ...searchParams,
        steering: steering[0],
      };
    }
    if (drive.length === 1) {
      if (type === "") {
        searchParams = {
          ...searchParams,
          type: "CAR,TRUCK",
          car_drive: drive[0],
          truck_drive: drive[0],
        };
      } else if (type === "CAR") {
        searchParams = {
          ...searchParams,
          car_drive: drive[0],
        };
      } else if (type === "TRUCK") {
        searchParams = {
          ...searchParams,
          truck_drive: drive[0],
        };
      }
    }
    if (minPrice !== "") {
      if (priceType === "") {
        searchParams = {
          ...searchParams,
          min_price: minPrice,
        };
      } else if (priceType === "ADPrice") {
        searchParams = {
          ...searchParams,
          price_type: "ADPrice",
          min_price: minPrice,
        };
      }
    }
    if (maxPrice !== "") {
      if (priceType === "") {
        searchParams = {
          ...searchParams,
          max_price: maxPrice,
        };
      } else if (priceType === "ADPrice") {
        searchParams = {
          ...searchParams,
          price_type: "ADPrice",
          max_price: maxPrice,
        };
      }
    }
    if (minYear !== "") {
      searchParams = {
        ...searchParams,
        min_year: minYear,
      };
    }
    if (maxYear !== "") {
      searchParams = {
        ...searchParams,
        max_year: maxYear,
      };
    }
    if (minMileage !== "") {
      searchParams = {
        ...searchParams,
        min_mileage: minMileage,
      };
    }
    if (maxMileage !== "") {
      searchParams = {
        ...searchParams,
        max_mileage: maxMileage,
      };
    }
    if (registStartDate !== null || registEndDate !== null) {
      const criteria: { type: string; period: string }[] =
        searchParams["criteria"] !== undefined
          ? JSON.parse(searchParams["criteria"])
          : [];

      criteria.push({
        type: "CREATED",
        period:
          (registStartDate !== null
            ? getUTCDate(getStartDate(registStartDate))
            : "") +
          "~" +
          (registEndDate !== null ? getUTCDate(getEndDate(registEndDate)) : ""),
      });
      searchParams = {
        ...searchParams,
        criteria: JSON.stringify(criteria),
      };
    }
    if (salesOnStartDate !== null || salesOnEndDate !== null) {
      const criteria: { type: string; period: string }[] =
        searchParams["criteria"] !== undefined
          ? JSON.parse(searchParams["criteria"])
          : [];

      criteria.push({
        type: "SALES_ON",
        period:
          (salesOnStartDate !== null
            ? getUTCDate(getStartDate(salesOnStartDate))
            : "") +
          "~" +
          (salesOnEndDate !== null
            ? getUTCDate(getEndDate(salesOnEndDate))
            : ""),
      });
      searchParams = {
        ...searchParams,
        criteria: JSON.stringify(criteria),
      };
    }
    if (soldOutStartDate !== null || soldOutEndDate !== null) {
      const criteria: { type: string; period: string }[] =
        searchParams["criteria"] !== undefined
          ? JSON.parse(searchParams["criteria"])
          : [];

      criteria.push({
        type: "SOLD_OUT",
        period:
          (soldOutStartDate !== null
            ? getUTCDate(getStartDate(soldOutStartDate))
            : "") +
          "~" +
          (soldOutEndDate !== null
            ? getUTCDate(getEndDate(soldOutEndDate))
            : ""),
      });
      searchParams = {
        ...searchParams,
        criteria: JSON.stringify(criteria),
      };
    }
    if (promotionStartDate !== null || promotionEndDate !== null) {
      const criteria: { type: string; period: string }[] =
        searchParams["criteria"] !== undefined
          ? JSON.parse(searchParams["criteria"])
          : [];
      criteria.push({
        type: "PROMOTION",
        period:
          (promotionStartDate !== null
            ? getUTCDate(getStartDate(promotionStartDate))
            : "") +
          "~" +
          (promotionEndDate !== null
            ? getUTCDate(getEndDate(promotionEndDate))
            : ""),
      });
      searchParams = {
        ...searchParams,
        criteria: JSON.stringify(criteria),
      };
    }
    if (accidentHistory.length === 1) {
      searchParams = {
        ...searchParams,
        accident_history: accidentHistory[0],
      };
    }
    if (simpleRepair.length === 1) {
      searchParams = {
        ...searchParams,
        simple_repair: simpleRepair[0],
      };
    }
    if (inspection.length === 1) {
      searchParams = {
        ...searchParams,
        inspection: inspection[0],
      };
    }
    if (status.length > 0) {
      searchParams = {
        ...searchParams,
        status: status.join(","),
      };
    }
    if (carOption.length > 0) {
      searchParams = {
        ...searchParams,
        car_option: carOption.join(","),
      };
    }
    if (truckOption.length > 0) {
      searchParams = {
        ...searchParams,
        truck_option: truckOption.join(","),
      };
    }
    if (busOption.length > 0) {
      searchParams = {
        ...searchParams,
        bus_option: busOption.join(","),
      };
    }
    if (searchText !== "") {
      searchParams = {
        ...searchParams,
        search_text: searchText,
      };
    }
    setParams(searchParams);
    setOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const checkRange = (
    type: string,
    value: string,
    compare: string,
    dispatch: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (isNull(value) || isNull(compare)) {
      dispatch(value);
    } else {
      let isCorrectRange = true;

      const checkValue = Number(value);
      const checkCompare = Number(compare);
      if (isNaN(checkValue) || isNaN(checkCompare)) {
        isCorrectRange = false;
      }

      if (type === "MIN") {
        if (checkValue > checkCompare) {
          enqueueSnackbar(t("MANAGE.MSG.COMPARE_MIN_VALUE"), {
            variant: "warning",
          });
          isCorrectRange = false;
        }
      } else if (type === "MAX") {
        if (checkValue < checkCompare) {
          enqueueSnackbar(t("MANAGE.MSG.COMPARE_MAX_VALUE"), {
            variant: "warning",
          });
          isCorrectRange = false;
        }
      }

      if (isCorrectRange) {
        dispatch(String(checkValue));
      } else {
        dispatch("");
      }
    }
  };
  const checkDateRange = (
    type: string,
    value: Date | null,
    compare: Date | null,
    dispatch: React.Dispatch<React.SetStateAction<Date | null>>
  ) => {
    if (isNull(value) || isNull(compare)) {
      dispatch(value);
    } else {
      let isCorrectRange = true;

      const checkValue = value;
      const checkCompare = compare;
      if (checkValue === null || checkCompare === null) {
        isCorrectRange = false;
      } else {
        if (type === "MIN") {
          if (checkValue > checkCompare) {
            enqueueSnackbar(t("MANAGE.MSG.COMPARE_MIN_VALUE"), {
              variant: "warning",
            });
            isCorrectRange = false;
          }
        } else if (type === "MAX") {
          if (checkValue < checkCompare) {
            enqueueSnackbar(t("MANAGE.MSG.COMPARE_MAX_VALUE"), {
              variant: "warning",
            });
            isCorrectRange = false;
          }
        }
      }

      if (isCorrectRange) {
        dispatch(checkValue);
      } else {
        dispatch(null);
      }
    }
  };

  const getCheckboxValue_Option = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          return true;
        }
      }
    }
    return false;
  };
  const handleCheckboxOnClick_Option = (
    isCheck: boolean,
    optionList: Option[]
  ) => {
    if (isCheck) {
      // Append
      if (type === "CAR") {
        setCarOption((prevState) => [...prevState, optionList[0].value]);
      } else if (type === "TRUCK") {
        setTruckOption((prevState) => [...prevState, optionList[0].value]);
      } else if (type === "BUS") {
        setBusOption((prevState) => [...prevState, optionList[0].value]);
      }
    } else {
      // Remove
      for (let idx = 0; idx < optionList.length; idx++) {
        if (type === "CAR") {
          if (carOption.indexOf(optionList[idx].value) !== -1) {
            setCarOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        } else if (type === "TRUCK") {
          if (truckOption.indexOf(optionList[idx].value) !== -1) {
            setTruckOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        } else if (type === "BUS") {
          if (busOption.indexOf(optionList[idx].value) !== -1) {
            setBusOption((prevState) =>
              prevState.filter((item) => item !== optionList[idx].value)
            );
          }
        }
      }
    }
  };
  const getRadioGroupValue_Option = (optionList: Option[]) => {
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          return optionList[idx].value;
        }
      }
    }
    return "";
  };
  const handleRadioGroupOnClick_Option = (
    value: string,
    optionList: Option[]
  ) => {
    // Remove
    for (let idx = 0; idx < optionList.length; idx++) {
      if (type === "CAR") {
        if (carOption.indexOf(optionList[idx].value) !== -1) {
          setCarOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      } else if (type === "TRUCK") {
        if (truckOption.indexOf(optionList[idx].value) !== -1) {
          setTruckOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      } else if (type === "BUS") {
        if (busOption.indexOf(optionList[idx].value) !== -1) {
          setBusOption((prevState) =>
            prevState.filter((item) => item !== optionList[idx].value)
          );
        }
      }
    }
    // Append
    if (type === "CAR") {
      setCarOption((prevState) => [...prevState, value]);
    } else if (type === "TRUCK") {
      setTruckOption((prevState) => [...prevState, value]);
    } else if (type === "BUS") {
      setBusOption((prevState) => [...prevState, value]);
    }
  };
  const getSelectLabel_Option = () => {
    const optionLabelList: string[] = [];
    const selectOptionList =
      type === "CAR"
        ? carOption
        : type === "TRUCK"
        ? truckOption
        : type === "BUS"
        ? busOption
        : [];

    if (isNotNull(type)) {
      ["INEXTERIOR", "SEAT", "CONVINIENCE", "SAFETY"].forEach((part) => {
        Object.entries(optionList[type][part]).forEach(([key, value]) => {
          value.forEach((option) => {
            if (selectOptionList.includes(option.value)) {
              optionLabelList.push(
                value.length > 1
                  ? t("VEHICLE.OPTION." + key).replace("<br>", " ") +
                      "(" +
                      option.label.replace("<br>", " ") +
                      ")"
                  : option.label.replace("<br>", " ")
              );
            }
          });
        });
      });
    }

    return optionLabelList.join(" / ");
  };

  useEffect(() => {
    if (init) {
      setMakerList(getMakerList(type));
      if (
        isNotNull(type) &&
        isNotNull(maker) &&
        !Object.keys(productList[maker]).includes(type)
      ) {
        setMaker("");
      }

      setCarCategory("");
      setTruckCategory("");
      setBusCategory("");

      setCarOption([]);
      setTruckOption([]);
      setBusOption([]);
    }
  }, [type]);
  useEffect(() => {
    if (init && type !== "TRUCK") {
      setTruckSubCategory([]);
    }
  }, [type, truckCategory]);

  useEffect(() => {
    if (init) {
      setModelList(getModelList(type, maker));
      if (isNotNull(model)) {
        setModel("");
      }
    }
  }, [maker]);
  useEffect(() => {
    if (init) {
      setDetailModelList(getDetailModelList(maker, model));
      if (isNotNull(detailModel)) {
        setDetailModel("");
      }
      if (isNotNull(type) && isNull(model)) {
        setType("");
      } else if (isNull(prevType) && isNotNull(maker) && isNotNull(model)) {
        if (
          productList[maker]["CAR"] !== undefined &&
          Object.keys(productList[maker]["CAR"]).includes(model)
        ) {
          setType("CAR");
        } else if (
          productList[maker]["TRUCK"] !== undefined &&
          Object.keys(productList[maker]["TRUCK"]).includes(model)
        ) {
          setType("TRUCK");
        } else if (
          productList[maker]["BUS"] !== undefined &&
          Object.keys(productList[maker]["BUS"]).includes(model)
        ) {
          setType("BUS");
        }
      }
    }
  }, [model]);

  useEffect(() => {
    if (isNull(minYear)) {
      setMaxYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMaxYearList(getYearList(Number(minYear), currentYear));
    }
  }, [minYear]);
  useEffect(() => {
    if (isNull(maxYear)) {
      setMinYearList(yearList);
    } else {
      const currentYear = new Date().getFullYear() + 1;
      setMinYearList(getYearList(currentYear - 30, Number(maxYear)));
    }
  }, [maxYear]);

  useEffect(() => {
    setInit(true);
  }, []);

  return (
    <>
      <Card classes={{ root: classes.card }} variant="outlined">
        <CardContent onClick={() => setOpen(!open)}>
          <Typography>{t("MANAGE.SEARCH.TITLE")}</Typography>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </CardContent>
        <Accordion
          className={classes.accordion}
          square
          elevation={0}
          expanded={open}
        >
          <AccordionSummary />
          <AccordionDetails>
            <Divider />
            <table className={classes.table}>
              <colgroup>
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.335%" }} />
                <col style={{ width: "8.335%" }} />
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.335%" }} />
                <col style={{ width: "8.335%" }} />
                <col style={{ width: "8.33%" }} />
                <col style={{ width: "8.33%" }} />
              </colgroup>
              <thead></thead>
              <tbody>
                <tr>
                  <th colSpan={6}>{t("MANAGE.SEARCH.TYPE/CATEGORY")}</th>
                  <th colSpan={2}>
                    {t("MANAGE.SEARCH.PRICE")}
                    <RadioGroup
                      row
                      className={classes.radioGroup}
                      value={priceType}
                      onChange={(e) => setPriceType(e.target.value)}
                      style={{
                        justifyContent: "center",
                      }}
                    >
                      <Radio label={t("MANAGE.SEARCH.CLPRICE")} value="" />
                      <Radio
                        label={t("MANAGE.SEARCH.ADPRICE")}
                        value="ADPrice"
                      />
                    </RadioGroup>
                  </th>
                  <td colSpan={4}>
                    <div className={classes.priceContainer}>
                      <Input
                        type="integer"
                        label="Min"
                        variant="standard"
                        value={minPrice}
                        dispatch={(value: string) => {
                          if (Number(value) === 0) {
                            setMinPrice("");
                          } else {
                            setMinPrice(value);
                          }
                        }}
                        onBlur={(value: string) => {
                          if (Number(value) === 0) {
                            checkRange("MIN", "", maxPrice, setMinPrice);
                          } else {
                            checkRange("MIN", value, maxPrice, setMinPrice);
                          }
                        }}
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              USD
                            </InputAdornment>
                          ),
                        }}
                      />
                      <span>~</span>
                      <Input
                        type="integer"
                        label="Max"
                        variant="standard"
                        value={maxPrice}
                        dispatch={(value: string) => {
                          if (Number(value) === 0) {
                            setMaxPrice("");
                          } else {
                            setMaxPrice(value);
                          }
                        }}
                        onBlur={(value: string) => {
                          if (Number(value) === 0) {
                            checkRange("MAX", "", minPrice, setMaxPrice);
                          } else {
                            checkRange("MAX", value, minPrice, setMaxPrice);
                          }
                        }}
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              USD
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Dropdown
                      id={"info-type"}
                      labelId={"info-type-label"}
                      label={t("VEHICLE.PRODUCT.TYPE")}
                      variant="standard"
                      value={type}
                      options={getTypeList(maker)}
                      dispatch={(_value: string) => setType(_value)}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <td colSpan={2}>
                    {isNull(type) && (
                      <Dropdown
                        id={"init-category"}
                        labelId={"init-category-label"}
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        variant="standard"
                        value={""}
                        options={initList}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    )}
                    {type === "CAR" && (
                      <Dropdown
                        id={"info-car-category"}
                        labelId={"info-car-category-label"}
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        variant="standard"
                        value={carCategory}
                        options={carCategoryList}
                        dispatch={setCarCategory}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    )}
                    {type === "TRUCK" && (
                      <Dropdown
                        id={"info-truck-category"}
                        labelId={"info-truck-category-label"}
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        variant="standard"
                        value={truckCategory}
                        options={truckCategoryList}
                        dispatch={setTruckCategory}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    )}
                    {type === "BUS" && (
                      <Dropdown
                        id={"info-bus-category"}
                        labelId={"info-bus-category-label"}
                        label={t("VEHICLE.PRODUCT.CATEGORY")}
                        variant="standard"
                        value={busCategory}
                        options={busCategoryList}
                        dispatch={setBusCategory}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    )}
                  </td>
                  <td colSpan={2}>
                    {type !== "TRUCK" && (
                      <div className="init">
                        <p>-</p>
                        <span>( {t("MANAGE.MSG.TRUCK_ONLY")} )</span>
                      </div>
                    )}
                    {type === "TRUCK" && isNull(truckCategory) && (
                      <Dropdown
                        isClearable
                        id={"init-sub-category"}
                        labelId={"init-sub-category-label"}
                        label={t("VEHICLE.PRODUCT.SUB_CATEGORY")}
                        variant="standard"
                        value={""}
                        options={initList}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    )}
                    {type === "TRUCK" && isNotNull(truckCategory) && (
                      <Dropdown
                        multiple
                        id={"info-truck-sub-category"}
                        labelId={"info-truck-sub-category-label"}
                        label={t("VEHICLE.PRODUCT.SUB_CATEGORY")}
                        variant="standard"
                        value={truckSubCategory}
                        options={truckSubCategoryList[truckCategory]}
                        dispatch={setTruckSubCategory}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    )}
                  </td>
                  <th colSpan={2}>{t("MANAGE.SEARCH.YEAR")}</th>
                  <td colSpan={4}>
                    <div className={classes.yearContainer}>
                      <Dropdown
                        label="From"
                        variant="standard"
                        value={minYear}
                        options={minYearList}
                        dispatch={setMinYear}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                      <span>~</span>
                      <Dropdown
                        label="To"
                        variant="standard"
                        value={maxYear}
                        options={maxYearList}
                        dispatch={setMaxYear}
                        formControlClassName={classes.dropdownFormControl}
                        menuItemClassName={classes.dropdownMenuItem}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan={6}>
                    {t("MANAGE.SEARCH.MAKER/MODEL/DETAIL_MODEL")}
                  </th>
                  <th colSpan={2}>{t("MANAGE.SEARCH.MILEAGE")}</th>
                  <td colSpan={4}>
                    <div className={classes.priceContainer}>
                      <Input
                        type="integer"
                        label="Min"
                        variant="standard"
                        value={minMileage}
                        dispatch={(value: string) => {
                          if (Number(value) === 0) {
                            setMinMileage("");
                          } else {
                            setMinMileage(value);
                          }
                        }}
                        onBlur={(value: string) => {
                          if (Number(value) === 0) {
                            checkRange("MIN", "", maxMileage, setMinMileage);
                          } else {
                            checkRange("MIN", value, maxMileage, setMinMileage);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              km
                            </InputAdornment>
                          ),
                        }}
                      />
                      <span>~</span>
                      <Input
                        type="integer"
                        label="Max"
                        variant="standard"
                        value={maxMileage}
                        dispatch={(value: string) => {
                          if (Number(value) === 0) {
                            setMaxMileage("");
                          } else {
                            setMaxMileage(value);
                          }
                        }}
                        onBlur={(value: string) => {
                          if (Number(value) === 0) {
                            checkRange("MAX", "", minMileage, setMaxMileage);
                          } else {
                            checkRange("MAX", value, minMileage, setMaxMileage);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              km
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Dropdown
                      id={"info-maker"}
                      labelId={"info-maker-label"}
                      label={t("VEHICLE.PRODUCT.MAKER")}
                      variant="standard"
                      value={maker}
                      options={makerList}
                      dispatch={(_value: string) => setMaker(_value)}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <td colSpan={2}>
                    <Dropdown
                      id={"info-model"}
                      labelId={"info-model-label"}
                      label={t("VEHICLE.PRODUCT.MODEL")}
                      variant="standard"
                      value={model}
                      options={modelList}
                      dispatch={(_value: string) => setModel(_value)}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <td colSpan={2}>
                    <Dropdown
                      id={"info-detail-model"}
                      labelId={"info-detail-model-label"}
                      label={t("VEHICLE.PRODUCT.DETAIL_MODEL")}
                      variant="standard"
                      value={detailModel}
                      options={detailModelList}
                      dispatch={(_value: string) => setDetailModel(_value)}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <th colSpan={2}>{t("MANAGE.SEARCH.CREATED")}</th>
                  <td colSpan={4}>
                    <div className={classes.dateContainer}>
                      <DatePicker
                        label="From"
                        maxDate={
                          registEndDate === null ? new Date() : registEndDate
                        }
                        selected={registStartDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MIN",
                            date,
                            registEndDate,
                            setRegistStartDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                      <span>~</span>
                      <DatePicker
                        label="To"
                        minDate={registStartDate}
                        maxDate={new Date()}
                        selected={registEndDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MAX",
                            date,
                            registStartDate,
                            setRegistEndDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan={6}>{t("MANAGE.SEARCH.MISSION/FUEL/COLOR")}</th>
                  <th colSpan={2}>{t("MANAGE.SEARCH.SALES_ON")}</th>
                  <td colSpan={4}>
                    <div className={classes.dateContainer}>
                      <DatePicker
                        label="From"
                        maxDate={
                          salesOnEndDate === null ? new Date() : salesOnEndDate
                        }
                        selected={salesOnStartDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MIN",
                            date,
                            salesOnEndDate,
                            setSalesOnStartDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                      <span>~</span>
                      <DatePicker
                        label="To"
                        minDate={salesOnStartDate}
                        maxDate={new Date()}
                        selected={salesOnEndDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MAX",
                            date,
                            salesOnStartDate,
                            setSalesOnEndDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Dropdown
                      multiple
                      isClearable
                      id={"info-mission"}
                      labelId={"info-mission-label"}
                      label={t("VEHICLE.PRODUCT.MISSION")}
                      variant="standard"
                      value={mission}
                      options={missionList}
                      dispatch={setMission}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <td colSpan={2}>
                    <Dropdown
                      multiple
                      isClearable
                      id={"info-fuel"}
                      labelId={"info-fuel-label"}
                      label={t("VEHICLE.PRODUCT.FUEL")}
                      variant="standard"
                      value={fuel}
                      options={fuelList}
                      dispatch={setFuel}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <td colSpan={2}>
                    <Dropdown
                      multiple
                      isClearable
                      id={"info-color"}
                      labelId={"info-color-label"}
                      label={t("VEHICLE.PRODUCT.COLOR")}
                      variant="standard"
                      value={color}
                      options={colorList}
                      dispatch={setColor}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <th colSpan={2}>{t("MANAGE.SEARCH.SOLD_OUT")}</th>
                  <td colSpan={4}>
                    <div className={classes.dateContainer}>
                      <DatePicker
                        label="From"
                        maxDate={
                          soldOutEndDate === null ? new Date() : soldOutEndDate
                        }
                        selected={soldOutStartDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MIN",
                            date,
                            soldOutEndDate,
                            setSoldOutStartDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                      <span>~</span>
                      <DatePicker
                        label="To"
                        minDate={soldOutStartDate}
                        maxDate={new Date()}
                        selected={soldOutEndDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MAX",
                            date,
                            soldOutStartDate,
                            setSoldOutEndDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{t("MANAGE.SEARCH.STEERING")}</th>
                  <td colSpan={2}>
                    <div className={classes.checkboxGroup}>
                      <Checkbox
                        label="LHD"
                        checked={steering.includes("LHD")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !steering.includes("LHD")) {
                            setSteering([...steering, "LHD"]);
                          } else if (!isCheck && steering.includes("LHD")) {
                            setSteering(
                              steering.filter((value) => value !== "LHD")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                      <Checkbox
                        label="RHD"
                        checked={steering.includes("RHD")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !steering.includes("RHD")) {
                            setSteering([...steering, "RHD"]);
                          } else if (!isCheck && steering.includes("RHD")) {
                            setSteering(
                              steering.filter((value) => value !== "RHD")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                    </div>
                  </td>
                  <th>{t("MANAGE.SEARCH.DRIVE")}</th>
                  <td colSpan={2}>
                    {type === "BUS" && (
                      <div className="init">
                        <p>-</p>
                        <span>
                          ({" "}
                          <Markup
                            content={t("MANAGE.MSG.CANNOT_BE_SELECTED")}
                          />{" "}
                          )
                        </span>
                      </div>
                    )}
                    {type !== "BUS" && (
                      <div className={classes.checkboxGroup}>
                        <Checkbox
                          label="2WD"
                          checked={drive.includes("2WD")}
                          dispatch={(isCheck: boolean) => {
                            if (isCheck && !drive.includes("2WD")) {
                              setDrive([...drive, "2WD"]);
                            } else if (!isCheck && drive.includes("2WD")) {
                              setDrive(
                                drive.filter((value) => value !== "2WD")
                              );
                            }
                          }}
                          formControlClassName={classes.mainFormControl}
                          checkboxClassName={classes.mainCheckbox}
                        />
                        <Checkbox
                          label="4WD"
                          checked={drive.includes("4WD")}
                          dispatch={(isCheck: boolean) => {
                            if (isCheck && !drive.includes("4WD")) {
                              setDrive([...drive, "4WD"]);
                            } else if (!isCheck && drive.includes("4WD")) {
                              setDrive(
                                drive.filter((value) => value !== "4WD")
                              );
                            }
                          }}
                          formControlClassName={classes.mainFormControl}
                          checkboxClassName={classes.mainCheckbox}
                        />
                      </div>
                    )}
                  </td>
                  <th colSpan={2}>{t("MANAGE.SEARCH.PROMOTION")}</th>
                  <td colSpan={4}>
                    <div className={classes.dateContainer}>
                      <DatePicker
                        label="From"
                        maxDate={
                          promotionEndDate === null
                            ? new Date()
                            : promotionEndDate
                        }
                        selected={promotionStartDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MIN",
                            date,
                            promotionEndDate,
                            setPromotionStartDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                      <span>~</span>
                      <DatePicker
                        label="To"
                        minDate={promotionStartDate}
                        maxDate={new Date()}
                        selected={promotionEndDate}
                        onChange={(date) => {
                          checkDateRange(
                            "MAX",
                            date,
                            promotionStartDate,
                            setPromotionEndDate
                          );
                        }}
                        isClearable
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Markup content={t("MANAGE.SEARCH.ACCIDENT_HISTORY")} />
                  </th>
                  <td colSpan={2}>
                    <div className={classes.checkboxGroup}>
                      <Checkbox
                        label={t("MANAGE.MSG.EXIST")}
                        checked={accidentHistory.includes("Y")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !accidentHistory.includes("Y")) {
                            setAccidentHistory([...accidentHistory, "Y"]);
                          } else if (
                            !isCheck &&
                            accidentHistory.includes("Y")
                          ) {
                            setAccidentHistory(
                              accidentHistory.filter((value) => value !== "Y")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                      <Checkbox
                        label={t("MANAGE.MSG.NOT_EXIST")}
                        checked={accidentHistory.includes("N")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !accidentHistory.includes("N")) {
                            setAccidentHistory([...accidentHistory, "N"]);
                          } else if (
                            !isCheck &&
                            accidentHistory.includes("N")
                          ) {
                            setAccidentHistory(
                              accidentHistory.filter((value) => value !== "N")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                    </div>
                  </td>
                  <th>
                    <Markup content={t("MANAGE.SEARCH.SIMPLE_REPAIR")} />
                  </th>
                  <td colSpan={2}>
                    <div className={classes.checkboxGroup}>
                      <Checkbox
                        label={t("MANAGE.MSG.EXIST")}
                        checked={simpleRepair.includes("Y")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !simpleRepair.includes("Y")) {
                            setSimpleRepair([...simpleRepair, "Y"]);
                          } else if (!isCheck && simpleRepair.includes("Y")) {
                            setSimpleRepair(
                              simpleRepair.filter((value) => value !== "Y")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                      <Checkbox
                        label={t("MANAGE.MSG.NOT_EXIST")}
                        checked={simpleRepair.includes("N")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !simpleRepair.includes("N")) {
                            setSimpleRepair([...simpleRepair, "N"]);
                          } else if (!isCheck && simpleRepair.includes("N")) {
                            setSimpleRepair(
                              simpleRepair.filter((value) => value !== "N")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                    </div>
                  </td>
                  <th colSpan={2}>
                    <Markup content={t("MANAGE.SEARCH.INSPECTION")} />
                  </th>
                  <td colSpan={4}>
                    <div className={classes.checkboxGroup}>
                      <Checkbox
                        label={t("MANAGE.MSG.EXIST")}
                        checked={inspection.includes("Y")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !inspection.includes("Y")) {
                            setInspection([...inspection, "Y"]);
                          } else if (!isCheck && inspection.includes("Y")) {
                            setInspection(
                              inspection.filter((value) => value !== "Y")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                      <Checkbox
                        label={t("MANAGE.MSG.NOT_EXIST")}
                        checked={inspection.includes("N")}
                        dispatch={(isCheck: boolean) => {
                          if (isCheck && !inspection.includes("N")) {
                            setInspection([...inspection, "N"]);
                          } else if (!isCheck && inspection.includes("N")) {
                            setInspection(
                              inspection.filter((value) => value !== "N")
                            );
                          }
                        }}
                        formControlClassName={classes.mainFormControl}
                        checkboxClassName={classes.mainCheckbox}
                      />
                    </div>
                    <div className="init">
                      <span>( {t("MANAGE.MSG.INSPECTION_ONLY")})</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan={3}>{t("MANAGE.SEARCH.STATUS")}</th>
                  <td colSpan={3}>
                    <Dropdown
                      multiple
                      isClearable
                      id={"info-status"}
                      labelId={"info-status-label"}
                      label={t("VEHICLE.SALES.STATUS")}
                      variant="standard"
                      value={status}
                      options={statusList}
                      dispatch={setStatus}
                      formControlClassName={classes.dropdownFormControl}
                      menuItemClassName={classes.dropdownMenuItem}
                    />
                  </td>
                  <th colSpan={2}>{t("MANAGE.SEARCH.OPTION")}</th>
                  <td colSpan={4}>
                    {isNull(type) && (
                      <div className="init">
                        <p>-</p>
                        <span>( {t("MANAGE.MSG.TYPE_FIRST")} )</span>
                      </div>
                    )}
                    {isNotNull(type) && (
                      <div className="init">
                        <Input
                          inputRef={anchorRef}
                          fullWidth
                          variant="standard"
                          value={getSelectLabel_Option()}
                          onClick={() => setOptionOpen(true)}
                          InputProps={{
                            readOnly: true,
                          }}
                          placeholder={t("MANAGE.MSG.CLICK_OPTION")}
                          className={classes.optionInput}
                        />
                      </div>
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan={3}>{t("MANAGE.SEARCH.SEARCH")}</th>
                  <td colSpan={9}>
                    <Input
                      fullWidth
                      isClearable
                      variant="standard"
                      placeholder="Ex. Prod No, VIN, Advert text..."
                      value={searchText}
                      dispatch={setSearchText}
                      className={classes.searchInput}
                      inputProps={{ maxLength: 3000 }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      onClick={handleOnInit}
                    >
                      {t("MANAGE.SEARCH.INIT")}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={handleOnSearch}
                    >
                      {t("MANAGE.SEARCH.SEARCH")}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
      </Card>
      <Popper
        open={optionOpen}
        anchorEl={anchorRef.current}
        placement="bottom"
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOptionOpen(false)}>
                <div className={classes.optionContainer}>
                  <Divider />
                  <div className="title">
                    <Typography>{t("VEHICLE.OPTION.INEXTERIOR")}</Typography>
                  </div>
                  <Divider />
                  {isNotNull(type) && (
                    <ul className={classes.optionList}>
                      {Object.keys(optionList[type]["INEXTERIOR"]).map(
                        (option, idx) => {
                          return (
                            <li key={idx}>
                              <div style={{ flexDirection: "column" }}>
                                <Checkbox
                                  label={
                                    <Markup
                                      content={t("VEHICLE.OPTION." + option)}
                                    />
                                  }
                                  checked={getCheckboxValue_Option(
                                    optionList[type]["INEXTERIOR"][option]
                                  )}
                                  dispatch={(isCheck: boolean) =>
                                    handleCheckboxOnClick_Option(
                                      isCheck,
                                      optionList[type]["INEXTERIOR"][option]
                                    )
                                  }
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                {type === "CAR" &&
                                  ["SUNROOF"].includes(option) && (
                                    <RadioGroup
                                      row
                                      className={classes.radioGroup}
                                      value={getRadioGroupValue_Option(
                                        optionList[type]["INEXTERIOR"][option]
                                      )}
                                      onChange={(e) =>
                                        handleRadioGroupOnClick_Option(
                                          e.target.value,
                                          optionList[type]["INEXTERIOR"][option]
                                        )
                                      }
                                    >
                                      {optionList[type]["INEXTERIOR"][
                                        option
                                      ].map((detail_option, idx) => {
                                        return (
                                          <Radio
                                            key={idx}
                                            label={
                                              <Markup
                                                content={detail_option.label}
                                              />
                                            }
                                            value={detail_option.value}
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            radioClassName={classes.radio}
                                          />
                                        );
                                      })}
                                    </RadioGroup>
                                  )}
                                {["HEADLAMP"].includes(option) && (
                                  <RadioGroup
                                    row
                                    className={classes.radioGroup}
                                    value={getRadioGroupValue_Option(
                                      optionList[type]["INEXTERIOR"][option]
                                    )}
                                    onChange={(e) =>
                                      handleRadioGroupOnClick_Option(
                                        e.target.value,
                                        optionList[type]["INEXTERIOR"][option]
                                      )
                                    }
                                  >
                                    {optionList[type]["INEXTERIOR"][option].map(
                                      (detail_option, idx) => {
                                        return (
                                          <Radio
                                            key={idx}
                                            label={
                                              <Markup
                                                content={detail_option.label}
                                              />
                                            }
                                            value={detail_option.value}
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            radioClassName={classes.radio}
                                          />
                                        );
                                      }
                                    )}
                                  </RadioGroup>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                  <Divider />
                  <div className="title">
                    <Typography>{t("VEHICLE.OPTION.SEAT")}</Typography>
                  </div>
                  <Divider />
                  {isNotNull(type) && (
                    <ul className={classes.optionList}>
                      {Object.keys(optionList[type]["SEAT"]).map(
                        (option, idx) => {
                          return (
                            <li key={idx}>
                              <div
                                style={{
                                  flexDirection: "column",
                                }}
                              >
                                <Checkbox
                                  label={
                                    <Markup
                                      content={t("VEHICLE.OPTION." + option)}
                                    />
                                  }
                                  checked={getCheckboxValue_Option(
                                    optionList[type]["SEAT"][option]
                                  )}
                                  dispatch={(isCheck: boolean) =>
                                    handleCheckboxOnClick_Option(
                                      isCheck,
                                      optionList[type]["SEAT"][option]
                                    )
                                  }
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                {type === "CAR" &&
                                  [
                                    "HEAT_SEAT",
                                    "POWER_SEAT",
                                    "VENT_SEAT",
                                    "MEMORY_SEAT",
                                  ].includes(option) && (
                                    <div className={classes.checkboxGroup}>
                                      {optionList[type]["SEAT"][option].map(
                                        (detail_option, idx) => {
                                          return (
                                            <Checkbox
                                              key={idx}
                                              label={
                                                <Markup
                                                  content={detail_option.label}
                                                />
                                              }
                                              checked={getCheckboxValue_Option([
                                                optionList[type]["SEAT"][
                                                  option
                                                ][idx],
                                              ])}
                                              dispatch={(isCheck: boolean) =>
                                                handleCheckboxOnClick_Option(
                                                  isCheck,
                                                  [
                                                    optionList[type]["SEAT"][
                                                      option
                                                    ][idx],
                                                  ]
                                                )
                                              }
                                              formControlClassName={
                                                classes.formControl
                                              }
                                              checkboxClassName={
                                                classes.checkbox
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                  <Divider />
                  <div className="title">
                    <Typography>{t("VEHICLE.OPTION.CONVINIENCE")}</Typography>
                  </div>
                  <Divider />
                  {isNotNull(type) && (
                    <ul className={classes.optionList}>
                      {Object.keys(optionList[type]["CONVINIENCE"]).map(
                        (option, idx) => {
                          return (
                            <li key={idx}>
                              <div style={{ flexDirection: "column" }}>
                                <Checkbox
                                  label={
                                    <Markup
                                      content={t("VEHICLE.OPTION." + option)}
                                    />
                                  }
                                  checked={getCheckboxValue_Option(
                                    optionList[type]["CONVINIENCE"][option]
                                  )}
                                  dispatch={(isCheck: boolean) =>
                                    handleCheckboxOnClick_Option(
                                      isCheck,
                                      optionList[type]["CONVINIENCE"][option]
                                    )
                                  }
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                {type === "CAR" &&
                                  ["CRUISE_CONTROL"].includes(option) && (
                                    <RadioGroup
                                      row
                                      className={classes.radioGroup}
                                      value={getRadioGroupValue_Option(
                                        optionList[type]["CONVINIENCE"][option]
                                      )}
                                      onChange={(e) =>
                                        handleRadioGroupOnClick_Option(
                                          e.target.value,
                                          optionList[type]["CONVINIENCE"][
                                            option
                                          ]
                                        )
                                      }
                                    >
                                      {optionList[type]["CONVINIENCE"][
                                        option
                                      ].map((detail_option, idx) => {
                                        return (
                                          <Radio
                                            key={idx}
                                            label={
                                              <Markup
                                                content={detail_option.label}
                                              />
                                            }
                                            value={detail_option.value}
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            radioClassName={classes.radio}
                                          />
                                        );
                                      })}
                                    </RadioGroup>
                                  )}
                                {["AIRCON"].includes(option) && (
                                  <RadioGroup
                                    row
                                    className={classes.radioGroup}
                                    value={getRadioGroupValue_Option(
                                      optionList[type]["CONVINIENCE"][option]
                                    )}
                                    onChange={(e) =>
                                      handleRadioGroupOnClick_Option(
                                        e.target.value,
                                        optionList[type]["CONVINIENCE"][option]
                                      )
                                    }
                                  >
                                    {optionList[type]["CONVINIENCE"][
                                      option
                                    ].map((detail_option, idx) => {
                                      return (
                                        <Radio
                                          key={idx}
                                          label={
                                            <Markup
                                              content={detail_option.label}
                                            />
                                          }
                                          value={detail_option.value}
                                          formControlClassName={
                                            classes.formControl
                                          }
                                          radioClassName={classes.radio}
                                        />
                                      );
                                    })}
                                  </RadioGroup>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                  <Divider />
                  <div className="title">
                    <Typography>{t("VEHICLE.OPTION.SAFETY")}</Typography>
                  </div>
                  <Divider />
                  {isNotNull(type) && (
                    <ul className={classes.optionList}>
                      {Object.keys(optionList[type]["SAFETY"]).map(
                        (option, idx) => {
                          return (
                            <li key={idx}>
                              <div style={{ flexDirection: "column" }}>
                                <Checkbox
                                  label={
                                    <Markup
                                      content={t("VEHICLE.OPTION." + option)}
                                    />
                                  }
                                  checked={getCheckboxValue_Option(
                                    optionList[type]["SAFETY"][option]
                                  )}
                                  dispatch={(isCheck: boolean) =>
                                    handleCheckboxOnClick_Option(
                                      isCheck,
                                      optionList[type]["SAFETY"][option]
                                    )
                                  }
                                  formControlClassName={classes.formControl}
                                  checkboxClassName={classes.checkbox}
                                />
                                {["AIRBAG", "CAMERA", "PARK_SENSOR"].includes(
                                  option
                                ) && (
                                  <div className={classes.checkboxGroup}>
                                    {optionList[type]["SAFETY"][option].map(
                                      (detail_option, idx) => {
                                        return (
                                          <Checkbox
                                            key={idx}
                                            label={
                                              <Markup
                                                content={detail_option.label}
                                              />
                                            }
                                            checked={getCheckboxValue_Option([
                                              optionList[type]["SAFETY"][
                                                option
                                              ][idx],
                                            ])}
                                            dispatch={(isCheck: boolean) =>
                                              handleCheckboxOnClick_Option(
                                                isCheck,
                                                [
                                                  optionList[type]["SAFETY"][
                                                    option
                                                  ][idx],
                                                ]
                                              )
                                            }
                                            formControlClassName={
                                              classes.formControl
                                            }
                                            checkboxClassName={classes.checkbox}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                  <Divider />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ManageSearchContainer;
