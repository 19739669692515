/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import { setErrorText } from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// API
import { onError } from "@/common/lib/api/common";
import { refreshTokenAPI } from "@/common/lib/api/auth";
// Libs
import { isNotNull, parseJWT } from "@/common/lib/common";

const AuthVerify = () => {
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { init } = useTypedSelector((state) => state.common);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const refreshTokenMutation = useMutation(refreshTokenAPI, {
    onSuccess: (response) => {
      if (Object.keys(response.data).includes("access_token")) {
        dispatch(setLoginToken(response.data.access_token));
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
            content: t("DIALOG.404.FAIL_TO_GET_TOKEN"),
            onClose: () => {
              navigate("/login");
            },
          })
        );
      }
    },
    onError: (error) => {
      onError(error);
      dispatch(setLoginToken(""));
    },
  });

  useEffect(() => {
    if (init) {
      if (isNotNull(loginToken)) {
        const data = parseJWT(loginToken);
        const accessTokenExpired = data.exp * 1000 - Date.now();
        setTimeout(() => {
          refreshTokenMutation.mutate();
        }, accessTokenExpired - 30000);
      }
    }
  }, [init, loginToken]);

  useEffect(() => {
    if (init) {
      if (isNotNull(loginToken)) {
        const data = parseJWT(loginToken);
        const accessTokenExpired = data.exp * 1000 - Date.now();
        setTimeout(() => {
          refreshTokenMutation.mutate();
        }, accessTokenExpired - 30000);
      } else {
        navigate("/login");
      }
    }
  }, [loginToken]);

  return <></>;
};

export default AuthVerify;
