/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { useSnackbar } from "notistack";
import classnames from "classnames";
// Redux
import { useTypedSelector, useTypedDispatch } from "@/common/lib/redux/store";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
import { refetchOn } from "@/common/lib/redux/actions/vehicleManageAction";
// Components
import {
  SortingState,
  PaginationState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";
// Components
import {
  Divider,
  Menu,
  MenuItem,
  Popover,
  Theme,
  Typography,
} from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LoopIcon from "@mui/icons-material/Loop";
import { Button, Checkbox } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// API
import { onError } from "@/common/lib/api/common";
import {
  getVehicleList,
  deleteVehicleInfo,
  patchVehicleInfo,
} from "@/common/lib/api/vehicle";
// Libs
import {
  isNull,
  getDateFormat,
  getVehicleTableData,
  exportCSVFile,
} from "@/common/lib/common";
import { optionList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  containerControl: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 50,
    userSelect: "none",
    "& > div:first-child": {
      marginRight: 5,
    },
    "& > div:not(:first-child):not(:last-child)": {
      margin: "0 5px",
    },
    "& > div:last-child": {
      marginLeft: 5,
    },
  },
  containerHeader: {
    width: "100%",
    height: "fit-content",
    borderTop: "1px solid " + theme.palette.divider,
    borderLeft: "2px solid " + theme.palette.divider,
    borderRight: "2px solid " + theme.palette.divider,
    overflow: "hidden",
  },
  containerRow: {
    width: "100%",
    height: "fit-content",
    maxHeight: 897.5,
    borderLeft: "2px solid " + theme.palette.divider,
    borderRight: "2px solid " + theme.palette.divider,
    borderBottom: "1px solid " + theme.palette.divider,
    overflow: "auto",
  },
  noItem: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "12.5px 15px",
    textAlign: "center",
  },
  tableHeader: {
    width: "max-content",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    borderLeft: "1px solid " + theme.palette.divider,
    borderRight: "1px solid " + theme.palette.divider,
    "& > thead > tr": {
      height: 50,
    },
    "& th": {
      position: "relative",
      background: colors.blue[7],
      color: "white",
      fontSize: "0.925rem",
      fontWeight: 600,
      textAlign: "center",
      padding: "0 12.5px",
      width: 115,
      "& .MuiSvgIcon-root": {
        position: "absolute",
        top: "calc(50% - 9px)",
        right: 4.5,
        fontSize: 18,
      },
      "&.select": {
        width: 45,
      },
      "&.photo": {
        width: 95,
      },
      "&.vin": {
        width: 185,
      },
      "&.maker": {
        width: 125,
      },
      "&.model": {
        width: 145,
      },
      "&.detail_model": {
        width: 165,
      },
      "&.price": {
        width: 100,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.discount": {
        width: 300,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.status": {
        width: 115,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.mission": {
        width: 100,
      },
      "&.fuel": {
        width: 165,
      },
      "&.steering": {
        width: 100,
      },
      "&.drive": {
        width: 100,
      },
      "&.inspection": {
        width: 300,
        "& span:after": {
          content: "'+'",
          paddingLeft: 3.5,
        },
      },
      "&.option": {
        width: 350,
        "& span:after": {
          content: "'+'",
          paddingLeft: 3.5,
        },
      },
      "&.memo": {
        width: 450,
        "& span:after": {
          content: "'*'",
          paddingLeft: 3.5,
        },
      },
      "&.created, &.modified, &.sales_on, &.sold_out, &.price_update": {
        width: 160,
      },
    },
    "& th:first-child": {
      borderRight: "1px solid white",
    },
    "& th:not(:first-child):not(:last-child)": {
      borderLeft: "1px solid white",
      borderRight: "1px solid white",
    },
    "& th:last-child": {
      borderLeft: "1px solid white",
    },
  },
  tableRow: {
    width: "max-content",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    "& > tbody > tr": {
      height: 56.5,
      "&:not(:last-child):hover": {
        backgroundColor: "rgba(0, 0, 0, 0.06) !important",
      },
    },
    "& > tbody > tr:nth-child(2n)": {
      backgroundColor: colors.gray[1],
    },
    "& > tbody > tr:last-child": {
      height: 31.5,
      backgroundColor: colors.blue[7],
    },
    "& td": {
      fontSize: "0.875rem",
      border: "1px solid " + theme.palette.divider,
      width: 115,
      padding: "0 12.5px",
      "&.select": {
        width: 45,
      },
      "&.photo": {
        width: 95,
        padding: "0 10px",
      },
      "&.id": {
        "& > span": {
          "&:hover": {
            cursor: "pointer",
            color: theme.palette.primary.main,
          },
        },
      },
      "&.vin": {
        width: 185,
        "& > span": {
          cursor: "copy",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
      },
      "&.maker": {
        width: 125,
      },
      "&.model": {
        width: 145,
      },
      "&.detail_model": {
        width: 165,
      },
      "&.price": {
        width: 100,
        textAlign: "center",
      },
      "&.discount": {
        width: 300,
        textAlign: "center",
      },
      "&.status": {
        textAlign: "center",
      },
      "&.mileage": {
        textAlign: "end",
      },
      "&.mission": {
        width: 100,
        textAlign: "center",
      },
      "&.fuel": {
        width: 165,
      },
      "&.steering": {
        width: 100,
        textAlign: "center",
      },
      "&.drive": {
        width: 100,
        textAlign: "center",
      },
      "&.accident_history": {
        textAlign: "center",
      },
      "&.simple_repair": {
        textAlign: "center",
      },
      "&.inspection": {
        width: 300,
        maxWidth: 300,
        textAlign: "center",
      },
      "&.option": {
        width: 350,
        maxWidth: 350,
      },
      "&.memo": {
        width: 450,
        "& > div > textarea": {
          cursor: "pointer",
        },
      },
      "&.created, &.modified, &.sales_on, &.sold_out, &.price_update": {
        width: 160,
      },
      "&.modify": {
        textAlign: "center",
        "& > button": {
          fontSize: "0.8rem",
          padding: "0 .8em",
          minWidth: "unset",
        },
      },
    },
  },
  controlItem: {
    display: "flex",
    alignItems: "center",
    padding: 7.5,
    color: theme.palette.primary.main,
    userSelect: "none",
    cursor: "pointer",
    "& > svg": {
      fontSize: "1.65rem",
      marginRight: "0.25rem",
    },
    "& > .MuiTypography-root": {
      fontSize: "0.95rem",
      fontWeight: 500,
    },
    "&:hover": {
      background: theme.palette.primary.soft,
      color: theme.palette.primary.light,
    },
  },
  menuPaper: {
    height: 350,
  },
  menuList: {
    padding: ".45em 0",
  },
  menuItem: {
    padding: ".3em .6em",
  },
  confirmPaper: {
    padding: "15px 25px",
    userSelect: "none",
    "& > .MuiTypography-body1": {
      fontWeight: 600,
      "& > span.ON > span": {
        color: theme.palette.primary.main,
      },
      "& > span.NOT > span": {
        color: theme.palette.error.main,
      },
      "& > span.DELETE": {
        color: theme.palette.error.main,
      },
    },
    "& > .MuiTypography-body2": {
      marginTop: 5,
      marginBottom: 10,
      fontSize: "0.825rem",
      color: theme.palette.error.main,
    },
    "& > div.actions": {
      display: "flex",
      justifyContent: "flex-end",
      "& > button": {
        padding: 0,
      },
      "& > button:first-child": {
        marginRight: 7.5,
      },
      "& > button:last-child": {
        marginLeft: 7.5,
      },
    },
  },
  formControl: {
    cursor: "pointer",
    userSelect: "none",
    "& > .MuiTypography-root": {
      fontSize: 14,
      "&.Mui-disabled": {
        color: theme.palette.text.primary,
      },
    },
    "&.Mui-disabled": {
      cursor: "pointer",
      "& + div": {
        "&::before": {
          color: theme.palette.text.primary,
        },
        "&::after": {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  checkbox: {
    padding: ".3em",
    "& > .MuiSvgIcon-root": {
      fontSize: 18,
    },
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
});

const ManageDataTable = ({
  data,
  columns,
  page,
  pageSize,
  totalCount,
  status,
  params,
}: any) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { refetch } = useTypedSelector((state) => state.vehicleManage);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: (page - 1) % (300 / pageSize),
    pageSize: pageSize,
  });
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOpen, setColumnOpen] = useState<boolean>(false);
  const [downloadOpen, setDownloadOpen] = useState<boolean>(false);

  const table = useReactTable({
    data: data,
    columns: columns,
    pageCount: totalCount,
    state: {
      sorting: sorting,
      rowSelection: rowSelection,
      pagination: pagination,
      columnVisibility: columnVisibility,
    },
    enableMultiSort: true,
    enableRowSelection: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    maxMultiSortColCount: 3,
    isMultiSortEvent: () => {
      return true;
    },
  });
  const tableRef = useRef<HTMLTableElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<OverlayScrollbarsComponentRef>(null);
  const columnRef = useRef<HTMLDivElement>(null);
  const downloadRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [anchorStatus, setAnchorStatus] = useState<string | undefined>(
    undefined
  );

  const patchVehicleMutation = useMutation(patchVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_VEHICLE_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });
  const deleteVehicleMutation = useMutation(deleteVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.204.SUCCESS_DELETE_VEHICLE_INFO"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      handleOnClose();
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 56.5,
    overscan: 10,
    getScrollElement: () => tableRef.current,
  });

  useEffect(() => {
    if (!refetch) {
      setRowSelection({});
    }
  }, [refetch]);

  useEffect(() => {
    setRowSelection({});
  }, [status]);

  useEffect(() => {
    setPagination({
      pageIndex: (page - 1) % (300 / pageSize),
      pageSize: pageSize,
    });
    headerRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [data, page, pageSize]);

  const getHeaders = () => {
    const headerList = [];

    const getHeaderLabel = (value: string) => {
      const label = t("MANAGE.COLUMN." + value.toUpperCase(), {
        lng: "en-US",
      });

      const notFoundValueRegex = /^MANAGE\.COLUMN\./i;
      if (notFoundValueRegex.test(label)) {
        return value.toUpperCase();
      } else {
        return label.replaceAll(/<br\s*\/>/gi, " ");
      }
    };
    for (const headerGroup of table.getHeaderGroups()) {
      for (const header of headerGroup.headers) {
        if (["select", "photo", "modify"].includes(header.id)) {
          continue;
        }
        if (header.id === "discount") {
          headerList.push({
            label: getHeaderLabel("discount"),
            key: "discount",
          });
          headerList.push({
            label: getHeaderLabel("discount_created"),
            key: "discount_created",
          });
          headerList.push({
            label: getHeaderLabel("discount_expired"),
            key: "discount_expired",
          });
        } else {
          headerList.push({
            label: getHeaderLabel(header.id),
            key: header.id,
          });
        }
      }
    }
    return headerList;
  };
  const getCheckboxLabel = (value: string) => {
    const label = t("MANAGE.COLUMN." + value.toUpperCase());

    const notFoundValueRegex = /^MANAGE\.COLUMN\./i;
    if (notFoundValueRegex.test(label)) {
      return value.toUpperCase();
    } else {
      return label.replaceAll(/<br\s*\/>/gi, " ");
    }
  };
  const handleOnScroll = (e: any) => {
    if (headerRef.current !== null) {
      headerRef.current.scrollLeft = e.target.scrollLeft;
    }
    if (rowRef.current !== null) {
      const instance = rowRef.current.osInstance();
      if (instance !== null) {
        instance.elements().content.scrollLeft = e.target.scrollLeft;
      }
    }
  };
  const handleOnClick = (e: any, value: string) => {
    setAnchorEl(e.currentTarget);
    setAnchorStatus(value);
  };
  const handleOnClose = () => {
    setAnchorEl(null);
    setAnchorStatus(undefined);
  };
  const handleOnDelete = async () => {
    if (Object.keys(rowSelection).length === 0) {
      enqueueSnackbar(t("MANAGE.MSG.NOT_SELECT_ITEM"), {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      const uidList: string[] = [];
      Object.keys(rowSelection).forEach((index: string) => {
        uidList.push(data[index]["id"]);
      });
      await deleteVehicleMutation.mutateAsync({
        uid: uidList.join(";"),
      });
      dispatch(refetchOn());
    }
    setAnchorEl(null);
    setAnchorStatus(undefined);
  };
  const handleOnStatus = async (status: string | undefined) => {
    if (Object.keys(rowSelection).length === 0) {
      enqueueSnackbar(t("MANAGE.MSG.NOT_SELECT_ITEM"), {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {
      const uidList: string[] = [];
      Object.keys(rowSelection).forEach((index: string) => {
        uidList.push(data[index]["id"]);
      });
      await patchVehicleMutation.mutateAsync({
        uid: uidList.join(";"),
        data: {
          sales: {
            status: status,
          },
        },
      });
      dispatch(refetchOn());
    }
    setAnchorEl(null);
    setAnchorStatus(undefined);
  };
  const handleOnDownload = async () => {
    setDownloadOpen(false);

    let listParams = { ...params };
    if (status !== "ALL") {
      listParams = {
        ...params,
        status: status,
      };
    }
    dispatch(loadingOn());
    await getVehicleList(1, 1000000, "-created", listParams).then(
      (response) => {
        const csvData = [];

        for (const result of response.data.results) {
          const convertData: { [key: string]: any } = {};
          const tableData = getVehicleTableData([result]);
          if (tableData.length === 0) {
            continue;
          }

          for (const key of Object.keys(tableData[0])) {
            if (
              [
                "created",
                "modified",
                "sales_on",
                "sold_out",
                "price_update",
                "discount_created",
                "discount_expired",
              ].includes(key)
            ) {
              convertData[key] = getDateFormat(
                tableData[0][key],
                "yyyy-MM-dd hh:mm:ss"
              );
            } else if (key === "option") {
              if (tableData[0]["option"].length === 0) {
                convertData["option"] = "-";
              } else {
                const optionLabelList: string[] = [];
                ["INEXTERIOR", "SEAT", "CONVINIENCE", "SAFETY"].forEach(
                  (part) => {
                    Object.entries(
                      optionList[result.product.type][part]
                    ).forEach(([key, value]) => {
                      value.forEach((option) => {
                        if (tableData[0]["option"].includes(option.value)) {
                          optionLabelList.push(
                            value.length > 1
                              ? t("VEHICLE.OPTION." + key).replace(
                                  "<br>",
                                  " "
                                ) +
                                  "(" +
                                  option.label.replace("<br>", " ") +
                                  ")"
                              : option.label.replace("<br>", " ")
                          );
                        }
                      });
                    });
                  }
                );
                convertData["option"] = optionLabelList.join(",");
              }
            } else if (key === "inspection") {
              if (
                isNull(tableData[0]["inspection"]["accident_history"]) &&
                isNull(tableData[0]["inspection"]["accident_history"])
              ) {
                convertData["inspection"] = "-";
              } else {
                const result: { [key: string]: string[] } = {
                  X: [],
                  W: [],
                  C: [],
                  A: [],
                  U: [],
                  T: [],
                };

                for (const [key, value] of Object.entries(
                  tableData[0]["inspection"]
                )) {
                  if (
                    [
                      "corrosion",
                      "hole",
                      "crack",
                      "accident_history",
                      "simple_repair",
                    ].includes(key)
                  ) {
                    continue;
                  }

                  if (value === "X") {
                    result["X"].push(key);
                  } else if (value === "W") {
                    result["W"].push(key);
                  } else if (value === "C") {
                    result["C"].push(key);
                  } else if (value === "A") {
                    result["A"].push(key);
                  } else if (value === "U") {
                    result["U"].push(key);
                  } else if (value === "T") {
                    result["T"].push(key);
                  }
                }

                const getOptionText = (valueList: string[]) => {
                  const resultText: string[] = [];
                  for (const value of valueList) {
                    resultText.push(
                      t("VEHICLE.INSPECTION." + value.toUpperCase())
                    );
                  }
                  return resultText.join(", ");
                };
                const resultText = [];
                if (result["X"].length > 0) {
                  resultText.push(
                    t("VEHICLE.INSPECTION.EXCHANGE") +
                      " : " +
                      getOptionText(result["X"])
                  );
                }
                if (result["W"].length > 0) {
                  resultText.push(
                    t("VEHICLE.INSPECTION.PLATE_WELDING") +
                      " : " +
                      getOptionText(result["W"])
                  );
                }
                if (result["C"].length > 0) {
                  resultText.push(
                    t("VEHICLE.INSPECTION.CORROSION") +
                      " : " +
                      getOptionText(result["C"])
                  );
                }
                if (result["A"].length > 0) {
                  resultText.push(
                    t("VEHICLE.INSPECTION.SCRATCH") +
                      " : " +
                      getOptionText(result["A"])
                  );
                }
                if (result["U"].length > 0) {
                  resultText.push(
                    t("VEHICLE.INSPECTION.UNEVEN") +
                      " : " +
                      getOptionText(result["U"])
                  );
                }
                if (result["T"].length > 0) {
                  resultText.push(
                    t("VEHICLE.INSPECTION.DAMAGE") +
                      " : " +
                      getOptionText(result["T"])
                  );
                }
                convertData["inspection"] = resultText.join(" | ");
              }
            } else {
              convertData[key] =
                tableData[0][key] !== null ? tableData[0][key] : "";
            }
          }
          csvData.push(convertData);
        }

        exportCSVFile(
          getHeaders(),
          csvData,
          getDateFormat(new Date(), "autoskorea_yyyyMMddhhmmss") + ".csv"
        );
      }
    );
    dispatch(loadingOff());
  };

  return (
    <>
      <div className={classes.containerControl}>
        <div
          ref={columnRef}
          className={classes.controlItem}
          onClick={() => setColumnOpen(true)}
        >
          <FilterAltIcon />
          <Typography>{t("MANAGE.TABLE.CHANGE_COLUMN")}</Typography>
        </div>
        {status === "WAIT" && (
          <>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "ON")}
            >
              <LoopIcon />
              <Typography>{t("MANAGE.TABLE.CHANGE_ON")}</Typography>
            </div>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "DELETE")}
            >
              <DeleteIcon />
              <Typography>{t("MANAGE.TABLE.DELETE_ITEM")}</Typography>
            </div>
          </>
        )}
        {status === "ON" && (
          <>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "REZ")}
            >
              <LoopIcon />
              <Typography>{t("MANAGE.TABLE.CHANGE_REZ")}</Typography>
            </div>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "DONE")}
            >
              <LoopIcon />
              <Typography>{t("MANAGE.TABLE.CHANGE_DONE")}</Typography>
            </div>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "NOT")}
            >
              <LoopIcon />
              <Typography>{t("MANAGE.TABLE.CHANGE_NOT")}</Typography>
            </div>
          </>
        )}
        {status === "REZ" && (
          <>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "ON")}
            >
              <LoopIcon />
              <Typography>{t("MANAGE.TABLE.CHANGE_ON")}</Typography>
            </div>
            <div
              className={classes.controlItem}
              onClick={(e) => handleOnClick(e, "DONE")}
            >
              <LoopIcon />
              <Typography>{t("MANAGE.TABLE.CHANGE_DONE")}</Typography>
            </div>
          </>
        )}
        {status === "DONE" && (
          <div
            className={classes.controlItem}
            onClick={(e) => handleOnClick(e, "ON")}
          >
            <LoopIcon />
            <Typography>{t("MANAGE.TABLE.CHANGE_ON")}</Typography>
          </div>
        )}
        {status === "NOT" && (
          <div
            className={classes.controlItem}
            onClick={(e) => handleOnClick(e, "ON")}
          >
            <LoopIcon />
            <Typography>{t("MANAGE.TABLE.CHANGE_ON")}</Typography>
          </div>
        )}
        <div
          ref={downloadRef}
          className={classes.controlItem}
          onClick={() => setDownloadOpen(true)}
        >
          <DownloadIcon />
          <Typography>{t("MANAGE.TABLE.DOWNLOAD_CSV")}</Typography>
        </div>
      </div>
      <div
        ref={headerRef}
        className={classes.containerHeader}
        onScroll={handleOnScroll}
      >
        <table className={classes.tableHeader}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={classnames(`${header.id}`)}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <NorthIcon />,
                          desc: <SouthIcon />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      {rows.length > 0 ? (
        <OverlayScrollbarsComponent
          ref={rowRef}
          className={classes.containerRow}
          options={{
            scrollbars: {
              autoHide: "leave",
            },
          }}
          events={{
            scroll: (_, event) => {
              handleOnScroll(event);
            },
          }}
          defer
        >
          <table ref={tableRef} className={classes.tableRow}>
            <tbody>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index];
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className={cell.column.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr>
                <td colSpan={columns.length} />
              </tr>
            </tbody>
          </table>
        </OverlayScrollbarsComponent>
      ) : (
        <div className={classes.containerRow}>
          <div className={classes.noItem}>
            {t("MANAGE.TABLE.NO_RESULT_ITEM")}
          </div>
        </div>
      )}
      <Menu
        open={columnOpen}
        onClose={() => setColumnOpen(false)}
        anchorEl={columnRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.menuPaper,
          list: classes.menuList,
        }}
      >
        <MenuItem
          key={"column_menu_all"}
          onClick={table.getToggleAllColumnsVisibilityHandler()}
          classes={{
            root: classes.menuItem,
          }}
        >
          <Checkbox
            disabled
            label={"ALL"}
            checked={table.getIsAllColumnsVisible()}
            formControlClassName={classes.formControl}
            checkboxClassName={classes.checkbox}
          />
        </MenuItem>
        <Divider style={{ margin: ".3em 0" }} />
        {table
          .getAllLeafColumns()
          .filter((item) => item.getCanHide())
          .map((column, idx) => {
            return (
              <MenuItem
                key={"column_menu_" + idx}
                onClick={column.getToggleVisibilityHandler()}
                classes={{
                  root: classes.menuItem,
                }}
              >
                <Checkbox
                  disabled
                  label={getCheckboxLabel(column.id)}
                  checked={column.getIsVisible()}
                  formControlClassName={classes.formControl}
                  checkboxClassName={classes.checkbox}
                />
              </MenuItem>
            );
          })}
      </Menu>
      <Popover
        open={anchorEl !== null}
        onClose={() => handleOnClose()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.confirmPaper,
        }}
      >
        {anchorStatus === "DELETE" && (
          <>
            <Typography variant="body1">
              <Markup
                className="DELETE"
                content={t("MANAGE.MSG.CONFIRM_DELETE")}
              />
            </Typography>
            <Typography variant="body2">
              {t("MANAGE.MSG.NOTICE_DELETE")}
            </Typography>
            <div className="actions">
              <Button variant="outlined" onClick={() => handleOnDelete()}>
                {t("OPTION.YES")}
              </Button>
              <Button variant="outlined" onClick={() => handleOnClose()}>
                {t("OPTION.NO")}
              </Button>
            </div>
          </>
        )}
        {anchorStatus !== "DELETE" && (
          <>
            <Typography variant="body1">
              {anchorStatus !== undefined && (
                <Markup
                  className={anchorStatus}
                  content={t("MANAGE.MSG.CHANGE_" + anchorStatus)}
                />
              )}
            </Typography>
            <Typography variant="body2">
              {t("MANAGE.MSG.NOTICE_REPLECT")}
            </Typography>
            <div className="actions">
              <Button
                variant="outlined"
                onClick={() => handleOnStatus(anchorStatus)}
              >
                {t("OPTION.YES")}
              </Button>
              <Button variant="outlined" onClick={() => handleOnClose()}>
                {t("OPTION.NO")}
              </Button>
            </div>
          </>
        )}
      </Popover>
      <Popover
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        anchorEl={downloadRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.confirmPaper,
        }}
      >
        <Typography variant="body1">{t("MANAGE.MSG.DOWNLOAD_CSV")}</Typography>
        <Typography variant="body2">
          {t("MANAGE.MSG.NOTICE_DOWNLOAD_CSV")}
        </Typography>
        <div className="actions">
          <Button variant="outlined" onClick={() => handleOnDownload()}>
            {t("OPTION.YES")}
          </Button>
          <Button variant="outlined" onClick={() => setDownloadOpen(false)}>
            {t("OPTION.NO")}
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default ManageDataTable;
