import { useState } from "react";
import classNames from "classnames";
// Components
import { ClickAwayListener, IconButton, Theme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StatusMark from "./StatusMark";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import { cloneDeep } from "lodash";

const styles = (theme: Theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&.open": {
      zIndex: 3,
      "& > button": {
        opacity: 1,
      },
      "& > button.N": {
        transform: "rotate(45deg) translateY(40px)",
        "& > svg": {
          transform: "rotate(-45deg)",
        },
      },
      "& > button.X": {
        transform: "rotate(90deg) translateY(40px)",
        "& > div": {
          transform: "rotate(-90deg)",
        },
      },
      "& > button.W": {
        transform: "rotate(135deg) translateY(40px)",
        "& > div": {
          transform: "rotate(-135deg)",
        },
      },
      "& > button.C": {
        transform: "rotate(180deg) translateY(40px)",
        "& > div": {
          transform: "rotate(-180deg)",
        },
      },
      "& > button.A": {
        transform: "rotate(225deg) translateY(40px)",
        "& > div": {
          transform: "rotate(-225deg)",
        },
      },
      "& > button.U": {
        transform: "rotate(270deg) translateY(40px)",
        "& > div": {
          transform: "rotate(-270deg)",
        },
      },
      "& > button.T": {
        transform: "rotate(315deg) translateY(40px)",
        "& > div": {
          transform: "rotate(-315deg)",
        },
      },
    },
  },
  mainButton: {
    zIndex: 5,
    width: 24,
    height: 24,
    padding: 0,
    transition: "all 0.6s ease",
    "& > svg": {
      color: "white",
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main + "!important",
    },
  },
  subButton: {
    zIndex: 0,
    position: "absolute",
    transition: "all 0.6s ease",
    opacity: 0,
    "&.N": {
      "& > svg": {
        color: "white",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main + "!important",
      },
    },
  },
});

const StatusButton = ({ part, value, dispatch, ...props }: any) => {
  const classes = useStyles(styles);

  const [open, setOpen] = useState(false);

  const handleOnClick = (status: string) => {
    if (open) {
      const inspectionData = cloneDeep(value.inspection);
      inspectionData[part] = status;
      dispatch({
        ...value,
        inspection: inspectionData,
      });
      setOpen(false);
    }
  };

  return (
    <div
      className={classNames(classes.content, props.className, { open: open })}
    >
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <IconButton
          className={classes.mainButton}
          onClick={() => setOpen(!open)}
        >
          {value.inspection[part] === "" ? (
            <AddIcon />
          ) : (
            <StatusMark status={value.inspection[part]} />
          )}
        </IconButton>
      </ClickAwayListener>
      <IconButton
        className={`${classes.subButton} N`}
        onClick={() => handleOnClick("")}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        className={`${classes.subButton} X`}
        onClick={() => handleOnClick("X")}
      >
        <StatusMark status="X" />
      </IconButton>
      <IconButton
        className={`${classes.subButton} W`}
        onClick={() => handleOnClick("W")}
      >
        <StatusMark status="W" />
      </IconButton>
      <IconButton
        className={`${classes.subButton} C`}
        onClick={() => handleOnClick("C")}
      >
        <StatusMark status="C" />
      </IconButton>
      <IconButton
        className={`${classes.subButton} A`}
        onClick={() => handleOnClick("A")}
      >
        <StatusMark status="A" />
      </IconButton>
      <IconButton
        className={`${classes.subButton} U`}
        onClick={() => handleOnClick("U")}
      >
        <StatusMark status="U" />
      </IconButton>
      <IconButton
        className={`${classes.subButton} T`}
        onClick={() => handleOnClick("T")}
      >
        <StatusMark status="T" />
      </IconButton>
    </div>
  );
};

export default StatusButton;
