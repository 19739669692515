/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { RadioGroup, Theme, Typography, InputAdornment } from "@mui/material";
import { Checkbox, Dropdown, Input, Radio } from "@/common/components";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
// Libs
import i18n from "@/common/lib/lang/i18n";
import {
  yearList,
  carCategoryList,
  truckCategoryList,
  truckSubCategoryList,
  busCategoryList,
  getMakerList,
  getModelList,
  getDetailModelList,
  steeringList,
  truckEngineTypeList,
  truckCabinTypeList,
  truckWheelbaseList,
  colorList,
  doorList,
  missionList,
  carDriveList,
  truckDriveList,
  fuelList,
} from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  table: {
    width: 950,
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    margin: "45px auto",
    userSelect: "none",
    "& th": {
      backgroundColor: colors.gray[1],
      fontSize: "0.925rem",
      fontWeight: "normal",
      textAlign: "left",
      "& .title": {
        textAlign: "center",
      },
    },
    "& th, & td": {
      padding: "15px 17.5px",
      border: "1px solid " + theme.palette.divider,
      wordBreak: "keep-all",
      "&.require-value::after": {
        content: "'*'",
        color: theme.palette.error.main,
        padding: 3,
      },
    },
    "& td.title": {
      border: "1px solid " + theme.palette.divider,
      padding: "20px 35px",
      "& > div": {
        display: "flex",
        alignItems: "flex-start",
        "& > svg": {
          fontSize: "5rem",
          color: theme.palette.primary.main,
          marginLeft: 15,
          marginRight: 25,
        },
        "& > div": {
          width: "100%",
          userSelect: "none",
          "& > .MuiTypography-body1 > span": {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: theme.palette.text.primary,
            "& > span": {
              fontSize: "1.15rem",
            },
          },
          "& > .MuiTypography-body2": {
            fontSize: "1rem",
            lineHeight: "1.35",
            "& > span": {
              color: theme.palette.primary.main,
              fontSize: "0.85rem",
            },
          },
          "& > .MuiTypography-caption": {
            display: "block",
            color: theme.palette.error.main,
            fontSize: "0.875rem",
            fontWeight: "bold",
          },
        },
      },
    },
    "& td.vin": {
      "& > div:nth-child(1)": {
        marginBottom: 18.5,
        "& > .MuiTypography-caption": {
          background: theme.palette.primary.main,
          padding: "5px 10px",
          color: "white",
          fontSize: "0.825rem",
          fontWeight: 400,
          lineHeight: "1",
          userSelect: "none",
        },
      },
      "& > div:nth-child(2)": {
        display: "flex",
        alignItems: "center",
        "& > .MuiFormControl-root": {
          marginRight: 10,
        },
        "& > .MuiFormControlLabel-root": {
          marginBottom: -2.5,
          "& > .MuiCheckbox-root": {
            padding: 5,
          },
          "& > label": {
            fontSize: "0.85rem",
          },
        },
      },
      "& > div:nth-child(3)": {
        marginTop: 3.5,
        "& > .MuiTypography-caption": {
          color: theme.palette.error.main,
          fontSize: "0.825rem",
          fontWeight: 500,
          lineHeight: "1",
          userSelect: "none",
        },
      },
    },
    "& td.size": {
      "& > .MuiTextField-root:nth-child(2)": {
        margin: "0 62.3px",
      },
    },
  },
  dropdownFormControl: {
    width: 175,
    fontSize: "0.875rem !important",
  },
  dropdownMenuItem: {
    fontSize: "0.875rem !important",
  },
  input: {
    width: 175,
    fontSize: "0.875rem !important",
  },
  radioGroup: {
    "& > label:first-child": {
      marginRight: "0.2rem",
    },
    "& > label:last-child": {
      marginLeft: "0.2rem",
    },
  },
  formControl: {
    "& > .MuiTypography-root": {
      fontSize: "0.875rem",
    },
  },
  checkbox: {
    padding: 3,
    "& > .MuiSvgIcon-root": {
      fontSize: "1.25rem",
    },
  },
  radio: {
    padding: 3,
    "& > span > svg": {
      fontSize: "1.25rem",
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const InfoContainer = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  useEffect(() => {
    if (value.product.type === "TRUCK" && value.truck_product !== undefined) {
      if (value.truck_product.category === "") {
        dispatch({
          ...value,
          truck_product: {
            ...value.truck_product,
            load_weight: null,
            tank_volume: null,
            crane_boom_height: null,
          },
        });
      } else {
        if (
          ![
            "CargoTruck",
            "TopTruck",
            "WingbodyTruck",
            "DumpTruck",
            "Tractor",
            "Trailer",
            "TowTruck",
            "CampingCar",
          ].includes(value.truck_product.category)
        ) {
          dispatch({
            ...value,
            truck_product: {
              ...value.truck_product,
              load_weight: null,
            },
          });
        } else if (!["CraneTruck"].includes(value.truck_product.category)) {
          dispatch({
            ...value,
            truck_product: {
              ...value.truck_product,
              crane_boom_height: null,
            },
          });
        } else if (!["TankLorry"].includes(value.truck_product.category)) {
          dispatch({
            ...value,
            truck_product: {
              ...value.truck_product,
              tank_volume: null,
            },
          });
        }
      }
    }
  }, [value.truck_product?.category]);

  return (
    <table className={classes.table}>
      <colgroup>
        <col style={{ width: "15%" }} />
        <col style={{ width: "35%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "35%" }} />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td colSpan={4} className="title">
            <div>
              <DirectionsCarIcon />
              <div>
                <Typography variant="body1">
                  <Markup content={t("REGISTER.INFO.INFO.TITLE")} />
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.INFO.INFO.CONTENT_1")}
                </Typography>
                <Typography variant="caption">
                  {t("REGISTER.INFO.INFO.CONTENT_2")}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.YEAR")}</th>
          <td colSpan={3}>
            <Dropdown
              id={"info-year"}
              labelId={"info-year-label"}
              label={t("VEHICLE.PRODUCT.YEAR")}
              value={value.product.year !== 0 ? String(value.product.year) : ""}
              options={yearList}
              dispatch={(_value: string) =>
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    year: _value !== "" ? Number(_value) : 0,
                  },
                })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.MAKER")}</th>
          <td colSpan={3}>
            <Dropdown
              id={"info-maker"}
              labelId={"info-maker-label"}
              label={t("VEHICLE.PRODUCT.MAKER")}
              value={value.product.maker}
              options={getMakerList(value.product.type)}
              dispatch={(_value: string) =>
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    maker: _value,
                    model: "",
                    detail_model: "",
                  },
                })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.MODEL")}</th>
          <td colSpan={3}>
            <Dropdown
              id={"info-model"}
              labelId={"info-model-label"}
              label={t("VEHICLE.PRODUCT.MODEL")}
              value={value.product.model}
              options={getModelList(value.product.type, value.product.maker)}
              dispatch={(_value: string) =>
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    model: _value,
                    detail_model: "",
                  },
                })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.DETAIL_MODEL")}</th>
          <td colSpan={3}>
            <Dropdown
              id={"info-detail-model"}
              labelId={"info-detail-model-label"}
              label={t("VEHICLE.PRODUCT.DETAIL_MODEL")}
              value={value.product.detail_model}
              options={getDetailModelList(
                value.product.maker,
                value.product.model
              )}
              dispatch={(_value: string) =>
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    detail_model: _value,
                  },
                })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.VIN")}</th>
          <td colSpan={3} className="vin">
            <div>
              <Typography variant="caption">
                {t("REGISTER.INFO.INFO.NOTICE_1")}
              </Typography>
            </div>
            <div>
              <Input
                disabled
                className={classes.input}
                label={t("VEHICLE.PRODUCT.VIN")}
                value={value.product.vin}
              />
              <Checkbox
                id={"info-is-restamp"}
                label={t("VEHICLE.PRODUCT.IS_RESTAMP")}
                checked={value.product.is_restamp}
                dispatch={(_value: boolean) =>
                  dispatch({
                    ...value,
                    product: {
                      ...value.product,
                      is_restamp: _value,
                    },
                  })
                }
                formControlClassName={classes.formControl}
                checkboxClassName={classes.checkbox}
              />
            </div>
            <div>
              <Typography variant="caption">
                {t("REGISTER.INFO.INFO.NOTICE_2")}
              </Typography>
            </div>
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.CATEGORY")}</th>
          <td colSpan={value.product.type === "TRUCK" ? 1 : 3}>
            <Dropdown
              id={"info-category"}
              labelId={"info-category-label"}
              label={t("VEHICLE.PRODUCT.CATEGORY")}
              value={
                value.product.type === "CAR"
                  ? value.car_product !== undefined
                    ? value.car_product.category
                    : ""
                  : value.product.type === "TRUCK"
                  ? value.truck_product !== undefined
                    ? value.truck_product.category
                    : ""
                  : value.product.type === "BUS"
                  ? value.bus_product !== undefined
                    ? value.bus_product.category
                    : ""
                  : ""
              }
              options={
                value.product.type === "CAR"
                  ? carCategoryList
                  : value.product.type === "TRUCK"
                  ? truckCategoryList
                  : value.product.type === "BUS"
                  ? busCategoryList
                  : [{ label: i18n.t("DROPDOWN.SELECT"), value: "" }]
              }
              dispatch={(_value: string) =>
                value.product.type === "CAR"
                  ? value.car_product !== undefined
                    ? dispatch({
                        ...value,
                        car_product: {
                          ...value.car_product,
                          category: _value,
                        },
                      })
                    : dispatch({
                        ...value,
                        car_product: {
                          category: _value,
                          seats: 0,
                          drive: "",
                        },
                      })
                  : value.product.type === "TRUCK"
                  ? value.truck_product !== undefined
                    ? dispatch({
                        ...value,
                        truck_product: {
                          ...value.truck_product,
                          category: _value,
                          sub_category: "",
                        },
                      })
                    : dispatch({
                        ...value,
                        truck_product: {
                          category: _value,
                          sub_category: "",
                          axle: "",
                          drive: "",
                          tonner: 0,
                          engine_type: "",
                          cabin_type: "",
                          wheelbase: "",
                          load_weight: null,
                          tank_volume: null,
                          crane_boom_height: null,
                        },
                      })
                  : value.product.type === "BUS"
                  ? value.bus_product !== undefined
                    ? dispatch({
                        ...value,
                        bus_product: {
                          ...value.bus_product,
                          category: _value,
                        },
                      })
                    : dispatch({
                        ...value,
                        bus_product: {
                          category: _value,
                          seats: 0,
                          cargo: null,
                          total_weight: null,
                        },
                      })
                  : () => {}
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
          {value.product.type === "TRUCK" && (
            <>
              <th className="require-value">
                {t("VEHICLE.PRODUCT.SUB_CATEGORY")}
              </th>
              <td>
                <Dropdown
                  id={"info-sub-category"}
                  labelId={"info-sub-category-label"}
                  label={t("VEHICLE.PRODUCT.SUB_CATEGORY")}
                  value={
                    value.truck_product !== undefined
                      ? value.truck_product.sub_category
                      : ""
                  }
                  options={
                    truckSubCategoryList[
                      value.truck_product !== undefined
                        ? value.truck_product.category
                        : ""
                    ]
                  }
                  dispatch={(_value: string) =>
                    value.truck_product !== undefined
                      ? dispatch({
                          ...value,
                          truck_product: {
                            ...value.truck_product,
                            sub_category: _value,
                          },
                        })
                      : () => {}
                  }
                  formControlClassName={classes.dropdownFormControl}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </td>
            </>
          )}
        </tr>
        {value.product.type === "TRUCK" && (
          <>
            {value.truck_product !== undefined &&
              value.truck_product.category !== "" && (
                <>
                  {(value.truck_product.category === "CargoTruck" ||
                    value.truck_product.category === "TopTruck" ||
                    value.truck_product.category === "WingbodyTruck" ||
                    value.truck_product.category === "DumpTruck" ||
                    value.truck_product.category === "Tractor" ||
                    value.truck_product.category === "Trailer" ||
                    value.truck_product.category === "TowTruck" ||
                    value.truck_product.category === "CampingCar") && (
                    <tr>
                      <th>{t("VEHICLE.PRODUCT.LOAD_WEIGHT")}</th>
                      <td colSpan={3}>
                        <Input
                          type="float"
                          className={classes.input}
                          label={t("VEHICLE.PRODUCT.LOAD_WEIGHT")}
                          value={
                            value.truck_product !== undefined
                              ? value.truck_product.load_weight
                              : ""
                          }
                          dispatch={(_value: number) => {
                            value.truck_product !== undefined
                              ? dispatch({
                                  ...value,
                                  truck_product: {
                                    ...value.truck_product,
                                    load_weight: _value === 0 ? null : _value,
                                  },
                                })
                              : dispatch({
                                  ...value,
                                  truck_product: {
                                    category: "",
                                    sub_category: "",
                                    axle: "",
                                    drive: "",
                                    tonner: 0,
                                    engine_type: "",
                                    cabin_type: "",
                                    wheelbase: "",
                                    load_weight: _value === 0 ? null : _value,
                                    tank_volume: null,
                                    crane_boom_height: null,
                                  },
                                });
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">t</InputAdornment>
                            ),
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {value.truck_product.category === "CraneTruck" && (
                    <tr>
                      <th className="require-value">
                        {t("VEHICLE.PRODUCT.CRANE_HEIGHT")}
                      </th>
                      <td colSpan={3}>
                        <Input
                          type="float"
                          className={classes.input}
                          label={t("VEHICLE.PRODUCT.CRANE_HEIGHT")}
                          value={
                            value.truck_product !== undefined
                              ? value.truck_product.crane_boom_height
                              : ""
                          }
                          dispatch={(_value: number) => {
                            value.truck_product !== undefined
                              ? dispatch({
                                  ...value,
                                  truck_product: {
                                    ...value.truck_product,
                                    crane_boom_height:
                                      _value === 0 ? null : _value,
                                  },
                                })
                              : dispatch({
                                  ...value,
                                  truck_product: {
                                    category: "",
                                    sub_category: "",
                                    axle: "",
                                    drive: "",
                                    tonner: 0,
                                    engine_type: "",
                                    cabin_type: "",
                                    wheelbase: "",
                                    load_weight: null,
                                    tank_volume: null,
                                    crane_boom_height:
                                      _value === 0 ? null : _value,
                                  },
                                });
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">m</InputAdornment>
                            ),
                          }}
                        />
                      </td>
                    </tr>
                  )}
                  {value.truck_product.category === "TankLorry" && (
                    <tr>
                      <th className="require-value">
                        {t("VEHICLE.PRODUCT.TANK_VOLUME")}
                      </th>
                      <td colSpan={3}>
                        <Input
                          type="float"
                          className={classes.input}
                          label={t("VEHICLE.PRODUCT.TANK_VOLUME")}
                          value={
                            value.truck_product !== undefined
                              ? value.truck_product.tank_volume
                              : ""
                          }
                          dispatch={(_value: number) => {
                            value.truck_product !== undefined
                              ? dispatch({
                                  ...value,
                                  truck_product: {
                                    ...value.truck_product,
                                    tank_volume: _value === 0 ? null : _value,
                                  },
                                })
                              : dispatch({
                                  ...value,
                                  truck_product: {
                                    category: "",
                                    sub_category: "",
                                    axle: "",
                                    drive: "",
                                    tonner: 0,
                                    engine_type: "",
                                    cabin_type: "",
                                    wheelbase: "",
                                    load_weight: null,
                                    tank_volume: _value === 0 ? null : _value,
                                    crane_boom_height: null,
                                  },
                                });
                          }}
                          inputProps={{
                            maxLength: 5,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">L</InputAdornment>
                            ),
                          }}
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
            <tr>
              <th className="require-value">
                {t("VEHICLE.PRODUCT.ENGINE_TYPE")}
              </th>
              <td>
                <Dropdown
                  id={"info-engine-type"}
                  labelId={"info-engin-type-label"}
                  label={t("VEHICLE.PRODUCT.ENGINE_TYPE")}
                  value={
                    value.truck_product !== undefined
                      ? value.truck_product.engine_type
                      : ""
                  }
                  options={truckEngineTypeList}
                  dispatch={(_value: string) =>
                    value.truck_product !== undefined
                      ? dispatch({
                          ...value,
                          truck_product: {
                            ...value.truck_product,
                            engine_type: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          truck_product: {
                            category: "",
                            sub_category: "",
                            axle: "",
                            drive: "",
                            tonner: 0,
                            engine_type: _value,
                            cabin_type: "",
                            wheelbase: "",
                            load_weight: null,
                            tank_volume: null,
                            crane_boom_height: null,
                          },
                        })
                  }
                  formControlClassName={classes.dropdownFormControl}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </td>
              <th className="require-value">
                {t("VEHICLE.PRODUCT.CABIN_TYPE")}
              </th>
              <td>
                <Dropdown
                  id={"info-cabin-type"}
                  labelId={"info-cabin-type-label"}
                  label={t("VEHICLE.PRODUCT.CABIN_TYPE")}
                  value={
                    value.truck_product !== undefined
                      ? value.truck_product.cabin_type
                      : ""
                  }
                  options={truckCabinTypeList}
                  dispatch={(_value: string) =>
                    value.truck_product !== undefined
                      ? dispatch({
                          ...value,
                          truck_product: {
                            ...value.truck_product,
                            cabin_type: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          truck_product: {
                            category: "",
                            sub_category: "",
                            axle: "",
                            drive: "",
                            tonner: 0,
                            engine_type: "",
                            cabin_type: _value,
                            wheelbase: "",
                            load_weight: null,
                            tank_volume: null,
                            crane_boom_height: null,
                          },
                        })
                  }
                  formControlClassName={classes.dropdownFormControl}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </td>
            </tr>
            {value.truck_product !== undefined && (
              <>
                {value.truck_product.sub_category !== "Tractor" &&
                  value.truck_product.sub_category !== "WreckCar" &&
                  value.truck_product.sub_category !== "CampingCar" && (
                    <tr>
                      <th className="require-value">
                        {t("VEHICLE.PRODUCT.WHEELBASE")}
                      </th>
                      <td colSpan={3}>
                        <Dropdown
                          id={"info-wheelbase"}
                          labelId={"info-wheelbase-label"}
                          label={t("VEHICLE.PRODUCT.WHEELBASE")}
                          value={
                            value.truck_product !== undefined
                              ? value.truck_product.wheelbase
                              : ""
                          }
                          options={truckWheelbaseList}
                          dispatch={(_value: string) =>
                            value.truck_product !== undefined
                              ? dispatch({
                                  ...value,
                                  truck_product: {
                                    ...value.truck_product,
                                    wheelbase: _value,
                                  },
                                })
                              : dispatch({
                                  ...value,
                                  truck_product: {
                                    category: "",
                                    sub_category: "",
                                    axle: "",
                                    drive: "",
                                    tonner: 0,
                                    engine_type: "",
                                    cabin_type: "",
                                    wheelbase: _value,
                                    load_weight: null,
                                    tank_volume: null,
                                    crane_boom_height: null,
                                  },
                                })
                          }
                          formControlClassName={classes.dropdownFormControl}
                          menuItemClassName={classes.dropdownMenuItem}
                        />
                      </td>
                    </tr>
                  )}
              </>
            )}
          </>
        )}
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.COLOR")}</th>
          <td>
            <Dropdown
              id={"info-color"}
              labelId={"info-color-label"}
              label={t("VEHICLE.PRODUCT.COLOR")}
              value={value.product.color}
              options={colorList}
              dispatch={(_value: string) =>
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    color: _value,
                  },
                })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
          <th className="require-value">{t("VEHICLE.PRODUCT.DOOR")}</th>
          <td>
            <Dropdown
              id={"info-door"}
              labelId={"info-door-label"}
              label={t("VEHICLE.PRODUCT.DOOR")}
              value={value.product.door === 0 ? "" : value.product.door}
              options={doorList}
              dispatch={(_value: string) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    door: Number(_value),
                  },
                });
              }}
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.STEERING")}</th>
          <td colSpan={3}>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={value.product.steering}
              onChange={(_e, _value: string) =>
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    steering: _value,
                  },
                })
              }
            >
              {steeringList.map((item, idx) => {
                return (
                  <Radio
                    key={idx}
                    label={<Markup content={item.label} />}
                    value={item.label}
                    formControlClassName={classes.formControl}
                    radioClassName={classes.radio}
                  />
                );
              })}
            </RadioGroup>
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.MILEAGE")}</th>
          <td colSpan={value.product.type === "" ? 3 : 1}>
            <Input
              type="integer"
              className={classes.input}
              label={t("VEHICLE.PRODUCT.MILEAGE")}
              value={value.product.mileage}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    mileage: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 9,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">km</InputAdornment>
                ),
              }}
            />
          </td>
          {value.product.type === "CAR" && (
            <>
              <th className="require-value">{t("VEHICLE.PRODUCT.SEATS")}</th>
              <td>
                <Input
                  type="integer"
                  className={classes.input}
                  label={t("VEHICLE.PRODUCT.SEATS")}
                  value={
                    value.car_product !== undefined
                      ? value.car_product.seats
                      : 0
                  }
                  dispatch={(_value: number) => {
                    value.car_product !== undefined
                      ? dispatch({
                          ...value,
                          car_product: {
                            ...value.car_product,
                            seats: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          car_product: {
                            category: "",
                            seats: _value,
                            drive: "",
                          },
                        });
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t("VEHICLE.PRODUCT.SEATS_END")}
                      </InputAdornment>
                    ),
                  }}
                />
              </td>
            </>
          )}
          {value.product.type === "TRUCK" && (
            <>
              <th className="require-value">{t("VEHICLE.PRODUCT.TONNER")}</th>
              <td>
                <Input
                  type="float"
                  className={classes.input}
                  label={t("VEHICLE.PRODUCT.TONNER")}
                  value={
                    value.truck_product !== undefined
                      ? value.truck_product.tonner
                      : ""
                  }
                  dispatch={(_value: number) => {
                    value.truck_product !== undefined
                      ? dispatch({
                          ...value,
                          truck_product: {
                            ...value.truck_product,
                            tonner: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          truck_product: {
                            category: "",
                            sub_category: "",
                            axle: "",
                            drive: "",
                            tonner: _value,
                            engine_type: "",
                            cabin_type: "",
                            wheelbase: "",
                            load_weight: null,
                            tank_volume: null,
                            crane_boom_height: null,
                          },
                        });
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">t</InputAdornment>
                    ),
                  }}
                />
              </td>
            </>
          )}
          {value.product.type === "BUS" && (
            <>
              <th className="require-value">{t("VEHICLE.PRODUCT.SEATS")}</th>
              <td>
                <Input
                  type="integer"
                  className={classes.input}
                  label={t("VEHICLE.PRODUCT.SEATS")}
                  value={
                    value.bus_product !== undefined
                      ? value.bus_product.seats
                      : 0
                  }
                  dispatch={(_value: number) => {
                    value.bus_product !== undefined
                      ? dispatch({
                          ...value,
                          bus_product: {
                            ...value.bus_product,
                            seats: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          bus_product: {
                            category: "",
                            seats: _value,
                            cargo: null,
                            total_weight: null,
                          },
                        });
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t("VEHICLE.PRODUCT.SEATS_END")}
                      </InputAdornment>
                    ),
                  }}
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.MISSION")}</th>
          <td colSpan={value.product.type === "" ? 3 : 1}>
            <Dropdown
              id={"info-mission"}
              labelId={"info-mission-label"}
              label={t("VEHICLE.PRODUCT.MISSION")}
              value={value.product.mission}
              options={missionList}
              dispatch={(_value: string) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    mission: _value,
                  },
                });
              }}
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
          {value.product.type === "CAR" && (
            <>
              <th className="require-value">{t("VEHICLE.PRODUCT.DRIVE")}</th>
              <td>
                <Dropdown
                  id={"info-car-drive"}
                  labelId={"info-car-drive-label"}
                  label={t("VEHICLE.PRODUCT.DRIVE")}
                  value={
                    value.car_product !== undefined
                      ? value.car_product.drive
                      : ""
                  }
                  options={carDriveList}
                  dispatch={(_value: string) => {
                    value.car_product !== undefined
                      ? dispatch({
                          ...value,
                          car_product: {
                            ...value.car_product,
                            drive: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          car_product: {
                            category: "",
                            seats: 0,
                            drive: _value,
                          },
                        });
                  }}
                  formControlClassName={classes.dropdownFormControl}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </td>
            </>
          )}
          {value.product.type === "TRUCK" && (
            <>
              <th className="require-value">{t("VEHICLE.PRODUCT.DRIVE")}</th>
              <td>
                <Dropdown
                  id={"info-truck-drive"}
                  labelId={"info-truck-drive-label"}
                  label={t("VEHICLE.PRODUCT.DRIVE")}
                  value={
                    value.truck_product !== undefined
                      ? value.truck_product.drive
                      : ""
                  }
                  options={truckDriveList}
                  dispatch={(_value: string) => {
                    value.truck_product !== undefined
                      ? dispatch({
                          ...value,
                          truck_product: {
                            ...value.truck_product,
                            drive: _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          truck_product: {
                            category: "",
                            sub_category: "",
                            axle: "",
                            drive: _value,
                            tonner: 0,
                            engine_type: "",
                            cabin_type: "",
                            wheelbase: "",
                            load_weight: null,
                            tank_volume: null,
                            crane_boom_height: null,
                          },
                        });
                  }}
                  formControlClassName={classes.dropdownFormControl}
                  menuItemClassName={classes.dropdownMenuItem}
                />
              </td>
            </>
          )}
          {value.product.type === "BUS" && (
            <>
              <th>{t("VEHICLE.PRODUCT.TOTAL_WEIGHT")}</th>
              <td>
                <Input
                  type="float"
                  className={classes.input}
                  label={t("VEHICLE.PRODUCT.TOTAL_WEIGHT")}
                  value={
                    value.bus_product !== undefined
                      ? value.bus_product.total_weight
                      : ""
                  }
                  dispatch={(_value: number) => {
                    value.bus_product !== undefined
                      ? dispatch({
                          ...value,
                          bus_product: {
                            ...value.bus_product,
                            total_weight: _value === 0 ? null : _value,
                          },
                        })
                      : dispatch({
                          ...value,
                          bus_product: {
                            category: "",
                            seats: 0,
                            cargo: null,
                            total_weight: _value === 0 ? null : _value,
                          },
                        });
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">t</InputAdornment>
                    ),
                  }}
                />
              </td>
            </>
          )}
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.FUEL")}</th>
          <td colSpan={3}>
            <Dropdown
              id={"info-fuel"}
              labelId={"info-fuel-label"}
              label={t("VEHICLE.PRODUCT.FUEL")}
              value={value.product.fuel}
              options={fuelList}
              dispatch={(_value: string) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    fuel: _value,
                  },
                });
              }}
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.EXHAUST")}</th>
          <td>
            <Input
              type="integer"
              className={classes.input}
              label={t("VEHICLE.PRODUCT.EXHAUST")}
              value={value.product.exhaust}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    exhaust: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">cc</InputAdornment>
                ),
              }}
            />
          </td>
          <th
            className={
              value.product.type === "BUS" ? "require-value" : undefined
            }
          >
            {t("VEHICLE.PRODUCT.HORSE_POWER")}
          </th>
          <td>
            <Input
              type="integer"
              className={classes.input}
              label={t("VEHICLE.PRODUCT.HORSE_POWER")}
              value={value.product.horse_power}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    horse_power: _value === 0 ? null : _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">HP</InputAdornment>
                ),
              }}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.PRODUCT.SIZE")}</th>
          <td colSpan={3} className="size">
            <Input
              type="integer"
              className={classes.input}
              label={t("VEHICLE.PRODUCT.LENGTH")}
              value={value.product.length}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    length: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
            />
            <Input
              type="integer"
              className={classes.input}
              label={t("VEHICLE.PRODUCT.WIDTH")}
              value={value.product.width}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    width: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
            />
            <Input
              type="integer"
              className={classes.input}
              label={t("VEHICLE.PRODUCT.HEIGHT")}
              value={value.product.height}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  product: {
                    ...value.product,
                    height: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default InfoContainer;
