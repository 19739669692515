import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { RadioGroup, Theme, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TaskSharpIcon from "@mui/icons-material/TaskSharp";
import { Radio } from "@/common/components";
import {
  FrontSide,
  RearSide,
  LeftSide,
  RightSide,
  StatusMark,
} from "./inspection";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  container: {
    width: 1075,
    margin: "45px auto",
    userSelect: "none",
  },
  title: {
    padding: "0px 15px 15px",
    marginBottom: 10,
    borderBottom: "1px solid " + theme.palette.text.primary,
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    "& > svg": {
      fontSize: "3rem",
      fill: theme.palette.primary.main,
      marginRight: 15,
    },
    "& > div": {
      "& > .MuiTypography-body1 > span": {
        fontSize: "1.375rem",
        fontWeight: "bold",
        "& > span": {
          fontSize: "1.15rem",
        },
      },
      "& > .MuiTypography-body2": {
        fontSize: "0.925rem",
        lineHeight: "1.35",
        "& > span": {
          color: theme.palette.primary.main,
          fontSize: "0.85rem",
        },
      },
      "& > .MuiTypography-caption": {
        display: "block",
        color: theme.palette.error.main,
        fontSize: "0.875rem",
        fontWeight: "bold",
        userSelect: "none",
      },
    },
  },
  inspectionCheck: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 25,
    marginBottom: 15,
    "& > .MuiTypography-body1": {
      color: theme.palette.primary.main,
      fontSize: "1.35rem",
      fontWeight: "bold",
      userSelect: "none",
      marginBottom: 5,
    },
  },
  inspectionNotice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > .MuiTypography-body1": {
      color: theme.palette.error.main,
      fontSize: "1.25rem",
      fontWeight: "bold",
      userSelect: "none",
    },
    "& > .MuiTypography-body2": {
      color: theme.palette.error.main,
      fontSize: "0.95rem",
      fontWeight: "bold",
      userSelect: "none",
    },
  },
  inspection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "15px 0",
    "& > div": {
      margin: "15px 35px",
    },
  },
  inspectionMark: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 25,
    "& > div": {
      display: "flex",
      alignItems: "center",
      margin: "0 7.5px",
      "& > .MuiTypography-root": {
        fontSize: "0.925rem",
        fontWeight: 600,
        marginLeft: 5,
      },
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "20%",
      "& > p": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        margin: 0,
        marginBottom: 5,
        fontSize: "1.125rem",
        fontWeight: 500,
        "& > .MuiSvgIcon-root": {
          position: "absolute",
          right: -20,
          width: "1.15rem",
          height: "1.15rem",
          marginLeft: 2.5,
          color: colors.gray[6],
          "&:hover": {
            color: colors.blue[5],
          },
        },
      },
    },
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-around",
    "& > label:first-child": {
      marginRight: "0.15rem",
    },
    "& > label:last-child": {
      marginLeft: "0.15rem",
    },
  },
  formControl: {
    cursor: "pointer",
    "& > .MuiTypography-root": {
      fontSize: "0.875rem",
      fontWeight: "bold",
      userSelect: "none",
    },
    "&:hover.error": {
      "& > .MuiRadio-root > span > svg": {
        color: theme.palette.error.main + "!important",
      },
      "& > .MuiTypography-root": {
        color: theme.palette.error.main + "!important",
      },
    },
  },
  radio: {
    padding: 3,
    "& > span > svg": {
      fontSize: "1.25rem",
    },
    "&.Mui-checked.error": {
      "& > span > svg": {
        color: theme.palette.error.main + "!important",
      },
      "& ~ .MuiTypography-root": {
        color: theme.palette.error.main + "!important",
      },
    },
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const InspectionContainer = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  const [isCheck, setIsCheck] = useState(false);

  const corrosionList: { [key: string]: string } = {
    N: t("OPTION.NONE_0"),
    L: t("OPTION.LESS_25"),
    M: t("OPTION.LESS_50"),
    H: t("OPTION.LESS_75"),
    F: t("OPTION.EXIST_100"),
  };
  const ynList: { [key: string]: string } = {
    Y: t("OPTION.EXIST"),
    N: t("OPTION.NOT_EXIST"),
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <TaskSharpIcon />
        <div>
          <Typography variant="body1">
            <Markup content={t("REGISTER.DETAIL.INSPECTION.TITLE")} />
          </Typography>
          <Typography variant="body2">
            {t("REGISTER.DETAIL.INSPECTION.CONTENT_1")}
          </Typography>
          <Typography variant="caption">
            {t("REGISTER.DETAIL.INSPECTION.CONTENT_2")}
          </Typography>
        </div>
      </div>
      <div className={classes.inspectionCheck}>
        <Typography variant="body1">
          {t("REGISTER.DETAIL.INSPECTION.IS_CHECK")}
        </Typography>
        <RadioGroup
          row
          className={classes.radioGroup}
          value={isCheck ? "TRUE" : "FALSE"}
          onChange={(_e, _value: string) => {
            if (_value === "TRUE") {
              setIsCheck(true);
              dispatch({
                ...value,
                inspection: {
                  corrosion: "L",
                  hole: "N",
                  crack: "N",
                  accident_history: "",
                  simple_repair: "",
                  bonnet: "",
                  front_fender_l: "",
                  front_fender_r: "",
                  door_f_l: "",
                  door_f_r: "",
                  door_r_l: "",
                  door_r_r: "",
                  trunk_lid: "",
                  radiator_support: "",
                  rear_fender_l: "",
                  rear_fender_r: "",
                  roof_panel: "",
                  rocker_panel_l: "",
                  rocker_panel_r: "",
                  front_panel: "",
                  cross_member: "",
                  inside_panel_l: "",
                  inside_panel_r: "",
                  trunk_floor: "",
                  rear_panel: "",
                  side_member_f_l: "",
                  side_member_f_r: "",
                  side_member_r_l: "",
                  side_member_r_r: "",
                  wheelhouse_f_l: "",
                  wheelhouse_f_r: "",
                  wheelhouse_r_l: "",
                  wheelhouse_r_r: "",
                  pillar_a_l: "",
                  pillar_a_r: "",
                  pillar_b_l: "",
                  pillar_b_r: "",
                  pillar_c_l: "",
                  pillar_c_r: "",
                  package_tray: "",
                  dash_panel: "",
                  floor_panel: "",
                },
              });
            } else {
              setIsCheck(false);
              dispatch({
                ...value,
                inspection: {
                  corrosion: "L",
                  hole: "N",
                  crack: "N",
                  accident_history: "",
                  simple_repair: "",
                  bonnet: "",
                  front_fender_l: "",
                  front_fender_r: "",
                  door_f_l: "",
                  door_f_r: "",
                  door_r_l: "",
                  door_r_r: "",
                  trunk_lid: "",
                  radiator_support: "",
                  rear_fender_l: "",
                  rear_fender_r: "",
                  roof_panel: "",
                  rocker_panel_l: "",
                  rocker_panel_r: "",
                  front_panel: "",
                  cross_member: "",
                  inside_panel_l: "",
                  inside_panel_r: "",
                  trunk_floor: "",
                  rear_panel: "",
                  side_member_f_l: "",
                  side_member_f_r: "",
                  side_member_r_l: "",
                  side_member_r_r: "",
                  wheelhouse_f_l: "",
                  wheelhouse_f_r: "",
                  wheelhouse_r_l: "",
                  wheelhouse_r_r: "",
                  pillar_a_l: "",
                  pillar_a_r: "",
                  pillar_b_l: "",
                  pillar_b_r: "",
                  pillar_c_l: "",
                  pillar_c_r: "",
                  package_tray: "",
                  dash_panel: "",
                  floor_panel: "",
                },
              });
            }
          }}
        >
          <Radio
            label={t("REGISTER.DETAIL.INSPECTION.CHECK")}
            value={"TRUE"}
            formControlClassName={classes.formControl}
            radioClassName={classes.radio}
          />
          <Radio
            label={t("REGISTER.DETAIL.INSPECTION.NOT_CHECK")}
            value={"FALSE"}
            formControlClassName={`${classes.formControl} error`}
            radioClassName={`${classes.radio} error`}
          />
        </RadioGroup>
      </div>
      {isCheck ? (
        <>
          <div className={classes.inspectionNotice}>
            <Typography variant="body1">
              {t("REGISTER.DETAIL.INSPECTION.NOTICE_1")}
            </Typography>
            <Typography variant="body2">
              {t("REGISTER.DETAIL.INSPECTION.NOTICE_2")}
            </Typography>
          </div>
          <div className={classes.inspection}>
            <LeftSide value={value} dispatch={dispatch} />
            <FrontSide value={value} dispatch={dispatch} />
            <RearSide value={value} dispatch={dispatch} />
            <RightSide value={value} dispatch={dispatch} />
          </div>
          <div className={classes.inspectionMark}>
            <div>
              <StatusMark status="X" />
              <Typography>{t("VEHICLE.INSPECTION.EXCHANGE")}</Typography>
            </div>
            <div>
              <StatusMark status="W" />
              <Typography>{t("VEHICLE.INSPECTION.PLATE_WELDING")}</Typography>
            </div>
            <div>
              <StatusMark status="C" />
              <Typography>{t("VEHICLE.INSPECTION.CORROSION")}</Typography>
            </div>
            <div>
              <StatusMark status="A" />
              <Typography>{t("VEHICLE.INSPECTION.SCRATCH")}</Typography>
            </div>
            <div>
              <StatusMark status="U" />
              <Typography>{t("VEHICLE.INSPECTION.UNEVEN")}</Typography>
            </div>
            <div>
              <StatusMark status="T" />
              <Typography>{t("VEHICLE.INSPECTION.DAMAGE")}</Typography>
            </div>
          </div>
          <div className={classes.content}>
            <div>
              <p>
                {t("VEHICLE.INSPECTION.ACCIDENT_HISTORY")}
                <Tooltip
                  arrow
                  disableFocusListener
                  title={t("MSG.ACCIDENT_HISTORY")}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.accident_history}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      accident_history: _value,
                    },
                  });
                }}
              >
                {Object.entries(ynList).map(([key, value], idx: number) => {
                  return (
                    <Radio
                      key={idx}
                      label={value}
                      value={key}
                      formControlClassName={
                        key === "Y"
                          ? `${classes.formControl} error`
                          : `${classes.formControl}`
                      }
                      radioClassName={
                        key === "Y"
                          ? `${classes.radio} error`
                          : `${classes.radio}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </div>
            <div>
              <p>
                {t("VEHICLE.INSPECTION.SIMPLE_REPAIR")}
                <Tooltip
                  arrow
                  disableFocusListener
                  title={t("MSG.SIMPLE_REPAIR")}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.simple_repair}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      simple_repair: _value,
                    },
                  });
                }}
              >
                {Object.entries(ynList).map(([key, value], idx: number) => {
                  return (
                    <Radio
                      key={idx}
                      label={value}
                      value={key}
                      formControlClassName={
                        key === "Y"
                          ? `${classes.formControl} error`
                          : `${classes.formControl}`
                      }
                      radioClassName={
                        key === "Y"
                          ? `${classes.radio} error`
                          : `${classes.radio}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </div>
            <div>
              <p>{t("VEHICLE.INSPECTION.IS_CORROSION")}</p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.corrosion}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      corrosion: _value,
                    },
                  });
                }}
              >
                {Object.entries(corrosionList).map(
                  ([key, value], idx: number) => {
                    return (
                      <Radio
                        key={idx}
                        label={value}
                        value={key}
                        formControlClassName={
                          key === "F" || key === "H"
                            ? `${classes.formControl} error`
                            : `${classes.formControl}`
                        }
                        radioClassName={
                          key === "F" || key === "H"
                            ? `${classes.radio} error`
                            : `${classes.radio}`
                        }
                      />
                    );
                  }
                )}
              </RadioGroup>
            </div>
            <div>
              <p>{t("VEHICLE.INSPECTION.IS_HOLE")}</p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.hole}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      hole: _value,
                    },
                  });
                }}
              >
                {Object.entries(ynList).map(([key, value], idx: number) => {
                  return (
                    <Radio
                      key={idx}
                      label={value}
                      value={key}
                      formControlClassName={
                        key === "Y"
                          ? `${classes.formControl} error`
                          : `${classes.formControl}`
                      }
                      radioClassName={
                        key === "Y"
                          ? `${classes.radio} error`
                          : `${classes.radio}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </div>
            <div>
              <p>{t("VEHICLE.INSPECTION.IS_CRACK")}</p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.crack}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      crack: _value,
                    },
                  });
                }}
              >
                {Object.entries(ynList).map(([key, value], idx: number) => {
                  return (
                    <Radio
                      key={idx}
                      label={value}
                      value={key}
                      formControlClassName={
                        key === "Y"
                          ? `${classes.formControl} error`
                          : `${classes.formControl}`
                      }
                      radioClassName={
                        key === "Y"
                          ? `${classes.radio} error`
                          : `${classes.radio}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={classes.inspectionNotice}
            style={{
              marginBottom: 20,
            }}
          >
            <Typography variant="body1">
              {t("REGISTER.DETAIL.INSPECTION.NOTICE_3")}
            </Typography>
            <Typography variant="body2">
              {t("REGISTER.DETAIL.INSPECTION.NOTICE_4")}
            </Typography>
          </div>
          <div className={classes.content}>
            <div>
              <p>{t("VEHICLE.INSPECTION.IS_CORROSION")}</p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.corrosion}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      corrosion: _value,
                    },
                  });
                }}
              >
                {Object.entries(corrosionList).map(
                  ([key, value], idx: number) => {
                    return (
                      <Radio
                        key={idx}
                        label={value}
                        value={key}
                        formControlClassName={
                          key === "F" || key === "H"
                            ? `${classes.formControl} error`
                            : `${classes.formControl}`
                        }
                        radioClassName={
                          key === "F" || key === "H"
                            ? `${classes.radio} error`
                            : `${classes.radio}`
                        }
                      />
                    );
                  }
                )}
              </RadioGroup>
            </div>
            <div>
              <p>{t("VEHICLE.INSPECTION.IS_HOLE")}</p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.hole}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      hole: _value,
                    },
                  });
                }}
              >
                {Object.entries(ynList).map(([key, value], idx: number) => {
                  return (
                    <Radio
                      key={idx}
                      label={value}
                      value={key}
                      formControlClassName={
                        key === "Y"
                          ? `${classes.formControl} error`
                          : `${classes.formControl}`
                      }
                      radioClassName={
                        key === "Y"
                          ? `${classes.radio} error`
                          : `${classes.radio}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </div>
            <div>
              <p>{t("VEHICLE.INSPECTION.IS_CRACK")}</p>
              <RadioGroup
                row
                className={classes.radioGroup}
                value={value.inspection.crack}
                onChange={(_e, _value: string) => {
                  dispatch({
                    ...value,
                    inspection: {
                      ...value.inspection,
                      crack: _value,
                    },
                  });
                }}
              >
                {Object.entries(ynList).map(([key, value], idx: number) => {
                  return (
                    <Radio
                      key={idx}
                      label={value}
                      value={key}
                      formControlClassName={
                        key === "Y"
                          ? `${classes.formControl} error`
                          : `${classes.formControl}`
                      }
                      radioClassName={
                        key === "Y"
                          ? `${classes.radio} error`
                          : `${classes.radio}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InspectionContainer;
