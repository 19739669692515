/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { Markup } from "interweave";
import { cloneDeep } from "lodash";
// Redux
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
import {
  loadingOn,
  loadingOff,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
// Components
import { Theme, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RegisterVINContainer from "./RegisterVINContainer";
import RegisterTypeContainer from "./RegisterTypeContainer";
import RegisterMediaContainer from "./RegisterMediaContainer";
import RegisterInfoContainer from "./RegisterInfoContainer";
import RegisterDetailContainer from "./RegisterDetailContainer";
import RegisterSalesContainer from "./RegisterSalesContainer";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
import {
  getImageFile,
  isNull,
  isNotNull,
  parseJWT,
  resizeImage,
} from "@/common/lib/common";
// API
import { onError } from "@/common/lib/api/common";
import { getGroupInfo } from "@/common/lib/api/group";
import {
  getVehicleInfo,
  patchVehicleInfo,
  postVehicleInfo,
} from "@/common/lib/api/vehicle";
import { usePrevious } from "@/common/lib/hooks";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
    "& > div.content": {
      display: "flex",
      alignItems: "center",
      "&::before": {
        display: "inline-block",
        content: "''",
        width: 15,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
      },
      "& > div": {
        "& > .MuiTypography-body1": {
          fontSize: "1.15rem",
          userSelect: "none",
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.975rem",
          userSelect: "none",
          "& > span > span": {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
        },
        "& > .MuiTypography-caption": {
          display: "block",
          color: theme.palette.error.main,
          fontSize: "0.875rem",
          fontWeight: "bold",
          userSelect: "none",
        },
      },
    },
  },
  table: {
    width: 1473,
    tableLayout: "fixed",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    marginBottom: 45,
    "& td": {
      fontSize: "0.925rem",
      fontWeight: 500,
      textAlign: "center",
      padding: 8.5,
      border: "1px solid " + theme.palette.divider,
      "& > .MuiTypography-root": {
        fontSize: "0.95rem",
        fontWeight: "bold",
        "&::before": {
          display: "inline-block",
          content: "''",
          width: 10,
          height: 10,
          marginRight: 7.5,
          borderRadius: "50%",
        },
      },
      "&.active": {
        color: colors.gray[1],
        backgroundColor: theme.palette.primary.main,
      },
      "&:not(.active):hover": {
        cursor: "pointer",
        color: theme.palette.primary.main,
        backgroundColor: colors.gray[1],
      },
    },
  },
  content: {
    width: 1473,
    padding: "0 45px",
  },
});

const RegisterContainer = () => {
  const params = useParams();
  const classes = useStyles(styles);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const _dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState<number>(0);
  const [productCount, setProductCount] = useState(0);
  const [limitCount, setLimitCount] = useState(0);

  const [initData, setInitData] = useState<VehicleData>({
    product: {
      uid: "",
      type: "",
      year: 0,
      maker: "",
      model: "",
      detail_model: "",
      mileage: 0,
      vin: "",
      is_restamp: false,
      mission: "",
      steering: "LHD",
      exhaust: 0,
      horse_power: null,
      fuel: "",
      width: 0,
      height: 0,
      length: 0,
      color: "",
      door: 0,
    },
    media: {
      image: [],
      youtube: "",
      dropbox: "",
    },
    sales: {
      price: 0,
      discount: null,
      discount_created: null,
      discount_expired: null,
      status: "WAIT",
      location_country: "",
      location_region: "",
      advert_text: "",
      advert_explain: "",
      memo: "",
    },
    condition: {
      engine: 0,
      mission: 0,
      brake: 0,
      steering: 0,
      exhaust: 0,
      is_4WD: "",
      is_audio: "",
      is_heater: "",
      is_aircon: "",
      is_window: "",
    },
    inspection: {
      corrosion: "L",
      hole: "N",
      crack: "N",
      accident_history: "",
      simple_repair: "",
      bonnet: "",
      front_fender_l: "",
      front_fender_r: "",
      door_f_l: "",
      door_f_r: "",
      door_r_l: "",
      door_r_r: "",
      trunk_lid: "",
      radiator_support: "",
      rear_fender_l: "",
      rear_fender_r: "",
      roof_panel: "",
      rocker_panel_l: "",
      rocker_panel_r: "",
      front_panel: "",
      cross_member: "",
      inside_panel_l: "",
      inside_panel_r: "",
      trunk_floor: "",
      rear_panel: "",
      side_member_f_l: "",
      side_member_f_r: "",
      side_member_r_l: "",
      side_member_r_r: "",
      wheelhouse_f_l: "",
      wheelhouse_f_r: "",
      wheelhouse_r_l: "",
      wheelhouse_r_r: "",
      pillar_a_l: "",
      pillar_a_r: "",
      pillar_b_l: "",
      pillar_b_r: "",
      pillar_c_l: "",
      pillar_c_r: "",
      package_tray: "",
      dash_panel: "",
      floor_panel: "",
    },
    special_point: undefined,
    car_product: undefined,
    truck_product: undefined,
    bus_product: undefined,
    car_option: undefined,
    truck_option: undefined,
    bus_option: undefined,
  });
  const [data, setData] = useState<VehicleData>({
    product: {
      uid: "",
      type: "",
      year: 0,
      maker: "",
      model: "",
      detail_model: "",
      mileage: 0,
      vin: "",
      is_restamp: false,
      mission: "",
      steering: "LHD",
      exhaust: 0,
      horse_power: null,
      fuel: "",
      width: 0,
      height: 0,
      length: 0,
      color: "",
      door: 0,
    },
    media: {
      image: [],
      youtube: "",
      dropbox: "",
    },
    sales: {
      price: 0,
      discount: null,
      discount_created: null,
      discount_expired: null,
      status: "WAIT",
      location_country: "",
      location_region: "",
      advert_text: "",
      advert_explain: "",
      memo: "",
    },
    condition: {
      engine: 0,
      mission: 0,
      brake: 0,
      steering: 0,
      exhaust: 0,
      is_4WD: "",
      is_audio: "",
      is_heater: "",
      is_aircon: "",
      is_window: "",
    },
    inspection: {
      corrosion: "L",
      hole: "N",
      crack: "N",
      accident_history: "",
      simple_repair: "",
      bonnet: "",
      front_fender_l: "",
      front_fender_r: "",
      door_f_l: "",
      door_f_r: "",
      door_r_l: "",
      door_r_r: "",
      trunk_lid: "",
      radiator_support: "",
      rear_fender_l: "",
      rear_fender_r: "",
      roof_panel: "",
      rocker_panel_l: "",
      rocker_panel_r: "",
      front_panel: "",
      cross_member: "",
      inside_panel_l: "",
      inside_panel_r: "",
      trunk_floor: "",
      rear_panel: "",
      side_member_f_l: "",
      side_member_f_r: "",
      side_member_r_l: "",
      side_member_r_r: "",
      wheelhouse_f_l: "",
      wheelhouse_f_r: "",
      wheelhouse_r_l: "",
      wheelhouse_r_r: "",
      pillar_a_l: "",
      pillar_a_r: "",
      pillar_b_l: "",
      pillar_b_r: "",
      pillar_c_l: "",
      pillar_c_r: "",
      package_tray: "",
      dash_panel: "",
      floor_panel: "",
    },
    special_point: undefined,
    car_product: undefined,
    truck_product: undefined,
    bus_product: undefined,
    car_option: undefined,
    truck_option: undefined,
    bus_option: undefined,
  });
  const [isCheckInspection, setIsCheckInspection] = useState<boolean>(false);

  const prevData = usePrevious(data);

  const groupInfoQuery = useQuery(
    "getGroupInfo",
    () => {
      const tokenData = parseJWT(loginToken);
      _dispatch(loadingOn());
      return getGroupInfo(tokenData["guid"]);
    },
    {
      retry: false,
      onSettled: () => {
        _dispatch(loadingOff());
      },
    }
  );
  const vehicleInfoQuery = useQuery(
    "vehicleInfo",
    () => {
      _dispatch(loadingOn());
      if (params.uid !== undefined) {
        return getVehicleInfo(params.uid);
      } else {
        throw new Error("Check if Vehicle's UID exists.");
      }
    },
    {
      staleTime: 1000,
      enabled: isNotNull(params.uid),
      onSettled: () => {
        _dispatch(loadingOff());
      },
    }
  );

  const postVehicleMutation = useMutation(postVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_POST_VEHICLE_INFO"), {
        variant: "success",
      });
      navigate("/vehicle/manage");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      _dispatch(loadingOff());
    },
  });
  const patchVehicleMutation = useMutation(patchVehicleInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_VEHICLE_INFO"), {
        variant: "success",
      });
      navigate("/vehicle/manage");
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      _dispatch(loadingOff());
    },
  });

  const checkStep = (value: number) => {
    if (value === 1) {
      if (isNotNull(data.product.vin)) {
        return true;
      }
    } else if (value === 2) {
      if (isNotNull(data.product.type)) {
        return true;
      }
    } else if (value === 3) {
      if (data.media.image.length > 0) {
        return true;
      }
    } else if (value === 4) {
      if (data.product.type === "CAR") {
        if (data.car_product === undefined) {
          return false;
        }
        // seats : 0
        // category, drive : ""
        if (
          data.car_product.seats === 0 ||
          isNull(data.car_product.category) ||
          isNull(data.car_product.drive)
        ) {
          return false;
        }
      } else if (data.product.type === "TRUCK") {
        if (data.truck_product === undefined) {
          return false;
        }
        // tonner : 0
        // category, sub_category, axle, engine_type, cabin_type, drive : ""
        if (
          data.truck_product.tonner === 0 ||
          isNull(data.truck_product.category) ||
          isNull(data.truck_product.sub_category) ||
          isNull(data.truck_product.engine_type) ||
          isNull(data.truck_product.cabin_type) ||
          isNull(data.truck_product.drive)
        ) {
          return false;
        }

        if (
          data.truck_product.sub_category !== "Tractor" &&
          data.truck_product.sub_category !== "WreckCar" &&
          data.truck_product.sub_category !== "CampingCar" &&
          isNull(data.truck_product.wheelbase)
        ) {
          return false;
        }
      } else if (data.product.type === "BUS") {
        if (data.bus_product === undefined) {
          return false;
        }
        // seats : 0
        // horse_power : null
        // category : ""
        if (
          data.bus_product.seats === 0 ||
          isNull(data.product.horse_power) ||
          isNull(data.bus_product.category)
        ) {
          return false;
        }
      }
      // year, mileage, width, height, length, door : 0
      // maker, model, detail_model, mission, exhaust, fuel, color : ""
      if (
        data.product.year === 0 ||
        data.product.mileage === 0 ||
        data.product.exhaust === 0 ||
        data.product.width === 0 ||
        data.product.height === 0 ||
        data.product.length === 0 ||
        data.product.door === 0 ||
        isNull(data.product.maker) ||
        isNull(data.product.model) ||
        isNull(data.product.detail_model) ||
        isNull(data.product.mission) ||
        isNull(data.product.fuel) ||
        isNull(data.product.color)
      ) {
        return false;
      }
      return true;
    } else if (value === 5) {
      // 스페셜 포인트
      if (data.special_point !== undefined) {
        if (
          (isNotNull(data.special_point.image) &&
            isNull(data.special_point.type)) ||
          (isNull(data.special_point.image) &&
            isNotNull(data.special_point.type))
        ) {
          return false;
        }
      }
      // 컨디션 리포트
      if (
        data.condition.engine === 0 ||
        data.condition.mission === 0 ||
        data.condition.steering === 0 ||
        data.condition.brake === 0 ||
        data.condition.exhaust === 0 ||
        isNull(data.condition.is_heater) ||
        isNull(data.condition.is_aircon) ||
        isNull(data.condition.is_audio) ||
        isNull(data.condition.is_window) ||
        isNull(data.condition.is_4WD)
      ) {
        return false;
      }
      // 성능상태점검기록부
      if (
        isNull(data.inspection.corrosion) ||
        isNull(data.inspection.hole) ||
        isNull(data.inspection.crack)
      ) {
        return false;
      }

      return true;
    } else if (value === 6) {
      if (data.sales.price === 0 || isNull(data.sales.location_region)) {
        return false;
      }
      return true;
    }
    return false;
  };
  const onTabClick = (value: number) => {
    if (step >= value) {
      setStep(value);
      window.scrollTo(0, 250);
    } else {
      if (value === 0) {
        setStep(value);
        window.scrollTo(0, 250);
      } else if (value === 1) {
        if (params.uid === undefined && productCount >= limitCount) {
          enqueueSnackbar(t("MSG.EXCEED_VEHICLE_COUNT"), { variant: "error" });
          return;
        }

        if (checkStep(value)) {
          setStep(value);
        } else {
          alert(t("REGISTER.STEP.INPUT_VIN"));
        }
        window.scrollTo(0, 250);
      } else if (value === 2) {
        if (step >= 2) {
          setStep(value);
        } else {
          if (checkStep(value)) {
            setStep(value);
          } else {
            alert(t("REGISTER.STEP.INPUT_TYPE"));
          }
        }
        window.scrollTo(0, 250);
      } else if (value === 3) {
        if (step >= 3) {
          setStep(value);
        } else {
          if (checkStep(value)) {
            setStep(value);
          } else {
            alert(t("REGISTER.STEP.INPUT_IMAGE"));
          }
        }
        window.scrollTo(0, 250);
      } else if (value === 4) {
        if (step >= 4) {
          setStep(value);
        } else {
          if (checkStep(value)) {
            setStep(value);
          } else {
            alert(t("REGISTER.STEP.INPUT_INFO"));
          }
        }
        window.scrollTo(0, 250);
      } else if (value === 5) {
        if (step >= 5) {
          setStep(value);
        } else {
          if (checkStep(value)) {
            setStep(value);
          } else {
            alert(t("REGISTER.STEP.INPUT_DETAIL"));
            window.scrollTo(0, 250);
          }
        }
      } else if (value === 6) {
        if (checkStep(value)) {
          handleOnSubmit();
        } else {
          alert(t("REGISTER.STEP.INPUT_SALES"));
        }
      }
    }
  };

  const handleOnHome = () => {
    navigate("/index");
  };
  const handleOnSubmit = () => {
    let errorTabIdx = 0;
    for (let idx = 1; idx <= 6; idx++) {
      if (!checkStep(idx)) {
        errorTabIdx = idx;
        break;
      }
    }

    if (errorTabIdx === 0) {
      _dispatch(
        setConfirmText({
          title: t("DIALOG.TITLE.CHECK_REGISTER"),
          content: t("DIALOG.CONTENT.CHECK_REGISTER"),
          onApply: async () => {
            _dispatch(loadingOn());
            if (params.uid !== undefined) {
              const sendData: { [key: string]: any } = {};
              const commonItemList = [
                "product",
                "sales",
                "condition",
                "inspection",
                "special_point",
              ];
              const typeItemList: string[] = [];
              if (data.product.type === "CAR") {
                typeItemList.push(...["car_product", "car_option"]);
              } else if (data.product.type === "TRUCK") {
                typeItemList.push(...["truck_product", "truck_option"]);
              } else if (data.product.type === "BUS") {
                typeItemList.push(...["bus_product", "bus_option"]);
              }

              sendData["media"] = {};
              if (initData["media"]["youtube"] !== data["media"]["youtube"]) {
                sendData["media"]["youtube"] = data["media"]["youtube"];
              }
              if (initData["media"]["dropbox"] !== data["media"]["dropbox"]) {
                sendData["media"]["dropbox"] = data["media"]["dropbox"];
              }
              if (Object.keys(sendData["media"]).length === 0) {
                delete sendData["media"];
              }

              let isChangeImage = false;
              if (
                initData["media"]["image"].length !==
                data["media"]["image"].length
              ) {
                isChangeImage = true;
              } else {
                for (
                  let idx = 0;
                  idx < initData["media"]["image"].length;
                  idx++
                ) {
                  if (
                    initData["media"]["image"][idx].name !==
                    data["media"]["image"][idx].name
                  ) {
                    isChangeImage = true;
                    break;
                  }
                }
              }
              if (isChangeImage) {
                sendData["image"] = [];
                const appendFile = data.media.image.filter(
                  (file) => !initData.media.image.includes(file)
                );
                for (const image of data.media.image) {
                  if (appendFile.includes(image)) {
                    const fileData = await resizeImage(
                      image,
                      1280,
                      960,
                      100,
                      0,
                      "blob"
                    );
                    const blobData = fileData as BlobPart;
                    sendData["image"].push(
                      new File([blobData], uuidv4() + ".jpg", {
                        type: "image/jpg",
                      })
                    );
                  } else {
                    sendData["image"].push(image);
                  }
                }
              }

              commonItemList.forEach((item: string) => {
                if (initData[item] === null && data[item] !== null) {
                  sendData[item] = data[item];
                } else if (initData[item] !== null && data[item] === null) {
                  sendData[item] = null;
                } else {
                  sendData[item] = {};
                  if (
                    Object.keys(initData).includes(item) &&
                    Object.keys(data).includes(item)
                  ) {
                    Object.keys(initData[item]).forEach((key) => {
                      if (Object.keys(data[item]).includes(key)) {
                        if (initData[item][key] !== data[item][key]) {
                          sendData[item][key] = data[item][key];
                        }
                      }
                    });
                  }
                  if (Object.keys(sendData[item]).length === 0) {
                    delete sendData[item];
                  }
                }
              });
              typeItemList.forEach((item: string) => {
                if (initData[item] === null && data[item] !== null) {
                  sendData[item] = data[item];
                } else if (initData[item] !== null && data[item] === null) {
                  sendData[item] = null;
                } else {
                  if (
                    Object.keys(initData).includes(item) &&
                    Object.keys(data).includes(item)
                  ) {
                    sendData[item] = {};
                    Object.keys(initData[item]).forEach((key) => {
                      if (Object.keys(data[item]).includes(key)) {
                        if (initData[item][key] !== data[item][key]) {
                          sendData[item][key] = data[item][key];
                        }
                      }
                    });
                    if (Object.keys(sendData[item]).length === 0) {
                      delete sendData[item];
                    }
                  }
                }
              });

              if (Object.keys(sendData).length > 0) {
                patchVehicleMutation.mutate({
                  uid: params.uid,
                  data: sendData,
                });
              } else {
                _dispatch(loadingOff());
                enqueueSnackbar(t("MSG.NO_DATA_MODIFY"), { variant: "info" });
              }
            } else {
              const sendData: { [key: string]: any } = {};
              sendData["product"] = Object.assign({}, data.product);
              sendData["media"] = {
                youtube: data.media.youtube,
                dropbox: data.media.dropbox,
              };
              sendData["image"] = [];
              for (const image of data.media.image) {
                const fileData = await resizeImage(
                  image,
                  1280,
                  960,
                  100,
                  0,
                  "blob"
                );
                const blobData = fileData as BlobPart;
                sendData["image"].push(
                  new File([blobData], uuidv4() + ".jpg", { type: "image/jpg" })
                );
              }
              sendData["sales"] = Object.assign({}, data.sales);
              sendData["condition"] = Object.assign({}, data.condition);
              sendData["inspection"] = Object.assign({}, data.inspection);
              sendData["special_point"] = isNotNull(data.special_point)
                ? data.special_point
                : null;
              if (data.product.type === "CAR") {
                sendData["car_product"] = isNotNull(data.car_product)
                  ? data.car_product
                  : null;
                sendData["car_option"] = isNotNull(data.car_option)
                  ? data.car_option
                  : {
                      normal_sunroof: false,
                      panoramic_sunroof: false,
                      hid_headlamp: false,
                      led_headlamp: false,
                      alloy_wheel: false,
                      steering_remocon: false,
                      power_steering: false,
                      heat_steering: false,
                      ecm: false,
                      afm: false,
                      power_window: false,
                      power_tailgate: false,
                      power_doorlock: false,
                      paddle_shift: false,
                      rear_spoiler: false,
                      roof_rack: false,
                      leather_seat: false,
                      heat_front_seat: false,
                      heat_rear_seat: false,
                      power_driver_seat: false,
                      power_passenger_seat: false,
                      power_rear_seat: false,
                      memory_driver_seat: false,
                      memory_passenger_seat: false,
                      ventilated_driver_seat: false,
                      ventilated_passenger_seat: false,
                      ventilated_rear_seat: false,
                      smart_key: false,
                      button_boot: false,
                      aircon: false,
                      auto_aircon: false,
                      navigation: false,
                      radio: false,
                      cd_player: false,
                      dvd_player: false,
                      usb_terminal: false,
                      bluetooth: false,
                      cruise_control: false,
                      adaptive_cruise_control: false,
                      hud: false,
                      wireless_doorlock: false,
                      blackbox: false,
                      driver_airbag: false,
                      passenger_airbag: false,
                      side_airbag: false,
                      curtain_airbag: false,
                      knee_airbag: false,
                      abs: false,
                      aeb: false,
                      ldws: false,
                      tcs: false,
                      esp: false,
                      tpms: false,
                      bsd: false,
                      lsd: false,
                      ecs: false,
                      front_camera: false,
                      rear_camera: false,
                      around_view_camera: false,
                      front_sensor: false,
                      rear_sensor: false,
                      spare_tire: false,
                    };
              } else if (data.product.type === "TRUCK") {
                sendData["truck_product"] = isNotNull(data.truck_product)
                  ? data.truck_product
                  : null;
                sendData["truck_option"] = isNotNull(data.truck_option)
                  ? data.truck_option
                  : {
                      sunroof: false,
                      hid_headlamp: false,
                      led_headlamp: false,
                      alloy_wheel: false,
                      steering_remocon: false,
                      power_steering: false,
                      heat_steering: false,
                      ecm: false,
                      afm: false,
                      greaser: false,
                      pto: false,
                      tachometer: false,
                      roof_spoiler: false,
                      power_liftgate: false,
                      power_captilt: false,
                      leather_seat: false,
                      heat_seat: false,
                      power_seat: false,
                      memory_seat: false,
                      ventilated_seat: false,
                      smart_key: false,
                      button_boot: false,
                      aircon: false,
                      auto_aircon: false,
                      navigation: false,
                      radio: false,
                      cd_player: false,
                      dvd_player: false,
                      usb_terminal: false,
                      bluetooth: false,
                      tv: false,
                      hud: false,
                      wireless_doorlock: false,
                      blackbox: false,
                      refrigerator: false,
                      purifier: false,
                      pre_heater: false,
                      pre_aircon: false,
                      heat_bed: false,
                      driver_airbag: false,
                      passenger_airbag: false,
                      side_airbag: false,
                      curtain_airbag: false,
                      knee_airbag: false,
                      abs: false,
                      aeb: false,
                      ldws: false,
                      tcs: false,
                      esp: false,
                      tpms: false,
                      bsd: false,
                      lsd: false,
                      ecs: false,
                      front_camera: false,
                      rear_camera: false,
                      around_view_camera: false,
                      front_sensor: false,
                      rear_sensor: false,
                      spare_tire: false,
                      retarder: false,
                      ehs: false,
                      air_brake: false,
                    };
              } else if (data.product.type === "BUS") {
                sendData["bus_product"] = isNotNull(data.bus_product)
                  ? data.bus_product
                  : null;
                sendData["bus_option"] = isNotNull(data.bus_option)
                  ? data.bus_option
                  : {
                      sunroof: false,
                      hid_headlamp: false,
                      led_headlamp: false,
                      alloy_wheel: false,
                      steering_remocon: false,
                      power_steering: false,
                      heat_steering: false,
                      ecm: false,
                      afm: false,
                      greaser: false,
                      pto: false,
                      tachometer: false,
                      roof_spoiler: false,
                      leather_seat: false,
                      heat_seat: false,
                      power_seat: false,
                      memory_seat: false,
                      ventilated_seat: false,
                      smart_key: false,
                      button_boot: false,
                      aircon: false,
                      auto_aircon: false,
                      navigation: false,
                      radio: false,
                      cd_player: false,
                      dvd_player: false,
                      usb_terminal: false,
                      bluetooth: false,
                      tv: false,
                      hud: false,
                      wireless_doorlock: false,
                      blackbox: false,
                      refrigerator: false,
                      purifier: false,
                      pre_heater: false,
                      pre_aircon: false,
                      karaoke: false,
                      amplifier: false,
                      driver_airbag: false,
                      passenger_airbag: false,
                      side_airbag: false,
                      curtain_airbag: false,
                      knee_airbag: false,
                      abs: false,
                      aeb: false,
                      ldws: false,
                      tcs: false,
                      esp: false,
                      tpms: false,
                      bsd: false,
                      lsd: false,
                      ecs: false,
                      front_camera: false,
                      rear_camera: false,
                      around_view_camera: false,
                      front_sensor: false,
                      rear_sensor: false,
                      spare_tire: false,
                      retarder: false,
                      ehs: false,
                      air_brake: false,
                    };
              }
              postVehicleMutation.mutate({ data: sendData });
            }
          },
          onClose: () => {},
        })
      );
    } else {
      enqueueSnackbar(t("REGISTER.STEP.INPUT_REQUIRED"), {
        variant: "error",
      });
      setStep(errorTabIdx);
      window.scrollTo(0, 250);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (groupInfoQuery.isSuccess) {
      const result = groupInfoQuery.data.data;

      setProductCount(result["count_product"]);
      setLimitCount(result["limit_product"]);
    }
  }, [groupInfoQuery.isLoading]);

  useEffect(() => {
    const getVehicleData = async () => {
      if (vehicleInfoQuery.isSuccess) {
        const result = vehicleInfoQuery.data.data;
        const recvData: { [key: string]: any } = {};
        if (Object.keys(result).includes("product")) {
          recvData["product"] = {};
          Object.keys(data.product).forEach((key) => {
            if (Object.keys(result["product"]).includes(key)) {
              recvData["product"][key] = result["product"][key];
            }
          });
        }
        if (Object.keys(result).includes("media")) {
          recvData["media"] = {};
          recvData["media"]["image"] = [];
          recvData["media"]["youtube"] = result["media"]["youtube"];
          recvData["media"]["dropbox"] = result["media"]["dropbox"];

          const getImageFileList = async (fileURLList: string[]) => {
            await Promise.all(
              fileURLList.map((fileURL: string) => {
                return getImageFile(fileURL);
              })
            ).then((imageList) => {
              recvData["media"]["image"] = imageList;
            });
          };
          await getImageFileList(result["media"]["image"]);
        }
        if (Object.keys(result).includes("sales")) {
          recvData["sales"] = {};
          Object.keys(data.sales).forEach((key) => {
            if (Object.keys(result["sales"]).includes(key)) {
              recvData["sales"][key] = result["sales"][key];
            }
          });
        }
        if (Object.keys(result).includes("condition")) {
          recvData["condition"] = {};
          Object.keys(data.condition).forEach((key) => {
            if (Object.keys(result["condition"]).includes(key)) {
              recvData["condition"][key] = result["condition"][key];
            }
          });
        }
        if (Object.keys(result).includes("inspection")) {
          recvData["inspection"] = {};
          Object.keys(data.inspection).forEach((key) => {
            if (Object.keys(result["inspection"]).includes(key)) {
              recvData["inspection"][key] = result["inspection"][key];
            }
          });
        }
        if (Object.keys(result).includes("special_point")) {
          recvData["special_point"] = {};
          result["special_point"].forEach((item: any) => {
            if (
              item.type !== "ACCIDENT_FREE" &&
              item.type !== "ACCIDENT_FREE_FULL"
            ) {
              recvData["special_point"]["type"] = item.type;
              recvData["special_point"]["image"] = null;
              for (let idx = 0; idx < result.media.image.length; idx++) {
                if (result.media.image[idx] === item.image) {
                  recvData["special_point"]["image"] = idx;
                  break;
                }
              }
            }
          });
        }
        if (Object.keys(result).includes("car_product")) {
          recvData["car_product"] = {};
          ["category", "seats", "drive"].forEach((key) => {
            if (Object.keys(result["car_product"]).includes(key)) {
              recvData["car_product"][key] = result["car_product"][key];
            }
          });
        }
        if (Object.keys(result).includes("truck_product")) {
          recvData["truck_product"] = {};
          [
            "category",
            "sub_category",
            "axle",
            "drive",
            "tonner",
            "engine_type",
            "cabin_type",
            "wheelbase",
            "load_weight",
            "tank_volume",
            "crane_boom_height",
          ].forEach((key) => {
            if (Object.keys(result["truck_product"]).includes(key)) {
              recvData["truck_product"][key] = result["truck_product"][key];
            }
          });
        }
        if (Object.keys(result).includes("bus_product")) {
          recvData["bus_product"] = {};
          ["category", "seats", "cargo", "total_weight"].forEach((key) => {
            if (Object.keys(result["bus_product"]).includes(key)) {
              recvData["bus_product"][key] = result["bus_product"][key];
            }
          });
        }
        if (Object.keys(result).includes("car_option")) {
          recvData["car_option"] = {};
          const carInitOption = {
            normal_sunroof: false,
            panoramic_sunroof: false,
            hid_headlamp: false,
            led_headlamp: false,
            alloy_wheel: false,
            steering_remocon: false,
            power_steering: false,
            heat_steering: false,
            ecm: false,
            afm: false,
            power_window: false,
            power_tailgate: false,
            power_doorlock: false,
            paddle_shift: false,
            rear_spoiler: false,
            roof_rack: false,
            leather_seat: false,
            heat_front_seat: false,
            heat_rear_seat: false,
            power_driver_seat: false,
            power_passenger_seat: false,
            power_rear_seat: false,
            memory_driver_seat: false,
            memory_passenger_seat: false,
            ventilated_driver_seat: false,
            ventilated_passenger_seat: false,
            ventilated_rear_seat: false,
            smart_key: false,
            button_boot: false,
            aircon: false,
            auto_aircon: false,
            navigation: false,
            radio: false,
            cd_player: false,
            dvd_player: false,
            usb_terminal: false,
            bluetooth: false,
            cruise_control: false,
            adaptive_cruise_control: false,
            hud: false,
            wireless_doorlock: false,
            blackbox: false,
            driver_airbag: false,
            passenger_airbag: false,
            side_airbag: false,
            curtain_airbag: false,
            knee_airbag: false,
            abs: false,
            aeb: false,
            ldws: false,
            tcs: false,
            esp: false,
            tpms: false,
            bsd: false,
            lsd: false,
            ecs: false,
            front_camera: false,
            rear_camera: false,
            around_view_camera: false,
            front_sensor: false,
            rear_sensor: false,
            spare_tire: false,
          };
          Object.keys(carInitOption).forEach((key) => {
            if (Object.keys(result["car_option"]).includes(key)) {
              recvData["car_option"][key] = result["car_option"][key];
            }
          });
        }
        if (Object.keys(result).includes("truck_option")) {
          recvData["truck_option"] = {};
          const truckInitOption = {
            sunroof: false,
            hid_headlamp: false,
            led_headlamp: false,
            alloy_wheel: false,
            steering_remocon: false,
            power_steering: false,
            heat_steering: false,
            ecm: false,
            afm: false,
            greaser: false,
            pto: false,
            tachometer: false,
            roof_spoiler: false,
            power_liftgate: false,
            power_captilt: false,
            leather_seat: false,
            heat_seat: false,
            power_seat: false,
            memory_seat: false,
            ventilated_seat: false,
            smart_key: false,
            button_boot: false,
            aircon: false,
            auto_aircon: false,
            navigation: false,
            radio: false,
            cd_player: false,
            dvd_player: false,
            usb_terminal: false,
            bluetooth: false,
            tv: false,
            hud: false,
            wireless_doorlock: false,
            blackbox: false,
            refrigerator: false,
            purifier: false,
            pre_heater: false,
            pre_aircon: false,
            heat_bed: false,
            driver_airbag: false,
            passenger_airbag: false,
            side_airbag: false,
            curtain_airbag: false,
            knee_airbag: false,
            abs: false,
            aeb: false,
            ldws: false,
            tcs: false,
            esp: false,
            tpms: false,
            bsd: false,
            lsd: false,
            ecs: false,
            front_camera: false,
            rear_camera: false,
            around_view_camera: false,
            front_sensor: false,
            rear_sensor: false,
            spare_tire: false,
            retarder: false,
            ehs: false,
            air_brake: false,
          };
          Object.keys(truckInitOption).forEach((key) => {
            if (Object.keys(result["truck_option"]).includes(key)) {
              recvData["truck_option"][key] = result["truck_option"][key];
            }
          });
        }
        if (Object.keys(result).includes("bus_option")) {
          recvData["bus_option"] = {};
          const busInitOption = {
            sunroof: false,
            hid_headlamp: false,
            led_headlamp: false,
            alloy_wheel: false,
            steering_remocon: false,
            power_steering: false,
            heat_steering: false,
            ecm: false,
            afm: false,
            greaser: false,
            pto: false,
            tachometer: false,
            roof_spoiler: false,
            leather_seat: false,
            heat_seat: false,
            power_seat: false,
            memory_seat: false,
            ventilated_seat: false,
            smart_key: false,
            button_boot: false,
            aircon: false,
            auto_aircon: false,
            navigation: false,
            radio: false,
            cd_player: false,
            dvd_player: false,
            usb_terminal: false,
            bluetooth: false,
            tv: false,
            hud: false,
            wireless_doorlock: false,
            blackbox: false,
            refrigerator: false,
            purifier: false,
            pre_heater: false,
            pre_aircon: false,
            karaoke: false,
            amplifier: false,
            driver_airbag: false,
            passenger_airbag: false,
            side_airbag: false,
            curtain_airbag: false,
            knee_airbag: false,
            abs: false,
            aeb: false,
            ldws: false,
            tcs: false,
            esp: false,
            tpms: false,
            bsd: false,
            lsd: false,
            ecs: false,
            front_camera: false,
            rear_camera: false,
            around_view_camera: false,
            front_sensor: false,
            rear_sensor: false,
            spare_tire: false,
            retarder: false,
            ehs: false,
            air_brake: false,
          };
          Object.keys(busInitOption).forEach((key) => {
            if (Object.keys(result["bus_option"]).includes(key)) {
              recvData["bus_option"][key] = result["bus_option"][key];
            }
          });
        }

        setInitData(cloneDeep(recvData as VehicleData));
        setData(cloneDeep(recvData as VehicleData));
      }
    };
    getVehicleData();
  }, [vehicleInfoQuery.isLoading]);

  useEffect(() => {
    if (prevData !== undefined) {
      // 스페셜포인트가 설정인 이미지를 삭제하였거나 삭제로 인하여 순서가 변경된 경우
      const removeFile = prevData.media.image.filter(
        (file) => !data.media.image.includes(file)
      );
      const removeFileIndex: number[] = [];
      removeFile.forEach((file) => {
        for (let idx = 0; idx < prevData.media.image.length; idx++) {
          if (file === prevData.media.image[idx]) {
            removeFileIndex.push(idx);
            return;
          }
        }
      });
      if (
        data.special_point !== undefined &&
        data.special_point.image !== null &&
        isNotNull(data.special_point.image)
      ) {
        if (removeFileIndex.includes(data.special_point.image)) {
          // 스페셜 포인트에 설정된 이미지가 삭제된 경우
          setData({
            ...data,
            special_point: undefined,
          });
        } else {
          // 삭제로 인하여 순서가 변경된 경우
          let currentIdx = data.special_point.image;
          for (let idx = 0; idx < removeFileIndex.length; idx++) {
            if (removeFileIndex[idx] < data.special_point.image) {
              currentIdx -= 1;
            }
          }
          setData({
            ...data,
            special_point: {
              ...data.special_point,
              image: currentIdx,
            },
          });
        }
      }
      // 2. 스페셜포인트가 설정인 이미지 순서가 변경된 경우
      const changeFile = prevData.media.image.filter(
        (file, idx) => file !== data.media.image[idx]
      );
      const changeFileIndex: number[] = [];
      changeFile.forEach((file) => {
        for (let idx = 0; idx < prevData.media.image.length; idx++) {
          if (file === prevData.media.image[idx]) {
            changeFileIndex.push(idx);
            return;
          }
        }
      });
      if (
        data.special_point !== undefined &&
        data.special_point.image !== null &&
        isNotNull(data.special_point.image)
      ) {
        if (changeFileIndex.includes(data.special_point.image)) {
          const prevFile = prevData.media.image[data.special_point.image];
          for (let idx = 0; idx < data.media.image.length; idx++) {
            if (prevFile === data.media.image[idx]) {
              setData({
                ...data,
                special_point: {
                  ...data.special_point,
                  image: idx,
                },
              });
            }
          }
        }
      }
    }
  }, [data.media.image]);

  useEffect(() => {
    setIsCheckInspection(false);
  }, [data.inspection]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <AddCircleOutlineIcon />
          <Typography>{t("REGISTER.TITLE")}</Typography>
        </div>
        <div className="content">
          <div>
            <Typography variant="body1">
              <Markup content={t("REGISTER.CONTENT_1")} />
            </Typography>
            <Typography variant="body2">
              <Markup content={t("REGISTER.CONTENT_2")} />
            </Typography>
            <Typography variant="caption">
              <Markup content={t("REGISTER.CONTENT_3")} />
            </Typography>
          </div>
        </div>
      </div>
      <table className={classes.table}>
        <colgroup>
          <col style={{ width: "16.66%" }} />
          <col style={{ width: "16.67%" }} />
          <col style={{ width: "16.66%" }} />
          <col style={{ width: "16.66%" }} />
          <col style={{ width: "16.67%" }} />
          <col style={{ width: "16.66%" }} />
        </colgroup>
        <thead></thead>
        <tbody>
          <tr>
            <td
              className={step === 0 ? "active" : ""}
              onClick={() => onTabClick(0)}
            >
              <Markup content={t("REGISTER.STEP.STEP0")} />
            </td>
            <td
              className={step === 1 ? "active" : ""}
              onClick={() => onTabClick(1)}
            >
              <Markup content={t("REGISTER.STEP.STEP1")} />
            </td>
            <td
              className={step === 2 ? "active" : ""}
              onClick={() => onTabClick(2)}
            >
              <Markup content={t("REGISTER.STEP.STEP2")} />
            </td>
            <td
              className={step === 3 ? "active" : ""}
              onClick={() => onTabClick(3)}
            >
              <Markup content={t("REGISTER.STEP.STEP3")} />
            </td>
            <td
              className={step === 4 ? "active" : ""}
              onClick={() => onTabClick(4)}
            >
              <Markup content={t("REGISTER.STEP.STEP4")} />
            </td>
            <td
              className={step === 5 ? "active" : ""}
              onClick={() => onTabClick(5)}
            >
              <Markup content={t("REGISTER.STEP.STEP5")} />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={classes.content}>
        {step === 0 && (
          <RegisterVINContainer
            value={data}
            dispatch={setData}
            productCount={productCount}
            limitCount={limitCount}
            prev={() => {
              handleOnHome();
            }}
            next={() => {
              onTabClick(1);
            }}
          />
        )}
        {step === 1 && (
          <RegisterTypeContainer
            value={data}
            dispatch={setData}
            prev={() => {
              onTabClick(0);
            }}
            next={() => {
              onTabClick(2);
            }}
          />
        )}
        {step === 2 && (
          <RegisterMediaContainer
            value={data}
            dispatch={setData}
            prev={() => {
              onTabClick(1);
            }}
            next={() => {
              onTabClick(3);
            }}
          />
        )}
        {step === 3 && (
          <RegisterInfoContainer
            value={data}
            dispatch={setData}
            prev={() => {
              onTabClick(2);
            }}
            next={() => {
              onTabClick(4);
            }}
          />
        )}
        {step === 4 && (
          <RegisterDetailContainer
            value={data}
            dispatch={setData}
            prev={() => {
              onTabClick(3);
            }}
            next={() => {
              if (!isCheckInspection) {
                _dispatch(
                  setConfirmText({
                    title: t("DIALOG.TITLE.CHECK_INSPECTION"),
                    content: t("DIALOG.CONTENT.CHECK_INSPECTION"),
                    onApply: () => {
                      setIsCheckInspection(true);
                      onTabClick(5);
                    },
                    onClose: () => {
                      window.scrollTo(0, 1250);
                    },
                  })
                );
              } else {
                onTabClick(5);
              }
            }}
          />
        )}
        {step === 5 && (
          <RegisterSalesContainer
            value={data}
            dispatch={setData}
            prev={() => {
              onTabClick(4);
            }}
            next={() => {
              onTabClick(6);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RegisterContainer;
