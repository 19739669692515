/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, RefObject, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
// Components
import { Theme, Typography } from "@mui/material";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";

const styles = (theme: Theme) => ({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
    userSelect: "none",
    marginBottom: 10,
    "& tr": {
      height: 50,
    },
    "& td": {
      border: "1px solid " + theme.palette.divider,
      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > .MuiTypography-root": {
          fontSize: "0.95rem",
          fontWeight: "bold",
          marginRight: 3,
        },
        "& > span": {
          fontSize: "0.875rem",
        },
        "&::before": {
          display: "inline-block",
          content: "''",
          width: 10,
          height: 10,
          marginRight: 7.5,
          borderRadius: "50%",
        },
      },
      "&:not(.disabled)": {
        "&:hover": {
          cursor: "pointer",
          backgroundColor: colors.gray[1],
        },
      },
      "&.on": {
        color: "white",
        "& > div::before": {
          backgroundColor: "white !important",
        },
        "&:hover": {
          boxShadow: "inset 0 0 100px 100px rgba(255, 255, 255, 0.1)",
        },
      },
      "&.ALL": {
        "& > div::before": {
          backgroundColor: theme.palette.text.primary,
        },
        "&.on": {
          backgroundColor: theme.palette.text.primary,
        },
      },
      "&.WAIT": {
        "& > div::before": {
          backgroundColor: colors.gray[8],
        },
        "&.on": {
          backgroundColor: colors.gray[8],
        },
      },
      "&.ON": {
        "& > div::before": {
          backgroundColor: colors.blue[7],
        },
        "&.on": {
          backgroundColor: colors.blue[7],
        },
      },
      "&.REZ": {
        "& > div::before": {
          backgroundColor: colors.cyan[6],
        },
        "&.on": {
          backgroundColor: colors.cyan[6],
        },
      },
      "&.DONE": {
        "& > div::before": {
          backgroundColor: colors.teal[7],
        },
        "&.on": {
          backgroundColor: colors.teal[7],
        },
      },
      "&.NOT": {
        "& > div::before": {
          backgroundColor: colors.red[7],
        },
        "&.on": {
          backgroundColor: colors.red[7],
        },
      },
    },
  },
});

interface propType {
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
  statusData: { [key: string]: any };
  tableRef: RefObject<HTMLTableElement>;
}

const ManageStatusContainer = ({
  status,
  setStatus,
  statusData,
  tableRef,
}: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <table ref={tableRef} className={classes.table}>
      <colgroup>
        <col style={{ width: "16.66%" }} />
        <col style={{ width: "16.67%" }} />
        <col style={{ width: "16.66%" }} />
        <col style={{ width: "16.66%" }} />
        <col style={{ width: "16.67%" }} />
        <col style={{ width: "16.66%" }} />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          {["ALL", "WAIT", "ON", "REZ", "DONE", "NOT"].map(
            (item: string, idx: number) => {
              return (
                <td
                  key={idx}
                  className={classnames(
                    item,
                    { on: status === item },
                    { disabled: statusData[item] === 0 }
                  )}
                  onClick={() => {
                    if (statusData[item] !== 0) {
                      setStatus(item);
                    }
                  }}
                >
                  <div>
                    <Typography>{t("VEHICLE.STATUS." + item)}</Typography>
                    <span>({statusData[item]})</span>
                  </div>
                </td>
              );
            }
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default ManageStatusContainer;
