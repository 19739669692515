/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
// Redux
import { useTypedSelector, useTypedDispatch } from "@/common/lib/redux/store";
import { refetchOff } from "@/common/lib/redux/actions/vehicleManageAction";
import { loadingOn, loadingOff } from "@/common/lib/redux/actions/commonAction";
// Components
import ManageSearchContainer from "./ManageSearchContainer";
import ManageStatusContainer from "./ManageStatusContainer";
import ManageDataContainer from "./ManageDataContainer";
import ManagePagination from "./ManagePagination";
import { Theme, Typography } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { getVehicleCount, getVehicleList } from "@/common/lib/api/vehicle";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
    "& > div.content": {
      display: "flex",
      alignItems: "center",
      "&::before": {
        display: "inline-block",
        content: "''",
        width: 15,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
      },
      "& > div": {
        "& > .MuiTypography-body1": {
          fontSize: "1.15rem",
          userSelect: "none",
        },
        "& > .MuiTypography-body2": {
          fontSize: "0.975rem",
          userSelect: "none",
          "& > span > span": {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
        },
        "& > .MuiTypography-caption": {
          display: "block",
          color: theme.palette.error.main,
          fontSize: "0.875rem",
          fontWeight: "bold",
          userSelect: "none",
        },
      },
    },
  },
  content: {
    width: 1473,
  },
});

const ManageContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { refetch } = useTypedSelector((state) => state.vehicleManage);
  const dispatch = useTypedDispatch();

  const statusRef = useRef<HTMLTableElement>(null);

  const [data, setData] = useState<VehicleData[]>([]);
  const [countData, setCountData] = useState<{ [key: string]: any }>({
    total: 0,
    status: {},
    type: {},
    maker: {},
    mission: {},
    fuel: {},
    color: {},
  });
  const [statusData, setStatusData] = useState<{ [key: string]: number }>({
    ALL: 0,
    WAIT: 0,
    ON: 0,
    REZ: 0,
    DONE: 0,
    NOT: 0,
  });
  const [params, setParams] = useState<{ [key: string]: any }>({});

  const [init, setInit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("ALL");

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [pageDot, setPageDot] = useState<number>(1);

  const vehicleStatusCountQuery = useQuery(
    "vehicleCount",
    () => {
      return getVehicleCount(params);
    },
    {
      retry: false,
      onSuccess: (response) => {
        setStatusData({
          ALL: response.data.count["total"],
          WAIT:
            response.data.count["status"] !== undefined &&
            response.data.count["status"]["WAIT"] !== undefined
              ? response.data.count["status"]["WAIT"]
              : 0,
          ON:
            response.data.count["status"] !== undefined &&
            response.data.count["status"]["ON"] !== undefined
              ? response.data.count["status"]["ON"]
              : 0,
          REZ:
            response.data.count["status"] !== undefined &&
            response.data.count["status"]["REZ"] !== undefined
              ? response.data.count["status"]["REZ"]
              : 0,
          DONE:
            response.data.count["status"] !== undefined &&
            response.data.count["status"]["DONE"] !== undefined
              ? response.data.count["status"]["DONE"]
              : 0,
          NOT:
            response.data.count["status"] !== undefined &&
            response.data.count["status"]["NOT"] !== undefined
              ? response.data.count["status"]["NOT"]
              : 0,
        });
      },
    }
  );
  const vehicleListQuery = useQuery(
    "vehicleList",
    () => {
      dispatch(loadingOn());
      let listParams = { ...params };
      if (status !== "ALL") {
        listParams = {
          ...params,
          status: status,
        };
      }
      return getVehicleList(pageDot, 300, "-created", listParams);
    },
    {
      retry: false,
      onSuccess: (response) => {
        setData(response.data.results);
        setCountData(response.data.count);
        setInit(true);
      },
      onSettled: () => {
        dispatch(refetchOff());
        dispatch(loadingOff());
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [status]);

  useEffect(() => {
    setPageDot(Math.floor((page - 1) / (300 / pageSize)) + 1);
  }, [page]);

  useEffect(() => {
    if (init) {
      window.scrollTo({
        top: statusRef.current !== null ? statusRef.current.offsetTop - 30 : 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [page, pageSize, params]);

  useEffect(() => {
    if (init) {
      vehicleStatusCountQuery.refetch();
    }
  }, [refetch, pageDot, pageSize, params]);

  useEffect(() => {
    if (init) {
      vehicleListQuery.refetch();
    }
  }, [refetch, status, pageDot, pageSize, params]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <ViewListIcon />
          <Typography>{t("MANAGE.TITLE")}</Typography>
        </div>
      </div>
      <div className={classes.content}>
        <ManageSearchContainer params={params} setParams={setParams} />
        <ManageStatusContainer
          status={status}
          setStatus={setStatus}
          statusData={statusData}
          tableRef={statusRef}
        />
        <ManageDataContainer
          data={data}
          setData={setData}
          page={page}
          pageSize={pageSize}
          totalCount={countData["total"]}
          status={status}
          params={params}
        />
        <ManagePagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalCount={countData["total"]}
        />
      </div>
    </div>
  );
};

export default ManageContainer;
