import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import langEn from "./lang.en.json";
import langKo from "./lang.ko.json";

const resources = {
  "ko-KR": {
    label: "KOR",
    translation: langKo,
  },
  "en-US": {
    label: "ENG",
    translation: langEn,
  },
};

i18n.on("languageChanged", () => {
  if (i18n.isInitialized) {
    window.location.reload();
  }
});

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: "en-US",
    load: "currentOnly",
    debug: process.env.NODE_ENV === "development",
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
      ],
    },
  });

export default i18n;
