/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { Markup } from "interweave";
import { cloneDeep } from "lodash";
// Redux
import {
  loadingOn,
  loadingOff,
  setConfirmText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Paper, IconButton, Theme, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CollectionsIcon from "@mui/icons-material/Collections";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Button, Image, Input, NationAutocomplete } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import {
  checkGroupDuplicate,
  getGroupInfo,
  patchGroupInfo,
} from "@/common/lib/api/group";
// Libs
import {
  isNull,
  isNotNull,
  resizeImage,
  getTokenData,
} from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    padding: "45px 75px",
  },
  title: {
    minWidth: 900,
    marginBottom: 30,
    "& > div.title": {
      display: "flex",
      alignItems: "center",
      marginBottom: 10,
      "& > svg": {
        fontSize: "2.35rem",
        color: theme.palette.primary.main,
        marginRight: 10,
      },
      "& > p": {
        fontSize: "2.35rem",
        fontWeight: 500,
        color: theme.palette.primary.main,
        userSelect: "none",
      },
    },
    "& > div.content": {
      display: "flex",
      alignItems: "center",
      "&::before": {
        display: "inline-block",
        content: "''",
        width: 15,
        height: 80,
        backgroundColor: theme.palette.primary.main,
        marginRight: 15,
      },
      "& > ul": {
        margin: 0,
        padding: 0,
        userSelect: "none",
        listStyleType: "none",
        "& > li": {
          fontSize: 15,
          marginBottom: 3,
          "&::marker": {
            fontSize: 12,
          },
          "& > span > span": {
            color: theme.palette.error.main,
            fontWeight: "bold",
            padding: "0 3px",
          },
          "&.listStyleNone": {
            fontSize: "0.8rem",
            listStyleType: "none",
            paddingLeft: 5,
          },
        },
      },
    },
  },
  content: {
    display: "flex",
    width: 1473,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: 1473,
    height: 600,
    "& > .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 500,
      height: 200,
      margin: "0 auto",
      padding: 45,
      "& > div.title": {
        display: "flex",
        marginBottom: 15,
        "& > svg": {
          fontSize: "3.5rem",
          color: theme.palette.primary.main,
          marginRight: 10,
        },
        "& > div": {
          userSelect: "none",
          "& > .MuiTypography-body1": {
            fontSize: "1.3rem",
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
          "& > .MuiTypography-body2": {
            fontSize: "0.825rem",
          },
        },
      },
      "& > div.content": {
        "& > button": {
          margin: "0 10px",
        },
      },
    },
  },
  imageContent: {
    width: 235,
    marginLeft: 45,
    marginRight: 45,
    "& > div": {
      position: "relative",
      width: "100%",
      "& > .MuiSvgIcon-root": {
        width: "100%",
        height: "100%",
        color: "rgba(0, 0, 0, 0.54)",
      },
      "& > img": {
        width: "100%",
        aspectRatio: "1",
        borderRadius: "50%",
        objectFit: "cover",
        padding: 20,
      },
      "& > .MuiIconButton-root": {
        position: "absolute",
        bottom: 5,
        right: 5,
        border: "3.5px solid rgba(0, 0, 0, 0.54)",
        "& > svg": {
          fontSize: "1.5rem",
        },
        "&:hover": {
          borderColor: theme.palette.primary.main,
          "& > svg": {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },
  infoContent: {
    width: 500,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
      },
      "& > .MuiFormControl-root": {
        marginBottom: 10,
        "& > .MuiInputBase-root": {
          color: theme.palette.text.primary + "!important",
          "& > input": {
            textAlign: "right",
            WebkitTextFillColor: "unset",
          },
        },
      },
    },
  },
  locationContent: {
    width: 600,
    padding: 20,
    "& > h1": {
      userSelect: "none",
      fontSize: 22,
      fontWeight: "bold",
      color: theme.palette.primary.main,
      marginBottom: 25,
    },
    "& > div": {
      width: "95%",
      marginLeft: "5%",
      "& > div.subcontent": {
        display: "flex",
        justifyContent: "space-between",
        "& > div": {
          width: "45%",
          "& > p": {
            userSelect: "none",
            fontSize: 15,
            fontWeight: "bold",
          },
          "& > .MuiFormControl-root": {
            marginBottom: 15,
          },
        },
      },
      "& > p": {
        userSelect: "none",
        fontSize: 15,
        fontWeight: "bold",
      },
      "& > .MuiFormControl-root": {
        marginBottom: 15,
      },
      "& > button": {
        marginTop: 15,
        padding: ".5em 1em",
        fontSize: "1.125rem",
        fontWeight: "bold",
      },
    },
  },
});

const InfoContainer = () => {
  const classes = useStyles(styles);
  const { t } = useTranslation();
  const { loginToken } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [guid, setGuid] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState(false);
  const [groupNameHelperText, setGroupNameHelperText] = useState<string | null>(
    ""
  );
  const [initGroupData, setInitGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      puid: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });
  const [groupData, setGroupData] = useState<{ [key: string]: any }>({
    id: "",
    name: "",
    manager: {
      id: "",
      puid: "",
      username: "",
      image: undefined,
      name: "",
      email: "",
      phone: "",
    },
    image: undefined,
    address1: "",
    address2: "",
    address3: "",
    zip_code: "",
    country: "",
    city: "",
    state: "",
    count_product: 0,
    limit_product: 0,
    count_staff: 0,
    limit_staff: 0,
    created: null,
    expired: null,
  });
  const [tokenData, setTokenData] = useState<TokenData>({
    id: null,
    puid: undefined,
    guid: undefined,
    name: undefined,
    username: undefined,
    group: null,
    image: undefined,
    is_staff: false,
    is_manager: false,
    is_superuser: false,
  });

  const groupInfoQuery = useQuery(
    ["groupInfo", guid],
    () => {
      if (guid !== undefined) {
        dispatch(loadingOn());
        return getGroupInfo(guid);
      }
    },
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      onSettled: () => {
        dispatch(loadingOff());
      },
    }
  );
  const checkGroupMutation = useMutation(checkGroupDuplicate, {
    onSuccess: (response) => {
      if (response.data.guid !== null) {
        setGroupNameHelperText(t("GROUP.MSG.FOUND_GROUP"));
      } else {
        setGroupNameHelperText(t("GROUP.MSG.NOT_FOUND_GROUP"));
        setGroupNameError(true);
      }
      setGuid(response.data.guid);
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });
  const patchGroupInfoMutation = useMutation(patchGroupInfo, {
    onSuccess: () => {
      enqueueSnackbar(t("DIALOG.200.SUCCESS_PATCH_GROUP_INFO"), {
        variant: "success",
      });
    },
    onError: (error) => {
      onError(error);
    },
    onSettled: () => {
      dispatch(loadingOff());
    },
  });

  const handleOnSearch = async () => {
    if (isNull(groupName)) {
      setGroupNameError(true);
      setGroupNameHelperText(t("GROUP.MSG.INPUT_GROUP_NAME"));
    } else {
      checkGroupMutation.mutateAsync({ data: { name: groupName } });
    }
  };
  const handleOnSubmit = () => {
    dispatch(
      setConfirmText({
        title: t("DIALOG.TITLE.CONFIRM_MODIFY"),
        content: t("DIALOG.CONTENT.NOTICE_MODIFY"),
        onApply: async () => {
          const sendData: { [key: string]: any } = {};
          Object.keys(groupData).forEach((key: string) => {
            if (
              ![
                "id",
                "name",
                "manager",
                "count_product",
                "limit_product",
                "count_staff",
                "limit_staff",
                "created",
                "expired",
              ].includes(key)
            ) {
              if (initGroupData[key] !== groupData[key]) {
                sendData[key] = groupData[key];
              }
            }
          });

          if (Object.keys(sendData).includes("image")) {
            const fileData = await resizeImage(
              sendData["image"],
              320,
              240,
              100,
              0,
              "blob"
            );
            const blobData = fileData as BlobPart;
            sendData["image"] = new File([blobData], uuidv4() + ".jpg", {
              type: "image/jpg",
            });
          }

          if (guid !== undefined) {
            dispatch(loadingOn());
            patchGroupInfoMutation.mutate({
              guid: guid,
              data: sendData,
            });
          }
        },
        onClose: () => {},
      })
    );
  };

  useEffect(() => {
    setTokenData(getTokenData());
  }, [loginToken]);

  useEffect(() => {
    if (
      tokenData["guid"] !== undefined &&
      !tokenData["is_superuser"] &&
      (tokenData["is_manager"] || tokenData["is_staff"])
    ) {
      setGuid(tokenData["guid"]);
    }
  }, [tokenData]);

  useEffect(() => {
    if (isNotNull(guid)) {
      groupInfoQuery.refetch();
    }
  }, [guid]);

  useEffect(() => {
    if (groupInfoQuery.isSuccess) {
      if (groupInfoQuery.data !== undefined) {
        const result = groupInfoQuery.data.data;

        const recvData: { [key: string]: any } = {};
        recvData["id"] = result["id"];
        recvData["name"] = result["name"];
        recvData["manager"] = {
          id: result["manager"]["id"],
          puid: result["manager"]["puid"],
          name: result["manager"]["name"],
          username: result["manager"]["username"],
          image: result["manager"]["image"],
          email: result["manager"]["email"],
          phone: result["manager"]["phone"],
        };
        recvData["image"] = result["image"];
        recvData["address1"] = isNotNull(result["address1"])
          ? result["address1"]
          : "";
        recvData["address2"] = isNotNull(result["address2"])
          ? result["address2"]
          : "";
        recvData["address3"] = isNotNull(result["address3"])
          ? result["address3"]
          : "";
        recvData["zip_code"] = isNotNull(result["zip_code"])
          ? result["zip_code"]
          : "";
        recvData["country"] = isNotNull(result["country"])
          ? result["country"]
          : "";
        recvData["city"] = isNotNull(result["city"]) ? result["city"] : "";
        recvData["state"] = isNotNull(result["state"]) ? result["state"] : "";
        recvData["count_product"] = result["count_product"];
        recvData["limit_product"] = result["limit_product"];
        recvData["count_staff"] = result["count_staff"];
        recvData["limit_staff"] = result["limit_staff"];
        recvData["created"] = result["created"];
        recvData["expired"] = result["expired"];

        setInitGroupData(cloneDeep(recvData));
        setGroupData(cloneDeep(recvData));
      } else {
        setGuid("");
      }
    }
  }, [groupInfoQuery.isLoading]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className="title">
          <ViewListIcon />
          <Typography>{t("GROUP.TITLE_INFO")}</Typography>
        </div>
        <div className="content">
          <ul>
            <li>
              <Markup content={t("GROUP.MSG.REGISTER_NOTICE_1")} />
            </li>
            <li>
              <Markup content={t("GROUP.MSG.REGISTER_NOTICE_2")} />
            </li>
            <li className="listStyleNone">
              {t("GROUP.MSG.REGISTER_NOTICE_3")}
            </li>
          </ul>
        </div>
      </div>
      {isNull(groupData["id"]) ? (
        <div className={classes.searchContainer}>
          <Paper>
            <div className="title">
              <SearchIcon />
              <div>
                <Typography variant="body1">
                  {t("STAFF.SEARCH_GROUP")}
                </Typography>
                <Typography variant="body2">
                  {t("STAFF.MSG.INPUT_GROUP_NAME")}
                </Typography>
              </div>
            </div>
            <div className="content">
              <Input
                label={t("STAFF.GROUP_NAME")}
                value={groupName}
                dispatch={setGroupName}
                error={groupNameError}
                helperText={groupNameHelperText}
                inputProps={{
                  maxLength: 32,
                }}
                disabled={!tokenData["is_superuser"]}
              />
              <Button
                variant="outlined"
                onClick={() => handleOnSearch()}
                disabled={!tokenData["is_superuser"]}
              >
                {t("BUTTON.INQUIRE")}
              </Button>
            </div>
          </Paper>
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.imageContent}>
            <div>
              {isNull(groupData["image"]) ? (
                <AccountCircleIcon
                  onDoubleClick={() => {
                    document.getElementById("imageUpload")?.click();
                  }}
                />
              ) : (
                <Image
                  alt={""}
                  src={groupData["image"]}
                  onDoubleClick={() => {
                    document.getElementById("imageUpload")?.click();
                  }}
                />
              )}
              <IconButton
                onClick={() => {
                  document.getElementById("imageUpload")?.click();
                }}
              >
                <CollectionsIcon />
              </IconButton>
              <input
                type="file"
                id="imageUpload"
                name="imageUpload"
                accept="image/jpg, image/gif, image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={async (e: any) => {
                  const resizeFile = await resizeImage(
                    e.target.files[0],
                    320,
                    240,
                    75,
                    0,
                    "file"
                  );
                  setGroupData({
                    ...groupData,
                    image: resizeFile,
                  });
                }}
              />
            </div>
          </div>
          <div className={classes.infoContent}>
            <Typography component="h1">Group Info</Typography>
            <div>
              <Typography className="unique-value">Group Name</Typography>
              <Input
                disabled
                variant="standard"
                value={groupData["name"]}
                fullWidth
              />
              <Typography>Group Manager</Typography>
              <Input
                disabled
                variant="standard"
                value={groupData["manager"]["name"]}
                fullWidth
              />
              <Typography>Group Create Date</Typography>
              <Input
                variant="standard"
                disabled
                value={groupData["created"]}
                fullWidth
              />
              <Typography>Staff Count</Typography>
              <Input
                variant="standard"
                disabled
                value={t("GROUP.STAFF_COUNT_SIMPLE", {
                  staff: groupData["count_staff"].toLocaleString(),
                  limit: groupData["limit_staff"].toLocaleString(),
                })}
                fullWidth
              />
              <Typography>Product Count</Typography>
              <Input
                variant="standard"
                disabled
                value={t("GROUP.PRODUCT_COUNT_SIMPLE", {
                  product: groupData["count_product"].toLocaleString(),
                  limit: groupData["limit_product"].toLocaleString(),
                })}
                fullWidth
              />
            </div>
          </div>
          <div className={classes.locationContent}>
            <Typography component="h1">Group Location</Typography>
            <div>
              <Typography>Address</Typography>
              <Input
                variant="standard"
                value={groupData["address1"]}
                dispatch={(value: string) => {
                  setGroupData({
                    ...groupData,
                    address1: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
              <Typography>Address 2</Typography>
              <Input
                variant="standard"
                value={groupData["address2"]}
                dispatch={(value: string) => {
                  setGroupData({
                    ...groupData,
                    address2: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
              <Typography>Address 3</Typography>
              <Input
                variant="standard"
                value={groupData["address3"]}
                dispatch={(value: string) => {
                  setGroupData({
                    ...groupData,
                    address3: value,
                  });
                }}
                fullWidth
                inputProps={{
                  maxLength: 80,
                }}
              />
            </div>
            <div>
              <div className="subcontent">
                <div>
                  <Typography>City</Typography>
                  <Input
                    variant="standard"
                    value={groupData["city"]}
                    dispatch={(value: string) => {
                      setGroupData({
                        ...groupData,
                        city: value,
                      });
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </div>
                <div>
                  <Typography>State</Typography>
                  <Input
                    variant="standard"
                    value={groupData["state"]}
                    dispatch={(value: string) => {
                      setGroupData({
                        ...groupData,
                        state: value,
                      });
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </div>
              </div>
              <div className="subcontent">
                <div>
                  <Typography>Country</Typography>
                  <NationAutocomplete
                    variant="standard"
                    value={groupData["country"]}
                    dispatch={(value: string) => {
                      setGroupData({
                        ...groupData,
                        country: value,
                      });
                    }}
                    fullWidth
                  />
                </div>
                <div>
                  <Typography>Zip Code</Typography>
                  <Input
                    variant="standard"
                    value={groupData["zip_code"]}
                    dispatch={(value: string) => {
                      setGroupData({
                        ...groupData,
                        zip_code: value,
                      });
                    }}
                    fullWidth
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => handleOnSubmit()}
                fullWidth
              >
                {t("BUTTON.MODIFY")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoContainer;
