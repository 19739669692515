/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Redux
import { setLoginToken } from "@/common/lib/redux/actions/authAction";
import {
  setAccessURL,
  setErrorText,
} from "@/common/lib/redux/actions/commonAction";
import { useTypedDispatch, useTypedSelector } from "@/common/lib/redux/store";
// Components
import { Paper, Theme, Typography } from "@mui/material";
import { Button, Dropdown, Input } from "@/common/components";
import { CompanyLogoIcon } from "@/common/icons";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// API
import { onError } from "@/common/lib/api/common";
import { loginAPI } from "@/common/lib/api/auth";
// Libs
import i18n from "@/common/lib/lang/i18n";
import { isNull, isNotNull, parseJWT } from "@/common/lib/common";

const styles = (theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100vw",
    minHeight: "100vh !important",
    userSelect: "none",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 400,
    padding: 24,
    borderRadius: 10,
  },
  logo: {
    width: 275,
    fill: theme.palette.logo.blue,
    marginBottom: 7.5,
  },
  input: {
    width: 320,
    margin: "3px 0",
  },
  button: {
    width: 320,
    margin: "10px 0 !important",
    fontSize: "1.125rem !important",
    fontWeight: "bold !important",
  },
  content: {
    width: 320,
    "& > div:first-child": {
      display: "flex",
      alignItems: "center",
      marginBottom: 5,
    },
    "& > div:last-child": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  text: {
    fontSize: "0.85rem",
    color: theme.palette.text.secondary,
  },
  dropdownFormControl: {
    width: 72.5,
    fontSize: "14px !important",
  },
  dropdownSelect: {
    height: "27.5px !important",
    letterSpacing: "-0.75px",
  },
  dropdownMenuItem: {
    fontSize: 14,
    letterSpacing: "-0.75px",
  },
});

const LoginPage = () => {
  const classes = useStyles(styles);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { loginToken } = useTypedSelector((state) => state.auth);
  const { init, accessURL } = useTypedSelector((state) => state.common);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const langList = useMemo(() => {
    const i18nLangList: Option[] = [];
    if (i18n.options.resources) {
      for (const [key, value] of Object.entries(i18n.options.resources)) {
        i18nLangList.push({ label: String(value.label), value: key });
      }
    }
    return i18nLangList;
  }, []);

  const loginMutation = useMutation(loginAPI, {
    onSuccess: (response) => {
      if (Object.keys(response.data).includes("access_token")) {
        const tokenData = parseJWT(response.data.access_token);
        if (
          tokenData.is_staff ||
          tokenData.is_manager ||
          tokenData.is_superuser
        ) {
          dispatch(setLoginToken(response.data.access_token));
        } else {
          dispatch(
            setErrorText({
              title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
              content: t("DIALOG.CONTENT.MANAGER_ONLY_PAGE"),
            })
          );
        }
      } else {
        dispatch(
          setErrorText({
            title: t("DIALOG.TITLE.AUTH_SERVER_NOTICE"),
            content: t("DIALOG.404.FAIL_TO_GET_TOKEN"),
          })
        );
      }
    },
    onError: (error) => {
      onError(error);
    },
  });

  const handleOnSubmit = () => {
    if (isNull(username) || isNull(password)) {
      dispatch(
        setErrorText({
          title: t("DIALOG.404.FAIL_TO_LOGIN"),
          content: t("DIALOG.CONTENT.INPUT_ID_PASSWORD"),
        })
      );
    } else {
      loginMutation.mutate({
        type: "WEB",
        username: username,
        password: password,
      });
    }
  };

  useEffect(() => {
    if (init && isNotNull(loginToken)) {
      if (isNotNull(accessURL)) {
        navigate(accessURL);
        dispatch(setAccessURL(""));
      } else {
        navigate("/index");
      }
    }
  }, [init, loginToken]);

  return isNull(loginToken) ? (
    <div className={classes.container}>
      <Paper classes={{ root: classes.paper }} square={true}>
        <CompanyLogoIcon className={classes.logo} />
        <Input
          className={classes.input}
          variant="standard"
          placeholder="ID"
          value={username}
          dispatch={setUsername}
        />
        <Input
          className={classes.input}
          type="password"
          variant="standard"
          placeholder="PASSWORD"
          value={password}
          dispatch={setPassword}
        />
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          onClick={handleOnSubmit}
        >
          {t("MENU.LOGIN")}
        </Button>
        <div className={classes.content}>
          <div>
            <Typography className={classes.text}>
              {t("MSG.MANAGEMENT_PAGE")}
            </Typography>
          </div>
          <div>
            <Dropdown
              id={"login-language"}
              labelId={"login-language-label"}
              value={i18n.language}
              options={langList}
              dispatch={i18n.changeLanguage}
              formControlClassName={classes.dropdownFormControl}
              selectClassName={classes.dropdownSelect}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </div>
        </div>
      </Paper>
    </div>
  ) : (
    <></>
  );
};

export default LoginPage;
