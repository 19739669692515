/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
// Components
import { Theme, Typography, InputAdornment } from "@mui/material";
import { Dropdown, Input } from "@/common/components";
import RequestQuoteSharpIcon from "@mui/icons-material/RequestQuoteSharp";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
import { colors } from "@/common/lib/style/palette";
import "react-datepicker/dist/react-datepicker.css";
// Libs
import { locationList } from "@/common/lib/varList";

const styles = (theme: Theme) => ({
  table: {
    width: 950,
    borderCollapse: "collapse",
    borderSpacing: 0,
    borderTop: "2px solid " + theme.palette.text.primary,
    margin: "45px auto",
    userSelect: "none",
    "& th": {
      backgroundColor: colors.gray[1],
      fontSize: "0.925rem",
      fontWeight: "normal",
      textAlign: "left",
      "& .title": {
        textAlign: "center",
      },
    },
    "& th, & td": {
      padding: "15px 17.5px",
      border: "1px solid " + theme.palette.divider,
      wordBreak: "keep-all",
      "&.require-value::after": {
        content: "'*'",
        color: theme.palette.error.main,
        padding: 3,
      },
    },
    "& td.title": {
      border: "1px solid " + theme.palette.divider,
      padding: "20px 35px",
      "& > div": {
        display: "flex",
        alignItems: "center",
        "& > svg": {
          fontSize: "5rem",
          color: theme.palette.primary.main,
          marginLeft: 15,
          marginRight: 25,
        },
        "& > div": {
          width: "100%",
          userSelect: "none",
          "& > .MuiTypography-body1 > span": {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: theme.palette.text.primary,
            "& > span": {
              fontSize: "1.15rem",
            },
          },
          "& > .MuiTypography-body2": {
            fontSize: "1rem",
            lineHeight: "1.35",
            "& > span": {
              color: theme.palette.primary.main,
              fontSize: "0.85rem",
            },
          },
          "& > .MuiTypography-caption": {
            display: "block",
            color: theme.palette.error.main,
            fontSize: "0.875rem",
            fontWeight: "bold",
          },
        },
      },
    },
    "& td.price": {
      "& > .MuiTextField-root": {
        width: 175,
        fontSize: "0.875rem !important",
      },
    },
    "& td.location": {
      display: "flex",
      alignItems: "center",
      "& > span": {
        margin: "0 15px 0 5px",
        fontSize: "0.95rem",
        fontWeight: 600,
      },
    },
    "& td.advertText": {
      position: "relative",
      "& > p": {
        position: "absolute",
        left: 420,
        bottom: 18.5,
        fontSize: "0.875rem",
      },
      "& > .MuiTextField-root": {
        width: 475,
        fontSize: "0.875rem !important",
      },
    },
    "& td.advertExplain": {
      position: "relative",
      "& > p": {
        position: "absolute",
        right: 30,
        bottom: 25,
        fontSize: "0.875rem",
      },
      "& > .MuiTextField-root": {
        width: "100%",
        fontSize: "0.875rem !important",
      },
    },
  },
  dropdownFormControl: {
    width: 175,
    fontSize: "0.875rem !important",
  },
  dropdownMenuItem: {
    fontSize: "0.875rem !important",
  },
});

interface propType {
  value: VehicleData;
  dispatch: React.Dispatch<React.SetStateAction<VehicleData>>;
}

const SalesContainer = ({ value, dispatch }: propType) => {
  const classes = useStyles(styles);
  const { t } = useTranslation();

  return (
    <table className={classes.table}>
      <colgroup>
        <col style={{ width: "17.5%" }} />
        <col style={{ width: "82.5%" }} />
      </colgroup>
      <thead></thead>
      <tbody>
        <tr>
          <td colSpan={2} className="title">
            <div>
              <RequestQuoteSharpIcon />
              <div>
                <Typography variant="body1">
                  <Markup content={t("REGISTER.SALES.SALES.TITLE")} />
                </Typography>
                <Typography variant="body2">
                  {t("REGISTER.SALES.SALES.CONTENT_1")}
                </Typography>
                <Typography variant="caption">
                  {t("REGISTER.SALES.SALES.CONTENT_2")}
                </Typography>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.SALES.PRICE")}</th>
          <td className="price">
            <Input
              type="integer"
              variant="standard"
              value={value.sales.price}
              dispatch={(_value: number) => {
                dispatch({
                  ...value,
                  sales: {
                    ...value.sales,
                    price: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">USD</InputAdornment>
                ),
              }}
            />
          </td>
        </tr>
        <tr>
          <th className="require-value">{t("VEHICLE.SALES.LOCATION")}</th>
          <td className="location">
            <img
              loading="lazy"
              width="25"
              src={`https://flagcdn.com/w40/kr.png`}
              srcSet={`https://flagcdn.com/w40/kr.png 2x`}
              alt=""
            />
            <span>South Korea</span>
            <Dropdown
              id={"sales-location"}
              labelId={"sales-location"}
              label={t("VEHICLE.SALES.LOCATION")}
              value={value.sales.location_region}
              options={locationList}
              dispatch={(_value: string) =>
                dispatch({
                  ...value,
                  sales: {
                    ...value.sales,
                    location_country: "South Korea",
                    location_region: _value,
                  },
                })
              }
              formControlClassName={classes.dropdownFormControl}
              menuItemClassName={classes.dropdownMenuItem}
            />
          </td>
        </tr>
        <tr>
          <th>{t("REGISTER.SALES.SALES.ADVERT_TEXT")}</th>
          <td className="advertText">
            <Input
              variant="standard"
              value={value.sales.advert_text}
              dispatch={(_value: string) => {
                dispatch({
                  ...value,
                  sales: {
                    ...value.sales,
                    advert_text: _value,
                  },
                });
              }}
              inputProps={{
                maxLength: 30,
              }}
            />
            <Typography>
              {value.sales.advert_text === undefined
                ? "0/30 Byte"
                : value.sales.advert_text.length + "/30 Byte"}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>{t("REGISTER.SALES.SALES.SELLER_COMMENT")}</th>
          <td className="advertExplain">
            <Input
              variant="outlined"
              value={value.sales.advert_explain}
              dispatch={(_value: string) => {
                dispatch({
                  ...value,
                  sales: {
                    ...value.sales,
                    advert_explain: _value,
                  },
                });
              }}
              multiline
              minRows={15}
              maxRows={15}
              inputProps={{
                maxLength: 3000,
              }}
            />
            <Typography>
              {value.sales.advert_explain === undefined
                ? "0/3000 Byte"
                : value.sales.advert_explain.length + "/3000 Byte"}
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SalesContainer;
