import { useMemo } from "react";
// Components
import { Theme } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { Dropdown } from "@/common/components";
// Styles
import { useStyles } from "@/common/lib/style/hooks";
// Libs
import i18n from "@/common/lib/lang/i18n";

const styles = (theme: Theme) => ({
  container: {
    minWidth: 1600,
    backgroundColor: theme.palette.header.main,
  },
  menu: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 1450,
    height: 40,
    marginLeft: 75,
  },
  languageIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: 22.5,
    marginRight: 7.5,
  },
  dropdownFormControl: {
    width: 72.5,
    fontSize: "14px !important",
  },
  dropdownSelect: {
    height: "27.5px !important",
    letterSpacing: "-0.75px",
    backgroundColor: theme.palette.grey[100],
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.hard,
    },
    "& .MuiSelect-select": {
      color: theme.palette.primary.hard,
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.hard,
    },
  },
  dropdownMenuItem: {
    fontSize: 14,
    letterSpacing: "-0.75px",
  },
});

const Header = () => {
  const classes = useStyles(styles);

  const langList = useMemo(() => {
    const i18nLangList: Option[] = [];
    if (i18n.options.resources) {
      for (const [key, value] of Object.entries(i18n.options.resources)) {
        i18nLangList.push({ label: String(value.label), value: key });
      }
    }
    return i18nLangList;
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.menu}>
        <LanguageIcon className={classes.languageIcon} />
        <Dropdown
          id={"header-language"}
          labelId={"header-language-label"}
          value={i18n.language}
          options={langList}
          dispatch={i18n.changeLanguage}
          formControlClassName={classes.dropdownFormControl}
          selectClassName={classes.dropdownSelect}
          menuItemClassName={classes.dropdownMenuItem}
        />
      </div>
    </div>
  );
};

export default Header;
